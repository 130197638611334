import { Box, Button, HStack, Spacer, VStack, useDisclosure } from '@chakra-ui/react'
import { Modality } from '@le2/jfd'
import { BioType } from '@le2/jfd'
import { TrashOutline } from 'heroicons-react'
import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'

import FrontFaceDefault from '../../../../assets/front-face.svg'
import goalposts from '../../../../assets/goalposts.svg'
import RightProfileDefault from '../../../../assets/right-profile.svg'
import ConfirmDialog from '../../../../components/ConfirmDialog'
import EditPhotoModalContents from '../../../../components/EditPhotoModalContents'
import FileSelectorModal from '../../../../components/FileSelectorModal'
import PhotoDisplayWithMenu from '../../../../components/PhotoDisplayWithMenu'
import { FaceCaptureImage, replaceImgByGroupDateTimeBioType, useDownloadImage } from '../../../../lib/api/record'
import { acceptedFileExtensions, maximumFileSize } from '../../../../lib/fileUtils'
import { HubStatus } from '../../../../nativeExtension/IWNativeExtDeviceDefines'
import { faceConfiguration } from '../../../../nativeExtension/configuration'
import { useDeviceManager } from '../../../../nativeExtension/hooks'
import { nativeExtensionsState } from '../../../../nativeExtension/nativeextState'
import { FaceCaptureModal } from '../../../booking/faceCapture/FaceCaptureModal'
import { getExportedFilename, validateImagesLimitRate } from '../../../booking/utils'
import { useIsReadOnlyRecord } from '../../hooks/faceCapture/useIsReadOnlyRecord'
import useToggleLoadFromFile from '../../hooks/faceCapture/useToggleLoadFromFile'
import { recordState } from '../../state'

interface AppearanceContainerProps {
  groupDateTime: Date
  index: number
  deleteAppearance: (groupDateTime: Date, index: number) => void
}

export function AppearanceContainer({ groupDateTime, deleteAppearance, index }: AppearanceContainerProps) {
  const { recordImages: recordImagesSnap, isLoadFromFile: isLoadFromFileSnap } = useSnapshot(recordState)
  const appearanceSnap = recordImagesSnap.getMappedImagesByGroupDateTime(groupDateTime) as Map<
    BioType,
    FaceCaptureImage
  >
  const isReadOnly = useIsReadOnlyRecord()

  useToggleLoadFromFile(recordState)

  useEffect(() => {
    recordState.maxImages.Face = validateImagesLimitRate(Modality.Face)
  }, [recordImagesSnap])

  function onCaptureDeleted(capturedAt: Date) {
    /*
     Delete image from local state, cancel the last request if there is one or delete
     the image from the server if it is uploaded
    */
    recordState.recordImages.deleteImage(capturedAt)
  }

  function onCaptureCompleted(imageSrc: string, bioType: BioType) {
    /*
     Cancel the last request if there is one or delete the image from the server
     if it is uploaded, set Image in local state, upload the image to the server
    */
    recordState.recordImages.setImage(imageSrc, bioType, { groupDateTime }, undefined, replaceImgByGroupDateTimeBioType)
  }

  // replace an image with a new one
  function onRetakeCaptureCompleted(imageSrc: string, bioType: BioType) {
    const snapImage = appearanceSnap.get(bioType)
    if (snapImage) {
      const imageToBeReplaced = recordState.recordImages.getImage(snapImage.capturedAt)
      if (imageToBeReplaced) {
        recordState.recordImages.replaceImage(imageToBeReplaced, imageSrc, bioType, { groupDateTime })
      }
    }
  }

  function handleDelete() {
    deleteAppearance(groupDateTime, index)
  }

  return (
    <VStack mt={4}>
      <HStack width="100%">
        <AppearanceImageContainer
          faceSnapImage={appearanceSnap.get(BioType.FaceFront)}
          defaultImage={FrontFaceDefault}
          title="Front"
          bioType={BioType.FaceFront}
          onCaptureCompleted={onCaptureCompleted}
          onRetakeCaptureCompleted={onRetakeCaptureCompleted}
          onCaptureDeleted={onCaptureDeleted}
          isLoadFromFile={isLoadFromFileSnap}
          isRequired
        />
        <AppearanceImageContainer
          faceSnapImage={appearanceSnap.get(BioType.FaceRight90)}
          defaultImage={RightProfileDefault}
          title="Right"
          bioType={BioType.FaceRight90}
          onCaptureCompleted={onCaptureCompleted}
          onRetakeCaptureCompleted={onRetakeCaptureCompleted}
          onCaptureDeleted={onCaptureDeleted}
          isLoadFromFile={isLoadFromFileSnap}
        />
      </HStack>
      <HStack w="100%">
        <Spacer />
        <Button
          leftIcon={<TrashOutline />}
          alt="trash symbol"
          variant="ghost"
          pl={2}
          color="gray.500"
          onClick={handleDelete}
          isDisabled={isReadOnly}
        >
          Delete Appearance
        </Button>
      </HStack>
    </VStack>
  )
}

interface AppearanceImageContainerProps {
  faceSnapImage?: FaceCaptureImage
  defaultImage: string
  title: string
  bioType: BioType
  isLoadFromFile: boolean
  isRequired?: boolean
  onCaptureCompleted: (image: string, bioType: BioType) => void
  onRetakeCaptureCompleted: (imageSrc: string, bioType: BioType) => void
  onCaptureDeleted: (capturedAt: Date) => void
}

function AppearanceImageContainer(props: AppearanceImageContainerProps) {
  const [isRetakeImage, setIsRetakeImage] = useState(false)
  const { recordNumber, hasSaveButtonBeenClicked, maxImages: maxImagesSnap } = useSnapshot(recordState)
  const faceCaptureImgState = (
    props.faceSnapImage ? recordState.recordImages.getImage(props.faceSnapImage.capturedAt) : null
  ) as FaceCaptureImage | null

  const { deviceHubStatus: hubStatusSnap } = useSnapshot(nativeExtensionsState)

  const getDeviceManagerProps = useDeviceManager(faceConfiguration, [], {})

  useDownloadImage(faceCaptureImgState)
  const isReadOnly = useIsReadOnlyRecord()

  const hasError =
    (props.isRequired && !props.faceSnapImage && hasSaveButtonBeenClicked) ||
    !!props.faceSnapImage?.image.hasUploadFailed

  const { isOpen: isDeleteOpen, onOpen: onDeleteOpen, onClose: onDeleteClose } = useDisclosure()
  const { isOpen: isFaceCaptureOpen, onOpen: onFaceCaptureOpen, onClose: onFaceCaptureClose } = useDisclosure()

  const deviceManagerProps = getDeviceManagerProps({
    sendDeviceCommand: () => {}
  })

  function deleteImage() {
    if (props.faceSnapImage) {
      props.onCaptureDeleted(props.faceSnapImage.capturedAt)
    }
  }

  function onRetakeImage() {
    setIsRetakeImage(true)
    try {
      console.debug(
        `Record Face Appearance::onRetakeImage(), isLoadFromFile: ${props.isLoadFromFile}, deviceManagerProps.isLoadFromFile: ${deviceManagerProps.isLoadFromFile}}`
      )
      // we want to open a session with the device only if it is not the LoadFromFile plugin
      if (
        (HubStatus.Connected === hubStatusSnap || HubStatus.Reconnected === hubStatusSnap) &&
        false === deviceManagerProps?.isLoadFromFile
      ) {
        deviceManagerProps?.startDevice()
      }
    } catch (ex) {
      console.error(`Record Face Appearance::onRetakeImage(), startDevice, exception: ${ex}`)
    }
    onFaceCaptureOpen()
  }

  function onCaptureCompleted(imageSrc: string): void {
    props.onCaptureCompleted(imageSrc, props.bioType)
  }

  function onRetakeCaptureCompleted(imageSrc: string): void {
    setIsRetakeImage(false)
    if (props.faceSnapImage) {
      props.onRetakeCaptureCompleted(imageSrc, props.bioType)
    }
  }

  function onClose() {
    try {
      if (
        (HubStatus.Connected === hubStatusSnap || HubStatus.Reconnected === hubStatusSnap) &&
        deviceManagerProps?.isDeviceOpen
      ) {
        deviceManagerProps.stopDevice()
      }
    } catch (ex) {
      console.error(`Face Appearance::onClose(), stopDevice, exception: ${ex}`)
    }
    onFaceCaptureClose()
  }

  function onStartCapture() {
    try {
      console.debug(
        `Face Appearance::onStartCapture(), isLoadFromFile: ${props.isLoadFromFile}, deviceManagerProps.isLoadFromFile: ${deviceManagerProps.isLoadFromFile}}`
      )
      // we want to open a session with the device only if it is not the LoadFromFile plugin
      if (
        (HubStatus.Connected === hubStatusSnap || HubStatus.Reconnected === hubStatusSnap) &&
        false === deviceManagerProps?.isLoadFromFile
      ) {
        deviceManagerProps?.startDevice()
      }
    } catch (ex) {
      console.error(`Face Appearance::onStartCapture(), startDevice, exception: ${ex}`)
    }
    onFaceCaptureOpen()
  }

  let src
  if (faceCaptureImgState?.image && !faceCaptureImgState.image.isURL) {
    src = faceCaptureImgState.image.src
  }

  return (
    <Box width="33%" px={5}>
      <PhotoDisplayWithMenu
        src={src}
        defaultSrc={props.defaultImage}
        overlay={props.faceSnapImage ? undefined : goalposts}
        hasError={hasError}
        aspectRatio={4 / 5}
        onDelete={onDeleteOpen}
        // onTakePhoto={onFaceCaptureOpen}  // previous implementation commented-out but left as a reference
        onTakePhoto={onStartCapture}
        onRetakeImage={onRetakeImage}
        handleUpload={() => faceCaptureImgState?.image?.upload()}
        isUploading={!!props.faceSnapImage?.image?.isUploading}
        hasUploadError={!!props.faceSnapImage?.image?.hasUploadFailed}
        uploadErrorMessage="There was a problem uploading the image"
        label={props.title}
        isRequired={props.isRequired}
        isReadOnly={isReadOnly}
        isCameraDisabled={maxImagesSnap.Face}
        bookingImage={faceCaptureImgState}
        exportedFileName={getExportedFilename(recordNumber, faceCaptureImgState)}
      />
      <ConfirmDialog
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        onConfirm={deleteImage}
        header="Delete the image?"
        cancelText="Keep it"
        confirmText="Delete it"
      />
      {props.isLoadFromFile ? (
        <FileSelectorModal
          onComplete={isRetakeImage ? onRetakeCaptureCompleted : onCaptureCompleted}
          isOpen={isFaceCaptureOpen}
          onClose={onFaceCaptureClose}
          acceptedFileExtensions={acceptedFileExtensions}
          maximumFileSize={maximumFileSize}
          nextView={({ imageSrc, finishCapture, reCapture, cancelCapture }) => (
            <EditPhotoModalContents
              imageSrc={imageSrc}
              onFinishCapture={finishCapture}
              onDismiss={cancelCapture}
              goToTakePhotoScreen={reCapture}
              bioType={props.bioType}
            />
          )}
        />
      ) : (
        <FaceCaptureModal
          onComplete={isRetakeImage ? onRetakeCaptureCompleted : onCaptureCompleted}
          bioType={props.bioType}
          isOpen={isFaceCaptureOpen}
          // onClose={onFaceCaptureClose}  // previous implementation commented-out but left as a reference
          onClose={onClose}
          deviceManagerProps={deviceManagerProps}
        />
      )}
    </Box>
  )
}
