import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { ExclamationCircleOutline } from 'heroicons-react'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'

import ReactSelectControl from '../../../components/ReactSelectControl'
import { NativeExtensionsContext } from '../../../contexts/NativeExtensionsContext'
import { HubStatus } from '../../../nativeExtension/IWNativeExtDeviceDefines'
import { CardsType } from '../../cards/types'
import { RecordReportType } from './RecordPrintReport'

interface PrintSelectionModalProps {
  onClose: () => void
  onConfirm: () => void
  setRecordReportType: React.Dispatch<React.SetStateAction<RecordReportType>>
  isOpen: boolean
  credentials: CardsType[]
  recordReportType: RecordReportType | null
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  downloadedPercent?: number
  hasError: boolean
  onRetry: () => void
  setOnPrintIdName: (data: string) => void
}

interface CardDatabaseSelectOption {
  value: number
  label: string
}

export default function PrintSelectionModal({
  isOpen,
  onClose,
  recordReportType,
  setRecordReportType,
  onConfirm,
  downloadedPercent = 0,
  isLoading,
  setIsLoading,
  hasError,
  onRetry,
  credentials,
  setOnPrintIdName
}: PrintSelectionModalProps) {
  const { formState, control, handleSubmit } = useForm()

  const [finishCapturestationLoading, setFinishCapturestationLoading] = React.useState(false)
  const [enaableIDCardSelection, setEnableIDCardSelection] = React.useState(false)

  // Get the card print manager status from the native extensions global context object
  // (make sure that the native extensions context provider wraps any root callers of this component)
  const { printHubStatus } = useContext(NativeExtensionsContext)

  // Checks if the connection with NativeExts was successful.
  React.useEffect(() => {
    if (printHubStatus === HubStatus.Connected || printHubStatus === HubStatus.Reconnected) {
      setFinishCapturestationLoading(true)
    }
  }, [printHubStatus])

  const filteredCredential: CardsType[] = credentials.filter((cred) => !cred.deletedBy)
  const groupsOptions: CardDatabaseSelectOption[] = filteredCredential.map((credentials) => {
    return { value: credentials.id, label: credentials.displayName === null ? '' : credentials.displayName }
  })

  function handleClose() {
    onClose()
  }

  function onSubmit({ cardtype }: { cardtype: string }) {
    setOnPrintIdName(cardtype)
  }

  const onChangeRecordType = (value: string | number) => {
    setRecordReportType(value as RecordReportType)
    if (value === 'IdCard') setEnableIDCardSelection(true)
    else setEnableIDCardSelection(false)
  }

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box>
          <LoadingOverlay hasError={hasError} show={isLoading} downloadedPercent={downloadedPercent} />
          <ModalHeader>Print options</ModalHeader>
          <ModalBody>
            <RadioGroup value={recordReportType || ''} onChange={(val: string | number) => onChangeRecordType(val)}>
              <SimpleGrid spacing={4}>
                <HStack align="start">
                  <Radio value={RecordReportType.Summary} mt={2}></Radio>
                  <VStack align="start" spacing={0}>
                    <Text fontSize="lg">Summary Record Report</Text>
                    <Text fontSize="sm">Includes all text information and main mugshot</Text>
                  </VStack>
                </HStack>
                <HStack align="start">
                  <Radio value={RecordReportType.Full} mt={2}></Radio>
                  <VStack align="start" spacing={0}>
                    <Text fontSize="lg">Full Record Report</Text>
                    <Text fontSize="sm">Includes all data, mugshots, and SMTs</Text>
                  </VStack>
                </HStack>
                <HStack align="start">
                  <Radio value={RecordReportType.IdCard} mt={2} disabled={!finishCapturestationLoading}></Radio>
                  <VStack align="start" spacing={0}>
                    <Text fontSize="lg" color={!finishCapturestationLoading ? 'gray.400' : 'gray.700'}>
                      Other
                    </Text>
                    {!finishCapturestationLoading && (
                      <Text fontSize="sm" color="gray.400">
                        Native Extensions are required to preview the card design
                      </Text>
                    )}
                    <form id="printIdCardForm" onSubmit={handleSubmit(onSubmit)}>
                      <FormControl w={483} pt={2}>
                        <Controller
                          name="cardtype"
                          key="cardtype"
                          control={control}
                          defaultValue={[]}
                          render={({ value, onChange }) => (
                            <Select<{ value: number; label: string }>
                              isDisabled={!finishCapturestationLoading || !enaableIDCardSelection}
                              placeholder="Card Type"
                              inputId="cardtype"
                              value={groupsOptions.find((opt) => opt.label === value) || null}
                              onChange={(opt) => onChange(opt?.label)}
                              options={groupsOptions}
                              components={{ Control: ReactSelectControl }}
                              isClearable
                            />
                          )}
                        />
                      </FormControl>
                    </form>
                  </VStack>
                </HStack>
              </SimpleGrid>
            </RadioGroup>
          </ModalBody>
        </Box>

        <ModalFooter>
          <Button variant="newSecondary" rounded={'md'} mr={3} onClick={handleClose}>
            Cancel
          </Button>
          {hasError && <Button onClick={onRetry}>Retry</Button>}
          {!hasError && (
            <Button
              disabled={!recordReportType || isLoading}
              variant="newPrimary"
              rounded={'md'}
              onClick={() => {
                setIsLoading(true)
                onConfirm()
              }}
              form="printIdCardForm"
              isLoading={formState.isSubmitting}
              type="submit"
            >
              {recordReportType === RecordReportType.IdCard ? 'Continue' : 'Print'}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

interface LoadingOverlayProps {
  show: boolean
  downloadedPercent: number
  hasError: boolean
}

function LoadingOverlay({ show, downloadedPercent, hasError }: LoadingOverlayProps) {
  return (
    <Box
      style={{
        borderRadius: '20px',
        position: 'absolute' /* Sit on top of the page content */,
        display: show ? 'block' : 'none' /* Hidden by default */,
        width: '100%' /* Full width (cover the whole page) */,
        height: '78%' /* Full height (cover the whole page) */,
        top: 0,
        left: 0,
        right: 'auto',
        bottom: 0,
        backgroundColor: 'rgba(255,255,255, 0.95)' /* Black background with opacity */,
        zIndex: 1800 /* Specify a stack order in case you're using a different order for other elements */
      }}
    >
      {hasError && (
        <Box mt="80px">
          <Flex justifyContent="center" flexDir="column">
            <Box alignSelf="center">
              <ExclamationCircleOutline size={30} />
            </Box>
            <Text textAlign="center">
              Oops, there was a problem processing your request <br />
              Please try again.
            </Text>
          </Flex>
        </Box>
      )}

      {!hasError && (
        <Box mt="60px">
          <Flex justifyContent="center" flexDir="column">
            <Box alignSelf="center">
              <Spinner color="secondary" thickness="5px" size="xl" speed="0.8s" />
            </Box>
            <Text mt={30} fontWeight={500} alignSelf="center">
              Generating report ...{downloadedPercent}%
            </Text>
          </Flex>
        </Box>
      )}
    </Box>
  )
}
