import { gql } from '@apollo/client'
import { JFDValues } from '@le2/jfd'

import { EnrollRecordImageInfo, RecordEnrollmentStatus } from '../lib/api/record'

export interface EnrollRecordReqData {
  recordBiographics: {
    recordNum: string
    biographics: JFDValues
  }
  recordImageMetadata: {
    recordNum: string
    imageMetadata: EnrollRecordImageInfo[]
  }
}

export interface EnrollRecordRespData {
  enrollRecordBiographics: {
    id: number
    recordNum: string
    enrollmentStatus: RecordEnrollmentStatus
    createdAt: string
    updatedAt: string
    isActive: boolean
    isSealed: boolean
  }
  enrollRecordImageMetadata: {
    enrollmentStatus: string
  }
}

export const ENROLL_RECORD = gql`
  mutation EnrollRecord(
    $recordBiographics: RecordBiographicEnrollmentInput!
    $recordImageMetadata: RecordImageMetadataEnrollmentInput!
  ) {
    enrollRecordBiographics(input: $recordBiographics) {
      id
      recordNum
      biographics
      enrollmentStatus
      createdAt
      updatedAt
      isActive
      isSealed
    }
    enrollRecordImageMetadata(input: $recordImageMetadata) {
      enrollmentStatus
    }
  }
`

export interface SubmitRecordRespData {
  gql: boolean
}

export const SUBMIT_RECORD = gql`
  mutation SubmitRecord($recordNum: String!) {
    submitRecord(recordNum: $recordNum)
  }
`

export const SEAL_RECORD = gql`
  mutation SealRecord($recordNum: String!, $seal: Boolean!) {
    sealRecord(recordNum: $recordNum, seal: $seal)
  }
`
