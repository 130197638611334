import { useEffect, useState } from 'react'

import { HubStatus } from '../IWNativeExtDeviceDefines'
import { nativeExtensionsState } from '../nativeextState'

/*
 * This hook starts a connection to the Print Manager NativeExtension hub when is mounted.
 * It only starts a connection if the HubStatus is DisConnected or Closed.
 * It keeps the connection open until the user closes the browser or
 * the NativeExtension server is turned off.
 * In order to start a connection is required to subscribe to the Hub Events first
 * using the useSubscribeHubEvents hook.
 */

export function useConnectToPrintHub() {
  // Get the card print manager status and object from the native extensions global context object
  // (make sure that the native extensions context provider wraps any root callers of this component)
  const [isConnecting, setIsConnecting] = useState(true)

  useEffect(() => {
    console.debug(`useConnectToPrintHub() entered, printHubStatus=${nativeExtensionsState.printHubStatus}`)

    if (
      nativeExtensionsState.printHubStatus === HubStatus.DisConnected ||
      nativeExtensionsState.printHubStatus === HubStatus.Closed
    ) {
      // Connect to the hub
      nativeExtensionsState.printManager.hubConnect().then(() => {
        setIsConnecting(false)
        console.debug(
          `useConnectToPrintHub(), hubConnect completed, printHubStatus=${nativeExtensionsState.printHubStatus}`
        )
      })
    }
    // ignore the dependencies, we want to call `hubConnect` once like `useConnectToPrintHub`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isConnecting
}
