import { useMutation } from '@apollo/client'
import { LE2UsersType } from '@le2/common-types'

import { CREATE_GROUP } from '../../../apollo/Permissions'
import { groupState } from '../utils'

export interface GroupCreateResponse {
  name: string
  users: LE2UsersType[]
  permissions: string[]
}
export interface GroupCreate {
  name: string
  users: number[]
  permissions: string[]
}

export default function useSaveGroup(onComplete: () => void, onError: (message: string) => void) {
  const [createGroup] = useMutation<
    { createGroup: GroupCreateResponse },
    { group: GroupCreate; operation: string; groupIdDuplicatedFrom: number }
  >(CREATE_GROUP, {
    onCompleted() {
      groupState.reset()
      onComplete()
      console.warn('Group created successfully')
    },
    update: (cache) => {
      cache.modify({
        fields: {
          groups(_, { DELETE }) {
            return DELETE
          },
          groupsAggregate(_, { DELETE }) {
            return DELETE
          }
        }
      })
    },
    onError(error) {
      console.warn('Error creating a group')
      console.warn(error)
      if (error && error.message === 'Group name already exists') {
        onError(error.message)
      } else {
        onError('An unexpected error happened')
      }
    }
  })

  return function (group: GroupCreate, operation: string, groupIdDuplicatedFrom: number) {
    const { name, permissions, users = [] } = group

    return createGroup({
      variables: {
        group: {
          name: name,
          permissions,
          // Contains the user identificators
          users: users
        },
        operation: operation,
        groupIdDuplicatedFrom: groupIdDuplicatedFrom
      }
    })
  }
}
