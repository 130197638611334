import { Box, Button, Flex, Spinner, VStack, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

interface StatusContentPageProps {
  isLoading: boolean
  hasError: boolean
  onRetry: () => void
  errorMessage: string
  children: React.ReactNode
}

export function StatusContentPage(props: StatusContentPageProps) {
  return (
    <StatusPageContainer>
      {props.isLoading ? (
        <LoadingStatus />
      ) : props.hasError ? (
        <ErrorStatus message={props.errorMessage} onRetry={props.onRetry} />
      ) : null}
      {props.children}
    </StatusPageContainer>
  )
}

export function LoadingContentPage() {
  return (
    <StatusPageContainer>
      <LoadingStatus />
    </StatusPageContainer>
  )
}

function LoadingStatus() {
  return (
    <MessageContainer>
      <Spinner color="secondary" size="xl" thickness="3px" />
    </MessageContainer>
  )
}

function ErrorStatus({ message, onRetry }: { message: string; onRetry: () => void }) {
  return (
    <MessageContainer>
      <VStack>
        <Box px={3}>{message}</Box>
        <Button variant="secondary" onClick={onRetry}>
          Try again
        </Button>
      </VStack>
    </MessageContainer>
  )
}

function MessageContainer({ children }: { children: React.ReactNode }) {
  const theme = useTheme()
  return (
    <Flex
      position="absolute"
      width="100%"
      height="100%"
      bgColor={transparentize('gray.100', 0.9)(theme)}
      zIndex={theme.zIndices.overlay}
      justifyContent="center"
      alignItems="center"
    >
      {children}
    </Flex>
  )
}

interface StatusPageContainerProps {
  children: React.ReactNode
}

function StatusPageContainer({ children }: StatusPageContainerProps) {
  return (
    <Box position="relative" height="100%">
      {children}
    </Box>
  )
}
