import { gql } from '@apollo/client'

export const CREATE_GROUP = gql`
  mutation createGroup($group: GroupCreateInput!, $operation: String, $groupIdDuplicatedFrom: Int) {
    createGroup(input: $group, operation: $operation, groupIdDuplicatedFrom: $groupIdDuplicatedFrom) {
      id
      name
      permissions
    }
  }
`

export const UPDATE_GROUP = gql`
  mutation updateGroup($group: GroupUpdateInput!) {
    updateGroup(input: $group) {
      id
      name
      permissions
    }
  }
`
