import axios, { AxiosError, AxiosResponse } from 'axios'
import * as jwt from 'jsonwebtoken'
import { proxy } from 'valtio'

interface TokenDecoded {
  aud: string
  exp: number
  iat: number
}

let inMemToken = ''
let tokenExpiry: number = 0

//returns number of seconds since Epoch
export const currentTimeAsEpochSeconds = () => Math.floor(new Date().getTime() / 1000)

let refreshTokenResolvers: Function[] = []

function refreshTokenResolveAll(v: AxiosResponse<any>) {
  refreshTokenResolvers.map((f: Function) => f(v))
  refreshTokenResolvers = []
}

const LOGIN_PATH = '/v1/login'
const LOGOUT_PATH = '/v1/logout'
const REFRESH_TOKEN_PATH = '/v1/refresh-token'
const OIDC_LOGIN_CALLBACK_PATH = '/v1/login/callback'

const Auth = {
  state: proxy({ loggedIn: false, refreshTokenFailed: false, lastRefresh: new Date() }),

  setToken: function (token: string) {
    inMemToken = token
    if (token !== '') {
      tokenExpiry = Auth.getTokenExpiry(token)
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
      tokenExpiry = 0
      delete axios.defaults.headers.common['Authorization']
    }
    Auth.state.loggedIn = inMemToken !== '' && tokenExpiry > currentTimeAsEpochSeconds()
  },

  getToken: function (): string {
    return inMemToken
  },

  isAuthorized: function () {
    return inMemToken !== '' && tokenExpiry > currentTimeAsEpochSeconds()
  },

  getOidcMetadata: async function (metadataUri: string): Promise<unknown> {
    const response = await axios.get(`${metadataUri}`)
    return response?.data
  },

  login: async function (values: { username: string; password: string }) {
    //login with username/password
    const response = await axios({
      method: 'post',
      url: LOGIN_PATH,
      auth: {
        username: values.username,
        password: values.password
      }
    })

    //set the in memory token
    Auth.setToken(response?.data?.access_token)
  },

  oidcLogin: async function (code: string, id_token: string) {
    const response = await axios.get(`${LOGIN_PATH}/callback?code=${code}&id_token=${id_token}`)
    //set the in memory token
    Auth.setToken(response?.data?.access_token)
  },

  logout: async function () {
    //logout user on server
    const response = await axios({
      method: 'post',
      url: LOGOUT_PATH
    })

    Auth.setToken('')
    window.localStorage.setItem('le2-logout', Date.now().toString())

    const signoutUri = response?.data?.signoutUri
    // see if we need to call a sign-out uri
    if (signoutUri) {
      try {
        // Call the OIDC provider logout
        const signoutResult = await axios({
          method: 'get',
          url: signoutUri
        })
        console.log(`Call to the OIDC user signoutUri returned: ${signoutResult.status}`)
      } catch (e) {
        console.error(`An error occurred while calling the OIDC user signoutUri: ${e}`)
      }
    }
  },

  //Calls API only once in case of multiple requests
  refreshToken: async function (): Promise<AxiosResponse<any>> {
    return new Promise((resolve, reject) => {
      refreshTokenResolvers.push(resolve)

      if (refreshTokenResolvers.length === 1) {
        axios({
          method: 'post',
          url: REFRESH_TOKEN_PATH
        }).then((response) => {
          //set the in memory token
          if (response?.data?.access_token) {
            Auth.setToken(response?.data?.access_token)
            Auth.state.refreshTokenFailed = false
            Auth.state.lastRefresh = new Date()
          } else {
            Auth.setToken('')
            Auth.state.loggedIn = false
            Auth.state.refreshTokenFailed = true
          }

          refreshTokenResolveAll(response)
        })
      }
    })
  },

  //returns exp as number of seconds since Epoch
  getTokenExpiry: (token: string): number => {
    const payload = jwt.decode(token)
    const { exp }: TokenDecoded = Object(payload)

    return exp
  },

  initialize: function () {
    // This listener allows to logout from multiple tabs
    window.addEventListener('storage', (event) => {
      if (event.key === 'le2-logout') {
        Auth.setToken('')
        window.location.href = '/web/login'
      }
    })

    function notInterceptableRoute(error: AxiosError) {
      let url = error?.response?.config?.url || ''
      // Not sure what the deal is but sometimes the url contains the parameters
      // hence, why we're trying to get rid of them before comparing the url
      url = url.split('?')[0]
      return url !== REFRESH_TOKEN_PATH && url !== LOGIN_PATH && url !== LOGOUT_PATH && url !== OIDC_LOGIN_CALLBACK_PATH
    }

    //handle 401 error transparently to obtain new access token using refresh token
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      async function (error) {
        //There's a 401 error, but not on refresh token route.
        if (error?.response?.status === 401 && notInterceptableRoute(error)) {
          await Auth.refreshToken()

          //rerun the original request
          const originalRequest = error.config

          //update the original request with new token
          originalRequest.headers['Authorization'] = `Bearer ${Auth.getToken()}`

          return axios(originalRequest)
        } else if (error?.response?.status === 401 && error?.response?.config?.url === REFRESH_TOKEN_PATH) {
          //redirect user to login when the refresh token fails
          window.location.href = '/web/login'
        } else {
          return Promise.reject(error)
        }
      }
    )
  }
}

export default Auth
