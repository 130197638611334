import { Box, Center, Heading, Text } from '@chakra-ui/react'

export default function PageNotFound() {
  return (
    <Center position="relative" width="100%" height="100%">
      <Box textAlign="center" py={10} px={6}>
        <Heading display="inline-block" as="h1" fontSize="150px" fontWeight={700}>
          404
        </Heading>
        <Text fontSize="32px" fontWeight={700} mb={2}>
          We couldn't find this page.
        </Text>
      </Box>
    </Center>
  )
}
