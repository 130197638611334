import filesize from 'filesize'
import mime from 'mime-types'

export enum FileExtension {
  png = '.png',
  jpg = '.jpg',
  jpeg = '.jpeg',
  bmp = '.bmp'
}

export interface LoadFromFileOptions {
  acceptedFileExtensions: FileExtension[]
  maximumFileSize: number
}

export const acceptedFileExtensions = [FileExtension.jpg, FileExtension.jpeg, FileExtension.png, FileExtension.bmp]
export const maximumFileSize = 10_485_760 // 10 MB

export function listFormat(list: string[], separator: string, lastSeparator: string): string {
  if (list.length === 0) {
    return ''
  } else if (list.length === 1) {
    return list[0]
  } else if (list.length === 2) {
    return list.join(` ${lastSeparator} `)
  } else {
    return list.slice(0, -1).join(`${separator} `) + `${separator} ${lastSeparator} ` + list.slice(-1)
  }
}

export function readFile(file: File, acceptedFileExtensions: FileExtension[], maximumFileSize: number) {
  return new Promise((resolve: (value: string) => void, reject) => {
    const extension = mime.extension(file.type) || ''
    const fileExt = FileExtension[extension as keyof typeof FileExtension]

    if (!acceptedFileExtensions.includes(fileExt)) {
      const filesExt = acceptedFileExtensions.map((v) => v.substring(1))
      const acceptedFileExt = listFormat(filesExt, ',', 'and')
      reject(`Invalid file format. The accepted file formats are ${acceptedFileExt}.`)
      return
    }

    if (file.size > maximumFileSize) {
      const maxSize = filesize(maximumFileSize, { base: 2, standard: 'jedec' })
      reject(`The file is too big. The maximum size allowed is ${maxSize}.`)
      return
    }

    const fr = new FileReader()
    fr.onload = () => {
      try {
        const data = (fr.result as String).split('base64,')[1]
        resolve(data)
      } catch (err) {
        reject('An unexpected error occurred.')
      }
    }
    fr.onerror = reject
    fr.readAsDataURL(file)
  })
}
