import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { LoginLayout } from '../layouts/LoginLayout'
import auth from '../services/auth'

export default function OIDCCallback() {
  // The old code was looking for parameters in window.location.search;
  // however, upon stepping through, it appears that the parameters are actually in window.location.href
  // For now, leaving both window.location.search and window.location.href
  const code =
    new URLSearchParams(window.location.search).get('code') ||
    new URLSearchParams(window.location.href).get('code') ||
    ''
  const idToken =
    new URLSearchParams(window.location.search).get('id_token') ||
    new URLSearchParams(window.location.href).get('id_token') ||
    ''

  // The old code was looking for parameters in window.location.search;
  // however, upon stepping through, it appears that the parameters are actually in window.location.href
  // Additionaly, when there's an error returned, the URL has the error parameters after the character of #
  // That's why calls like URLSearchParams(window.location.search).get('error'), for instance,
  // never found the error mesages
  // Adding the use of window.location.hash and the search for parameters starting in the string after the #
  const error =
    new URLSearchParams(window.location.hash.substring(window.location.hash.indexOf('#') + 1)).get('error') ||
    new URLSearchParams(window.location.search).get('error') ||
    new URLSearchParams(window.location.href.substring(window.location.href.indexOf('#') + 1)).get('error')
  const errorDescription =
    new URLSearchParams(window.location.hash.substring(window.location.hash.indexOf('#') + 1)).get(
      'error_description'
    ) ||
    new URLSearchParams(window.location.search).get('error_description') ||
    new URLSearchParams(window.location.href.substring(window.location.href.indexOf('#') + 1)).get('error_description')

  const history = useHistory()

  useEffect(() => {
    const doLogin = async () => {
      // Adding exception handling here because if oidcLogin() throws an exception,
      // this page starts getting called repeatedly, in an endless loop
      try {
        await auth.oidcLogin(code, idToken)

        // small hack so that useAutomaticLogoutOnPageLeave() doesn't make the logout
        // call once we land on the home page
        sessionStorage.setItem('reloaded', 'yes')
        history.replace('/')
      } catch (e) {
        console.log('oidc login exception: ' + e)
        // Not quite sure if this is what the page should do at this point
        // but seems reasonable enough for now
        history.replace(`/login?OIDCError=${e}`)
      }
    }

    if (!error) {
      doLogin()
    } else {
      const errorMessage = errorDescription || 'Failed to login'
      history.replace(`/login?OIDCError=${errorMessage}`)
    }
  }, [code, error, errorDescription, history, idToken])

  return (
    <LoginLayout>
      <div>Logging in...</div>
    </LoginLayout>
  )
}
