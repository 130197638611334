import { useQuery } from '@apollo/client'
import { Avatar, AvatarProps, Box, Heading, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { LE2UsersType } from '@le2/common-types'
import {
  BioType,
  GenericJFDValues,
  GenericMultipleOccurrenceNoSubfieldsValue,
  GenericSingleOccurrenceNoSubfieldsValue,
  GenericSingleOccurrenceSubfieldsValue,
  JFD,
  JFDField,
  JFDValue,
  Modality
} from '@le2/jfd'
import DayJS from 'dayjs'
import { Fragment, ReactNode, useCallback, useEffect, useState } from 'react'

import { ME } from '../../../apollo/Users'
import RecordAvatar from '../../../components/RecordAvatar'
import { Record, RecordImage } from '../../../lib/api/record'
import { RecordImageGroupMetadata, RecordImageMetadata } from '../../../lib/api/record/RecordImage'

interface FullRecordReportProps {
  jfd: JFD
  componentRef: React.RefObject<HTMLDivElement>
  records: Record[]
  printFields: string[]
  onChangeProgress: (percent: number) => void
  onFailed: () => void
}

type RecordData = { label: string; value: JFDValue } | undefined

interface FullRecordData {
  record: Record
  smts: RecordImage<RecordImageGroupMetadata>[][]
  mugshots: RecordImage<RecordImageGroupMetadata>[][]
}

export default function FullRecordReport({
  printFields,
  componentRef,
  records,
  jfd,
  onChangeProgress,
  onFailed
}: FullRecordReportProps) {
  const { data } = useQuery(ME, {
    onError(error) {
      console.error(JSON.stringify(error))
    }
  })

  const [fullBokingsData, setFullRecordsData] = useState<FullRecordData[]>([])
  const [loaded, setLoaded] = useState<boolean>(false)
  const recordsImages = records.reduce((images, record) => {
    const recordImages = record.images.getImagesByModality([Modality.Face, Modality.SMT])

    return images.concat(recordImages)
  }, [] as RecordImage<RecordImageMetadata>[])

  const handleProgress = useCallback(() => {
    const downloadedImages = recordsImages.filter((recordImage) => recordImage.image.isDownloaded)
    if (recordsImages.length === 0) {
      onChangeProgress(100)
    } else {
      const progress = Math.round((downloadedImages.length * 100) / recordsImages.length)
      onChangeProgress(progress)
    }
  }, [recordsImages, onChangeProgress])

  useEffect(() => {
    const init = async () => {
      const reportData = await getReportData(records, handleProgress, onFailed)
      setFullRecordsData(reportData)
    }

    if (!loaded) {
      init()
      setLoaded(true)
    }
  }, [loaded, handleProgress, onFailed, records])

  return (
    <Box display="none">
      <Box ref={componentRef}>
        {fullBokingsData.map((reportData, index) => (
          <FullRecordReportTable
            printFields={printFields}
            onFailed={onFailed}
            jfd={jfd}
            key={index}
            fullRecordData={reportData}
            data={data}
          />
        ))}
      </Box>
    </Box>
  )
}

interface FullRecordReportTableProps {
  fullRecordData: FullRecordData
  data: any
  jfd: JFD
  printFields: string[]
  onFailed: () => void
}

function FullRecordReportTable({ printFields, fullRecordData, data, jfd, onFailed }: FullRecordReportTableProps) {
  const { record } = fullRecordData

  jfd.resetFields()
  jfd.setValues(record.biographics)

  // Get title
  const title = jfd.getValue('DisplayFullName') || 'Unknown'
  // Get JFD Values
  const recordData = jfd.getFieldAttr((jfdField: JFDField) => {
    if (!printFields.includes(jfdField.fullName)) {
      return undefined
    }

    if (jfdField.options.length) {
      return { label: jfdField.label, value: jfdField.options.find((opt) => opt[0] === jfdField.value)?.[1] || '' }
    } else {
      return { label: jfdField.label, value: jfdField.value }
    }
  })

  /* TODO Refactor when agency config ready to only allow lvmpd */
  let recordNumber = ''
  try {
    recordNumber = jfd.recordNumber
  } catch (e) {}

  return (
    <Table
      variant="unstyled"
      background="white"
      sx={{
        pageBreakAfter: 'always',
        '@media print': {
          '@page': {
            margin: '1.5cm'
          }
        }
      }}
      textTransform="uppercase"
    >
      <Tbody>
        <HeaderReport
          title={title}
          record={record}
          user={data?.me}
          recordNum={recordNumber}
          createdAt={record.createdAt}
        />
        <BodyReport
          onLoad={() => {}}
          onFailed={onFailed}
          fullRecordData={fullRecordData}
          title="Biographic Info"
          jfd={jfd}
          recordData={recordData}
        />
      </Tbody>
    </Table>
  )
}

interface HeaderReportProps {
  title: string
  record: Record
  user: LE2UsersType | undefined
  recordNum: string
  createdAt: Date | undefined
}

function HeaderReport({ title, record, user, recordNum, createdAt }: HeaderReportProps) {
  const fullName = user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : ''
  return (
    <Tr>
      <Td w="45%">
        <RecordAvatar record={record} w="200px" h="200px" borderRadius="4px" />
      </Td>
      <Td pl={0}>
        <Heading as="h3" size="lg" mb={4}>
          {title}
        </Heading>
        <HeaderReportRow>Record Number: {recordNum}</HeaderReportRow>
        {createdAt && <HeaderReportRow>Created on {DayJS(createdAt).format('YYYY-MM-DD')}</HeaderReportRow>}
        <HeaderReportRow>
          Printed on {DayJS(new Date()).format('YYYY-MM-DD')} {fullName && `by ${fullName}`}
        </HeaderReportRow>
      </Td>
    </Tr>
  )
}

function HeaderReportRow({ children }: { children: ReactNode }) {
  return (
    <Box mb={4}>
      <Text display="inline-block" color="gray.600">
        {children}
      </Text>
    </Box>
  )
}

interface BodyReportProps {
  title: string
  jfd: JFD
  recordData: GenericJFDValues<RecordData>
  fullRecordData: FullRecordData
  onFailed: () => void
  onLoad: () => void
}

function BodyReport({ title, jfd, recordData, fullRecordData, onFailed, onLoad }: BodyReportProps) {
  const { mugshots, smts } = fullRecordData

  return (
    <>
      <Tr>
        <Td colSpan={2} px={0}>
          <Heading as="h4" size="sm" bg="gray.200" p={2} borderRadius="5px" m={0}>
            {title}
          </Heading>
        </Td>
      </Tr>
      {Object.keys(recordData).map((key, i) => {
        const fieldType = jfd.getFieldDef(key).getType()
        switch (fieldType) {
          case 'SONS':
            return (
              <BodyReportSONSRow key={i}>
                {recordData[key] as GenericSingleOccurrenceNoSubfieldsValue<RecordData>}
              </BodyReportSONSRow>
            )
          case 'SOS':
            return (
              <BodyReportSOSRow key={i}>
                {recordData[key] as GenericSingleOccurrenceSubfieldsValue<RecordData>}
              </BodyReportSOSRow>
            )
          case 'MONS':
            return (
              <BodyReportMONSRow key={i}>
                {recordData[key] as GenericMultipleOccurrenceNoSubfieldsValue<RecordData>}
              </BodyReportMONSRow>
            )
          case 'MOS':
            // MOS rows not implemented yet.
            return null
          default:
            return null
        }
      })}
      {mugshots.map((group, index) => (
        <Fragment key={index}>
          <Tr>
            <Td colSpan={2} px={0}>
              <Heading as="h4" size="sm" bg="gray.200" p={2} borderRadius="5px" m={0}>
                Mugshots {mugshots.length > 1 && <span>{index + 1}</span>}
              </Heading>
            </Td>
          </Tr>
          <Tr w="200px">
            <Td colSpan={4}>
              {group.map((recordImage, key) => (
                <ReportImage w="150px" h="150px" mr={3} key={key} recordImage={recordImage} />
              ))}
            </Td>
          </Tr>
        </Fragment>
      ))}

      {smts.map((group, index) => (
        <Fragment key={index}>
          <Tr>
            <Td colSpan={2} px={0}>
              <Heading as="h4" size="sm" bg="gray.200" p={2} borderRadius="5px" m={0}>
                SMTs {smts.length > 1 && <span>{index + 1}</span>}
              </Heading>
            </Td>
          </Tr>
          <Tr w="200px">
            <Td colSpan={4}>
              {group.map((recordImage, key) => (
                <ReportImage w="150px" h="150px" mr={3} key={key} recordImage={recordImage} />
              ))}
            </Td>
          </Tr>
        </Fragment>
      ))}
    </>
  )
}

function BodyReportSONSRow({ children }: { children: GenericSingleOccurrenceNoSubfieldsValue<RecordData> }) {
  return <BodyReportRow label={children?.label}>{children?.value}</BodyReportRow>
}

function BodyReportSOSRow({ children }: { children: GenericSingleOccurrenceSubfieldsValue<RecordData> }) {
  const sosRows = Object.values(children).map((field, i) => (
    <BodyReportRow key={`SOS_${i}`} label={field?.label}>
      {field?.value}
    </BodyReportRow>
  ))

  return <>{sosRows}</>
}

function BodyReportMONSRow({ children }: { children: GenericMultipleOccurrenceNoSubfieldsValue<RecordData> }) {
  const label = children[0]?.label
  const values = children.map((e, i) => e?.value && <Text key={`MONS_${i}`}>{e?.value}</Text>).filter((e) => !!e)
  return <BodyReportRow label={label}>{values.length ? values : null}</BodyReportRow>
}

function BodyReportRow({ label, children }: { label?: string; children: ReactNode }) {
  if (!children || !label) {
    return null
  }

  return (
    <Tr w="45%">
      <Td py={1} pr={1} pl={2} fontWeight="bold" verticalAlign="top">
        {label}
      </Td>
      <Td py={1} pr={1} pl={0} color="gray.600">
        {children}
      </Td>
    </Tr>
  )
}

interface ReportImageProps extends AvatarProps {
  recordImage: RecordImage<RecordImageGroupMetadata>
}

function ReportImage({ recordImage, ...props }: ReportImageProps) {
  const avatar = recordImage.image.isURL ? '' : recordImage.image.src

  return <Avatar src={avatar} borderRadius="4px" {...props} />
}

async function getReportData(records: Record[], onChangeProgress: () => void, onFailed: () => void) {
  const reportData: FullRecordData[] = []

  for (const record of records) {
    const smts = []
    const mugshots = []

    const smtsGroupTime = record.images.getGroupsByBioTypes([BioType.SMT])

    const mugshotsGroupTime = record.images.getGroupsByBioTypes([
      BioType.FaceLeft90,
      BioType.FaceFront,
      BioType.FaceRight90
    ])

    if (smtsGroupTime.length > 0) {
      for (const smtGroup of smtsGroupTime) {
        const groupImages = record.images.getImagesByGroupDateTime(smtGroup)

        for (const groupImage of groupImages) {
          if (groupImage.image.isURL) {
            await groupImage.image.download()

            if (groupImage.image.hasDownloadFailed) {
              onFailed()
            }
          }
        }

        onChangeProgress()
        smts.push(groupImages)
      }
    } else {
      onChangeProgress()
    }

    if (mugshotsGroupTime.length > 0) {
      for (const mugshotGroup of mugshotsGroupTime) {
        const mugshotImages = record.images.getImagesByGroupDateTime(mugshotGroup)

        for (const groupImage of mugshotImages) {
          if (groupImage.image.isURL) {
            await groupImage.image.download()

            if (groupImage.image.hasDownloadFailed) {
              onFailed()
            }
          }
        }

        onChangeProgress()
        mugshots.push(mugshotImages)
      }
    } else {
      onChangeProgress()
    }

    reportData.push({
      record: record,
      smts,
      mugshots
    })
  }

  return reportData
}
