const Select = {
  baseStyle: {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'inherit'
    }
  },
  variants: {
    jfd: {
      field: {
        _readOnly: {
          color: 'gray.400',
          pointerEvents: 'none'
        }
      }
    }
  }
}

export default Select
