import { useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'

import { ME } from '../../../apollo/Users'
import { handleLE2Error } from '../../../pages/Errors'
import { LE2UserResponse } from '../../../pages/users/types'
import { authUserState, mapUser } from '../../../utils/usersUtils'

export interface MEResponse {
  me: LE2UserResponse
}

export function useGetMeData() {
  const toast = useToast()
  const history = useHistory()

  const { data, loading } = useQuery<{ me: LE2UserResponse }>(ME, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const meData = mapUser(data.me)

      authUserState.me = {
        ...meData
      }
      authUserState.isLoaded = true
    },
    onError(error) {
      console.error(JSON.stringify(error))
      handleLE2Error(error, history, toast)
    }
  })

  return {
    data,
    loading
  }
}
