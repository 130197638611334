import { useMutation } from '@apollo/client'

import { LOG_EVENT } from './utils'

export enum AuditEventTypeEnum {
  AUTH = 'AUTH',
  AUDIT = 'AUDIT',
  BOOKING = 'BOOKING',
  RECORD = 'RECORD',
  TRANSACTION = 'TRANSACTION',
  LINEUP = 'LINEUP',
  USER = 'USER',
  SETTINGS = 'SETTINGS',
  CARD = 'CARD'
}

export enum BookingEventAction {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  UPDATE = 'UPDATE',
  PRINT = 'PRINT',
  SEAL = 'SEAL',
  UNSEAL = 'UNSEAL',
  IMPORT = 'IMPORT'
}

export enum RecordEventAction {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  UPDATE = 'UPDATE',
  PRINT = 'PRINT',
  SEARCH = 'SEARCH',
  SEAL = 'SEAL',
  UNSEAL = 'UNSEAL',
  IMPORT = 'IMPORT'
}

export enum LineupEventAction {
  CREATE = 'CREATE',
  VIEW = 'VIEW',
  PRINT = 'PRINT'
}

export enum AuditEventAction {
  VIEW = 'VIEW',
  PRINT = 'PRINT',
  EXPORT = 'EXPORT'
}

// When extends from this interface, action override is required with the correct type
interface BaseLogEventInput {
  action: BookingEventAction | LineupEventAction | AuditEventAction | RecordEventAction
  metadata?: Record<string, any>
}

export interface LogBookingEventInput extends BaseLogEventInput {
  action: BookingEventAction
  bookingNum: string
}

export interface LogLineupEventInput extends BaseLogEventInput {
  action: LineupEventAction
  lineupId: string
}

export interface LogAuditEventInput extends BaseLogEventInput {
  action: AuditEventAction
}

export interface LogRecordEventInput extends BaseLogEventInput {
  action: RecordEventAction
  recordNum: string
}

export type LogEvent =
  | {
      type: AuditEventTypeEnum
      [key: string]: string
    }
  | BaseLogEventInput

export function useLogBookingEvent(): (data: LogBookingEventInput) => void {
  const [logEvent] = useMutation(LOG_EVENT)

  function handleLogEvent(data: LogBookingEventInput) {
    const inputData: LogEvent = {
      type: AuditEventTypeEnum.BOOKING,
      ...data
    }
    logEvent({ variables: { input: inputData } })
  }

  return handleLogEvent
}

export function useLogRecordEvent(): (data: LogRecordEventInput) => void {
  const [logEvent] = useMutation(LOG_EVENT)

  function handleLogEvent(data: LogRecordEventInput) {
    const inputData: LogEvent = {
      type: AuditEventTypeEnum.RECORD,
      ...data
    }
    logEvent({ variables: { input: inputData } })
  }

  return handleLogEvent
}

export function useLogLineupEvent(): (data: LogLineupEventInput) => void {
  const [logEvent] = useMutation(LOG_EVENT)

  function handleLogEvent(data: LogLineupEventInput) {
    const inputData: LogEvent = {
      type: AuditEventTypeEnum.LINEUP,
      ...data
    }
    logEvent({ variables: { input: inputData } })
  }

  return handleLogEvent
}

export function useLogAuditEvent(): (data: LogAuditEventInput) => void {
  const [logEvent] = useMutation(LOG_EVENT)

  function handleLogEvent(data: LogAuditEventInput) {
    const inputData: LogEvent = {
      type: AuditEventTypeEnum.AUDIT,
      ...data
    }
    logEvent({ variables: { input: inputData } })
  }

  return handleLogEvent
}
