import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Icon,
  useTheme
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { Permission } from '@le2/permissions'
import { merge } from 'lodash'
import { useMemo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'

import PermissionWrapper from '../../components/PermissionWrapper'
import agencyConfig from '../../lib/agencyConfig'
import { useSideNav } from './sideNavContext'

export interface MenuItemWithSubitemsProps {
  icon?: any
  label: string
  permission?: Permission
  subitems?: MenuSubitemProps[]
}

export interface MenuSingleItemProps {
  icon: any
  label: string
  to: string
  permission?: Permission
}

export interface MenuSubitemProps {
  label: string
  to?: string
  permission?: Permission
  subitems?: MenuSubitemProps[]
}

export type MenuItemProps = MenuSingleItemProps | MenuItemWithSubitemsProps

interface MenuItemContainerProps {
  children: React.ReactElement | React.ReactElement[]
  isSubitem: boolean
  to: string
  mb?: string
  isActive: boolean
  hasSubitems?: boolean
}

const accordionTransitions = 'height 0.3s'
const activeLinkBgColor = transparentize('secondary', 0.1)
const hoverLinkBgColor = transparentize('secondary', 0.2)
const panelPaddingTop = 8
const panelItemMarginBotton = 4

export function MenuItem({ ...props }: MenuItemProps) {
  const {
    location: { pathname, search }
  } = useHistory()
  const completeUrl = pathname + search

  if ('subitems' in props && props.subitems && props.subitems.length > 0) {
    const itemProps = props as MenuItemWithSubitemsProps

    if (itemProps.subitems && itemProps.subitems?.length > 0) {
      const subitemActive = itemProps.subitems.find((subitem) => subitem.to && completeUrl.indexOf(subitem.to) !== -1)
      return <MenuSubitemContainer {...itemProps} subitemActive={subitemActive} />
    } else {
      return null
    }
  } else {
    const isActive = completeUrl.indexOf((props as MenuSingleItemProps).to) !== -1
    return <SingleMenuItem {...(props as MenuSingleItemProps)} isActive={isActive} />
  }
}

function SingleMenuItem(props: MenuSingleItemProps & { isActive: boolean }) {
  const { isSideNavOpen } = useSideNav()
  return (
    <MenuItemContainer
      data-cy={props.label.replace(/ /g, '')}
      to={props.to}
      isSubitem={false}
      isActive={props.isActive}
    >
      <Icon as={props.icon} w={6} h={6} />
      <Box ml={2} fontWeight={'bold'} display={{ base: 'inline-block', xl: isSideNavOpen ? 'inline-block' : 'none' }}>
        {props.label}
      </Box>
    </MenuItemContainer>
  )
}

function MenuSubitemContainer(props: MenuItemWithSubitemsProps & { subitemActive?: MenuSubitemProps }) {
  const { isSideNavOpen } = useSideNav()
  const theme = useTheme()
  const subitemsPermissions = useMemo(() => {
    if (props.subitems) {
      return props.subitems.reduce((subitems, subitem) => {
        if (subitem.permission) {
          subitems.push(subitem.permission)
        }
        return subitems
      }, [] as Permission[])
    } else {
      return [] as Permission[]
    }
  }, [props.subitems])
  return (
    // Verifies if satisfies as minimum one permission to display the menu item.
    <PermissionWrapper permission={merge(subitemsPermissions, [props.permission])} strict={false}>
      {/* The sidebar is expanded by default when the corresponding configuration is provided in the agency config */}
      <Accordion height="auto" allowMultiple defaultIndex={agencyConfig.features.sidebarExpandedByDefault ? [0] : []}>
        <AccordionItem borderColor="transparent" boxShadow="none" isFocusable={false} height="auto">
          {({ isExpanded }) => {
            let bgColor: string | undefined
            if (isSideNavOpen) {
              if (!isExpanded && props.subitemActive && !props.subitems) {
                bgColor = activeLinkBgColor(theme)
              }
            } else {
              if (props.subitemActive && !props.subitems) {
                bgColor = activeLinkBgColor(theme)
              }
            }
            const color = isSideNavOpen
              ? !isExpanded && props.subitemActive && !props.subitems
                ? 'secondary'
                : 'gray.200'
              : props.subitemActive && !props.subitems
              ? 'secondary'
              : 'gray.200'
            return (
              <>
                <AccordionButton
                  data-cy={props.label.replace(/ /g, '')}
                  color={color}
                  px={3}
                  py={4}
                  borderRadius={8}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  _hover={{
                    // @ts-ignore
                    background: hoverLinkBgColor
                  }}
                  backgroundColor={bgColor}
                  height="auto"
                >
                  <Box flex="1" textAlign="left">
                    {props.icon && <Icon as={props.icon} w={6} h={6} pl={0} />}
                    <Box
                      ml={2}
                      fontWeight={'bold'}
                      display={{ base: 'inline-block', xl: isSideNavOpen ? 'inline-block' : 'none' }}
                    >
                      {props.label}
                    </Box>
                  </Box>
                  {isSideNavOpen && (
                    <AccordionIcon
                      h={6}
                      color={!isExpanded && props.subitemActive && !props.subitems ? 'secondary' : 'gray.200'}
                    />
                  )}
                </AccordionButton>
                <AccordionPanel
                  pt={isSideNavOpen ? `${panelPaddingTop}px` : 0}
                  px={0}
                  pb={0}
                  height="auto"
                  transition={{ xl: accordionTransitions }}
                >
                  {props.subitems?.map((subitem) => (
                    // Verifies the subitems permissions.
                    <PermissionWrapper key={subitem.label} permission={subitem.permission}>
                      {subitem.subitems ? (
                        <MenuSubitemContainer {...subitem} subitemActive={props.subitemActive} />
                      ) : (
                        <MenuSubitem subitem={subitem} isSideNavOpen={isSideNavOpen} />
                      )}
                    </PermissionWrapper>
                  ))}
                </AccordionPanel>
              </>
            )
          }}
        </AccordionItem>
      </Accordion>
    </PermissionWrapper>
  )
}

interface CMenuSubitemProps {
  subitem: MenuSubitemProps
  isSideNavOpen: boolean
}

function MenuSubitems({ subitems, isSideNavOpen }: { subitems: MenuSubitemProps[]; isSideNavOpen: boolean }) {
  return (
    <>
      {subitems.map((subitem) => {
        return <MenuSubitem key={subitem.label} subitem={subitem} isSideNavOpen={isSideNavOpen} />
      })}
    </>
  )
}
function MenuSubitem({ subitem, isSideNavOpen }: CMenuSubitemProps) {
  const history = useHistory()

  const url = history.location.pathname + history.location.search
  const isBookingPathMatch = subitem.to ? subitem.to.startsWith('/bookings/new') : false
  const isCurrentBookingPath = history.location.pathname.startsWith('/bookings/new')
  const isSameQuery = subitem.to ? subitem.to.endsWith(history.location.search) : false
  const isActiveBookingPath = isBookingPathMatch && isCurrentBookingPath && isSameQuery
  const isActivePath = !isBookingPathMatch ? url.endsWith(subitem.to || 'unknown') : isActiveBookingPath

  return (
    <MenuItemContainer
      data-cy={subitem.label.replace(/ /g, '')}
      to={subitem.to || '#'}
      mb={`${panelItemMarginBotton}px`}
      isSubitem={true}
      isActive={isActivePath}
      hasSubitems={Boolean(subitem.subitems)}
    >
      <Box ml={8} fontWeight={'bold'} display={{ base: 'inline-block', xl: isSideNavOpen ? 'inline-block' : 'none' }}>
        {subitem.label}
      </Box>
      <>
        {subitem.subitems && isSideNavOpen && (
          <MenuSubitems subitems={subitem.subitems} isSideNavOpen={isSideNavOpen} />
        )}
      </>
    </MenuItemContainer>
  )
}

function MenuItemContainer({ isSubitem, isActive, hasSubitems, ...props }: MenuItemContainerProps) {
  const { togglePinSideNav, isSideNavOpen, isScreenLargerThanXL } = useSideNav()

  return (
    <Flex
      direction={hasSubitems ? 'column' : 'row'}
      as={NavLink}
      onClick={() => !isScreenLargerThanXL && togglePinSideNav()}
      px={3}
      py={4}
      borderRadius={8}
      // @ts-ignore
      background={isActive && (!isSubitem || isSideNavOpen) ? activeLinkBgColor : 'none'}
      color={isActive && (!isSubitem || isSideNavOpen) ? 'secondary' : 'gray.200'}
      _hover={{
        // @ts-ignore
        background: hoverLinkBgColor
      }}
      {...props}
    />
  )
}
