export interface CredentialFieldMapping {
  dgnField: string
  biographicsField: string
}

export enum DGNType {
  Data = 'Data', // DGN file content
  FileName = 'FileName', // DGN file name
  URL = 'URL' // DGN file URL
}

// Generate positions dynamically based on enum keys
type EnumPositions<T> = {
  [key in keyof T]: number
}

export const DGNTypePositions: EnumPositions<typeof DGNType> = Object.fromEntries(
  Object.keys(DGNType).map((key, index) => [key, index])
) as EnumPositions<typeof DGNType>

export interface CardsType {
  id: number
  displayName: string | null
  fileName: string | null
  version: number
  fieldMapping?: CredentialFieldMapping[] | null
  createdBy: number
  CreatedBy: createdByInterface
  createdAt?: Date
  updatedAt?: Date | null
  updatedBy?: number | null
  deletedAt?: Date | null
  deletedBy?: number | null
  type: string
  dgnType: DGNType
  dgn: string
}

interface createdByInterface {
  firstName: string
  lastName: string
}

export interface CardCreateData {
  displayName: string
  fileName: string
  type: string
  dgnType: DGNType
  version: number
  // fieldMapping: CredentialFieldMapping[]
  dgn: string
}

export interface CardUpsertData {
  dgnType: DGNType
  type: string
  fileName: string
  displayName: string
  dgn: string
  version: number
}
export interface CardColumnsInterface {
  displayName: string
  fileName: string
  type: string
  version: string
  // fieldMapping?: CredentialFieldMapping[]
  createdAt: string
  createdBy: string
}
