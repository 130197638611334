import { Image as JFDImage, Modality, ModalityBioTypeMap } from '@le2/jfd'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'

import { IWImage } from '../../../nativeExtension/IWNativeExtDeviceDefines'
import { Status } from '../../../pages/booking/interfaces'
import { convertDataURIToB64Data } from '../../imageUtils'
import BioType, { faceCaptureBioTypes } from './BioType'
import RecordImage, { GroupDateTimeMetadata, RecordImageGroupMetadata, RecordImageMetadata } from './RecordImage'
import RecordImagesToBeDeleted from './RecordImagesToBeDeleted'
import { ImageResponse } from './utils'

export enum RecordTabModality {
  FaceCapture = 'FaceCapture'
}

/**
 * If this class is used inside of Valtio, the methods that modify
 * the state (methods that ADD or DELETE something) must be used with the real state.
 * They should not be used with a snapshot.
 * The methods that ONLY GET information can be used with either the real
 * state or a snapshot.
 */

export default class RecordImages {
  recordImagesIdsOriginal: number[]
  recordImages: RecordImage<RecordImageMetadata>[]
  tabs: RecordImageTabs[]
  recordImagesToBeDeleted: RecordImagesToBeDeleted

  constructor(recordImages: RecordImage<RecordImageMetadata>[]) {
    this.recordImages = recordImages
    this.recordImagesIdsOriginal = recordImages.map(({ capturedAt }) => capturedAt.getTime())
    this.tabs = []
    this.recordImagesToBeDeleted = new RecordImagesToBeDeleted(this.recordImagesIdsOriginal)
  }

  static createFromAPIResponse(imageMetadata: ImageResponse[]): RecordImages {
    const mappedImages = imageMetadata.map((img) => {
      const recordImage = new RecordImage(
        `/v1/images/${img.imageId}`,
        BioType[img.bioType as keyof typeof BioType],
        RecordImages.getImageMetadata(img),
        undefined,
        img.capturedAt
      )
      recordImage.image.id = img.imageId
      recordImage.image.isURL = true
      return recordImage
    })

    const result = new RecordImages(mappedImages)

    result.getGroupsByBioTypes(faceCaptureBioTypes).forEach((date) => {
      result.addTab(RecordTabModality.FaceCapture, date)
    })

    return result
  }

  private static getImageMetadata(img: ImageResponse): RecordImageMetadata {
    const meta = img.metadata as unknown as RecordImageMetadata
    if (img.metadata.groupDateTime) {
      // Assume the image metadata has the necessary fields
      return { ...meta, groupDateTime: new Date(img.metadata.groupDateTime) }
    } else {
      return { ...meta }
    }
  }

  /**
   * It returns an array of the unique groupDateTimes that are in the
   * record images. It only loops over the record images of the bioTypes
   * passed as parameters.
   */
  getGroupsByBioTypes(bioTypes: BioType[]): Date[] {
    const dateTimeSet = this.recordImages.reduce((acc, recordImage) => {
      const groupDateTimeMetadata = recordImage.metadata as GroupDateTimeMetadata
      if (bioTypes.includes(recordImage.bioType) && groupDateTimeMetadata.groupDateTime) {
        acc.add(groupDateTimeMetadata.groupDateTime.toISOString())
      }
      return acc
    }, new Set<string>())
    return Array.from(dateTimeSet).map((strDatetime) => new Date(strDatetime))
  }

  /**
   * It returns a map of record images filtered by GroupDateTime where
   * the key is the bioType and the value is a record image.
   * If the groupDateTime has many images of the same bioType, those images will be
   * replaced by the other keeping the last one. A map can not have repeated keys.
   */
  getMappedImagesByGroupDateTime(groupDateTime: Date): Map<BioType, RecordImage<RecordImageGroupMetadata>> {
    return this.recordImages.reduce((acc, recordImage) => {
      if ((recordImage.metadata as GroupDateTimeMetadata).groupDateTime?.getTime() === groupDateTime.getTime()) {
        acc.set(recordImage.bioType, recordImage as RecordImage<RecordImageGroupMetadata>)
      }
      return acc
    }, new Map<BioType, RecordImage<RecordImageGroupMetadata>>())
  }

  /**
   * It returns a map of record images filtered by BioType where
   * the key is the bioType and the value is a record image.
   * If there are many images of the same bioType, those images will be
   * replaced by the other keeping the last one. A map can not have repeated keys.
   */
  getMappedImagesByBioType(bioType: BioType[]): Map<BioType, RecordImage<RecordImageMetadata>> {
    return this.recordImages.reduce((acc, recordImage) => {
      if (bioType.includes(recordImage.bioType)) {
        acc.set(recordImage.bioType, recordImage as RecordImage<RecordImageMetadata>)
      }
      return acc
    }, new Map<BioType, RecordImage<RecordImageMetadata>>())
  }

  /**
   * It returns an array of record images filtered by GroupDateTime.
   * If there is no any record image, the array is empty.
   */
  getImagesByGroupDateTime(groupDateTime: Date): RecordImage<RecordImageGroupMetadata>[] {
    return this.recordImages.filter((recordImage) => {
      return (recordImage.metadata as GroupDateTimeMetadata).groupDateTime?.getTime() === groupDateTime.getTime()
    }) as RecordImage<RecordImageGroupMetadata>[]
  }

  /**
   * It returns a record image if it was found, otherwise it returns undefined.
   */
  getImage(capturedAt: Date): RecordImage<RecordImageMetadata> | undefined {
    return this.recordImages.find((recordImage) => recordImage.capturedAt.getTime() === capturedAt.getTime())
  }

  /**
   * It returns an array with all the information of record images needed
   * to perform the request.
   * If one image is not uploaded or its id is undefined, the image is not
   * included in the array.
   * If there is no any record image, the array is empty.
   */
  // TODO Add imageWSQ to getAllImages()
  getAllImages(): EnrollRecordImageInfo[] {
    return this.recordImages
      .filter(({ image }) => image.isUploaded && image.id)
      .map(({ image, bioType, capturedAt, metadata }) => ({ imageId: image.id!, bioType, capturedAt, metadata }))
  }

  /**
   * It returns an array of IWImage. If there is no any record image, the array is empty.
   * The type IWImage is required by the NativeExtensions.
   */
  getIWImagesByBioType(bioTypes: BioType[]): IWImage[] {
    const recordImages = this.recordImages.filter((recordImage) => bioTypes.includes(recordImage.bioType))
    return recordImages.map(({ image, bioType }) => {
      const imageData = convertDataURIToB64Data(image.src)
      const metaData = { CaptureImageType: bioType }
      return { imageData, metaData }
    })
  }

  /**
   * Get images to generate an EBTS file
   */
  getAllImagesEBTS(): RecordImage<RecordImageMetadata>[] {
    // Only return uploaded images that have a valid image ID
    return this.recordImages.filter(({ image }) => image.isUploaded && image.id)
  }

  getJFDImages(): JFDImage[] {
    return this.recordImages.map((recordImage) => {
      // Only use the groupDateTime image metadata
      let groupId
      if ('groupDateTime' in recordImage.metadata) {
        groupId = recordImage.metadata?.groupDateTime?.getTime() + ''
      } else {
        groupId = ''
      }

      return {
        bioType: recordImage.bioType,
        imageId: recordImage.image.id!,
        groupId,
        metadata: recordImage.metadata as unknown as any
      } as JFDImage
    })
  }

  /**
   * It returns an array of RecordImageTabs.
   * If there is no found any tab, the array is empty.
   */
  getTabs(modality: RecordTabModality): RecordImageTabs[] {
    return this.tabs.filter((tab) => tab.modality === modality)
  }

  /**
   * It returns an array of RecordImages filtered by BioTypes.
   * If there is no found any record image, the array is empty.
   */
  getImagesByBioType(bioTypes: BioType[]): RecordImage<RecordImageMetadata>[] {
    return this.recordImages.filter((recordImage) => bioTypes.includes(recordImage.bioType))
  }

  /**
   *
   * It returns an array of RecordImages filtered by Modalities
   *  If there is no found any record image, the array is empty
   */
  getImagesByModality(modalites: Modality[]): RecordImage<RecordImageMetadata>[] {
    let modalitiesBioTypes: BioType[] = []

    modalites.forEach((modality) => {
      const bioTypes = ModalityBioTypeMap[modality]
      modalitiesBioTypes = modalitiesBioTypes.concat(bioTypes)
    })

    return this.getImagesByBioType(modalitiesBioTypes)
  }

  /**
   * It returns the status of the images that belogs to the bioTypes
   * passed as parameter.
   * If one image is uploading, it returns Status.Loading.
   * If for one image the uploading proccess has failed, and there is no image
   * uploading, it returns Status.Error.
   * If all images were upload properly and the save button has not been
   * clicked, it returns Status.Idle.
   * If all images were upload properly and the save button has been
   * clicked, it returns Status.Complete.
   */
  getImagesStatusByBioTypes(bioTypes: BioType[], hasSaveButtonBeenClicked: boolean): Status {
    let error = false
    for (const recordImage of this.recordImages) {
      if (!bioTypes.includes(recordImage.bioType)) {
        continue
      }
      if (recordImage.image?.isUploading) {
        return Status.Loading
      }
      if (recordImage.image?.hasUploadFailed) {
        error = true
      }
    }
    if (error) {
      return Status.Error
    }

    return hasSaveButtonBeenClicked ? Status.Complete : Status.Idle
  }

  isUploading(modality: Modality) {
    return some(this.getImagesByModality([modality]), (e) => e.image.isUploading)
  }

  hasUploadError(modality: Modality) {
    return some(this.getImagesByModality([modality]), (e) => e.image.hasUploadFailed)
  }

  getLatestFaceFront() {
    return this.getLatestImage(BioType.FaceFront)
  }

  getLatestImage(bioType: BioType) {
    const images = this.getImagesByBioType([bioType]) //
      .sort((imgA, imgB) => {
        if (imgA.capturedAt < imgB.capturedAt) {
          return 1
        } else if (imgA.capturedAt > imgB.capturedAt) {
          return -1
        } else {
          return 0
        }
      })

    if (images.length > 0) {
      return images[0]
    } else {
      return null
    }
  }

  getImageById(imageId: string) {
    return this.recordImages.find((b) => b.image.id === imageId)
  }

  /**
   * It creates a record image and adds it to the recordImages array.
   * It also uploads this image to the server.
   * In addition, it cancels or deletes (if the image is uploaded) the record image that satisfies the
   * condition of the replaceCallback parameter.
   */
  setImage(
    src: string,
    bioType: BioType,
    metadata: RecordImageMetadata,
    wsqSrc?: string,
    replaceCallback?: (
      bioType: BioType,
      metadata: RecordImageMetadata,
      recordImage: RecordImage<RecordImageMetadata>
    ) => boolean
  ): RecordImage<RecordImageMetadata> {
    if (replaceCallback) {
      const prevRecordImageIndex = this.recordImages.findIndex((recordImage) =>
        replaceCallback(bioType, metadata, recordImage)
      )
      if (prevRecordImageIndex !== -1) {
        this.recordImagesToBeDeleted.add(this.recordImages[prevRecordImageIndex])
        this.recordImages.splice(prevRecordImageIndex, 1)
      }
    }
    const newImage = new RecordImage(src, bioType, metadata, wsqSrc)
    const length = this.recordImages.push(newImage)

    const newImageFromState = this.recordImages[length - 1]
    newImageFromState.image.upload().then()

    // return the image from state since it's wrapped by valtio and we can
    // perform operations on it
    return newImageFromState
  }

  /**
   * It creates a record image in the position of the image to be replaced.
   * It also uploads this image to the server.
   * In addition, it deletes the image to be replaced.
   */
  replaceImage(
    recordImage: RecordImage<RecordImageMetadata>,
    src: string,
    bioType: BioType,
    metadata: RecordImageMetadata,
    wsqSrc?: string
  ): RecordImage<RecordImageMetadata> {
    const newImage = new RecordImage(src, bioType, metadata, wsqSrc)

    const prevRecordImageIndex = this.recordImages.findIndex((image) => image === recordImage)
    this.recordImagesToBeDeleted.add(this.recordImages[prevRecordImageIndex])
    this.recordImages.splice(prevRecordImageIndex, 1, newImage)

    const newImageFromState = this.recordImages[prevRecordImageIndex]
    newImageFromState.image.upload().then()

    return newImageFromState
  }

  /**
   * It adds a new record image tab to the array.
   */
  addTab(modality: RecordTabModality, groupDateTime?: Date, smtType?: string | null): void {
    this.tabs.push({ modality, groupDateTime: groupDateTime ? groupDateTime : new Date(), smtType })
  }

  /**
   * It deletes all the record images that belong to the groupDateTime parameter
   * from the recordImages array. It cancels all the image requests of this group if
   * there is one or deletes all the images of this group from the server if uploaded.
   * In addition, it deletes the tab that belongs to this groupDateTime from the array.
   */
  deleteGroup(bioTypes: BioType[], groupDateTime: Date): void {
    const recordImages = this.recordImages.filter((recordImage) => {
      if (bioTypes.includes(recordImage.bioType)) {
        if ((recordImage.metadata as GroupDateTimeMetadata).groupDateTime.getTime() === groupDateTime.getTime()) {
          this.recordImagesToBeDeleted.add(recordImage)
          return false
        }
      }
      return true
    })
    const tabs = this.tabs.filter((tab) => tab.groupDateTime.getTime() !== groupDateTime.getTime())
    this.recordImages = recordImages
    this.tabs = tabs
  }

  /**
   * It deletes the record image by capturedAt from the recordImages array.
   * It cancels the image request of the image if there is one or deletes the image
   * from the server if uploaded.
   */
  deleteImage(capturedAt: Date): void {
    const index = this.recordImages.findIndex(
      (recordImage) => recordImage.capturedAt.getTime() === capturedAt.getTime()
    )
    if (index !== -1) {
      const recordImage = this.recordImages[index]
      this.recordImagesToBeDeleted.add(recordImage)
      this.recordImages = this.recordImages.filter(
        (recordImage) => recordImage.capturedAt.getTime() !== capturedAt.getTime()
      )
    }
  }

  /**
   * It deletes the record images by bioTypes from the recordImages array.
   * It cancels the image request of the image if there is one or deletes the image
   * from the server if uploaded.
   */
  deleteImagesByBioType(bioTypes: BioType[]): void {
    this.recordImages = this.recordImages.filter((recordImage) => {
      if (bioTypes.includes(recordImage.bioType)) {
        this.recordImagesToBeDeleted.add(recordImage)
        return false
      }
      return true
    })
  }

  /**
   * It returns a boolean that indicates if the state is dirty.
   * The method checks whether the recordImagesIdsOriginal array and
   * the recordImagesIds array are equal or not to know if the state is dirty.
   */
  isDirty(): boolean {
    const recordImagesId = this.recordImages.map(({ capturedAt }) => capturedAt.getTime())

    return !isEqual(this.recordImagesIdsOriginal, recordImagesId)
  }

  /**
   * It synchronizes the new recordImagesIds with the recordImagesIdsOriginal array, and
   * it calls the RecordImagesToBeDeleted.deleteAll method to remove all the discarted images.
   */
  applyChanges(): void {
    this.recordImagesIdsOriginal = this.recordImages.map(({ capturedAt }) => capturedAt.getTime())
    this.recordImagesToBeDeleted.deleteAll(this.recordImagesIdsOriginal)
  }

  setImagesMetadata() {
    this.recordImages.forEach((img) => img.setMetadata())
  }
}

export function replaceImgByGroupDateTimeBioType(
  bioType: BioType,
  metadata: RecordImageMetadata,
  recordImage: RecordImage<RecordImageMetadata>
): boolean {
  return (
    recordImage.bioType === bioType &&
    (recordImage.metadata as GroupDateTimeMetadata).groupDateTime.getTime() ===
      (metadata as GroupDateTimeMetadata).groupDateTime.getTime()
  )
}

export function replaceImgByBioType(
  bioType: BioType,
  metadata: RecordImageMetadata,
  recordImage: RecordImage<RecordImageMetadata>
): boolean {
  return recordImage.bioType === bioType
}

export interface EnrollRecordImageInfo {
  imageId: string
  bioType: BioType
  capturedAt: Date
  metadata: RecordImageMetadata
}

export interface RecordImageTabs {
  modality: RecordTabModality
  groupDateTime: Date
  smtType?: string | null
}
