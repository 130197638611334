import { useMutation } from '@apollo/client'
import { Group, LE2UsersType } from '@le2/common-types'

import { UPDATE_GROUP } from '../../../apollo/Permissions'

export interface GroupUpdateResponse {
  id: number
  name: string
  users: LE2UsersType[]
  permissions: string[]
}

export interface GroupUpdate {
  id: number
  name: string
  users: number[]
  permissions: string[]
}

export default function useUpdateGroup(onComplete: () => void, onError: (message: string) => void) {
  const [updateGroup] = useMutation<{ updateGroup: GroupUpdateResponse; group: Group }>(UPDATE_GROUP, {
    onCompleted() {
      onComplete()
    },
    update: (cache) => {
      cache.modify({
        fields: {
          groups(_, { DELETE }) {
            return DELETE
          },
          groupsAggregate(_, { DELETE }) {
            return DELETE
          }
        }
      })
    },
    onError(error) {
      if (error && error.message === 'Group name already exists') {
        onError(error.message)
      } else {
        onError('An unexpected error happened')
      }
    }
  })

  return function (group: GroupUpdate) {
    const { id, name, permissions, users = [] } = group

    return updateGroup({
      variables: {
        group: {
          id: id,
          name: name,
          permissions,
          // Contains the user identificators
          users: users
        }
      }
    })
  }
}
