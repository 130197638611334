const FormError = {
  baseStyle: {
    text: {
      fontSize: '12px',
      color: 'red.500',
      mt: 1
    }
  }
}

export default FormError
