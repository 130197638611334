import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { Record as T5Record } from './record.interface'
import { GET_RECORDS, RecordsQueryParams, RecordsResponse, getAPIQueryParams, mapRecordResponse } from './utils'

export interface RecordsQueryResult {
  loading: boolean
  error: boolean
  records: T5Record[]
  count: number
  pages: number
}

export default function useRecordsQuery(params: RecordsQueryParams): RecordsQueryResult {
  const variables = getAPIQueryParams(params)
  const queryResult = useQuery<RecordsResponse>(GET_RECORDS, { variables })
  const pages = Math.ceil((queryResult.data?.recordsAggregate.count || 0) / variables.take)

  const records = useMemo(() => {
    return (queryResult.data?.records || []).map(mapRecordResponse)
  }, [queryResult.data])

  return {
    loading: queryResult.loading,
    error: !!queryResult.error,
    records,
    count: queryResult.data?.recordsAggregate.count || 0,
    pages
  }
}
