// Username 2 - 32 characters and can contain a-zA-Z0-9 and underscores
export const USERNAME_REGEXP = /^[a-zA-Z0-9_]{2,32}$/
export const USERNAME_CRITERIA: string = 'User name must be alphanumeric'

// Password is:
//   10 - 32 characters and can contain a-zA-Z0-9 and all keyboard special chars
//   A mixture of both uppercase and lowercase letters.
//   A mixture of letters and numbers.
//   Inclusion of at least one special character, e.g., ! @ # ?
//   Not matching the username
//
// Old implementation included as a reference below:
//   10 - 32 characters and can contain a-zA-Z0-9 and all keyboard special chars
// export const PASSWORD_REGEXP = /^[!@#$%^&*()_+-={}[\]:;"'|\\<,>.?/`~a-zA-Z0-9 ]{10,32}$/
//
// Current implementation below:
// (?=.\d)(?=.[a-z])(?=.[A-Z])((?=.\W)|(?=.*_))^[^ ]+$
//
// (?=.*\d)          // use positive look ahead to see if at least one digit exists
// (?=.*[a-z])       // use positive look ahead to see if at least one lower case letter exists
// (?=.*[A-Z])       // use positive look ahead to see if at least one upper case letter exists
// (?=.*\W)          // use positive look ahead to see if at least one non-word character exists
// (?=.*_)           // use positive look ahead to see if at least one underscore exists
// |                 // The Logical OR operator
// ^[^ ]+$           // Reject the strings having spaces in them.
// {10,32}           // min 10, max 32 characters
export const PASSWORD_REGEXP: RegExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]{10,32}$/

export const PASSWORD_CRITERIA: string = `Password must be: 
 a mixture of upper and lowercase letters; 
 a mixture of letters and numbers; 
 including at least one special character, e.g., ! @ # ?; 
 not containing spaces;
 not matching the username; 
 between 10 - 32 characters long 
`

export const EMAIL_REGEXP = /^\S+@\S+\.\S+$/
