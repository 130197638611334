import { As } from '@chakra-ui/react'
import { Permission } from '@le2/permissions'

import agencyConfig from '../../lib/agencyConfig'

export interface RecordActionItem {
  icon: As
  label: string
  permission?: Permission | Permission[]
  onClick?: () => void
}

export const onlyMandatoryCheckbox = agencyConfig.features.bookings.onlyMandatoryCheckbox
