import {
  JFD,
  JFDValues,
  JFLGroup,
  Modality,
  MultipleOccurrenceNoSubfieldsValue,
  MultipleOccurrenceSubfieldsValue,
  getAllFieldNames,
  getModality
} from '@le2/jfd'
import clone from 'lodash/clone'
import get from 'lodash/get'
import has from 'lodash/has'

import { RecordImages } from '../../lib/api/record'
import {
  BiographicsSectionValidationResult,
  Record,
  Status,
  ValidationResult,
  newBiographicsSectionValidationResult,
  newValidationResult
} from './interfaces'

interface Validator {
  validate(modality: keyof Record['validation']): ValidationResult
}

export class BiographicsValidator implements Validator {
  constructor(private jfdErrors: JFDValues, private hasSaveButtonBeenClicked: boolean) {}

  validate() {
    if (!this.hasSaveButtonBeenClicked) {
      return newValidationResult(Status.Idle)
    }

    // ignore SMT stuff
    // ToDo move this to `JFDStrategy`?
    const biographicsErrors = clone(this.jfdErrors)
    delete biographicsErrors['SMT']

    if (Object.keys(biographicsErrors).length > 0) {
      return newValidationResult(Status.Error)
    } else {
      return newValidationResult(Status.Complete)
    }
  }
}

export class JfdImagesValidator implements Validator {
  constructor(private jfd: JFD, private recordImages: RecordImages, private hasSaveButtonBeenClicked: boolean) {}

  validate(modality: keyof Record['validation']): ValidationResult {
    if (modality === 'biographics') {
      throw new Error('Invalid call, expected a modality, got biographics.')
    }

    // return Loading if any of the images is currently loading i.e. if they're
    // being uploaded
    if (this.recordImages.isUploading(modality)) {
      return newValidationResult(Status.Loading)
    }

    // return Error if any of the images failed to upload
    if (this.recordImages.hasUploadError(modality)) {
      return newValidationResult(Status.Error)
    }

    // return Idle if the user hasn't clicked Save
    if (!this.hasSaveButtonBeenClicked) {
      return newValidationResult(Status.Idle)
    }

    // return Error if we have one in the JFD
    // set images in the JFD so that we can retrieve the computed errors
    this.jfd.setImages(this.recordImages.getJFDImages())
    const errorMessage = this.getModalityErrorMessage(modality) || ''
    if (errorMessage) {
      return newValidationResult(Status.Error, errorMessage)
    }

    // finally, if everything is ok, return Complete
    return newValidationResult(Status.Complete)
  }

  private getModalityErrorMessage(modality: Modality) {
    for (const error of this.jfd.getImageErrors()) {
      switch (error.type) {
        case 'modality':
          if (modality === error.modality) {
            return error.error
          }
          break

        case 'modalities':
          for (const m of error.modalities) {
            if (modality === m) {
              return error.error
            }
          }
          break

        case 'groupBioType':
          if (modality === getModality(error.bioType)) {
            return error.error
          }
          break

        case 'global':
          throw new Error('Not implemented yet.')
      }
    }
  }
}

export class BiographicsSectionValidator {
  private readonly allErrors: JFDValues

  constructor(private jfd: JFD, private hasSaveButtonBeenClicked: boolean) {
    this.allErrors = jfd.getErrors()
  }

  validate(group: JFLGroup): BiographicsSectionValidationResult {
    if (!this.hasSaveButtonBeenClicked) {
      return newBiographicsSectionValidationResult(Status.Idle)
    }

    // iterate over each field in the JFLGroup and count the errors
    let errorCount = 0
    for (const fieldName of getAllFieldNames(group).map((g) => g.name)) {
      const [field, subfield] = fieldName.split('.')
      const fieldDef = this.jfd.getFieldDef(field)

      if (fieldDef.hasMultipleOccurrences()) {
        if (fieldDef.hasSubFields()) {
          // MOS case
          // here `allErrors` can be something like this:
          // {
          //   MOS: [
          //     { Subfield: 'Error message', AnotherSubfield: 'Error message' },
          //     { },
          //     { AnotherSubfield: 'Error message' },
          //   ]
          // }

          const fieldErrors = get(this.allErrors, field, []) as MultipleOccurrenceSubfieldsValue
          for (const errorObj of fieldErrors) {
            // Special case for 'fieldArray' groups: for MOS fields the JFLDef only includes the
            // parent field (e.g. only `MOS` instead of `MOS.Subfield`), and `JFDFieldArray`
            // internally iterates over the subfields, so we need to do the same here to calculate
            // the correct errorCount.
            //
            // Other group types require each subfield to be included, so we can just check for the `subfield`
            // in each `errorObj`.
            if (group.type === 'fieldArray') {
              for (const [subfield] of Object.entries(fieldDef.subFields.fields)) {
                if (has(errorObj, subfield)) {
                  errorCount++
                }
              }
            } else {
              if (has(errorObj, subfield)) {
                errorCount++
              }
            }
          }
        } else {
          // MONS case
          // here `allErrors` can be something like this:
          // {
          //   MONS: [
          //     'Error message',
          //     undefined,
          //     'Error message',
          //   ]
          // }
          //
          // So we count all truthy array elements in `allErrors['MONS']`.
          const fieldErrors = get(this.allErrors, fieldName, []) as MultipleOccurrenceNoSubfieldsValue
          errorCount += fieldErrors.filter((e) => !!e).length
        }
      } else {
        // single occurrence, `fieldName` can be like `SOS.Subfield` and `SONS`
        // so we can use them as paths for lodash
        if (has(this.allErrors, fieldName)) {
          errorCount++
        }
      }
    }

    if (errorCount > 0) {
      return newBiographicsSectionValidationResult(Status.Error, errorCount)
    } else {
      return newBiographicsSectionValidationResult(Status.Complete)
    }
  }
}
