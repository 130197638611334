import { useMemo, useState } from 'react'

export function useRotationStyle() {
  const rotationAngle = parseInt(localStorage.getItem('rotationAngle') || '0', 0)
  const rotationStyleMemo = useMemo(() => {
    console.debug(`localStorage rotationAngle: ${rotationAngle} degrees`)
    return {
      transform: `rotate(${rotationAngle}deg)`
    }
  }, [rotationAngle])
  const [rotationStyle, setRotationStyle] = useState(rotationStyleMemo)

  function setRotateImageStyle(rotationAngle: number) {
    localStorage.setItem('rotationAngle', rotationAngle.toString())
    setRotationStyle({
      transform: `rotate(${rotationAngle}deg)`
    })
    console.debug(`set rotationAngle: ${rotationAngle} degrees`)
  }

  return {
    rotationAngle,
    rotationStyle,
    setRotateImageStyle
  }
}
