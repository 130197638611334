import { gql } from '@apollo/client'
import { Group, GroupUserRelation } from '@le2/common-types'
import {
  Permission,
  permissionDependenciesMap,
  permissionDependentMap,
  permissionMapper,
  permissions
} from '@le2/permissions'
import { proxy } from 'valtio'

import { GroupResponse, PermissionData, PermissionModule } from './types'

export const defaultGroupMessage = 'You can only edit users in this group. To modify permissions, duplicate this group.'

export const groupState = proxy<GroupState>({
  id: 0,
  name: '',
  permissions: [],
  users: [],
  operation: 'add',
  groupIdDuplicatedFrom: 0,
  createdBy: undefined,
  updatedBy: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  isActive: true,
  isEditable: true,
  reset() {
    this.id = 0
    this.name = ''
    this.permissions = []
    this.users = []
    this.operation = 'add'
    this.groupIdDuplicatedFrom = 0
    this.isActive = true
    this.isEditable = true
  }
})

interface GroupState extends Group {
  reset: () => void
  users: GroupUserRelation[]
  operation: 'add' | 'edit' | 'duplicate'
  groupIdDuplicatedFrom: number
}

export function formattingPermissions(group: Group): PermissionModule[] {
  try {
    const mapedPermissions = permissionMapper(group.permissions)

    return mapModulePermissions(mapedPermissions)
  } catch (err) {
    // If the assigned permissions are invalid, the function returns a message to inform the user to update them.
    if (err instanceof Error) {
      console.warn(err.message)
    }

    return [
      {
        name: 'Invalid Permissions',
        permissions: [
          {
            name: '' as Permission,
            label: 'Please review or update the assigned permissions'
          } as PermissionData
        ]
      }
    ]
  }
}

export function mapGroup(group: GroupResponse): Group {
  return {
    ...group,
    id: group.id,
    users: group.users.map(({ addedAt, user }) => ({
      addedAt: new Date(addedAt),
      ...user
    })),
    isActive: group.isActive,
    isEditable: group.isEditable,
    updatedAt: group.updatedAt ? new Date(group.updatedAt) : undefined,
    createdAt: group.createdAt ? new Date(group.createdAt) : undefined
  }
}

export const GET_GROUPS = gql`
  query GetGroups($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    groupsAggregate(where: $where, aggregate: { count: true }) {
      count
    }
    groups(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      name
      permissions
      createdBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      createdAt
      updatedAt
      users {
        addedAt
        user {
          id
          firstName
          lastName
          email
          username
        }
      }
      isActive
      isEditable
    }
  }
`

export const GET_GROUP = gql`
  query GetGroup($where: JSON) {
    groups(where: $where) {
      id
      name
      permissions
      createdBy {
        firstName
        lastName
      }
      updatedBy {
        firstName
        lastName
      }
      createdAt
      updatedAt
      users {
        addedAt
        user {
          id
          firstName
          lastName
          email
          username
        }
      }
      isActive
      isEditable
    }
  }
`
export const ARCHIVE_GROUP = gql`
  mutation ArchiveGroup($id: Int!, $archive: Boolean!) {
    archiveGroup(id: $id, archive: $archive)
  }
`

export const modulesWithPermissions: PermissionModule[] = mapModulePermissions(permissions)

export type PermissionsWithLabels = {
  [key: string]: Record<string, string>
}

export function mapModulePermissions(list: PermissionsWithLabels) {
  return Object.entries(list).reduce((maped, [moduleName, modulePermissions]) => {
    const module: PermissionModule = {
      name: moduleName,
      permissions: Object.entries<string>(modulePermissions).map(([permissionName, label]) => {
        const permission = permissionName as Permission
        return {
          name: permission,
          label
        }
      })
    }

    maped.push(module)

    return maped
  }, [] as PermissionModule[])
}

export function getParentPermissions(permission: Permission, moduleName: string) {
  const typedPermissions = permissions as PermissionsWithLabels

  if (!(moduleName in typedPermissions)) {
    throw new Error('Module does not exist')
  }

  // Get only permissions related with the module
  const modulePermissionsMap = typedPermissions[moduleName]

  // Search parent permissions in the above list
  const parentPermissions = permissionDependentMap.get(permission)
  const parentPermissionsData: PermissionData[] = (parentPermissions ?? []).map((permission) => {
    return {
      name: permission,
      label: modulePermissionsMap[permission]
    }
  }, [])
  return parentPermissionsData
}

export function getChildrenPermissions(permission: Permission, moduleName: string) {
  const typedPermissions = permissions as PermissionsWithLabels

  if (!(moduleName in typedPermissions)) {
    throw new Error('Module does not exist')
  }

  // Get only permissions related with the module
  const modulePermissionsMap = typedPermissions[moduleName]

  // Search child permissions in the above list
  const childPermissions = permissionDependenciesMap.get(permission)
  const childPermissionsData: PermissionData[] = (childPermissions ?? []).map((permission) => {
    return {
      name: permission,
      label: modulePermissionsMap[permission]
    }
  }, [])
  return childPermissionsData
}
