import { gql, useQuery } from '@apollo/client'

import { Booking, BookingsResponse, mapBookingResponse } from './utils'

export const GET_BOOKING = gql`
  query GetBooking($bookingNum: String) {
    bookings(where: { bookingNum: $bookingNum }) {
      id
      bookingNum
      biographics
      enrollmentStatus
      imageMetadata
      createdAt
      updatedAt
      isActive
      isSealed
    }
  }
`

/**
 * Query the given booking from the API.
 * @param bookingNum
 * @param onCompleted
 */
export default function useGetBooking(bookingNum: string, onCompleted: (booking: Booking) => void) {
  return useQuery<BookingsResponse>(GET_BOOKING, {
    variables: { bookingNum },

    onCompleted: (data) => {
      const resp = data.bookings[0]
      if (resp) {
        const booking = mapBookingResponse(resp)
        onCompleted(booking)
      }
    }
  })
}
