/////////////////////////////////////////////////////////////////////////////////
//
//  This class provides methods to manipulate the print Events
//
/////////////////////////////////////////////////////////////////////////////////
import { DGNTypePositions } from '../pages/cards/types'
import { HubManager } from './HubManager'
import {
  DeviceError,
  DgnPrintEventDetail,
  FieldNamesEventDetail,
  IWImage,
  IWResult,
  PrintFormatInfo,
  PrinterInfo,
  PrinterStatus,
  PrinterStatusEventDetail,
  PrintersEventDetail
} from './IWNativeExtDeviceDefines'

export class PrintManager extends HubManager {
  constructor(eventsCallback?: () => void) {
    const PrintDeviceManagerUrl = 'http://localhost:5995/printmgr'
    super(PrintDeviceManagerUrl, eventsCallback)
    // bind the class methods to this instance
    this.GetFieldNames = this.GetFieldNames.bind(this)
    this.GetPrinters = this.GetPrinters.bind(this)
    this.GetPrinterStatus = this.GetPrinterStatus.bind(this)
    this.PageSetup = this.PageSetup.bind(this)
    this.Print = this.Print.bind(this)
    this.PrintPreview = this.PrintPreview.bind(this)
  }

  //
  // Methods that call the PrintManager server interfaces
  //

  /**
   * @description Get the list of printers
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  GetPrinters(
    onlyAvailablePrinters: boolean,
    callbackMethod?: (iwResult: IWResult, resultList: PrinterInfo[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let printers: string[] | null = null
    try {
      this.hubConnection
        ?.invoke('GetPrinters', onlyAvailablePrinters)
        .then(function (list: string[] | null) {
          console.debug('connection.GetPrinters.then() entered, result list: ' + list)
          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            printers = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No printers')
          }
        })
        .catch(function (err) {
          console.error('connection.GetPrinters.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.GetPrinters.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<PrintersEventDetail>('Printers', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: printers }
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }

  /**
   * @description Get the status of the specified printer
   * @param printerName The name of the printer to get the status of
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  GetPrinterStatus(
    printerName: string,
    callbackMethod?: (iwResult: IWResult, resultList: PrinterStatus[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let printerStatus: string[] | null = null
    try {
      console.debug('PrintMgr::GetPrinterStatus() entered')
      this.hubConnection
        ?.invoke('GetPrinterStatus', printerName)
        .then(function (list: string[] | null) {
          console.debug('connection.GetPrinterStatus.then() entered, result list: ' + list)
          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            printerStatus = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No printers')
          }
        })
        .catch(function (err) {
          console.error('connection.GetPrinterStatus.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.GetPrinterStatus.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<PrinterStatusEventDetail>('PrinterStatus', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: printerStatus }
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }

  /**
   * @description Get the preview images for the specified dgn format
   * @param dgn The dgn format to get the preview images for
   * @param personalizationData The personalization data to use when generating the preview images
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  PrintPreview(
    dgn: PrintFormatInfo,
    personalizationData?: { [key: string]: string }[],
    callbackMethod?: (iwResult: IWResult, resultList: IWImage[][] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let previewImages: IWImage[][] | null = null

    try {
      console.debug('PrintMgr::PrintPreview() entered')
      this.hubConnection
        ?.invoke('PrintPreview', { ...dgn, dgnType: DGNTypePositions[dgn.dgnType] }, personalizationData)
        .then(function (list: IWImage[][] | null) {
          console.debug('connection.PrintPreview.then() entered, result list count: ' + list?.length)
          if (list?.length) {
            //Commented out the previous implementation; leaving the comments as a historical reference for now
            //iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            previewImages = list
          } else {
            //Commented out the previous implementation; leaving the comments as a historical reference for now
            //iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No images')
          }
          // We treat all these cases as success, since the print to printer will return an empty list
          // Any errors from the cardprint plugin would have been thrown as hub errors and handles by the catch below
          iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
        })
        .catch(function (err) {
          console.error('connection.PrintPreview.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.PrintPreview.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<DgnPrintEventDetail>('PrintPreview', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: previewImages }
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }

  /**
   * @description Print the specified dgn format
   * @param dgn The dgn format to print
   * @param personalizationData The personalization data to use when printing
   * @param printerName The name of the printer to print to
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  Print(
    dgn: PrintFormatInfo,
    personalizationData: { [key: string]: string }[],
    printerName: string,
    callbackMethod?: (iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.debug('PrintMgr::Print() entered')
      this.hubConnection
        ?.invoke('Print', { ...dgn, dgnType: DGNTypePositions[dgn.dgnType] }, personalizationData, printerName)
        .then(function () {
          console.debug('connection.Print.then() entered')
          iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
        })
        .catch(function (err) {
          console.error('connection.Print.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.Print.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<DgnPrintEventDetail>('Print', {
              bubbles: true,
              cancelable: true
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }

  /**
   * @description Get the list of field names for the specified dgn format to edit
   * @param dgn The dgn format to get the field names for
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  GetFieldNames(
    dgn: PrintFormatInfo,
    callbackMethod?: (iwResult: IWResult, resultList: string[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let fieldNames: string[] | null = null
    try {
      console.debug('PrintMgr::GetFieldNames() entered')
      this.hubConnection
        ?.invoke('GetFieldNames', { ...dgn, dgnType: DGNTypePositions[dgn.dgnType] })
        .then(function (list: string[] | null) {
          console.debug('connection.GetFieldNames.then() entered, result list: ' + list)
          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            fieldNames = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No field names')
          }
        })
        .catch(function (err) {
          console.error('connection.GetFieldNames.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.GetFieldNames.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<FieldNamesEventDetail>('GetFieldNames', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: fieldNames }
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }

  /**
   * @description Set the page setup for the specified dgn format
   * @param dgn The dgn format to set the page setup for
   * @param callbackMethod Optional callback method to call when the operation is complete
   * @param callbackContext Optional context to pass to the callback method
   */
  PageSetup(
    dgn: PrintFormatInfo,
    callbackMethod?: (iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    try {
      console.debug('PrintMgr::PageSetup() entered')
      this.hubConnection
        ?.invoke('PageSetup', { ...dgn, dgnType: DGNTypePositions[dgn.dgnType] })
        .then(function () {
          console.debug('connection.PageSetup.then() entered')
          iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
        })
        .catch(function (err) {
          console.error('connection.PageSetup.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.debug('connection.PageSetup.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<DgnPrintEventDetail>('PageSetup', {
              bubbles: true,
              cancelable: true
            })
          )
        })
    } catch (e) {
      console.debug('Exception in PrintMgr::Print() : ' + e)
    }
  }
}
