import { AgencyConfig, JFD, JFL, createJFD, createJFL } from '@le2/jfd'
import { MultipleOccurrenceSubfields } from '@le2/jfd/dist/jfd-fields'

import agencyConfig from '../../../lib/agencyConfig'
import { BookingImages, SMTBioTypes } from '../../../lib/api/booking'
import { BookingImageMetadata } from '../../../lib/api/booking/BookingImage'

export interface JFDStrategy {
  getJFD: (index: number, create: boolean) => JFD
  jfl: JFL
  appendSmtGroup: (newIndex: number) => void
  deleteSmtGroup: (index: number) => void
  hasJFDErrors: (index: number) => boolean
  getTabName: (index: number) => string
  getMetadata: (index: number) => Partial<BookingImageMetadata>
  syncFrom: (bookingImages: BookingImages) => void
}

type StrategyType = AgencyConfig['features']['bookings']['smt']['strategy']

export function getJFDStrategy(jfd?: JFD, strategy?: StrategyType): JFDStrategy {
  switch (strategy || agencyConfig.features.bookings.smt.strategy) {
    case 'type10':
      return new SMTType10()
    case 'type2':
    default:
      if (!jfd) {
        throw new Error('Need to provide a JFD for Type 2 strategy.')
      }
      return new SMTType2(jfd)
  }
}

class SMTType2 implements JFDStrategy {
  private _jfl: JFL | null = null

  constructor(private jfd: JFD) {}

  getJFD() {
    return this.jfd
  }

  hasJFDErrors(index: number) {
    type MaybeMultipleOccurrenceSubfields = MultipleOccurrenceSubfields | undefined
    const smtErrors = this.jfd.getErrors()['SMT'] as unknown as MaybeMultipleOccurrenceSubfields
    return !!smtErrors?.[index]
  }

  get jfl() {
    if (!this._jfl) {
      this._jfl = createJFL(this.jfd, agencyConfig.features.bookings.smt.jfl)
    }
    return this._jfl
  }

  appendSmtGroup(newIndex: number): void {
    this.jfd.createOccurrence('SMT.Type', newIndex)
  }

  deleteSmtGroup(index: number): void {
    this.jfd.removeField('SMT.Type', index)
    if (this.jfd.numEntries('SMT.Type') === 0) {
      this.jfd.createOccurrence('SMT.Type', 0)
    }
  }

  getTabName(index: number): string {
    const field = this.jfd.getField('SMT.Type', index)
    const typeField = field.options.find((opt) => opt[0] === field.value)
    if (typeField) {
      return typeField[1]
    }
    return 'SMT'
  }

  getMetadata(index: number): Partial<BookingImageMetadata> {
    return {
      smtType: this.getTabName(index)
    }
  }

  syncFrom() {
    // nothing to do here...
  }
}

class SMTType10 implements JFDStrategy {
  private readonly _jfl: JFL
  private readonly _jfdList: JFD[]

  constructor() {
    const baseJFD = createJFD(agencyConfig.features.bookings.smt.jfd)
    this._jfl = createJFL(baseJFD, agencyConfig.features.bookings.smt.jfl)
    this._jfdList = []
  }

  getJFD(index: number, create: boolean) {
    if (!this._jfdList[index]) {
      if (create) {
        this._jfdList[index] = createJFD(agencyConfig.features.bookings.smt.jfd)
      } else {
        throw new Error(`Out of bounds: index=${index} length=${this._jfdList.length}`)
      }
    }
    return this._jfdList[index]
  }

  hasJFDErrors(index: number) {
    const errors = this.getJFD(index, false).getErrors()
    return Object.keys(errors).length > 0
  }

  get jfl() {
    return this._jfl
  }

  appendSmtGroup(newIndex: number): void {
    this.getJFD(newIndex, true)
  }

  deleteSmtGroup(index: number): void {
    this._jfdList.splice(index, 1)
  }

  getTabName(index: number): string {
    const fieldExits = this._jfdList[index].hasOccurrence('SMTDescriptors.SMTSource', 0)
    // Verifies if the SMT Source from SMT Descriptors is available.
    if (fieldExits) {
      // Returns the selected SMT Source if it has any value.
      const field = this._jfdList[index].getField('SMTDescriptors.SMTSource', 0)
      const typeField = field.options.find((opt) => opt[0] === field.value)
      if (typeField) {
        return typeField[1]
      }
    }
    // Returns as default
    return 'SMT'
  }

  getMetadata(index: number): Partial<BookingImageMetadata> {
    return {
      smtType: this.getTabName(index),
      ...this._jfdList[index].getValues()
    }
  }

  syncFrom(bookingImages: BookingImages): void {
    const groups = bookingImages.getGroupsByBioTypes(SMTBioTypes)
    groups.forEach((groupDateTime, idx) => {
      this.appendSmtGroup(idx)

      bookingImages.getImagesByGroupDateTime(groupDateTime).forEach((img) => {
        const jfd = this.getJFD(idx, false)

        // img.metadata may contain values other than those defined in the JFD
        // so, we clean it before passing them to `setValues`
        const cleanValues = jfd.cleanValues(img.metadata as {})
        jfd.setValues(cleanValues)

        img.metadataProvider = () => ({
          groupDateTime,
          ...this.getMetadata(idx)
        })
      })
    })

    // remove extra groups
    for (let i = this._jfdList.length - 1; i >= groups.length; i--) {
      this.deleteSmtGroup(i)
    }
  }
}
