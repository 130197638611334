import {
  JFD,
  JFDValues,
  JFLGroup,
  Modality,
  MultipleOccurrenceNoSubfieldsValue,
  MultipleOccurrenceSubfieldsValue,
  getAllFieldNames,
  getModality
} from '@le2/jfd'
import clone from 'lodash/clone'
import get from 'lodash/get'
import has from 'lodash/has'

import { BookingImages, BookingTabModality } from '../../lib/api/booking'
import {
  BiographicsSectionValidationResult,
  Booking,
  Status,
  ValidationResult,
  newBiographicsSectionValidationResult,
  newValidationResult
} from './interfaces'
import { JFDStrategy } from './smtCapture/jfdStrategy'

export class BookingSubmitValidator {
  constructor(
    private jfd: JFD,
    private bookingImages: BookingImages,
    private smtJfdStrategy: JFDStrategy,
    private hasSaveButtonBeenClicked: boolean
  ) {}

  validate(modality: keyof Booking['validation']): ValidationResult {
    const jfdImagesValidator = new JfdImagesValidator(this.jfd, this.bookingImages, this.hasSaveButtonBeenClicked)

    switch (modality) {
      case 'biographics':
        return new BiographicsValidator(this.jfd.getErrors(), this.hasSaveButtonBeenClicked).validate()
      case Modality.Face:
      case Modality.Fingerprint:
      case Modality.Palm:
      case Modality.Iris:
        return jfdImagesValidator.validate(modality)
      case Modality.SMT:
        return new SmtValidator(jfdImagesValidator, this.smtJfdStrategy, this.bookingImages).validate()
    }
    throw new Error('Invalid modality.')
  }
}

interface Validator {
  validate(modality: keyof Booking['validation']): ValidationResult
}

export class BiographicsValidator implements Validator {
  constructor(private jfdErrors: JFDValues, private hasSaveButtonBeenClicked: boolean) {}

  validate() {
    if (!this.hasSaveButtonBeenClicked) {
      return newValidationResult(Status.Idle)
    }

    // ignore SMT stuff
    // ToDo move this to `JFDStrategy`?
    const biographicsErrors = clone(this.jfdErrors)
    delete biographicsErrors['SMT']

    if (Object.keys(biographicsErrors).length > 0) {
      return newValidationResult(Status.Error)
    } else {
      return newValidationResult(Status.Complete)
    }
  }
}

export class JfdImagesValidator implements Validator {
  constructor(private jfd: JFD, private bookingImages: BookingImages, private hasSaveButtonBeenClicked: boolean) {}

  validate(modality: keyof Booking['validation']): ValidationResult {
    if (modality === 'biographics') {
      throw new Error('Invalid call, expected a modality, got biographics.')
    }

    // return Loading if any of the images is currently loading i.e. if they're
    // being uploaded
    if (this.bookingImages.isUploading(modality)) {
      return newValidationResult(Status.Loading)
    }

    // return Error if any of the images failed to upload
    if (this.bookingImages.hasUploadError(modality)) {
      return newValidationResult(Status.Error)
    }

    // return Idle if the user hasn't clicked Save
    if (!this.hasSaveButtonBeenClicked) {
      return newValidationResult(Status.Idle)
    }

    // return Error if we have one in the JFD
    // set images in the JFD so that we can retrieve the computed errors
    this.jfd.setImages(this.bookingImages.getJFDImages())
    const errorMessage = this.getModalityErrorMessage(modality) || ''
    if (errorMessage) {
      return newValidationResult(Status.Error, errorMessage)
    }

    // finally, if everything is ok, return Complete
    return newValidationResult(Status.Complete)
  }

  private getModalityErrorMessage(modality: Modality) {
    for (const error of this.jfd.getImageErrors()) {
      switch (error.type) {
        case 'modality':
          if (modality === error.modality) {
            return error.error
          }
          break

        case 'modalities':
          for (const m of error.modalities) {
            if (modality === m) {
              return error.error
            }
          }
          break

        case 'groupBioType':
          if (modality === getModality(error.bioType)) {
            return error.error
          }
          break

        case 'global':
          throw new Error('Not implemented yet.')
      }
    }
  }
}

export class SmtValidator implements Validator {
  constructor(
    private jfdImagesValidator: JfdImagesValidator,
    private smtJfdStrategy: JFDStrategy,
    private bookingImages: BookingImages
  ) {}

  validate(): ValidationResult {
    // first run the _base_ validations, i.e. uploading status, upload failures,
    // image errors in the JFD, etc.
    const result = this.jfdImagesValidator.validate(Modality.SMT)
    if (result.status !== Status.Complete) {
      return result
    }

    // now check the SMT-specific logic, i.e. for each tab (a.k.a group):
    // - check that the underlying JFD doesn't have any errors
    // - check that the tab has at least one image
    const tabs = this.bookingImages.getTabs(BookingTabModality.SMT)
    for (let i = 0; i < tabs.length; i++) {
      if (this.smtJfdStrategy.hasJFDErrors(i)) {
        return newValidationResult(Status.Error)
      }
      const tabImages = this.bookingImages.getImagesByGroupDateTime(tabs[i].groupDateTime)
      if (!tabImages.length) {
        return newValidationResult(Status.Error)
      }
    }
    return newValidationResult(Status.Complete)
  }
}

export class BiographicsSectionValidator {
  private readonly allErrors: JFDValues

  constructor(private jfd: JFD, private hasSaveButtonBeenClicked: boolean) {
    this.allErrors = jfd.getErrors()
  }

  validate(group: JFLGroup): BiographicsSectionValidationResult {
    if (!this.hasSaveButtonBeenClicked) {
      return newBiographicsSectionValidationResult(Status.Idle)
    }

    // iterate over each field in the JFLGroup and count the errors
    let errorCount = 0
    for (const fieldName of getAllFieldNames(group).map((g) => g.name)) {
      const [field, subfield] = fieldName.split('.')
      const fieldDef = this.jfd.getFieldDef(field)

      if (fieldDef.hasMultipleOccurrences()) {
        if (fieldDef.hasSubFields()) {
          // MOS case
          // here `allErrors` can be something like this:
          // {
          //   MOS: [
          //     { Subfield: 'Error message', AnotherSubfield: 'Error message' },
          //     { },
          //     { AnotherSubfield: 'Error message' },
          //   ]
          // }

          const fieldErrors = get(this.allErrors, field, []) as MultipleOccurrenceSubfieldsValue
          for (const errorObj of fieldErrors) {
            // Special case for 'fieldArray' groups: for MOS fields the JFLDef only includes the
            // parent field (e.g. only `MOS` instead of `MOS.Subfield`), and `JFDFieldArray`
            // internally iterates over the subfields, so we need to do the same here to calculate
            // the correct errorCount.
            //
            // Other group types require each subfield to be included, so we can just check for the `subfield`
            // in each `errorObj`.
            if (group.type === 'fieldArray') {
              for (const [subfield] of Object.entries(fieldDef.subFields.fields)) {
                if (has(errorObj, subfield)) {
                  errorCount++
                }
              }
            } else {
              if (has(errorObj, subfield)) {
                errorCount++
              }
            }
          }
        } else {
          // MONS case
          // here `allErrors` can be something like this:
          // {
          //   MONS: [
          //     'Error message',
          //     undefined,
          //     'Error message',
          //   ]
          // }
          //
          // So we count all truthy array elements in `allErrors['MONS']`.
          const fieldErrors = get(this.allErrors, fieldName, []) as MultipleOccurrenceNoSubfieldsValue
          errorCount += fieldErrors.filter((e) => !!e).length
        }
      } else {
        // single occurrence, `fieldName` can be like `SOS.Subfield` and `SONS`
        // so we can use them as paths for lodash
        if (has(this.allErrors, fieldName)) {
          errorCount++
        }
      }
    }

    if (errorCount > 0) {
      return newBiographicsSectionValidationResult(Status.Error, errorCount)
    } else {
      return newBiographicsSectionValidationResult(Status.Complete)
    }
  }
}
