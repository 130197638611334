import { FormControl } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Control, Controller, FieldValues } from 'react-hook-form'
import CreatableSelect from 'react-select/creatable'

interface Option {
  readonly label: string
  readonly value: string
}

interface InputCreateSelectableProps {
  options: string[]

  name: string
  key?: string
  value?: string
  control: Control<FieldValues>
  loading?: boolean
  width?: number
  isDisabled?: boolean
}

const createOption = (label: string) => ({
  label,
  value: label
})

export default function InputCreateSelectable(props: InputCreateSelectableProps) {
  const [options, setOptions] = useState<readonly Option[]>(props.options?.map((value) => createOption(value)))

  useEffect(() => {
    setOptions(props.options.map((value) => createOption(value)))
  }, [props.options])

  const handleCreate = (inputValue: string) => {
    const newOption = createOption(inputValue)
    setOptions([...options, newOption])
  }

  return (
    <FormControl w={props.width}>
      <Controller
        name={props.name}
        defaultValue={props.value || ''}
        control={props.control}
        render={({ value, onChange }) => (
          <CreatableSelect
            name={props.name}
            isClearable
            onChange={(opt) => {
              onChange(opt?.value)
            }}
            onCreateOption={(inputValue) => {
              handleCreate(inputValue)
              onChange(inputValue)
            }}
            isLoading={props.loading}
            options={options}
            value={value ? createOption(value) : undefined}
            isDisabled={props.isDisabled}
          />
        )}
      />
    </FormControl>
  )
}
