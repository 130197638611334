import { Box, Image } from '@chakra-ui/react'

import Background from '../assets/background.svg'
import { blackLogo as tech5 } from '../utils/logoUtils'

export interface LoginLayoutProps {
  children?: React.ReactNode
}

export function LoginLayout(props: LoginLayoutProps) {
  return (
    <Box position="relative" width="100vw" height="100vh">
      <Box w="376px" position="fixed" left="50%" top="50%" transform="translate(-50%, -50%)" align="left" zIndex="2">
        {props.children}
      </Box>
      <Image
        fit="cover"
        src={Background}
        position="absolute"
        width="100%"
        height="100%"
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
      <Image src={tech5} position="absolute" top={14} left={90} h="100px" />
    </Box>
  )
}
