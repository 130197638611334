import { isEmpty, isObject, transform } from 'lodash'

/**
 * Compute the difference between `object` and `base`. Returns an object with
 * those properties in `object` that don't exist in `base`.
 *
 * Adapted from https://gist.github.com/Yimiprod/7ee176597fef230d1451
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: object, base: object) {
  return transform<object, Record<string, object>>(object, (result, value, key) => {
    if (base.hasOwnProperty(key)) {
      if (isObject(value) && isObject(base[key])) {
        const nestedDiff = difference(value, base[key])
        if (!isEmpty(nestedDiff)) {
          result[key] = nestedDiff
        }
      }
    } else {
      result[key] = value
    }
  })
}

/**
 * Computes the intersection between `object` and `base`. Returns an object
 * with those properties that exist in both `object` and `base`, and have the
 * same type.
 *
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 */
export function intersection(object: object, base: object) {
  return transform<object, Record<string, object>>(object, (result, value, key) => {
    if (!base.hasOwnProperty(key) || typeof value !== typeof base[key]) {
      // skip fields that don't exist in `base` and those that have different type
      return
    }

    if (typeof value === 'object') {
      result[key] = intersection(value, base[key])
    } else {
      result[key] = value
    }
  })
}
