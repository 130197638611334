import { Box, Checkbox, Divider, Flex, Icon, Link, Text, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { JFD, JFL } from '@le2/jfd/'
import { CheckCircleOutline, DotsCircleHorizontalOutline, ExclamationCircleOutline } from 'heroicons-react'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import JFDForm from '../../components/JFDRender'
import { isEmptyGroup } from '../../components/JFDRender/utils'
import { ResourceSection, topSpace } from '../../layouts/resource'
import { contentPadding } from '../../layouts/user'
import agencyConfig from '../../lib/agencyConfig'
import { useRecordViewMode } from './hooks/useRecordViewMode'
import { BiographicSection, RecordViewMode, Status } from './interfaces'
import { biographicsState, recordState } from './state'
import { onlyMandatoryCheckbox } from './utils'
import { BiographicsSectionValidator } from './validator'

const top = `calc(${topSpace})`
const height = `calc(100vh - (${topSpace}) - ${contentPadding})`

export interface RecordInformationProps {
  action: 'create' | 'view'
}

export function RecordInformation(props: RecordInformationProps) {
  return (
    <ResourceSection bgAndPadding={false} submenu={false}>
      <Flex>
        <Box p={4} mr={2} borderRadius={4} bg="white" position="sticky" top={top} height={height}>
          <LeftNav />
        </Box>
        <JFDFormWrapper action={props.action} />
      </Flex>
    </ResourceSection>
  )
}

function LeftNav() {
  const snap = useSnapshot(recordState)
  const bioSnap = useSnapshot(biographicsState)
  usePopulateBiographicsSections()

  return (
    <>
      {onlyMandatoryCheckbox && (
        <>
          <Checkbox
            color="primary"
            textStyle="paragraph"
            fontWeight={500}
            isChecked={snap.isOnlyMandatory}
            onChange={(e) => (recordState.isOnlyMandatory = e.target.checked)}
            whiteSpace="nowrap"
          >
            Only Mandatory Fields
          </Checkbox>
          <Divider mt={4} color="gray.200" />
        </>
      )}
      <Text mt={4} fontWeight={700}>
        Sections ({bioSnap.sections.length})
      </Text>
      {bioSnap.errorCount > 0 && <TotalErrorsIndicator label={bioSnap.errorCountLabel} />}
      {bioSnap.sections.map((section, index) => {
        return (
          <Flex key={index} mt={4} alignItems="center">
            {section.status === Status.Idle && <Icon color="gray.700" as={DotsCircleHorizontalOutline} />}
            {section.status === Status.Error && <ErrorIndicator errorCount={section.errorCount} />}
            {section.status === Status.Complete && <Icon color="green.600" as={CheckCircleOutline} />}
            <Link
              ml={2}
              onClick={() => {
                const castedSection = section as BiographicSection
                const jflGroupRef = snap.jflGroupRefProvider.get(castedSection.jflGroup)
                jflGroupRef?.scrollIntoView({ behavior: 'smooth' })
              }}
            >
              {section.label}
            </Link>
          </Flex>
        )
      })}
    </>
  )
}

function usePopulateBiographicsSections() {
  const snap = useSnapshot(recordState)
  const recordJFL = snap.recordJFL as JFL

  useEffect(() => {
    const validator = new BiographicsSectionValidator(recordState.jfd, recordState.hasSaveButtonBeenClicked)

    biographicsState.sections = recordJFL.groups
      .filter((group) => !isEmptyGroup(snap.jfd, snap.isOnlyMandatory, group))
      .map((group) => {
        const result = validator.validate(group)
        return {
          ...group,
          label: group.label || '',
          status: result.status,
          errorCount: result.errorCount,
          jflGroup: group
        }
      })
  }, [recordJFL.groups, snap.jfd, snap.isOnlyMandatory, snap.recordNumber])
}

function JFDFormWrapper(props: RecordInformationProps) {
  const snap = useSnapshot(recordState)
  const viewMode = useRecordViewMode()

  return (
    <JFDForm
      key={snap.jfd.recordNumber}
      jfd={snap.jfd as JFD}
      jfl={snap.recordJFL as JFL}
      onlyMandatory={snap.isOnlyMandatory}
      readOnly={viewMode === RecordViewMode.view}
      action={props.action}
      editable={agencyConfig.features.bookings.edit}
      shouldTriggerValidation={snap.hasSaveButtonBeenClicked}
      errorCountProvider={(group) => {
        const validator = new BiographicsSectionValidator(snap.jfd, snap.hasSaveButtonBeenClicked)
        return validator.validate(group).errorCount
      }}
      jflGroupRefProvider={snap.jflGroupRefProvider}
    />
  )
}

function TotalErrorsIndicator({ label }: { label: string }) {
  const theme = useTheme()

  return (
    <Flex mt={4} py={2} px={1} alignItems="center" borderRadius="4px" bgColor={transparentize('red.500', 0.1)(theme)}>
      <Icon color="red.500" as={ExclamationCircleOutline} />
      <Text as="span" ml={2} fontSize={12} fontWeight={500}>
        {label}
      </Text>
    </Flex>
  )
}

function ErrorIndicator({ errorCount }: { errorCount: number }) {
  return (
    <Box
      bgColor="red.500"
      borderRadius="50%"
      width="14px"
      height="14px"
      fontSize="10px"
      fontWeight={700}
      color="#FEFEFE"
      textAlign="center"
    >
      {errorCount}
    </Box>
  )
}
