import get from 'lodash/get'
import { useCallback, useEffect, useState } from 'react'

import { Booking } from '../../lib/api/booking'
import { AgeFilter, Filter, SimpleFilter, ageFilterField, defaultResults, jfdInst } from './utils'

export type Filters = Record<string, string[]>

export interface UseLineupFiltersResult {
  filterFields: Filter[]
  maxResults: number
  setFromBooking: (booking: Booking | null) => void
  rollbackState: () => Filter[]
  getActiveFilters: (filter?: Filter[]) => Filter[]
  updateFilterState: () => void
  areInvalidFilterValues: boolean
  resetFilters: () => void
  onChangeMaxResults: (maxResults: number) => void
}

export default function useLineupFilters(
  fields: string[],
  initialState: Filter[] | null = null,
  initialMaxResults?: number
): UseLineupFiltersResult {
  // Creates all the filters available for lineups.
  const defaultFilterFieldValues = useCallback(() => {
    return fields.map((fieldName) => {
      return fieldName === ageFilterField ? new AgeFilter() : new SimpleFilter({ fieldName })
    })
  }, [fields])

  const [filterFields, setFilterFields] = useState<Filter[]>(
    initialState ? makeDeepCopy(initialState) : defaultFilterFieldValues()
  )
  const [defaultState, setDefaultState] = useState<Filter[]>(initialState ? makeDeepCopy(initialState) : [])
  const [maxResults, setMaxResults] = useState<number>(initialMaxResults || defaultResults)

  /**
   * Makes a deep copy of the Filter's class object provided
   * @param filtersState
   * @returns
   */
  function makeDeepCopy(filtersState: Filter[]) {
    return filtersState.map((filter) => {
      const { value, fieldName } = filter
      return fieldName === ageFilterField
        ? new AgeFilter({ value: value as string })
        : new SimpleFilter({ fieldName, value: value as string[] })
    })
  }

  /**
   * Sets the filter fields and default state when the initialValues prop changed.
   */
  useEffect(() => {
    if (initialState) {
      setDefaultState(makeDeepCopy(initialState))
      setFilterFields(makeDeepCopy(initialState))
    }
  }, [initialState])

  return {
    filterFields,
    maxResults,
    /**
     * Undo the changes and return the default state assigned.
     * @returns The default state assigned in the initialState prop.
     */
    rollbackState(): Filter[] {
      setFilterFields(makeDeepCopy(defaultState))

      return defaultState
    },
    /**
     * Get the current filters selected by the user.
     * @param filters
     * @returns Only the filters with value.
     */
    getActiveFilters(filters?: Filter[]): Filter[] {
      if (!filters) {
        filters = filterFields
      }

      return filters.filter((filter) => {
        return filter.isActive()
      })
    },
    updateFilterState(): void {
      setFilterFields([...filterFields])
    },
    /**
     * Fills the filters and replace the old ones with the data from the given Booking.
     */
    setFromBooking(booking: Booking | null) {
      if (booking) {
        const newFilterFields: Filter[] = []

        fields.forEach((fieldName) => {
          let value: string = ''
          let filter: Filter

          const [field, subfield] = fieldName.split('.')
          const isMOS = jfdInst.getFieldDef(field).getType() === 'MOS'

          if (isMOS) {
            // Gets always the first entry.
            value = get(booking.biographics, `${field}.0.${subfield}`) as string
          } else {
            value = get(booking.biographics, field) as string
          }

          if (field === ageFilterField) {
            filter = new AgeFilter({ DateOfBirth: value ? value : undefined })
          } else {
            filter = new SimpleFilter({ fieldName, value: value ? [value] : undefined })
          }

          newFilterFields.push(filter)
        })

        setFilterFields(newFilterFields)
      }
    },
    areInvalidFilterValues:
      filterFields.filter((filter) => {
        return filter.hasError
      }).length > 0,
    resetFilters: () => {
      const filters = fields.map((fieldName) => {
        return fieldName === ageFilterField ? new AgeFilter() : new SimpleFilter({ fieldName })
      })
      setFilterFields(filters)
    },
    onChangeMaxResults(maxResults: number) {
      setMaxResults(maxResults)
    }
  }
}
