import hotkeys from 'hotkeys-js'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { LE2Hotkeys, systemInfoState } from '../utils/useGetSystemInfo'

export interface DebugPreviewSetting {
  previewEnabled: boolean
}

/**
 * It creates a hotkey to toggle the debug face preview flag
 */
export function useToggleDebugPreview(doToggle: () => void) {
  const systemInfoSnap = useSnapshot(systemInfoState)
  useEffect(() => {
    const hotkey = LE2Hotkeys.ToggleDebugPreview
    if (systemInfoSnap.isHotkeyAvailable(hotkey)) {
      hotkeys(hotkey, function (event) {
        event.preventDefault()

        doToggle()
      })
    }

    // Unbind hotkeys so they don't get re-bound on next useEffect run
    return () => {
      hotkeys.unbind(hotkey)
    }
  }, [doToggle, systemInfoSnap])
}
