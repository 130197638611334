import * as React from 'react'

export interface Props extends React.SVGProps<SVGSVGElement> {
  size?: number
}

/**
 * Same as https://github.com/ecklf/heroicons-react/blob/main/src/icons/XOutline.tsx
 * but with `strokeWidth=4`.
 */
function XOutlineThick({ size = 24, ...props }: Props, svgRef: React.Ref<SVGSVGElement>) {
  return (
    <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" width={size} height={size} ref={svgRef} {...props}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={4} d="M6 18L18 6M6 6l12 12" />
    </svg>
  )
}

const ForwardRef = React.forwardRef(XOutlineThick)
export default ForwardRef
