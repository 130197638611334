import { gql, useQuery } from '@apollo/client'

import { Record as T5Record } from './record.interface'
import { RecordsResponse, mapRecordResponse } from './utils'

export const GET_RECORD = gql`
  query GetRecord($recordNum: String) {
    records(where: { recordNum: $recordNum }) {
      id
      recordNum
      biographics
      enrollmentStatus
      imageMetadata
      createdAt
      updatedAt
      isActive
      isSealed
    }
  }
`

/**
 * Query the given record from the API.
 * @param recordNum
 * @param onCompleted
 */
export default function useGetRecord(recordNum: string, onCompleted: (record: T5Record) => void) {
  return useQuery<RecordsResponse>(GET_RECORD, {
    variables: { recordNum },

    onCompleted: (data) => {
      const resp = data.records[0]
      if (resp) {
        const record = mapRecordResponse(resp)
        onCompleted(record)
      }
    }
  })
}
