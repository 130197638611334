import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertDescription,
  AlertDialogFooter,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Input,
  InputGroup,
  SimpleGrid,
  Switch,
  Text,
  VStack
} from '@chakra-ui/react'
import { Permission, permissionMapper } from '@le2/permissions'
import { ExclamationCircleOutline, InformationCircleOutline } from 'heroicons-react'
import { ChangeEvent, Fragment, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { useMemo } from 'react'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { useSnapshot } from 'valtio'

import { useFindGroupWithName, useSearchVars } from '../hooks/useGetGroups'
import { PermissionData, PermissionModule } from '../types'
import {
  defaultGroupMessage,
  getChildrenPermissions,
  getParentPermissions,
  groupState,
  mapModulePermissions,
  modulesWithPermissions
} from '../utils'
import { FormRefI } from './GroupUpsertDialog'

interface DetailPermissionsProps {
  formRef: React.RefObject<FormRefI>
}

interface FormInfo extends PermissionFields {
  groupName: string
}

interface PermissionFields {
  [key: string]: boolean | string
}

export default function DetailPermissions({ formRef }: DetailPermissionsProps) {
  const { isEditable: isEditableSnap, permissions: permissionsSnap } = useSnapshot(groupState)

  // List of all modules with their corresponding permissions.
  const moduleList = useMemo(() => {
    // Display the regular modules with their permissions.
    if (isEditableSnap) {
      return modulesWithPermissions
    } else {
      // Display only the assigned permissions to the group.
      return mapModulePermissions(permissionMapper(permissionsSnap))
    }
  }, [permissionsSnap, isEditableSnap])

  const formMethods = useForm<FormInfo>({
    defaultValues: {
      groupName: groupState.name,
      ...groupState.permissions.reduce((permissions, permission) => {
        permissions[permission] = true

        return permissions
      }, {} as Record<string, boolean>)
    }
  })

  const { handleSubmit, errors, register, setError, clearErrors } = formMethods

  const [permissionErrors, setPermissionErrors] = useState<string | undefined>(undefined)

  const [isTyping, setIsTyping] = useState(false)

  const { debouncedSearch: groupName, handleSearch } = useSearchVars()

  const { groups, loading } = useFindGroupWithName(groupName)
  const [showAlert, setShowAlert] = useState(false)
  const [parentUncheckConfirm, setParentUncheckConfirm] = useState(false)
  const [unCheckedParentName, setUnCheckedParentName] = useState<string>('')

  useImperativeHandle(formRef, () => ({
    // Returns if the form is valid.
    submit() {
      return new Promise((resolve) => {
        if (!validateExtra()) {
          resolve(false)
        } else {
          // Submits the form
          handleSubmit(
            (values) => {
              if (isEditableSnap) {
                const { groupName, ...permissions } = values
                if (getAppliedPermissions(permissions).length === 0) {
                  setPermissionErrors('The group requires a minimum of one permission')
                  resolve(false)
                } else {
                  setPermissionErrors(undefined)
                }

                onSubmit(values)
              }
              resolve(true)
            },
            // The form has invalid values
            () => {
              resolve(false)
            }
          )()
        }
      })
    }
  }))

  function validateExtra() {
    // Validates if there isn't any error, the group name validation is on process.
    if (Object.keys(errors).length > 0 || loading || isTyping) {
      return false
    }

    return true
  }

  function getAppliedPermissions(permissionFields: PermissionFields) {
    return Object.keys(permissionFields).reduce((data, key) => {
      // Verifies if the permission field is checked and it's not a module field.
      if (Boolean(permissionFields[key] && key.includes(':'))) {
        data.push(key as Permission)
      }

      return data
    }, [] as Permission[])
  }

  function onSubmit(values: FormInfo) {
    const { groupName, ...permissionFields } = values

    groupState.name = groupName as string
    groupState.permissions = getAppliedPermissions(permissionFields)
  }

  useEffect(() => {
    if (groups.length > 0 && !errors.groupName) {
      setError('groupName', { type: 'custom', message: 'Group name already exists' })
    }

    setIsTyping(false)
  }, [groups, setError, errors.groupName])

  const onChangeName = function (e: ChangeEvent<HTMLInputElement>) {
    clearErrors()
    handleSearch(e)
  }

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    if (groupState.name === e.target.value) {
      clearErrors()
    } else {
      setIsTyping(true)
      onChangeName(e)
    }
  }

  const areInputsDisabled = !isEditableSnap

  return (
    <Fragment>
      {areInputsDisabled && <DefaultGroupMessage />}
      <form id="createGroupPermission" onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid minChildWidth={365} columns={2} spacing={10}>
          <FormControl isRequired={true} isInvalid={Boolean(errors.groupName)}>
            <FormLabel textStyle="details" color="text.details" fontSize={16}>
              Group Name
            </FormLabel>
            <InputGroup>
              <Input
                isDisabled={areInputsDisabled}
                name="groupName"
                autoComplete="off"
                type="text"
                ref={register({
                  required: 'The group name is required.',
                  validate: {}
                })}
                maxLength={32}
                minLength={1}
                onChange={handleOnChange}
              />
            </InputGroup>

            {(isTyping || loading) && (
              <FormHelperText fontWeight={400} fontSize={12} mt="6px">
                Loading...
              </FormHelperText>
            )}
            {!isTyping && !loading && groupName.length > 0 && !errors.groupName && (
              <FormHelperText color="forestgreen" fontWeight={400} fontSize={12} mt="6px">
                The group name is available.
              </FormHelperText>
            )}
            <FormErrorMessage fontWeight={400} fontSize={12} mt="6px">
              {errors.groupName && errors.groupName.message}
            </FormErrorMessage>
          </FormControl>
        </SimpleGrid>
        <Heading textStyle="subtitle" color="gray.700" fontWeight={700} fontSize={16} my="16px">
          Add Permissions
        </Heading>
        {permissionErrors && (
          <Text color="red.500">
            <Icon w={5} h={5} mr={2} as={ExclamationCircleOutline} /> {permissionErrors}
          </Text>
        )}
        <FormProvider {...formMethods}>
          <Accordion allowToggle reduceMotion>
            {moduleList.map((module, index) => (
              <Module
                module={module}
                index={index}
                key={index}
                isReadOnly={areInputsDisabled}
                setShowAlert={setShowAlert}
                parentUncheckConfirm={parentUncheckConfirm}
                setParentUncheckConfirm={setParentUncheckConfirm}
                unCheckedParentName={unCheckedParentName}
                setUnCheckedParentName={setUnCheckedParentName}
              />
            ))}
          </Accordion>
        </FormProvider>
      </form>
      {/* TODO Add buttons funcionality. Prevent unchecked when cancel and uncheck element and children when confirm */}
      {showAlert && (
        <Alert status="error" borderRadius="lg" bgColor="red.100">
          <AlertIcon alignSelf="baseline" />
          <Box>
            <AlertTitle>Edit records contains dependents</AlertTitle>
            <AlertDescription>
              All the permissions associated will be deselected. Are you sure to continue with this operation?
            </AlertDescription>
            <AlertDialogFooter>
              <Button variant="secondary" onClick={() => setShowAlert(false)}>
                Cancel
              </Button>
              <Button
                ml={3}
                onClick={() => {
                  setParentUncheckConfirm(true)
                  setShowAlert(false)
                }}
              >
                Continue
              </Button>
            </AlertDialogFooter>
          </Box>
        </Alert>
      )}
    </Fragment>
  )
}

interface ModuleProps {
  module: PermissionModule
  index: number
  isReadOnly: boolean
  setShowAlert: (alert: boolean) => void
  parentUncheckConfirm: boolean
  setParentUncheckConfirm: (check: boolean) => void
  unCheckedParentName: string
  setUnCheckedParentName: (name: string) => void
}

function Module({
  module,
  index,
  isReadOnly,
  setShowAlert,
  parentUncheckConfirm,
  setParentUncheckConfirm,
  unCheckedParentName,
  setUnCheckedParentName
}: ModuleProps) {
  const { control, setValue, getValues } = useFormContext()

  function toggleModulePermissions(isChecked: boolean) {
    module.permissions.forEach((permission) => {
      setValue(permission.name, isChecked)
    })
  }

  const checkSelectAllStatus = useCallback(
    (isChecked: boolean) => {
      let areAllChecked = true

      const values = getValues()

      if (isChecked) {
        for (const permission of module.permissions) {
          if (!values[permission.name]) {
            areAllChecked = false
            break
          }
        }
      } else {
        areAllChecked = false
      }

      setValue(module.name, areAllChecked)
    },
    [setValue, getValues, module.name, module.permissions]
  )

  useEffect(() => checkSelectAllStatus(true), [checkSelectAllStatus])

  return (
    <AccordionItem border="none" tabIndex={index}>
      <AccordionButton pl={0} _focus={{ outline: 'none', boxShadow: 'none' }}>
        <Box flex="1" textAlign="left" fontWeight={700} fontSize={16} textTransform="uppercase">
          {module.name}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4} pl={1} height="auto" maxHeight="200px" overflowY={'auto'}>
        {isReadOnly ? (
          <ReadOnlyPermissions permissions={module.permissions} />
        ) : (
          <FormControl>
            <HStack py={1}>
              <Controller
                defaultValue={false}
                control={control}
                name={module.name}
                render={({ name, value, onChange }) => (
                  <Switch
                    id={name}
                    name={name}
                    isChecked={value}
                    onChange={(e) => {
                      const isChecked = e.target.checked
                      onChange(isChecked)
                      toggleModulePermissions(isChecked)
                    }}
                  />
                )}
              />

              <FormLabel pt="5px" htmlFor={module.name}>
                Select all
              </FormLabel>
            </HStack>
            {module.permissions.map((permission, key) => {
              return (
                <PermissionField
                  permission={permission}
                  module={module.name}
                  key={key}
                  onChangeValue={checkSelectAllStatus}
                  setShowAlert={setShowAlert}
                  parentUncheckConfirm={parentUncheckConfirm}
                  setParentUncheckConfirm={setParentUncheckConfirm}
                  unCheckedParentName={unCheckedParentName}
                  setUnCheckedParentName={setUnCheckedParentName}
                />
              )
            })}
          </FormControl>
        )}
      </AccordionPanel>
    </AccordionItem>
  )
}

interface PermissionFieldProps {
  permission: PermissionData
  module: string
  onChangeValue: (isChecked: boolean) => void
  setShowAlert: (alert: boolean) => void
  parentUncheckConfirm: boolean
  setParentUncheckConfirm: (check: boolean) => void
  unCheckedParentName: string
  setUnCheckedParentName: (name: string) => void
}

function PermissionField({
  permission,
  module,
  onChangeValue,
  setShowAlert,
  parentUncheckConfirm,
  setParentUncheckConfirm,
  unCheckedParentName,
  setUnCheckedParentName
}: PermissionFieldProps) {
  const { control, errors, setValue, watch } = useFormContext()
  const { name } = permission
  const parentPermissions = getParentPermissions(permission.name, module)
  const childrenPermissions = getChildrenPermissions(permission.name, module)

  // Watch current field and parent fields
  const isCheckedCurrentField = watch(name, false)
  const watchParentFields = watch(
    parentPermissions.map(({ name }) => {
      return name
    })
  )

  // Enable field if permission has no parent permissions or if at least one of the parent permissions is checked
  const isEnableCurrentField =
    Object.keys(watchParentFields).length === 0 || Object.entries(watchParentFields).some(([name, value]) => value)

  useEffect(() => {
    // Set child permissions as false when parent permission is unchecked
    if (
      Object.keys(watchParentFields).length > 0 &&
      Object.entries(watchParentFields).every(([name, value]) => !value) &&
      isCheckedCurrentField
    ) {
      setValue(name, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchParentFields, setValue, isCheckedCurrentField])

  useEffect(() => {
    if (parentUncheckConfirm && setUnCheckedParentName.length !== 0) {
      setValue(unCheckedParentName, false)
      setParentUncheckConfirm(false)
      onChangeValue(false)
    }
  }, [
    parentUncheckConfirm,
    setValue,
    name,
    setUnCheckedParentName,
    setParentUncheckConfirm,
    unCheckedParentName,
    onChangeValue
  ])

  return (
    <Box minHeight={10}>
      <FormControl isInvalid={Boolean(errors[name])}>
        <HStack py={1} alignItems="baseline">
          <Controller
            control={control}
            name={name}
            defaultValue={false}
            render={({ value, ref, onChange, name }) => (
              <Switch
                id={name}
                isDisabled={!isEnableCurrentField}
                isChecked={value}
                onChange={(e) => {
                  const isChecked = e.target.checked
                  if (!isChecked && childrenPermissions.length > 0) {
                    setShowAlert(true)
                    setUnCheckedParentName(name)
                  } else {
                    onChange(isChecked)
                    onChangeValue(isChecked)
                  }
                }}
                pt={1}
              />
            )}
          />

          <VStack justifyContent="start" spacing={1}>
            <FormLabel htmlFor={name} alignSelf="start" mb={0}>
              {permission.label}
            </FormLabel>
            {parentPermissions.length > 0 && (
              <FormHelperText alignSelf="start" fontSize={10}>
                {`This permission depends on <${parentPermissions.map(({ label }) => label).join(', ')}>`}
              </FormHelperText>
            )}
            {childrenPermissions.length > 0 && (
              <FormHelperText alignSelf="start" fontSize={10}>
                {`This permission contains dependents on <${childrenPermissions.map(({ label }) => label).join(', ')}>`}
              </FormHelperText>
            )}
          </VStack>
        </HStack>
      </FormControl>
    </Box>
  )
}

interface ReadOnlyPermissionsProps {
  permissions: PermissionData[]
}

function ReadOnlyPermissions({ permissions }: ReadOnlyPermissionsProps) {
  if (permissions.length > 0) {
    return (
      <Text fontSize={12} alignItems="baseline">
        <Text as="span" fontWeight="700" mr={2}>
          Selected:
        </Text>
        {permissions.map(({ label }) => label).join(', ')}
      </Text>
    )
  }

  return (
    <Text fontWeight="700" fontSize={12}>
      No permissions assigned for this module
    </Text>
  )
}

function DefaultGroupMessage() {
  return (
    <Box backgroundColor="green.200" borderRadius={8} mb={4} p={3}>
      <HStack>
        <Icon w={5} h={5} as={InformationCircleOutline} />
        <Text color="gray.700" fontSize={16} fontWeight="700">
          Default Group
        </Text>
      </HStack>
      <Text fontSize={14} fontWeight="400">
        {defaultGroupMessage}
      </Text>
    </Box>
  )
}
