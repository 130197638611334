import { useEffect, useState } from 'react'

// TODO: Receive the state as a parameter
import { HubStatus } from '../IWNativeExtDeviceDefines'
import { nativeExtensionsState } from '../nativeextState'

/*
 * This hook starts a connection to the NativeExtension hub when is mounted.
 * It only starts a connection if the HubStatus is DisConnected or Closed.
 * It keeps the connection open until the user closes the browser or
 * the NativeExtension server is turned off.
 * In order to start a connection is required to subscribe to the Hub Events first
 * using the useSubscribeHubEvents hook.
 */

export function useConnectToDeviceHub() {
  const [isConnecting, setIsConnecting] = useState(true)
  useEffect(() => {
    if (
      nativeExtensionsState.deviceHubStatus === HubStatus.DisConnected ||
      nativeExtensionsState.deviceHubStatus === HubStatus.Closed
    ) {
      // Connect to the hub
      nativeExtensionsState.deviceManager.hubConnect().then(() => setIsConnecting(false))
    }
  }, [])

  return isConnecting
}
