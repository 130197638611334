import { Modality } from '@le2/jfd'

import { BioType, fingerprintBioTypes, irisBioTypes, palmBioTypes } from '../../lib/api/booking'
import { ImageMimeType, convertB64DataToDataURI } from '../../lib/imageUtils'
// TODO: Refactor Fingerprint constants
import {
  FingerprintScanType,
  ScanOrder,
  ScanStep,
  fingerPosition,
  fourSlapFingers,
  isFingerprintDefinition
} from '../../pages/booking/fingerprint/constants'
import { Booking } from '../../pages/booking/interfaces'
import { activeCaptureAnnotationCode } from '../../utils/captureAnnotations'
import {
  CameraCommandOption,
  DeviceEventCodes,
  DeviceInfo,
  DeviceOptions,
  IWImage,
  ModalityDefinitions,
  ScanResultStatus
} from '../IWNativeExtDeviceDefines'
import { ptzCameraConfiguration } from '../configuration'

export function drawCanvas(canvasRef: React.RefObject<HTMLCanvasElement>, imageData: string, mimeType: ImageMimeType) {
  if (canvasRef.current) {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    if (context && imageData) {
      const image = new Image() // Using optional size for image
      const imageSrc = convertB64DataToDataURI(imageData, mimeType)
      image.src = imageSrc

      image.onload = function () {
        let imgWidth = image.naturalWidth
        let controlWidth = canvas.width
        let scaleX = 1
        if (imgWidth > controlWidth) scaleX = controlWidth / imgWidth
        let imgHeight = image.height
        let controlHeight = canvas.height
        let scaleY = 1
        if (imgHeight > controlHeight) scaleY = controlHeight / imgHeight
        let scale = scaleY
        if (scaleX < scaleY) scale = scaleX
        if (scale < 1) {
          imgHeight = imgHeight * scale
          imgWidth = imgWidth * scale
        }

        context.fillStyle = 'rgba(92, 92, 92, 0.5)' // partially transparent
        context.fillRect(0, 0, controlWidth, controlHeight)
        context.clearRect(0, 0, canvas.width, canvas.height)

        context.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          canvas.width / 2 - imgWidth / 2,
          canvas.height / 2 - imgHeight / 2,
          imgWidth,
          imgHeight
        )
      }
    }
  }
}

export function clearCanvas(canvasRef: React.RefObject<HTMLCanvasElement>) {
  if (canvasRef.current) {
    const canvas = canvasRef.current
    const context = canvas.getContext('2d')
    if (context) {
      context.fillStyle = 'rgba(92, 92, 92, 0.5)' // partially transparent
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.clearRect(0, 0, canvas.width, canvas.height)
    }
  }
}

export function calculateProgress(modality: ModalityDefinitions, eventCode: number): number | null {
  if (modality === ModalityDefinitions.Fingerprint) {
    switch (eventCode) {
      case DeviceEventCodes.CaptureSessionStarted:
        return 5
      case DeviceEventCodes.CapturePreviewImageReceived:
        return 10
      case DeviceEventCodes.CapturePreviewEnded:
        return 20
      case DeviceEventCodes.CaptureAcquisitionImageReceived:
        return 50
      case DeviceEventCodes.CaptureAcquisitionEnded:
        return 65
      case DeviceEventCodes.SequenceCheckStarted:
        return 70
      case DeviceEventCodes.SequenceCheckCompleted:
        return 100
      default:
        return null
    }
  } else {
    switch (eventCode) {
      case DeviceEventCodes.CaptureSessionStarted:
        return 10
      case DeviceEventCodes.CapturePreviewImageReceived:
        return 20
      case DeviceEventCodes.CapturePreviewEnded:
        return 50
      case DeviceEventCodes.CaptureAcquisitionImageReceived:
        return 70
      case DeviceEventCodes.CaptureAcquisitionEnded:
      case DeviceEventCodes.CaptureSessionClosed:
        return 100
      default:
        return null
    }
  }
}

export function getSequencerQualityResult(scoresStr: string[]): ScanResultStatus {
  const scores: number[] = scoresStr.map((score) => parseInt(score) || -1)
  const filtered = scores.filter((score) => score !== -1)

  if (!filtered.length) {
    return ScanResultStatus.NoQualityScore
  }

  const maxNumber = Math.max(...scores)

  if (maxNumber === 1 || maxNumber === 2) {
    return ScanResultStatus.GoodQuality
  } else if (maxNumber === 3) {
    return ScanResultStatus.AcceptableQuality
  } else {
    return ScanResultStatus.PoorQuality
  }
}

export function getPalmSequencerQualityResult(scoresStr: string): ScanResultStatus {
  return getSequencerQualityResult(
    scoresStr.split('|').map((element) => {
      const item = element.split(':')
      return item.length > 1 ? item[1] : item.length > 0 ? item[0] : '-1'
    })
  )
}

export function getScanCaptureQualityResult(scoresStr: string[]): ScanResultStatus {
  const scores: number[] = scoresStr.map((score) => parseInt(score) || -1)
  const filtered = scores.filter((score) => score !== -1)

  if (!filtered.length) {
    return ScanResultStatus.NoQualityScore
  }

  const minNumber = Math.min(...filtered)

  if (minNumber >= 80) {
    return ScanResultStatus.GoodQuality
  } else if (minNumber >= 60) {
    return ScanResultStatus.AcceptableQuality
  } else {
    return ScanResultStatus.PoorQuality
  }
}

export function isStepValid(scanElements: { [x: string]: string }, step: ScanStep): boolean {
  if (Array.isArray(step)) {
    return step.some((bioTypeStep) => scanElements[bioTypeStep] === activeCaptureAnnotationCode)
  }

  if (!isFingerprintDefinition(step)) {
    return scanElements[step] === activeCaptureAnnotationCode
  }

  if (step.type === FingerprintScanType.Slap) {
    return fourSlapFingers.some((finger) => scanElements[`${step.hand}_${finger}`] === activeCaptureAnnotationCode)
  }
  return scanElements[`${step.hand}_${step.scan}`] === activeCaptureAnnotationCode
}

export function generateValidStepIndex(
  scanElements: { [x: string]: string },
  scanOrder: ScanOrder,
  index: number
): number {
  let nextIndex = index + 1
  while (nextIndex < scanOrder.length && !isStepValid(scanElements, scanOrder[nextIndex])) {
    nextIndex += 1
  }
  return nextIndex >= scanOrder.length ? -1 : nextIndex
}

export function getMissingDigits(scanElements: { [x: string]: string }, modality: ModalityDefinitions) {
  const elementsArray = Object.entries(scanElements)
  const missingDigits = []

  for (let index = 0; index < elementsArray.length; index++) {
    const [name, state] = elementsArray[index]
    if (state !== activeCaptureAnnotationCode) {
      const missingDigit = modality === ModalityDefinitions.Fingerprint ? fingerPosition[name] : name
      missingDigits.push(missingDigit)
    }
  }

  return missingDigits.toString()
}

export function getIWImages(bookingState: Booking, modality: ModalityDefinitions) {
  switch (modality) {
    case ModalityDefinitions.Fingerprint:
      return bookingState.bookingImages.getIWImagesByBioType(fingerprintBioTypes)
    case ModalityDefinitions.Palm:
      return bookingState.bookingImages.getIWImagesByBioType(palmBioTypes)
    case ModalityDefinitions.Iris:
      return bookingState.bookingImages.getIWImagesByBioType(irisBioTypes)
    default:
      return []
  }
}

export function deleteBookingImages(bookingState: Booking, modality: ModalityDefinitions) {
  switch (modality) {
    case ModalityDefinitions.Fingerprint:
      bookingState.bookingImages.deleteImagesByBioType(fingerprintBioTypes)
      break
    case ModalityDefinitions.Palm:
      bookingState.bookingImages.deleteImagesByBioType(palmBioTypes)
      break
    case ModalityDefinitions.Iris:
      bookingState.bookingImages.deleteImagesByBioType(irisBioTypes)
      break
  }
}

export function getImageInfo(image: IWImage) {
  const format = image.metaData.ImageFormat
  const captureType = image.metaData.CaptureImageType
  const dataURI = convertB64DataToDataURI(image.imageData, `image/${format}`)
  const wsqB64 = image.metaData.CompressedImage
  const bioType = BioType[captureType as keyof typeof BioType]
  return { dataURI, wsqB64, bioType }
}

/**
 * Maps Native Extensions ModalityDefinitions to JFD Modality
 */
export const mapModalityDefinitionToModality: Map<ModalityDefinitions, Modality> = new Map<
  ModalityDefinitions,
  Modality
>([
  [ModalityDefinitions.Fingerprint, Modality.Fingerprint],
  [ModalityDefinitions.Palm, Modality.Palm],
  [ModalityDefinitions.Iris, Modality.Iris],
  [ModalityDefinitions.Face, Modality.Face],
  [ModalityDefinitions.SMT, Modality.SMT]
])

/**
 * Adds the Native Extensions provided info about the device used to capture the images for a given modality
 *  to the map of modality capture devices kept the booking state
 * @param bookingState
 * @param modalityDefinition
 * @param deviceInfo
 * @returns n/a
 */
export function addModalityCaptureDeviceInfoToBookingState(
  bookingState: Booking,
  modalityDefinition: ModalityDefinitions,
  deviceInfo: DeviceInfo
) {
  try {
    const modality = mapModalityDefinitionToModality.get(modalityDefinition)
    if (undefined === modality) return // no such modality supported
    bookingState.captureDevices.set(modality, deviceInfo)
  } catch (e) {
    console.log(
      `Exception in addModalityCaptureDeviceInfoToBookingState(), modality: ${modalityDefinition}, make: ${deviceInfo.Manufacturer}, model: ${deviceInfo.Model}, serialNo: ${deviceInfo.SerialNo}, exception: ${e}`
    )
  }
}

/**
 * Removes the Native Extensions provided info about the device used to capture the images for a given modality
 *  from the map of modality capture devices kept the booking state
 * @param bookingState
 * @param modalityDefinition
 * @returns n/a
 */
export function removeModalityCaptureDeviceInfoFromBookingState(
  bookingState: Booking,
  modalityDefinition: ModalityDefinitions
) {
  try {
    const modality = mapModalityDefinitionToModality.get(modalityDefinition)
    if (undefined === modality) return // no such modality supported
    bookingState.captureDevices.delete(modality)
  } catch (e) {
    console.log(
      `Exception in removeModalityCaptureDeviceInfoFromBookingState(), modality: ${modalityDefinition}, exception: ${e}`
    )
  }
}

/**
 * List of the camera commands that control the PTZ directional moves
 */
export const cameraCommandsPanTiltPositionMove: DeviceOptions[] = [
  DeviceOptions.CameraPanTiltUp,
  DeviceOptions.CameraPanTiltUpRight,
  DeviceOptions.CameraPanTiltRight,
  DeviceOptions.CameraPanTiltDownRight,
  DeviceOptions.CameraPanTiltDown,
  DeviceOptions.CameraPanTiltDownLeft,
  DeviceOptions.CameraPanTiltLeft,
  DeviceOptions.CameraPanTiltUpLeft
]

/**
 * List of the camera commands that set a position
 */
export const cameraCommandsPanTiltPositionSet = [
  DeviceOptions.CameraPanTiltAbsolute,
  DeviceOptions.CameraPanTiltRelative
]

/**
 * List of the camera commands that zoom
 */
export const cameraCommandsZoomMove = [DeviceOptions.CameraZoomIn, DeviceOptions.CameraZoomOut]
export const cameraCommandsZoomAbsolute = [DeviceOptions.CameraZoomAbsolute]

/**
 * List of the camera commands that focus
 */
export const cameraCommandsFocusMove = [DeviceOptions.CameraFocusIn, DeviceOptions.CameraFocusOut]
export const cameraCommandsFocusAbsolute = [DeviceOptions.CameraFocusAbsolute]

/**
 * Get the respective camera commands that stops a move command
 */
export const getCameraStopCommandForCommand = (commandName: DeviceOptions): DeviceOptions | null => {
  let result = null // default is null
  // some commands can run until explicitly stopped
  // for such commands, get the name of the respective stop command
  try {
    if (commandName) {
      if (cameraCommandsPanTiltPositionMove.includes(commandName)) result = DeviceOptions.CameraPanTiltStop
      else if (cameraCommandsZoomMove.includes(commandName)) result = DeviceOptions.CameraZoomStop
      else if (cameraCommandsFocusMove.includes(commandName)) result = DeviceOptions.CameraFocusStop
    }
  } catch (ex) {
    console.error(`GetCameraStopCommandNameForCommand, exception: ${ex}`)
  }

  return result
}

/**
 * List of the camera commands that move the unit
 */
export const isMoveCommand = (commandName: DeviceOptions): boolean => {
  let result: boolean = false
  try {
    if (
      commandName &&
      (cameraCommandsPanTiltPositionMove.includes(commandName) ||
        cameraCommandsZoomMove.includes(commandName) ||
        cameraCommandsFocusMove.includes(commandName))
    ) {
      result = true
    }
  } catch (ex) {
    console.error(`isMoveCommand, exception: ${ex}`)
  }
  return result
}

export const keyNameToCameraCommand = (keyEvent: KeyboardEvent): DeviceOptions | undefined => {
  let result: DeviceOptions | undefined = undefined
  try {
    result = mapKeyboardKeyToCameraCommand.get(keyEvent?.key)
    // TODO: Do we need to check for Alt/Ctrl/Shift?
  } catch (ex) {
    console.error(`keyNameToCameraCommand, exception: ${ex}`)
  }
  return result
}

/**
 * Maps keyboard key names to Native Extensions PTZ Camera commands
 */
export const mapKeyboardKeyToCameraCommand: Map<string, DeviceOptions> = new Map<string, DeviceOptions>([
  ['ArrowDown', DeviceOptions.CameraPanTiltDown],
  ['ArrowUp', DeviceOptions.CameraPanTiltUp],
  ['ArrowLeft', DeviceOptions.CameraPanTiltLeft],
  ['ArrowRight', DeviceOptions.CameraPanTiltRight],
  ['ShiftArrowDown', DeviceOptions.CameraZoomOut],
  ['ShiftArrowUp', DeviceOptions.CameraZoomIn]
])

/**
 * Maps keyboard key names to Native Extensions PTZ Camera commands
 */
export const mapKeyboardKeyToCameraCommandName: Map<string, string> = new Map<string, string>([
  ['ArrowDown', DeviceOptions[DeviceOptions.CameraPanTiltDown]],
  ['ArrowUp', DeviceOptions[DeviceOptions.CameraPanTiltUp]],
  ['ArrowLeft', DeviceOptions[DeviceOptions.CameraPanTiltLeft]],
  ['ArrowRight', DeviceOptions[DeviceOptions.CameraPanTiltRight]],
  ['ShiftArrowDown', DeviceOptions[DeviceOptions.CameraZoomOut]],
  ['ShiftArrowUp', DeviceOptions[DeviceOptions.CameraZoomIn]]
])

export const defaultCameraCommandOptions: Map<string, string> = new Map<string, string>([
  [CameraCommandOption[CameraCommandOption.Speed], ptzCameraConfiguration.defaultSpeed.toString()],
  [CameraCommandOption[CameraCommandOption.PanSpeed], ptzCameraConfiguration.panSpeed.toString()],
  [CameraCommandOption[CameraCommandOption.TiltSpeed], ptzCameraConfiguration.tiltSpeed.toString()],
  [
    CameraCommandOption[CameraCommandOption.StepCount],
    ptzCameraConfiguration.useDiscreteCommandMode
      ? 0 < ptzCameraConfiguration.stepCount
        ? ptzCameraConfiguration.stepCount.toString()
        : '1'
      : '0'
  ],
  [CameraCommandOption[CameraCommandOption.StepTime], ptzCameraConfiguration.stepTime.toString()]
])

export const defaultCameraCommandOptionsMaxSpeed: Map<string, string> = new Map<string, string>([
  [CameraCommandOption[CameraCommandOption.Speed], '100'],
  [CameraCommandOption[CameraCommandOption.PanSpeed], '100'],
  [CameraCommandOption[CameraCommandOption.TiltSpeed], '100'],
  [
    CameraCommandOption[CameraCommandOption.StepCount],
    ptzCameraConfiguration.useDiscreteCommandMode
      ? 0 < ptzCameraConfiguration.stepCount
        ? ptzCameraConfiguration.stepCount.toString()
        : '1'
      : '0'
  ]
])
