import { useDisclosure } from '@chakra-ui/react'
import { ContextMenuTrigger } from 'react-contextmenu'

import Image from '../lib/api/booking/Image'
import { convertBase64ToBlob } from '../lib/imageUtils'
import PhotoDisplay, { PhotoDisplayProps } from './PhotoDisplay'
import { useContextMenuId } from './contextMenu'
import { ImageModal } from './imageModals'

export interface PhotoDisplayWithMenuProps extends PhotoDisplayProps {
  bookingImage?: PhotoDisplayImage | null
  exportedFileName?: string
}

// note: this is a subset of BookingImage<BookingImageMetadata> with only the
// stuff used in this component; (hopefully) Typescript will handle the types
export interface PhotoDisplayImage {
  image: Image
  metadata?: {
    PhotoDate?: string
  }
}

export default function PhotoDisplayWithMenu(props: PhotoDisplayWithMenuProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const menuId = useContextMenuId()

  const photoDate = props.bookingImage?.metadata?.PhotoDate
  let extraDetails
  if (photoDate) {
    extraDetails = [{ key: 'photoDate', label: 'Photo Date', value: photoDate }]
  }

  function onDownload() {
    if (props.src) {
      exportImage(props.exportedFileName ?? 'raw_image.jpeg', convertBase64ToBlob(props.src))
    }
  }

  function exportImage(filename: string, image: Blob) {
    const anchor = window.document.createElement('a')
    anchor.href = URL.createObjectURL(image)
    anchor.download = filename
    // Append anchor to body and start download
    document.body.appendChild(anchor)
    anchor.click()
    // Remove anchor from body
    document.body.removeChild(anchor)
  }

  return (
    <>
      <ContextMenuTrigger id={menuId}>
        <PhotoDisplay {...props} onView={props.onView ?? onOpen} onDownload={onDownload} />
      </ContextMenuTrigger>

      <ImageModal image={props.bookingImage?.image} isOpen={isOpen} onClose={onClose} extraDetails={extraDetails} />
    </>
  )
}
