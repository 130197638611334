import {
  Box,
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from '@chakra-ui/react'

import { BioType, Booking } from '../../../lib/api/booking'
import { UseLineupFiltersResult } from '../useLineupFilters'
import ImportedLineupMessage from './ImportedLineupMessage'
import LineupFilters from './LineupFilters'

interface LineupFiltersDialogProps extends UseLineupFiltersResult {
  isOpen: boolean
  onClose: () => void
  onCancel: () => void
  onUpdate: () => void
  booking: Booking
  bioType?: BioType
  setBioType: (val: BioType) => void
  updateButtonText?: string
}

export default function UpdateLineupFiltersDialog(props: LineupFiltersDialogProps) {
  const {
    isOpen,
    onClose,
    onCancel,
    onUpdate,
    booking,
    bioType,
    setBioType,
    updateButtonText = 'Update',
    maxResults,
    onChangeMaxResults,
    ...filters
  } = props

  function onCancelHandler() {
    onCancel()
    onClose()
  }

  function onUpdateHandler() {
    onUpdate()
    onClose()
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader px={4} pb={0} pt={6} textStyle="title" color="gray.700">
          Create Lineup
          <ImportedLineupMessage booking={booking} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px={4} pb={4}>
          <Text textStyle="paragraph" color="gray.700">
            Update the search parameters you need.
          </Text>
          <Box mt={4}>
            <LineupFilters
              maxResults={maxResults}
              onChangeMaxResults={onChangeMaxResults}
              booking={booking}
              {...filters}
              bioType={bioType}
              setBioType={setBioType}
            />
          </Box>
        </ModalBody>
        <Box px={4}>
          <Divider color="gray.200" />
        </Box>
        <ModalFooter>
          <Button variant="secondary" mr={3} onClick={onCancelHandler}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onUpdateHandler} disabled={filters.areInvalidFilterValues}>
            {updateButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
