import { gql } from '@apollo/client'
import axios, { Canceler } from 'axios'

import client from '../../../apollo/Connect'
import {
  convertB64DataToBinary,
  convertBinaryToDataURI,
  convertDataURIToBinary,
  getMIMETypeFromDataURI
} from '../../imageUtils'

/**
 * If this class is used inside of Valtio, the methods UPLOAD, ABORTUPLOAD, DELETE, and
 * the getter SIZE must be used with the real state. They should not be used with a snapshot.
 * Since these methods modify the directly the state, they need to be used with the real state.
 * A snapshot should not modify the state.
 */

export default class Image {
  id: string | undefined
  src: string
  _size: number | undefined
  wsqSrc: string | undefined

  /**
   * Indicates if `src` is an URL otherwise it's the binary data.
   */
  isURL = false

  isUploaded = false
  isUploading = false
  isDownloading = false
  isDownloaded = false
  isUploadAborted = false
  hasUploadFailed = false
  hasDownloadFailed = false
  abortUpload: Canceler | undefined

  // Expected field name for uploaded images in the Fastify Request body
  IMAGE_PARAMETER_KEY_NAME = 'images'

  constructor(src: string, wsqSrc?: string) {
    this.src = src
    this.wsqSrc = wsqSrc
  }

  static createFromID(imageId: string) {
    const result = new Image('')
    result.id = imageId
    result.isURL = true
    return result
  }

  /**
   * It returns the size of the image in bytes when the source is defined or null otherwise.
   * It calculates the size once and stores it in the _size attribute.
   * The following times that the user requires the size, it will return the calculated value (_size).
   * If it is used with Valtio, it should be called with the real state. It cannot be called with a snapshot.
   */
  get size() {
    if (this.isURL || !this.src) {
      return null
    }
    if (!this._size) {
      const binary = convertDataURIToBinary(this.src)
      this._size = binary.byteLength
    }
    return this._size
  }

  /**
   * Upload method uploads the image to the server, and it prepares the cancellation method.
   * Since this method modifies the state; If it is used with Valtio, it should
   * be called with the real state. It cannot be called with a snapshot.
   */
  async upload() {
    this.isUploaded = false
    this.isUploading = true
    this.isUploadAborted = false
    this.hasUploadFailed = false

    try {
      if (!this.src) {
        throw new Error('ImageData is undefined.')
      }

      const mimeType = getMIMETypeFromDataURI(this.src)

      if (!mimeType) {
        throw new Error('mime type is undefined.')
      }

      const config = {
        headers: {
          'Content-Type': mimeType
        },
        cancelToken: new axios.CancelToken((axiosCancel) => {
          this.abortUpload = () => {
            this.isUploadAborted = true
            axiosCancel()
          }
        })
      }

      const formData = new FormData()
      const binary = convertDataURIToBinary(this.src)
      const file1 = new Blob([binary.buffer], { type: mimeType })
      formData.set(this.IMAGE_PARAMETER_KEY_NAME, file1, 'file1')

      if (this.wsqSrc) {
        const wsqBinary = convertB64DataToBinary(this.wsqSrc)
        const file2 = new Blob([wsqBinary.buffer])
        formData.append(this.IMAGE_PARAMETER_KEY_NAME, file2, 'file2')
      }

      const postData = formData

      const response = await axios.post('/v1/images', postData, config)
      if (response.status === 200 && response.data.imageId) {
        this.id = response.data.imageId
      }
      this.abortUpload = undefined
      this.isUploading = false
      this.isUploaded = true
    } catch (error) {
      console.error(error)
      this.abortUpload = undefined
      this.isUploading = false
      if (!this.isUploadAborted) {
        this.hasUploadFailed = true
      }
    }
  }

  async download() {
    this.isUploading = true
    this.isDownloading = true
    try {
      const response = await axios.get<ArrayBuffer>(`/v1/images/${this.id}`, { responseType: 'arraybuffer' })
      const data = response.data
      const mimeType: string | undefined = response?.headers?.['content-type']
      if (!data) {
        throw new Error('ImageData is undefined.')
      }
      if (!mimeType) {
        throw new Error('mimeType is undefined.')
      }
      this.src = convertBinaryToDataURI(data, mimeType)
      this.isURL = false
      this.isUploaded = true
      this.isDownloaded = true
      this.hasDownloadFailed = false
    } catch (error) {
      this.hasDownloadFailed = true
      console.log('failed download of image')
      console.error(error)
    } finally {
      this.isUploading = false
      this.isDownloading = false
    }
  }

  /**
   * Delete method cancels the last request if there is one or delete the image
   * from the server if it is uploaded.
   * Since this method modifies the state; If it is used with Valtio, it should
   * be called with the real state. It cannot be called with a snapshot.
   */
  async delete() {
    if (!this.isUploaded) {
      this.abortUpload?.()
    } else {
      try {
        this.isUploaded = true
        await client.mutate({
          mutation: DELETE_IMAGES,
          variables: { input: { imageIds: [this.id], resourceType: 'record' } }
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  setDownloadedSrc(data: ArrayBuffer, mimeType: string) {
    this.isDownloaded = true
    this.isURL = false
    this.src = convertBinaryToDataURI(data, mimeType)
  }
}

const DELETE_IMAGES = gql`
  mutation DeleteImages($input: DeleteImagesInput!) {
    deleteImages(input: $input)
  }
`
