import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { JFD, JFLTableLookupFieldDef } from '@le2/jfd'
import fuzzysort from 'fuzzysort'
import { SearchOutline } from 'heroicons-react'
import escape from 'lodash/escape'
import get from 'lodash/get'
import pick from 'lodash/pick'
import unescape from 'lodash/unescape'
import uniqueId from 'lodash/uniqueId'
import React, { useCallback, useMemo, useRef, useState } from 'react'

import Input from '../Input'
import { JFDFormField } from './JFDFormField'
import { useJFD } from './jfd-context'
import { getFieldName } from './utils'

export interface JFLTableLookupFieldProps {
  jflDef: JFLTableLookupFieldDef
  index?: number
}

export const MAX_ROWS = 30

/**
 * A control that renders a `<JFDFormField>` with a search icon on the right.
 *
 * When the user selects the input a Popover is displayed. It contains an input
 * that can be used to search, and a table with data provided by `props.jflDef.options.data`.
 * Note that only the fields defined in `props.jflDef.options.searchFields`
 * are included in the table.
 *
 * In this popover the user can type into the input to filter the rows in the
 * table. Then, she can click in one of the rows to use the selected record to
 * automatically fill the fields in the JFD. We use `props.jflDef.options.jfdFields`
 * to determine which fields in the table data map to the correct fields in
 * the JFD.
 *
 * @param props
 * @constructor
 */
export default function JFLTableLookupField(props: JFLTableLookupFieldProps) {
  const { jfd, forceUpdate } = useJFD()
  const { jflDef, index } = props
  validateJflDef(jfd, jflDef)

  // used to control the Popover state
  const { isOpen, onToggle, onClose } = useDisclosure()

  // used to render the search input, control its state, and render the correct
  // rows in the table i.e. those rows that match the current search
  const id = useRef(uniqueId('tableLookupSearch'))
  const [search, setSearch] = useState('')
  const data = useSearchData(props.jflDef, search)

  // callback to be used to pass the values from the selected row to the JFD
  // instance
  const setJFDValues = useCallback(
    (row: Record<string, string>) => {
      jflDef.options.jfdFields.forEach((f) => {
        // the values from `row` are HTML-escaped, as returned by `useSearchData`
        // so we unescape them here.
        jfd.setValue(f.jfdField, unescape(get(row, f.field)), index)
      })
      forceUpdate()
      onClose()
    },
    [jfd, jflDef, index, forceUpdate, onClose]
  )

  return (
    <Popover isOpen={isOpen} onClose={onClose} placement="bottom-start">
      <PopoverTrigger>
        <FormFieldContainer {...props} onClick={onToggle} />
      </PopoverTrigger>
      <PopoverContent width="900px">
        <PopoverCloseButton />
        <PopoverBody>
          <FormControl px={4}>
            <FormLabel htmlFor={id.current} fontSize="xs" display="inline">
              Search
            </FormLabel>
            <Input
              id={id.current}
              type="text"
              size="xs"
              width="350px"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </FormControl>
          <Box maxHeight="300px" overflowY="scroll" whiteSpace="nowrap" mt={5}>
            <Table size="sm">
              <Thead position="sticky" top={0} bg="white">
                <Tr>
                  {jflDef.options.searchFields.map(({ label }) => (
                    <Th key={label}>{label}</Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {data.map((result) => (
                  <Tr
                    key={result.obj.__id}
                    cursor="pointer"
                    _hover={{ bg: 'gray.100' }}
                    onClick={() => setJFDValues(result.obj)}
                  >
                    {jflDef.options.searchFields.map(({ field }) => (
                      // see the comment in useSearchData for why we use dangerouslySetInnerHTML
                      <Td
                        key={`${result.obj.__id}-${field}`}
                        dangerouslySetInnerHTML={{ __html: get(result.highlightedObj, field) }}
                      />
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

function validateJflDef(jfd: JFD, jflDef: JFLTableLookupFieldDef) {
  // check that each `searchFields.*.field` and `jfdFields.*.field` exist in
  // the provided data, and that each `jfdFields.*.jfdField` exist in the JFD
  const sample = jflDef.options.data[0]

  jflDef.options.searchFields.forEach((f) => {
    if (!sample.hasOwnProperty(f.field)) {
      console.error(`Invalid search field: "${f.field}"; rendering: "${jflDef.field}"; sample data: `, sample)
      throw new Error(`Invalid search field: "${f.field}". See the console for more info.`)
    }
  })

  jflDef.options.jfdFields.forEach((f) => {
    if (!sample.hasOwnProperty(f.field)) {
      console.error(
        `Invalid JFD field mapping (field): "${f.field}" rendering: "${jflDef.field}"; sample data: `,
        sample
      )
      throw new Error(`Invalid JFD field mapping: "${f.field}". See the console for more info.`)
    }

    if (!jfd.hasFieldDef(f.jfdField)) {
      console.error(`Invalid JFD field mapping (JFD field): "${f.jfdField}" rendering: "${jflDef.field}"`)
      throw new Error(`Invalid JFD field mapping: "${f.jfdField}". See the console for more info.`)
    }
  })
}

/**
 * Get the filtered rows for the table i.e. those records in `jflDef.options.data`
 * that match the given `search`.
 *
 * Note that we only use fields in `jflDef.options.searchFields` to determine
 * which records match.
 *
 * @param jflDef
 * @param search
 */
function useSearchData(jflDef: JFLTableLookupFieldDef, search: string) {
  // `preparedData is the same as `jflDef.options.data` with an added attribute
  // `__key` to be searched on, as suggested here:
  // https://github.com/farzher/fuzzysort/issues/98#issuecomment-1139200775
  const key = '__key'
  const keySeparator = '🍰' // the logic is tricky, so... have a cake

  const preparedData = useMemo(() => {
    const searchFields = jflDef.options.searchFields.map((f) => f.field)
    const jfdFields = jflDef.options.jfdFields.map((f) => f.field)

    return jflDef.options.data.map((e) => {
      // escape each element attributes for HTML so that we can safely pass them
      // to dangerouslySetInnerHTML
      const escapedObj: Record<string, string> = {}
      searchFields.forEach((field) => (escapedObj[field] = escape(get(e, field))))
      jfdFields.forEach((field) => (escapedObj[field] = escape(get(e, field))))

      return {
        ...escapedObj,
        __id: uniqueId('table-lookup-row-'),
        [key]: Object.values(pick(escapedObj, searchFields)).join(keySeparator)
      }
    })
  }, [jflDef])

  return useMemo(() => {
    return fuzzysort
      .go(escape(search), preparedData, {
        key: key,
        limit: MAX_ROWS,
        all: true
      })
      .map((result) => {
        // Note that `fuzzysort.highlight()` works on the `__key` that we search on, which is
        // a concatenation of all the `searchFields`. However, we need each search field so
        // that we can display it in each cell in the table, so we need to re-construct the
        // object from the highlighted string. To do this, we use a special character as a
        // key separator that we can then use to split the highlighted string and assign each
        // part to `highlightedObj`.
        //
        // Also note that we use `fuzzysort.highlight()` with `open` and `close` params
        // instead of a `callback` because the latter returns an array of React elements
        // ready to be rendered, and we wouldn't be able to re-construct the object with
        // that. This means that we need to output raw HTML i.e. we use dangerouslySetInnerHTML
        // instead of {text}.

        let highlightedObj: Record<string, string> = result.obj
        let highlighted

        if (result.score !== Number.NEGATIVE_INFINITY) {
          highlighted = fuzzysort.highlight(
            result,
            '<em style="background-color: yellow; font-style: normal">',
            '</em>'
          )

          const parts = (highlighted || '').split(keySeparator)
          highlightedObj = {}
          jflDef.options.searchFields.forEach(({ field }, index) => {
            highlightedObj[field] = parts[index]
          })
        }

        return {
          ...result,
          highlighted: highlighted,
          highlightedObj: highlightedObj
        }
      })
  }, [search, preparedData, jflDef.options.searchFields])
}

interface FormFieldContainerProps extends JFLTableLookupFieldProps {
  onClick?: () => void
}

/**
 * Wrapper for the <JFDFormField>. We use this as the anchor for Chakra's
 * Popover.
 */
const FormFieldContainer = React.forwardRef<HTMLDivElement, FormFieldContainerProps>((props, ref) => {
  const { jflDef, index, onClick } = props
  const { jfd } = useJFD()
  const jfdField = jfd.getField(jflDef.field, index)
  const name = getFieldName(jfdField, jflDef.field, index)

  return (
    <Box ref={ref} onClick={onClick}>
      <JFDFormField name={name} jfdField={jfdField} appendRight={<Icon as={SearchOutline} color="gray.700" />} />
    </Box>
  )
})
