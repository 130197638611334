import { darken } from '@chakra-ui/theme-tools'

const Checkbox = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderColor: 'gray.300',
      borderRadius: '4px',
      _checked: {
        bg: 'secondary',
        borderColor: 'secondary',
        _hover: {
          bg: darken('secondary', 5),
          borderColor: darken('secondary', 5)
        },
        _disabled: {
          bg: 'gray.400',
          borderColor: 'gray.400',
          color: 'white'
        }
      },
      _indeterminate: {
        bg: 'primary',
        borderColor: 'primary'
      },
      _disabled: {
        bg: 'gray.400',
        borderColor: 'gray.400'
      }
    }
  }
}

export default Checkbox
