import { useEffect } from 'react'

import useEvent from './useEvent'

/**
 * This hook should be called at the top level of the application once the user has
 * logged in and also in a component that is rendered all the time the user has an
 * active session.
 *
 * The logout api call is not executed when the user closes the tab or the browser
 * as one should expect at first sight, but when the user opens a new tab. This is
 * because it is until this very moment that we know the exact context of the user
 * session. It cannot be determined before.
 *
 * To detect if this is the first tab opened by the user we make use of the local
 * storage because this object persist the data after the tab or browser are closed.
 * If the <firstpage> key exists in the local storage then it means there is already
 * a page opened so the <multipletabs> key is set to indicate that there are
 * multiple tabs opened. In the other side if the <firstpage> key does not exist
 * then it means that this is the first time the user opens the webapp and we make
 * use of knowing this context to set two keys; <firstpage> to the value "yes"
 * and <tabs> with the value of "1". This last key will help us to count the number
 * of opened tabs in the browser.
 *
 * The useEffect hook is used here because it only executes once in the whole app
 * lifecycle. Inside the useEffect hook we read the value of the <tabs> key and
 * increment its value in one unit. To decrement the value in one unit we use the
 * useEvent hook in combination with the beforeunload native browser event. When
 * the value of the <tabs> key is zero then we must clear all of our keys from
 * local storage(<tabs>, <multipletabs>, <firstpage>), because by doing this we
 * make sure that in the next open tab the <multipletabs> will be unset and this
 * is one of the two conditions needed to trigger the handleLogout() function.
 *
 * To detect the reload event we make use of session storage native feature
 * that cleans itself when the tab is closed but survives on page reloads.
 * It is important to detect the reload event because we should do nothing
 * when it happens. If it is not detected the user would be automatically
 * logged out on the reload event.
 *
 * There is only one combination of events that should execute the handleLogout()
 * function and is the following: a new tab has been opened and the <multipletabs>
 * key is not set. When these two conditions occur we can be sure that the user
 * has opened a brand new tab and before open it the user has closed all the
 * previous tabs.
 */

/**
 * A hook that automatically logs out the user when she closes the last tab.
 *
 * Note: this should be used in a top-level component that is always rendered,
 * e.g. UserLayout
 *
 * @param handleLogout
 */
export default function useAutomaticLogoutOnPageLeave(handleLogout: () => void) {
  useEvent('beforeunload', () => {
    const tabs = parseInt(localStorage.getItem('tabs') || '', 10)
    if (Number.isInteger(tabs)) {
      const tabsMinusOne = tabs - 1
      if (tabsMinusOne === 0) {
        // clear local storage, because doing this we make sure that
        // in the next open window or tab... multipletabs=null
        localStorage.removeItem('tabs')
        localStorage.removeItem('multipletabs')
        localStorage.removeItem('firstpage')
      } else {
        localStorage.setItem('tabs', tabsMinusOne.toString())
      }
    }
  })

  useEffect(() => {
    const tabs = parseInt(localStorage.getItem('tabs') || '', 10)
    if (Number.isInteger(tabs)) {
      const tabsPlusOne = tabs + 1
      localStorage.setItem('tabs', tabsPlusOne.toString())
    }

    const firstpage = localStorage.getItem('firstpage')
    if (firstpage) {
      localStorage.setItem('multipletabs', 'yes')
    } else {
      localStorage.setItem('firstpage', 'yes')
      localStorage.setItem('tabs', '1')
    }

    if (sessionStorage.getItem('reloaded')) {
      // page reloaded, do nothing!
      sessionStorage.setItem('reloaded', 'yes')
    } else {
      // new tab opened, real logout is here
      sessionStorage.setItem('reloaded', 'yes')
      const multipletabs = localStorage.getItem('multipletabs')
      if (multipletabs) {
        // same session on multiple tabs in the browser is allowed
      } else {
        // but new tab after browser closes or all tabs were closed
        // trigger an automatic logout
        clearLocalStorageKeys()
        handleLogout()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

/**
 * Remove the keys from localStorage used by `useAutomaticLogoutOnPageLeave`
 */
export function clearLocalStorageKeys() {
  localStorage.removeItem('tabs')
  localStorage.removeItem('multipletabs')
  localStorage.removeItem('firstpage')
}
