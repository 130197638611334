import { Location } from 'history'
import { useLocation } from 'react-router-dom'

/**
 * Get a function that can be passed as `message` to 'react-router-dom's
 * <Prompt>. This function returns `true` (i.e. lets the user navigate) when
 * the target location corresponds to the same booking (determined by `basePath`
 * and the query params), and there's no unsaved data (determined by the
 * `isDirtyFunc` result).
 *
 * @param basePath e.g. '/bookings/new' or '/bookings/A1100011'
 * @param isDirtyFunc A function that determines if we have unsaved data.
 * @param enabled Turns off the prompt, i.e. it always lets the user navigate
 *                without asking.
 */
export function usePromptMessage(basePath: string, isDirtyFunc: () => boolean, enabled = true) {
  const currentLoc = useLocation()

  if (!enabled) {
    return function () {
      return true
    }
  }

  return function (location: Location) {
    if (isSameRecordItem(currentLoc, location, basePath) || !isDirtyFunc()) {
      return true
    } else {
      return 'Discard changes?'
    }
  }
}

function isSameRecordItem(current: Location, next: Location, basePath: string) {
  if (next.pathname.startsWith(basePath)) {
    return current.search === next.search
  } else {
    return false
  }
}
