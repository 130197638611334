import { BioType } from '@le2/jfd'

export default BioType

export const faceCaptureBioTypes = [
  BioType.FaceFront,
  BioType.FaceLeft45,
  BioType.FaceLeft90,
  BioType.FaceRight45,
  BioType.FaceRight90,
  BioType.FullBody
]
export const SMTBioTypes = [BioType.SMT]
export const fingerprintBioTypes = [
  BioType.LeftThumbFlat,
  BioType.LeftIndexFlat,
  BioType.LeftMiddleFlat,
  BioType.LeftRingFlat,
  BioType.LeftLittleFlat,
  BioType.RightThumbFlat,
  BioType.RightIndexFlat,
  BioType.RightMiddleFlat,
  BioType.RightRingFlat,
  BioType.RightLittleFlat,
  BioType.LeftThumbRoll,
  BioType.LeftIndexRoll,
  BioType.LeftMiddleRoll,
  BioType.LeftRingRoll,
  BioType.LeftLittleRoll,
  BioType.RightThumbRoll,
  BioType.RightIndexRoll,
  BioType.RightMiddleRoll,
  BioType.RightRingRoll,
  BioType.RightLittleRoll,
  BioType.LeftFourSlap,
  BioType.RightFourSlap,
  BioType.TwoThumbSlap
]
export const palmBioTypes = [
  BioType.LeftFullPalm,
  BioType.LeftHypothenar,
  BioType.LeftLowerPalm,
  BioType.LeftUpperPalm,
  BioType.RightFullPalm,
  BioType.RightHypothenar,
  BioType.RightLowerPalm,
  BioType.RightUpperPalm
]
export const irisBioTypes = [BioType.LeftIris, BioType.RightIris]
