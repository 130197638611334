import { gql } from '@apollo/client'
import { JFDFieldDef, JFDValues } from '@le2/jfd'
import { createJFD } from '@le2/jfd'
import get from 'lodash/get'
import set from 'lodash/set'

import { SMTFilter } from '../../../pages/search/useSearchJFD'
import agencyConfig from '../../agencyConfig'
import { OrderByDict } from '../../useTableSort'
import BioType from './BioType'
import { BookingImages } from './index'

const jfdInst = createJFD(agencyConfig.jfd)

export const disabledOnViewModeMessage = 'Not possible on view mode'

/**
 * Client-side params to query bookings. These can be mapped to a format
 * that can be consumed by the API with `getAPIQueryParams`
 */
export type BookingsQueryParams = SimpleBookingsQueryParams | AdvancedBookingsQueryParams

interface BaseBookingsQueryParams {
  currentPage?: number
  pageSize?: number
  orderBy?: OrderByDict
  bioTypes?: BioType[]
  smt?: SMTFilter
  isSealed?: boolean
}

interface SimpleBookingsQueryParams extends BaseBookingsQueryParams {
  type: 'simple'
  search: string
  moreFilters: {}
}

interface AdvancedBookingsQueryParams extends BaseBookingsQueryParams {
  type: 'advanced'
  search: {}
}

interface JsonBExtraParams {
  bioType?: BioType[]
  smt?: SMTFilter
  isSealed?: boolean
}

export const DEFAULT_PAGE_SIZE = 20

/**
 * Maps the given params to a format that can be used to query the API.
 */
export function getAPIQueryParams(params: BookingsQueryParams) {
  const currentPage = params.currentPage || 1
  const pageSize = params.pageSize || DEFAULT_PAGE_SIZE

  const extra = {
    skip: pageSize * (currentPage - 1),
    take: pageSize,
    orderBy: params.orderBy
  }

  const jsonbExtra: JsonBExtraParams = {}
  if (params.bioTypes) {
    jsonbExtra.bioType = params.bioTypes
  }

  if (params.smt) {
    jsonbExtra.smt = params.smt
  }

  if (undefined !== params.isSealed) {
    jsonbExtra.isSealed = params.isSealed
  }

  if (params.type === 'advanced') {
    return {
      jsonbWhere: {
        biographics: params.search,
        ...jsonbExtra
      },
      ...extra
    }
  } else {
    // we also use jsonb here so that we can order by JFD fields
    return {
      jsonbWhere: {
        biographics: {
          ...getBiographicsParams(params.search),
          ...params.moreFilters
        },
        ...jsonbExtra
      },
      ...extra
    }
  }
}

export function getBiographicsParams(search: string) {
  const result = {}

  // to utilize trigrams indexing, need search string of 3 or more length
  if (search.length > 2) {
    agencyConfig.features.bookings.search.simple.forEach((field) => {
      const jfdField: JFDFieldDef = jfdInst.getFieldDef(field)
      set(result, field, {
        contains: jfdField.convertToUpperCase ? search.toUpperCase() : search,
        operation: 'OR'
      })
    })
  }

  return result
}

/**
 * Bookings data, as returned by the API with the GET_BOOKINGS query.
 */
export interface BookingsResponse {
  bookings: BookingResponse[]
  bookingsAggregate: { count: number }
}

/**
 * Booking data, as returned by the API with the GET_BOOKINGS query.
 */
export interface BookingResponse {
  id: number
  bookingNum: string
  biographics: JFDValues
  enrollmentStatus: BookingEnrollmentStatus
  imageMetadata: ImageResponse[]
  createdAt: string
  updatedAt: string
  isActive: boolean
  isSealed: boolean
}

/**
 * Images data, as returned by the API with the GET_BOOKINGS query.
 */
export interface ImageResponse {
  imageId: string
  bioType: string
  metadata: Record<string, string>
  capturedAt: string
  createdAt: string
  updatedAt: string
}

export const GET_BOOKINGS = gql`
  query GetBookings($where: JSON, $jsonbWhere: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    bookings(orderBy: $orderBy, where: $where, jsonbWhere: $jsonbWhere, skip: $skip, take: $take) {
      id
      bookingNum
      biographics
      enrollmentStatus
      imageMetadata
      createdAt
      updatedAt
      isActive
      isSealed
    }
    bookingsAggregate(where: $where, jsonbWhere: $jsonbWhere, aggregate: { count: true }) {
      count
    }
  }
`

/**
 * Similar to BookingResponse but with some attributes mapped to objects.
 */
export interface Booking {
  id: number
  bookingNum: string
  biographics: JFDValues
  enrollmentStatus: BookingEnrollmentStatus
  images: BookingImages
  createdAt: Date
  updatedAt: Date
  isActive: boolean
  isSealed: boolean
  name: string
}

export enum BookingEnrollmentStatus {
  NEW = 'NEW',
  WIP = 'WIP',
  COMPLETE = 'COMPLETE'
}

/**
 * Map a booking as returned by the API to a client-side object.
 */
export function mapBookingResponse(data: BookingResponse): Booking {
  return {
    id: data.id,
    bookingNum: data.bookingNum,
    biographics: data.biographics,
    enrollmentStatus: data.enrollmentStatus,
    images: BookingImages.createFromAPIResponse(data.imageMetadata),
    createdAt: new Date(data.createdAt),
    updatedAt: new Date(data.updatedAt),
    isActive: data.isActive,
    isSealed: data.isSealed,

    get name() {
      return get(this.biographics, 'DisplayFullName') as string
    }
  }
}
