// TODO: Receive the state as a parameter
import {
  HubClosedEventDetail,
  HubConnectedEventDetail,
  HubDisConnectedEventDetail,
  HubIsConnectingEventDetail,
  HubIsDisConnectingEventDetail,
  HubIsReconnectingEventDetail,
  HubReconnectedEventDetail,
  HubStatus
} from '../IWNativeExtDeviceDefines'
import { nativeExtensionsState } from '../nativeextState'

export function getHubManagerFromURL(url: string) {
  return url?.substring(url?.lastIndexOf('/') + 1)
}

export function isDeviceManagerURL(url: string) {
  return getHubManagerFromURL(url)?.toUpperCase() === 'DEVICEMGR'
}

export function isCardPrintManagerURL(url: string) {
  return getHubManagerFromURL(url)?.toUpperCase() === 'FPCARDPRINTMGR'
}

export function isPrintManagerURL(url: string) {
  return getHubManagerFromURL(url)?.toUpperCase() === 'PRINTMGR'
}

export function hubConnectedEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubConnectedEventDetail>) {
    console.log('Event Listener for DeviceManagerEvents.hubConnected entered')
    console.log('Hub CONNECTED: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
    if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.Connected
    if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
      nativeExtensionsState.fpCardPrintHubStatus = HubStatus.Connected
    if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.Connected
  } as EventListener
}

export function hubIsDisConnectingEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubIsDisConnectingEventDetail>) {
    console.log('Event Listener for DeviceManagerEvents.DisConnecting entered')
    console.log('Hub IS_DISCONNECTING: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
    if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.DisConnecting
    if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
      nativeExtensionsState.fpCardPrintHubStatus = HubStatus.DisConnecting
    if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.DisConnecting
  } as EventListener
}

export function hubDisConnectedEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubDisConnectedEventDetail>) {
    console.log('Event Listener for DeviceManagerEvents.hubDisConnected entered, eventCtx: ' + eventCtx)

    if (eventCtx.detail.disconnectedReason) {
      console.log(
        'Event Listener for DeviceManagerEvents.hubDisConnected reason: ' + eventCtx.detail.disconnectedReason
      )
    }

    console.log('Hub DISCONNECTED: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
    if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.DisConnected
    if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
      nativeExtensionsState.fpCardPrintHubStatus = HubStatus.DisConnected
    if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.DisConnected
  } as EventListener
}

export function hubReconnectedEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubReconnectedEventDetail>) {
    console.log('Event Listener for DeviceManagerEvents.hubReconnected entered, eventCtx: ' + eventCtx)

    if (eventCtx.detail.connectionId) {
      console.log(
        'Event Listener for DeviceManagerEvents.hubReconnected, connectionIs: ' + eventCtx.detail.connectionId
      )
    }

    console.log('Hub RECONNECTED: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
    if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.Reconnected
    if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
      nativeExtensionsState.fpCardPrintHubStatus = HubStatus.Reconnected
    if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.Reconnected
  } as EventListener
}

export function hubClosedEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubClosedEventDetail>) {
    console.log('Event Listener for DeviceManagerEvents.hubClosed entered, eventCtx: ' + eventCtx)

    if (eventCtx.detail.error) {
      console.log('Event Listener for DeviceManagerEvents.hubClosed, connection lost' + eventCtx.detail.error)
    }

    console.log('Hub CLOSED: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
    if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.Closed
    if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.fpCardPrintHubStatus = HubStatus.Closed
    if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.Closed
  } as EventListener
}

export function hubIsConnectingEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubIsConnectingEventDetail>) {
    try {
      console.log('Event Listener for DeviceManagerEvents.hubIsConnecting entered, eventCtx: ' + eventCtx)
      console.log('Hub IS_CONNECTING: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
      if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.Connecting
      if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
        nativeExtensionsState.fpCardPrintHubStatus = HubStatus.Connecting
      if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.Connecting
    } catch (e) {
      console.log('Exception in DeviceManagerEvents.hubIsConnecting Event Listener : ' + e)
    }
  } as EventListener
}

export function hubIsReconnectingEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<HubIsReconnectingEventDetail>) {
    try {
      console.log('Event Listener for DeviceManagerEvents.hubIsReconnecting entered, eventCtx: ' + eventCtx)
      console.log(
        'Event Listener for DeviceManagerEvents.hubReconnecting status: ' + eventCtx.detail.reconnectingStatus
      )
      console.log('Hub IS_RECONNECTING: ' + getHubManagerFromURL(eventCtx?.detail?.hubUrl))
      if (isDeviceManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.deviceHubStatus = HubStatus.Connecting
      if (isCardPrintManagerURL(eventCtx?.detail?.hubUrl))
        nativeExtensionsState.fpCardPrintHubStatus = HubStatus.Connecting
      if (isPrintManagerURL(eventCtx?.detail?.hubUrl)) nativeExtensionsState.printHubStatus = HubStatus.Connecting
    } catch (e) {
      console.log('Exception in DeviceManagerEvents.hubIsReconnecting Event Listener : ' + e)
    }
  } as EventListener
}
