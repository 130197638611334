import { As, HStack, Icon, Text } from '@chakra-ui/react'
import { CloudOutline, ExclamationCircleOutline, RefreshOutline } from 'heroicons-react'
import TimeAgo from 'react-timeago'

import { Status } from '../pages/booking/interfaces'

interface StatusMessageProps {
  status: Status

  /**
   * Date to compute the "time ago" string.
   */
  date?: Date

  /**
   * Message to display when status==Status.Loading
   */
  loadingMessage: string

  /**
   * Message to display when status==Status.Error
   */
  errorMessage: string

  /**
   * Message to display when status==Status.Complete
   */
  completeMessage: string

  /**
   * Message to display when status==Status.Complete
   */
  completeMessageSeconds: string
}

export function StatusMessage(props: StatusMessageProps) {
  return (
    <HStack>
      {props.status === Status.Loading ? (
        <StatusIcon icon={RefreshOutline} message={props.loadingMessage} />
      ) : props.status === Status.Error ? (
        <StatusIcon
          icon={ExclamationCircleOutline}
          iconColor="gray.400"
          message={props.errorMessage}
          messageColor="gray.400"
        />
      ) : props.status === Status.Complete ? (
        <TimeMessage {...props} />
      ) : null}
    </HStack>
  )
}

function TimeMessage({ date, completeMessage, completeMessageSeconds }: StatusMessageProps) {
  if (date) {
    return (
      <TimeAgo
        date={date}
        minPeriod={60}
        component={({ children }) => {
          const timeSinceLastSave = children?.toString()
          if (timeSinceLastSave && !timeSinceLastSave.includes('second')) {
            return <StatusIcon icon={CloudOutline} message={`${completeMessage} ${timeSinceLastSave}`} />
          } else {
            return <StatusIcon icon={CloudOutline} iconColor="green.600" message={completeMessageSeconds} />
          }
        }}
      />
    )
  }
  return null
}

interface StatusIconProps {
  icon?: As
  iconColor?: string
  message?: string
  messageColor?: string
}

function StatusIcon({ icon, iconColor, message, messageColor }: StatusIconProps) {
  return (
    <>
      {icon && <Icon as={icon} color={iconColor} />}
      {message && <Text color={messageColor}>{message}</Text>}
    </>
  )
}
