import { getJEPFromEBTSBuffer } from '@le2/ebts'
import { JFD } from '@le2/jfd'
import axios from 'axios'
import { Buffer } from 'buffer'
import hotkeys from 'hotkeys-js'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { LE2Hotkeys, systemInfoState } from '../utils/useGetSystemInfo'

// This hack again
// @ts-ignore
window.Buffer = Buffer

/**
 * POST the EBTS file to the API to enroll it
 * @param ebtsFile
 */
async function doEnroll(ebtsFile: File) {
  const formData = new FormData()

  formData.set('files', ebtsFile, 'package.nst')

  await axios.post('/v1/booking/import', formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
}

/**
 * Setup a hotkey to asynchronously generate a JSON from an EBTS file
 * @param jfd
 */
export function useEBTSToJsonHotKey(jfd: JFD) {
  const systemInfoSnap = useSnapshot(systemInfoState)

  useEffect(() => {
    const hotkey = LE2Hotkeys.EBTSToJSON
    if (systemInfoSnap.isHotkeyAvailable(hotkey)) {
      hotkeys(hotkey, function (event) {
        // Prevent the default action
        event.preventDefault()

        // Display upload dialog JSX
        const fileInput = window.document.createElement('input')
        fileInput.type = 'file'
        fileInput.name = 'tmpFile'
        fileInput.onchange = async (e: Event) => {
          const ebtsFile = (e.target as HTMLInputElement | null)?.files?.[0]
          if (!ebtsFile) {
            console.error('No EBTS file loaded')
            return
          }

          // Generate payload
          const ebtsBuffer = Buffer.from(await ebtsFile.arrayBuffer())
          const ebtsJep = getJEPFromEBTSBuffer(ebtsBuffer, jfd)
          const jepJson = JSON.stringify(ebtsJep)

          // Generate a file stream asynchronously
          // Generate and download blob
          const formBlob = new Blob([jepJson], { type: 'application/json' })
          const anchor = window.document.createElement('a')
          anchor.href = window.URL.createObjectURL(formBlob)
          // Set the filename using the original filename plus the json extension, overriding .ebts if present
          anchor.download = ebtsFile.name.split('.ebts')[0] + '.json'

          // Append anchor to body and start download
          document.body.appendChild(anchor)
          anchor.click()

          // Remove anchor from body
          document.body.removeChild(anchor)

          // Upload and enroll package
          await doEnroll(ebtsFile)
        }

        document.body.appendChild(fileInput)
        fileInput.click()
      })
    }

    // Unbind hotkeys so they don't get re-bound on next useEffect run
    return () => {
      hotkeys.unbind(hotkey)
    }
  }, [jfd, systemInfoSnap])
}
