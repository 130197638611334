import { gql } from '@apollo/client'

export const defaultResults = 200
export const results = [50, 100, 200]

export interface AuditEvent {
  id: number
  username: string
  caseNumber?: string
  firstName?: string
  lastName?: string
  type: AuditEventType
  action: string
  metadata?: any
  bookingId?: number
  bookingNum?: string
  recordNum?: string
  lineupId?: number
  createdAt: Date
  ipAddress?: string
  userAgent?: string
  department: string
  lineupName?: string
  subjectName?: string
  actionFormatted?: string
  typeFormatted?: string
  isSealed: boolean
  appVersion: string
}

export enum AuditEventType {
  AUTH = 'AUTH',
  AUDIT = 'AUDIT',
  BOOKING = 'BOOKING',
  TRANSACTION = 'TRANSACTION',
  LINEUP = 'LINEUP',
  USER = 'USER',
  SETTINGS = 'SETTINGS',
  CARD = 'CARD'
}

const EVENT_FIELDS = gql`
  fragment EventFields on AuditEvent {
    id
    username
    caseNumber
    firstName
    lastName
    type
    action
    metadata
    bookingId
    bookingNum
    recordNum
    lineupId
    createdAt
    ipAddress
    userAgent
    department
    lineupName
    subjectName
    isSealed
    appVersion
  }
`

export const GET_EVENTS = gql`
  ${EVENT_FIELDS}
  query GetEvents($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    auditEvents(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      ...EventFields
    }
    auditsAggregate(where: $where, aggregate: { count: true }) {
      count
    }
  }
`

export const GET_ALL_EVENTS = gql`
  ${EVENT_FIELDS}
  query GetALLEvents($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    auditAllEvents(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      ...EventFields
    }
  }
`

export const LOG_EVENT = gql`
  mutation LogEvent($input: EventInput!) {
    logEvent(input: $input) {
      username
      action
      type
      metadata
    }
  }
`

export interface EventsResponse {
  auditEvents: AuditEvent[]
  auditsAggregate: { count: number }
}

export interface AllEventResponse {
  auditAllEvents: AuditEvent[]
}
