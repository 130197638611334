import { Box, Button, Flex, Icon, Text } from '@chakra-ui/react'
import { ExclamationCircleOutline } from 'heroicons-react'
import { useCallback, useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { StatusContentPage } from '../../components/StatusContentPage'
import { StatusMessage } from '../../components/StatusMessage'
import { useExtraJFDValues } from '../../hooks/useExtraJFDValues'
import { usePromptMessage } from '../../hooks/usePromptMessage'
import { ResourceDetails, ResourceLayout } from '../../layouts/resource'
import { HubStatus } from '../../nativeExtension/IWNativeExtDeviceDefines'
import { useConnectToDeviceHub } from '../../nativeExtension/hooks'
import { useCaptureStationInfo } from '../../nativeExtension/hooks/useCaptureStationInfo'
import { nativeExtensionsState } from '../../nativeExtension/nativeextState'
import { getHeaderFromPrintType } from '../../utils/getHeaderFromPrintType'
import { systemInfoState } from '../../utils/useGetSystemInfo'
import usePreventUnload from '../../utils/usePreventUnload'
import { authUserState } from '../../utils/usersUtils'
import RecordRoutes from './components/RecordRoutes'
import { useGetRecordNumber } from './hooks/useGetRecordNumber'
import { useRecordMenuItems } from './hooks/useRecordMenuItems'
import { useSaveRecord } from './hooks/useSaveRecord'
import { Status } from './interfaces'
import { biographicsState, recordState } from './state'

export default function RecordCreate() {
  const [finishCapturestationLoading, setFinishCapturestationLoading] = useState(false)

  // Start a connection to NativeExtension
  const loadingNativeExts = useConnectToDeviceHub()

  const { deviceHubStatus: deviceHubStatusSnap } = useSnapshot(nativeExtensionsState)

  // Checks if the connection with NativeExts was successful.
  useEffect(() => {
    if (!loadingNativeExts && [HubStatus.DisConnected, HubStatus.Connected].includes(deviceHubStatusSnap)) {
      // If the connection is unable, the app skips capture station info request.
      if (nativeExtensionsState.deviceHubStatus !== HubStatus.Connected) {
        setFinishCapturestationLoading(true)
      }
    }
  }, [deviceHubStatusSnap, loadingNativeExts])

  // Get Print Type from Url Params
  const extraJFDValues = useExtraJFDValues()
  const printType = extraJFDValues.get('jfd-value')
  if (!printType) {
    throw new Error('Missing print type.')
  }

  // Agency ID (a.k.a. machine code) is a PER-INSTALLATION value
  // Sample value, Casa Grande uses 4807 and 4808 (they have two sites)
  const { data: systemInfo } = useSnapshot(systemInfoState)

  const { me } = useSnapshot(authUserState)

  const { loading, error, fetch } = useGetRecordNumber(printType, me)

  // fetch from useGetBookingNumber is a manual call, and we execute it only
  // when we finished the system-info call and we actually have a machine code
  const doGetSystemInfo = useCallback(() => {
    if (systemInfo.machineCode) {
      fetch(systemInfo.machineCode)
    }
  }, [systemInfo, fetch])

  // generate a new booking number whenever we have a different printType
  useEffect(() => {
    doGetSystemInfo()
  }, [doGetSystemInfo, printType])

  const snap = useSnapshot(recordState)

  const isLoadedCaptureStationInfo = useCaptureStationInfo()

  useEffect(() => {
    if (isLoadedCaptureStationInfo) {
      setFinishCapturestationLoading(true)
    }
  }, [isLoadedCaptureStationInfo])

  // always prevent navigation since we're creating a new booking
  usePreventUnload(() => snap.isDirty())
  const promptMessage = usePromptMessage('/records/new', () => snap.isDirty())

  const {
    recordNumber: recordNumberSnap,
    isLoading: isLoadingSnap,
    createdAt: createdAtSnap,
    updatedAt: updatedAtSnap,
    submittedAt: submittedAtSnap,
    saveStatus: saveStatusSnap,
    submitStatus: submitStatusSnap
  } = useSnapshot(recordState)

  const { errorCount: errorCountSnap } = useSnapshot(biographicsState)

  const isSaveEnabled = !isLoadingSnap
  const bookingMenuItems = useRecordMenuItems()
  const handleSave = useSaveRecord()

  // register hotkey to generate and download an EBTS file
  // TODO Review if EBTS will be soported for Non-Criminal type records.
  // useGenerateEBTSWithImagesHotKey(recordState.jfd)

  return (
    <StatusContentPage
      isLoading={loading || !finishCapturestationLoading}
      hasError={Boolean(error)}
      errorMessage="Oops, it looks like there is a problem, please try again"
      onRetry={doGetSystemInfo}
    >
      <Prompt message={promptMessage} />
      <ResourceLayout
        header={`Record Number [${recordNumberSnap}]`}
        menuItems={bookingMenuItems}
        menuItemsAppend={
          errorCountSnap > 0 && (
            <Flex alignItems="center">
              <Icon color="red.500" as={ExclamationCircleOutline} />
              <Text as="span" ml={2} fontSize={12} fontWeight={500}>
                Mandatory fields must be completed before submission
              </Text>
            </Flex>
          )
        }
        details={
          <ResourceDetailsImpl
            printType={getHeaderFromPrintType(printType, 'records')}
            dateCreated={createdAtSnap}
            dateUpdated={updatedAtSnap}
            dateSubmitted={submittedAtSnap}
            saveStatus={saveStatusSnap}
            submitStatus={submitStatusSnap}
          />
        }
        actions={<RecordActions isSaveEnabled={isSaveEnabled} onSave={handleSave} />}
      >
        {/* wrapped in a Box because a margin collapse issue in biographics... */}
        <Box>
          <RecordRoutes action="create" />
        </Box>
      </ResourceLayout>
    </StatusContentPage>
  )
}

interface ResourceDetailsImplProps {
  printType: string
  dateCreated: Date | undefined
  dateUpdated: Date | undefined
  dateSubmitted: Date | undefined
  saveStatus: Status
  submitStatus: Status
}

function ResourceDetailsImpl(props: ResourceDetailsImplProps) {
  const createdDate = props.dateCreated?.toLocaleDateString()

  return (
    <ResourceDetails>
      <Text>{props.printType}</Text>
      {createdDate && <Text>Date created: {createdDate}</Text>}
      {props.saveStatus !== Status.Idle && (
        <StatusMessage
          status={props.saveStatus}
          date={props.dateUpdated}
          loadingMessage="Saving..."
          errorMessage="Save failed, please try again..."
          completeMessage="Saved:"
          completeMessageSeconds="Saved Changes"
        />
      )}
    </ResourceDetails>
  )
}

interface RecordActionsProps {
  isSaveEnabled: boolean
  onSave: () => void
}

function RecordActions(props: RecordActionsProps) {
  return (
    <>
      <Button variant="primary" isDisabled={!props.isSaveEnabled} onClick={props.onSave}>
        Save
      </Button>
    </>
  )
}
