import { Box, Flex, Text } from '@chakra-ui/react'
import { JFLFieldGroupDef } from '@le2/jfd'
import React from 'react'

import { JFDFormField } from './JFDFormField'
import { useJFD } from './jfd-context'
import { getFieldName } from './utils'

export interface JFDFormRangeFieldProps {
  jflDef: JFLFieldGroupDef
  index?: number
  hasLabel: boolean
}

export function JFDFormRangeField({ jflDef, index, hasLabel }: JFDFormRangeFieldProps) {
  const { jfd } = useJFD()

  const jfdField1 = jfd.getField(jflDef.field[0], index)
  const jfdField2 = jfd.getField(jflDef.field[1], index)
  const name1 = getFieldName(jfdField1, jflDef.field[0], index)
  const name2 = getFieldName(jfdField2, jflDef.field[1], index)

  return (
    <Flex flexDirection="row" alignItems="center">
      <Box>
        <JFDFormField name={name1} jfdField={jfdField1} hasLabel={hasLabel} />
      </Box>
      <Text color="gray.400" mb={hasLabel ? 0 : 5} alignSelf="center" textAlign="center" mx={2}>
        to
      </Text>
      <Box mt={hasLabel ? 6 : 0}>
        <JFDFormField name={name2} jfdField={jfdField2} hasLabel={false} />
      </Box>
    </Flex>
  )
}
