import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { LoginLayout } from '../layouts/LoginLayout'
import { systemInfoState } from '../utils/useGetSystemInfo'

export default function OIDCLogin() {
  const { data: systemInfoData, loading: stillLoading } = useSnapshot(systemInfoState)

  useEffect(() => {
    if (!stillLoading) {
      const params = new URLSearchParams({
        redirect_uri: `${window.location.origin}/web/OIDCCallback`,
        prompt: 'false'
      })
      const redirectURI = systemInfoData.oidcEnabled ? `/v1/iwa/login?${params.toString()}` : '/web/login'
      console.log('OIDCLogin redirecting to: ' + redirectURI)
      window.location.href = redirectURI
    }
  }, [stillLoading, systemInfoData.oidcEnabled])

  return (
    <LoginLayout>
      <div>Logging in...</div>
    </LoginLayout>
  )
}
