import { Permission, permissionVerifier } from '@le2/permissions'
import { useMemo } from 'react'
import { useSnapshot } from 'valtio'

import { authUserState } from '../utils/usersUtils'

interface PermissionWrapperProps {
  permission: Permission | Permission[] | undefined
  strict?: boolean
  children: JSX.Element
}

export default function PermissionWrapper({ children, permission, strict = true }: PermissionWrapperProps) {
  const grantAccess = usePermissionVerifier(permission, strict)

  if (!grantAccess) {
    return null
  }

  return children
}

export function usePermissionVerifier(permission?: Permission | Permission[], strict: boolean = true) {
  const {
    me: { groups: groupsSnap, isAdmin: isAdminSnap }
  } = useSnapshot(authUserState)

  const grantAccess = useMemo(() => {
    // Returns true if there isn't any required permission.
    if (!permission) {
      return true
    }
    // Return true if the required permission is an empty array.
    else if (Array.isArray(permission) && permission.length === 0) {
      return true
    }

    if (isAdminSnap) {
      return true
    }

    return permissionVerifier(groupsSnap || [], permission, strict)
  }, [permission, groupsSnap, isAdminSnap, strict])

  return grantAccess
}
