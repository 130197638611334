import { useMutation } from '@apollo/client'
import { useSnapshot } from 'valtio'

import { ENROLL_RECORD, EnrollRecordReqData, EnrollRecordRespData } from '../../../apollo/Record'
import { GET_RECORD } from '../../../lib/api/record'
import { Status } from '../interfaces'
import { jfdInst, recordState } from '../state'

export function useSaveRecord(): () => void {
  const { jfd: jfdSnap } = useSnapshot(recordState)

  const [enrollRecord] = useMutation<EnrollRecordRespData, EnrollRecordReqData>(ENROLL_RECORD, {
    async onCompleted({ enrollRecordBiographics }) {
      recordState.originalJFD.values = recordState.jfd.getValues()
      recordState.originalJFD.errors = recordState.jfd.getErrors()
      recordState.enrollmentStatus = enrollRecordBiographics.enrollmentStatus
      recordState.createdAt = new Date(enrollRecordBiographics.createdAt)
      recordState.updatedAt = new Date(enrollRecordBiographics.updatedAt)
      recordState.isActive = enrollRecordBiographics.isActive
      recordState.saveStatus = Status.Complete
      recordState.recordImages.applyChanges()
    },
    // remove cached records, so that the next navigation to "Search" re-fetches
    // data from the server
    update(cache) {
      cache.modify({
        fields: {
          records(_, { DELETE }) {
            return DELETE
          },
          recordsAggregate(_, { DELETE }) {
            return DELETE
          }
        }
      })
    },
    // Refetch the saved booking so that we pick up the latest image data from
    // the server. This is needed since the ENROLL_BOOKING call doesn't include
    // the imageMetadata in the response.
    refetchQueries: [
      {
        query: GET_RECORD,
        variables: { recordNum: jfdSnap.recordNumber }
      }
    ],
    onError(error) {
      recordState.saveStatus = Status.Error
      console.log(error)
    }
  })

  function handleSave() {
    recordState.recordImages.setImagesMetadata()
    recordState.hasSaveButtonBeenClicked = true
    recordState.saveStatus = Status.Loading
    if (!jfdSnap.recordNumber) {
      recordState.saveStatus = Status.Error
      console.log('RecordNumber is undefined')
      return
    }

    // TODO Review if Native Exts will be used on the Non-criminal section.

    // // Update the value of an existing field
    // const updateField = (fieldName: string, fieldValue: string) => {
    //   try {
    //     // make sure that the field exists
    //     if (jfdSnap.hasFieldDef(fieldName)) {
    //       const fieldDef = jfdSnap.getFieldDef(fieldName)
    //       jfdSnap.setValue(fieldName, fieldValue.substring(0, fieldDef.maxLength))
    //     }
    //   } catch (e) {
    //     console.log(
    //       `Exception in booking handleSave(), updateField(), field: ${fieldName}, value: ${fieldValue}, exception: ${e}`
    //     )
    //   }
    // }

    // // Add the information about any capture devices used in this booking session
    // const supportedModalities = jfdSnap.getModalitesAvailable() // only for modalities supported by the jfd
    // supportedModalities.forEach((modality) => {
    //   const images = recordState.recordImages.getImagesByModality([modality])
    //   // If no images exist for that modality or no capture devices were used, set the equipment info to none
    //   if (!images || 0 === images.length) {
    //     // No images found for this modality or previously captured images were deleted:
    //     // make sure to clear any equipment fields, if any such fields exist for the modality
    //     updateField(`${modality}ImageCaptureEquipment.EquipmentMake`, '')
    //     updateField(`${modality}ImageCaptureEquipment.EquipmentModel`, '')
    //     updateField(`${modality}ImageCaptureEquipment.EquipmentSerialNumber`, '')
    //   } else {
    //     // Images exist for this modality: make sure to update the equipment fields, but only if we did a capture
    //     if (deviceInfo) {
    //       updateField(`${modality}ImageCaptureEquipment.EquipmentMake`, deviceInfo.Manufacturer)
    //       updateField(`${modality}ImageCaptureEquipment.EquipmentModel`, deviceInfo.Model)
    //       updateField(`${modality}ImageCaptureEquipment.EquipmentSerialNumber`, deviceInfo.SerialNo)
    //     }
    //   }
    // })
    const biographics = jfdSnap.getValues()
    const recordType = getPrintTypeValue()
    const recordNum = jfdSnap.recordNumber

    const recordImages = recordState.recordImages.getAllImages()
    const recordBiographics = { biographics, recordNum, recordType }
    const recordImageMetadata = {
      recordNum,
      recordType,
      imageMetadata: recordImages
    }

    return enrollRecord({ variables: { recordBiographics, recordImageMetadata } })
  }

  return handleSave
}

function getPrintTypeValue() {
  if (jfdInst.hasFieldDef('RecordPrintType')) {
    return jfdInst.getValue('RecordPrintType')
  } else if (jfdInst.hasFieldDef('PrintType')) {
    return jfdInst.getValue('PrintType')
  } else {
    throw new Error('The Print type field is not defined in the JFD')
  }
}
