import { useCallback, useEffect, useState } from 'react'

import { IWImage, ManagerEvents, PrintFormatInfo } from '../IWNativeExtDeviceDefines'
import { PrintManager } from '../PrintManager'
import {
  availablePrintersEventHandlerWrapper,
  fieldNamesEventHandlerWrapper,
  printEventHandlerWrapper,
  printPreviewEventHandlerWrapper,
  printerStatusEventHandlerWrapper,
  printersEventHandlerWrapper
} from './printEventHandlers'

/*
 * This hook creates listeners for the NativeExtension Print Events.
 * It subscribes to the Print Events and cleans them up when it is unmounted.
 * It keeps track of all variables involved in the scanning process.
 * This hook is not intended to be used by itself. It should be used by calling the
 * usePrintManager hook.
 */

export function usePrintDeviceEvents(printManager: PrintManager) {
  const [printersList, setPrintersList] = useState<string[]>([])
  const [imagesList, setImagesList] = useState<IWImage[][]>([])
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [fieldNames, setFieldNames] = useState<string[]>([])
  const [printerStatus, setPrinterStatus] = useState<string[] | null>(null)

  useEffect(() => {
    const availablePrintersEventHandler = availablePrintersEventHandlerWrapper(setPrintersList)
    const printEventHandler = printEventHandlerWrapper(setErrorMessage)
    const printersEventHandler = printersEventHandlerWrapper(setPrintersList, setErrorMessage)
    const fieldNamesEventHandler = fieldNamesEventHandlerWrapper(setFieldNames, setErrorMessage)
    const printerStatusEventHandler = printerStatusEventHandlerWrapper(setPrinterStatus, setErrorMessage)
    const printPreviewEventHandler = printPreviewEventHandlerWrapper(setImagesList, setErrorMessage)

    document.addEventListener(ManagerEvents.AvailablePrinters, availablePrintersEventHandler)
    document.addEventListener(ManagerEvents.Print, printEventHandler)
    document.addEventListener(ManagerEvents.Printers, printersEventHandler)
    document.addEventListener(ManagerEvents.FieldNames, fieldNamesEventHandler)
    document.addEventListener(ManagerEvents.PrinterStatus, printerStatusEventHandler)
    document.addEventListener(ManagerEvents.PrintPreview, printPreviewEventHandler)

    return () => {
      document.removeEventListener(ManagerEvents.AvailablePrinters, availablePrintersEventHandler)
      document.removeEventListener(ManagerEvents.Print, printEventHandler)
      document.removeEventListener(ManagerEvents.Printers, printersEventHandler)
      document.removeEventListener(ManagerEvents.FieldNames, fieldNamesEventHandler)
      document.removeEventListener(ManagerEvents.PrinterStatus, printerStatusEventHandler)
      document.removeEventListener(ManagerEvents.PrintPreview, printPreviewEventHandler)
    }
  }, [])

  const print = useCallback(
    (dgn: PrintFormatInfo, personalizationData: { [key: string]: string }[], printerName: string) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.Print(dgn, personalizationData, printerName)
    },
    [printManager]
  )

  const printPreview = useCallback(
    (dgn: PrintFormatInfo, personalizationData?: { [key: string]: string }[]) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.PrintPreview(dgn, personalizationData)
    },
    [printManager]
  )

  const getPrinters = useCallback(
    (onlyAvailablePrinters: boolean) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.GetPrinters(onlyAvailablePrinters)
    },
    [printManager]
  )

  const getFieldNames = useCallback(
    (dgn: PrintFormatInfo) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.GetFieldNames(dgn)
    },
    [printManager]
  )

  const getPrinterStatus = useCallback(
    (printerName: string) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.GetPrinterStatus(printerName)
    },
    [printManager]
  )

  const pageSetup = useCallback(
    (dgn: PrintFormatInfo) => {
      if (!printManager || !printManager.IsConnected()) setErrorMessage('Print Manager not connected')
      else printManager?.PageSetup(dgn)
    },
    [printManager]
  )

  const clearPrintData = useCallback(() => {
    setErrorMessage(null)
    setPrintersList([])
    setImagesList([])
    setFieldNames([])
    setPrinterStatus(null)
  }, [])

  return {
    clearPrintData,
    errorMessage,
    fieldNames,
    getFieldNames,
    getPrinters,
    getPrinterStatus,
    imagesList,
    pageSetup,
    print,
    printersList,
    printerStatus,
    printPreview
  }
}
