import { Box, Flex, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from '@chakra-ui/react'
import { PlusOutline } from 'heroicons-react'
import { useEffect, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'

import { QuickActionButton } from '../../../../components/QuickActionButton'
import { usePrevious } from '../../../../hooks/usePrevious'
import { ResourceSection } from '../../../../layouts/resource'
import { RecordTabModality, faceCaptureBioTypes } from '../../../../lib/api/record'
import { useIsReadOnlyRecord } from '../../hooks/faceCapture/useIsReadOnlyRecord'
import { recordState } from '../../state'
import { AppearanceContainer } from './Appearance'

export function FaceCaptureContainer() {
  const { recordImages: recordImagesSnap, maxImages: maxImagesSnap } = useSnapshot(recordState)
  const [tabIndex, setTabIndex] = useState(0)
  const faceTabListRef = useRef<HTMLDivElement>(null)

  const faceCaptureTabs = recordImagesSnap.getTabs(RecordTabModality.FaceCapture)
  !faceCaptureTabs.length && recordState.recordImages.addTab(RecordTabModality.FaceCapture)

  const faceTabsPreviousLength = usePrevious(faceCaptureTabs.length)

  useEffect(() => {
    const { current: tabList } = faceTabListRef

    if (tabList !== null && faceCaptureTabs.length > Number(faceTabsPreviousLength)) {
      tabList.scrollLeft = tabList.scrollWidth
    }
  }, [faceCaptureTabs.length, faceTabsPreviousLength])

  let tabs = faceCaptureTabs.map((_, index) => (
    <Tab
      minW={150}
      key={index}
      color="primary"
      _selected={{
        color: 'secondary',
        borderColor: 'secondary'
      }}
    >
      {`Appearance ${index + 1}`}
    </Tab>
  ))

  let tabPanels = faceCaptureTabs.map((tab, index) => (
    <TabPanel key={index} padding={0}>
      <Text color="gray.700" textStyle="subtitle" textTransform="uppercase">
        Face Capture
      </Text>
      <AppearanceContainer deleteAppearance={deleteAppearance} groupDateTime={tab.groupDateTime} index={index} />
    </TabPanel>
  ))

  function appendAppearence() {
    if (faceCaptureTabs.length < 20) {
      recordState.recordImages.addTab(RecordTabModality.FaceCapture)
      setTabIndex(faceCaptureTabs.length)
    }
  }

  function deleteAppearance(groupDateTime: Date, index: number) {
    /* 
     Cancel all the image requests of this group if there is one or delete all the
     images of this group from the server if uploaded
    */
    recordState.recordImages.deleteGroup(faceCaptureBioTypes, groupDateTime)

    /*
     We want to make deletion feel natural, so when the user deletes an Appearance we
     choose the appearance to its right (or the max appearance index): 
    */
    setTabIndex(Math.max(0, Math.min(faceCaptureTabs.length - 2, index)))
  }

  const isReadOnly = useIsReadOnlyRecord()

  return (
    <Tabs index={tabIndex} onChange={setTabIndex}>
      <ResourceSection
        submenu={
          <Flex alignItems="center" px={2}>
            <Box ref={faceTabListRef} overflow="auto" overflowY="hidden">
              <TabList>{tabs}</TabList>
            </Box>
            <Spacer />
            <Tooltip
              isDisabled={!maxImagesSnap.Face}
              label="You have reached the maximum number of images"
              placement="auto"
            >
              <Box ml={5}>
                <QuickActionButton
                  label="New Appearance"
                  onClick={appendAppearence}
                  isDisabled={isReadOnly || maxImagesSnap.Face}
                  iconAs={PlusOutline}
                />
              </Box>
            </Tooltip>
          </Flex>
        }
      >
        <TabPanels>{tabPanels}</TabPanels>
      </ResourceSection>
    </Tabs>
  )
}
