import { useEffect } from 'react'

export function useKeyDownListener(callback: (event: any) => void, supportedTargets: string[] | undefined = undefined) {
  useEffect(() => {
    const listener = (e: any) => {
      const tagName: string = e?.target?.localName || e?.target?.tagName
      console.debug(`useKeyDownListener(), listener, tagName: ${tagName}`)
      // Only accept key-events that are not restricted by specified targets
      // or are dispatched onto a supported target.
      // For instance, to avoid key strokes in controls such as text fields being included
      // one may want to restrict the target to 'body' or 'section'
      if (!supportedTargets || !!supportedTargets.find((el) => el.toUpperCase() === tagName.toUpperCase())) {
        callback(e)
      }
    }
    document.addEventListener('keydown', listener, true)

    return () => {
      document.removeEventListener('keydown', listener, true)
    }
  }, [callback, supportedTargets])
}

export function useKeyUpListener(callback: (event: any) => void, supportedTargets: string[] | undefined = undefined) {
  useEffect(() => {
    const listener = (e: any) => {
      const tagName: string = e?.target?.localName || e?.target?.tagName
      console.debug(`useKeyUpListener(), listener, tagName: ${tagName}`)
      // Only accept key-events that are not restricted by specified targets
      // or are dispatched onto a supported target.
      // For instance, to avoid key strokes in controls such as text fields being included
      // one may want to restrict the target to 'body' or 'section'
      if (!supportedTargets || !!supportedTargets.find((el) => el.toUpperCase() === tagName.toUpperCase())) {
        callback(e)
      }
    }
    document.addEventListener('keyup', listener, true)

    return () => {
      document.removeEventListener('keyup', listener, true)
    }
  }, [callback, supportedTargets])
}
