import { useState } from 'react'

import { ScanOrder, ScanStep } from '../../pages/booking/fingerprint/constants'
import { generateValidStepIndex } from './utils'

export interface ScanSteps {
  currentStep: ScanStep
  currentIndex: number
  nextIndex: number
}

export function useScanStepsGenerator(scanOrder: ScanOrder, scanElements: { [x: string]: string }) {
  const [isDirty, setIsDirty] = useState(false)
  const [scanStepsOrder, setScanStepsOrder] = useState<ScanOrder>(scanOrder)
  const [scanCurrentIndex, setScanCurrentIndex] = useState(() =>
    generateValidStepIndex(scanElements, scanStepsOrder, -1)
  )
  const [scanNextIndex, setScanNextIndex] = useState(() =>
    generateValidStepIndex(scanElements, scanStepsOrder, scanCurrentIndex)
  )
  const currentStep = scanStepsOrder[scanCurrentIndex]

  function nextScanStep() {
    setScanCurrentIndex(scanNextIndex)

    const nextIndex = generateValidStepIndex(scanElements, scanStepsOrder, scanNextIndex)
    setScanNextIndex(nextIndex)
    setIsDirty(true)
  }

  function changeScanOrder(scanOrder: ScanOrder) {
    setScanStepsOrder(scanOrder)
    const currentIndex = generateValidStepIndex(scanElements, scanOrder, -1)
    setScanCurrentIndex(currentIndex)
    const nextIndex = generateValidStepIndex(scanElements, scanOrder, currentIndex)
    setScanNextIndex(nextIndex)
    setIsDirty(false)
  }

  return {
    scanSteps: { currentStep, currentIndex: scanCurrentIndex, nextIndex: scanNextIndex },
    isDirty,
    changeScanOrder,
    nextScanStep
  }
}
