import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

import {
  Booking,
  BookingsQueryParams,
  BookingsResponse,
  GET_BOOKINGS,
  getAPIQueryParams,
  mapBookingResponse
} from './utils'

export interface BookingsQueryResult {
  loading: boolean
  error: boolean
  bookings: Booking[]
  count: number
  pages: number
}

export default function useBookingsQuery(params: BookingsQueryParams): BookingsQueryResult {
  const variables = getAPIQueryParams(params)
  const queryResult = useQuery<BookingsResponse>(GET_BOOKINGS, { variables })
  const pages = Math.ceil((queryResult.data?.bookingsAggregate.count || 0) / variables.take)

  const bookings = useMemo(() => {
    return (queryResult.data?.bookings || []).map(mapBookingResponse)
  }, [queryResult.data])

  return {
    loading: queryResult.loading,
    error: !!queryResult.error,
    bookings,
    count: queryResult.data?.bookingsAggregate.count || 0,
    pages
  }
}
