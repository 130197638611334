import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spacer,
  Text,
  useToast
} from '@chakra-ui/react'
import { ExclamationCircleOutline } from 'heroicons-react'
import { Fragment, useRef, useState } from 'react'
import { useSnapshot } from 'valtio'

import useSaveGroup, { GroupCreate } from '../hooks/useSaveGroup'
import useUpdateGroup from '../hooks/useUpdateGroup'
import { groupState } from '../utils'
import DetailPermissions from './DetailPermissions'
import UsersSelection from './UsersSelection'

interface GroupUpsertDialogProps {
  isOpen: boolean
  onClose: () => void
}

interface GroupUpsertDialogSection {
  label: string
  component: JSX.Element
  afterNext?: () => void
}

export default function GroupUpsertDialog({ isOpen, onClose }: GroupUpsertDialogProps) {
  const formRef = useRef<FormRefI>(null)
  const [currentStep, setCurrentStep] = useState(1)
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const toast = useToast()
  const { operation, users, name, permissions, id, groupIdDuplicatedFrom } = useSnapshot(groupState)

  const executeToastSuccessful = () => {
    toast({
      description: `The group ${name} successfully ${operation !== 'edit' ? 'created' : 'updated'}`,
      status: 'info',
      position: 'top'
    })
  }
  const saveGroup = useSaveGroup(() => {
    setCurrentStep(1)
    onClose()
    executeToastSuccessful()
  }, setErrorMessage)

  const updateGroup = useUpdateGroup(() => {
    setCurrentStep(1)
    onClose()
    executeToastSuccessful()
  }, setErrorMessage)

  const sections: GroupUpsertDialogSection[] = [
    {
      label: 'Details & Permissions',
      component: <DetailPermissions formRef={formRef} />,
      afterNext: async () => {
        if (formRef.current) {
          const isValid = (await formRef.current.submit()) as boolean

          if (isValid) {
            goNextStep()
          }
        }
      }
    },
    {
      label: 'Users',
      component: <UsersSelection formRef={formRef} />,
      afterNext: async () => {
        if (formRef.current) {
          const isValid = (await formRef.current.submit()) as boolean

          if (isValid) {
            handleCreateGroup()
          }
        }
      }
    }
  ]

  async function handleCreateGroup() {
    setErrorMessage(undefined)

    const groupData: GroupCreate = {
      name: name,
      permissions: permissions,
      users: users.map((user) => user.id)
    }

    if (operation !== 'edit') {
      await saveGroup(groupData, operation, groupIdDuplicatedFrom)
    } else {
      await updateGroup({
        id: id,
        ...groupData
      })
    }
  }

  const isFirstStep = currentStep === 1
  const isLastStep = currentStep === sections.length
  const previousStep = sections[currentStep - 2]
  const nextStep = sections[currentStep]

  function onCancel() {
    if (operation !== 'edit') {
      groupState.reset()
    }
    setCurrentStep(1)
    setErrorMessage(undefined)

    onClose()
  }

  function handleNextStep() {
    const step = sections[currentStep - 1]
    if (step.afterNext) {
      step.afterNext()
    } else {
      goNextStep()
    }
  }

  function goNextStep() {
    setCurrentStep(currentStep + 1)
  }

  return (
    <Modal
      size="full"
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      isCentered
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent minH="750px" minW="950px" maxW="950px" maxH="900px">
        <ModalBody p={0}>
          <Box>
            <Heading p={4} as="h4" fontSize={25}>
              {operation !== 'edit' ? 'Create a Group' : 'Edit Group'}
            </Heading>
            <Divider />
            <Grid templateColumns="repeat(4, 1fr)">
              <GridItem rowSpan={2} colSpan={1}>
                <Box bgColor="gray.100" height="837px" p={3}>
                  {sections.map((section, index) => (
                    <Fragment key={index}>
                      <Box>
                        <StepIndicator currentStep={currentStep} index={index + 1} key={index} label={section.label} />
                      </Box>
                      {sections.length - 1 !== index && (
                        <Box ml={4} height="20px">
                          <Divider borderColor="gray.400" borderStyle="dashed" orientation="vertical" />
                        </Box>
                      )}
                    </Fragment>
                  ))}
                </Box>
              </GridItem>
              <GridItem colSpan={3}>
                <Box p={1} maxHeight="auto" overflowY="auto">
                  <Box>
                    {errorMessage && (
                      <Flex px={3}>
                        <Icon w={6} h={6} color="red.500" as={ExclamationCircleOutline} />
                        <Text ml={1} color="red.400">
                          {errorMessage}
                        </Text>
                      </Flex>
                    )}
                    <Box p={4}>{sections[currentStep - 1].component}</Box>
                  </Box>
                  <Box w="100%" p={3}>
                    <Flex w="70%" bottom={3} position="absolute">
                      <Box>
                        <Button variant="secondary" onClick={onCancel}>
                          Cancel
                        </Button>
                        {!isFirstStep && (
                          <Button onClick={() => setCurrentStep(currentStep - 1)} ml={1} variant="primary">
                            Back: {previousStep.label}
                          </Button>
                        )}
                      </Box>

                      <Spacer />
                      {isLastStep ? (
                        <Button variant="primary" onClick={handleNextStep}>
                          {operation !== 'edit' ? 'Create' : 'Save'}
                        </Button>
                      ) : (
                        <Button variant="primary" onClick={handleNextStep}>
                          Next: {nextStep.label}
                        </Button>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </GridItem>
            </Grid>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

interface StepIndicatorProps {
  index: number
  label: string
  currentStep: number
}
function StepIndicator({ index, label, currentStep }: StepIndicatorProps) {
  const isActive = currentStep === index

  return (
    <HStack>
      <Box
        my={1}
        textColor={isActive ? 'white' : 'gray'}
        textAlign="center"
        borderRadius="50%"
        bgColor={isActive ? 'secondary' : 'gray.200'}
        width={35}
        height={35}
      >
        <Text pt="15%" fontWeight="600">
          {index}
        </Text>
      </Box>
      <Text fontWeight="700">{label}</Text>
    </HStack>
  )
}

export interface FormRefI {
  submit: () => Promise<any>
}
