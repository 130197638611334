import { Text } from '@chakra-ui/react'

export function SystemVersionLabel({ le2Version }: { le2Version: string }) {
  const visibility = le2Version ? 'visible' : 'hidden'
  return (
    <Text fontSize="xs" color="gray.300" align="right" visibility={visibility}>
      {le2Version}
    </Text>
  )
}
