const Table = {
  baseStyle: {
    th: {
      textTransform: 'none',
      fontWeight: 500
    }
  },
  variants: {
    simple: {
      th: {
        color: 'gray.700'
      }
    }
  },
  sizes: {
    md: {
      th: {
        fontSize: '16px'
      }
    }
  }
}

export default Table
