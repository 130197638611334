export const getXmlVersion = (xml: string): number | null => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(xml, 'text/xml')
  const versionElement = xmlDoc.querySelector('Version')
  if (versionElement) {
    const version = versionElement.textContent
    return parseInt(version || '0') // return version as number
  } else {
    console.error('Version element not found.')
    return null
  }
}

export const getImageUrlFromB64 = (imgData: string, imgType: string) => {
  if (undefined === imgType || null == imgType) {
    imgType = 'jpg' // use jpg as default
  }

  let imageUrl = 'data:image/' + imgType + ';base64,' + imgData
  return imageUrl
}

export const isBase64ImageEmpty = (base64String: string) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = base64String

    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      if (ctx === null) {
        reject('Unable to get context from canvas')
        return
      }
      ctx.drawImage(img, 0, 0, img.width, img.height)

      const imageData = ctx.getImageData(0, 0, img.width, img.height).data
      let isAllWhite = true

      for (let i = 0; i < imageData.length; i += 4) {
        // Check if any pixel is not white (255, 255, 255)
        if (imageData[i] !== 255 || imageData[i + 1] !== 255 || imageData[i + 2] !== 255) {
          isAllWhite = false
          break
        }
      }

      resolve(isAllWhite)
    }
  })
}
