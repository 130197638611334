import {
  AuditPermissions,
  BookingPermissions,
  CardPermissions,
  IdentifyPermissions,
  LineupPermissions, // TODO: Uncomment when Mug Books are ready, Disabled by LENEXTGEN-1773
  // MugBookPermissions,
  Permission,
  RecordPermissions,
  SettingsPermissions,
  TransactionsPermissions
} from '@le2/permissions'
import {
  ClipboardCheckOutline,
  ClipboardListOutline,
  CogOutline,
  CreditCardOutline,
  DocumentSearchOutline,
  SearchCircleOutline,
  UserGroupOutline
} from 'heroicons-react'
import { Suspense, lazy, useEffect, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import PageNotFound from './components/PageNotFound'
import PrivateRoute from './components/PrivateRoute'
import { LoadingContentPage } from './components/StatusContentPage'
import { NativeExtensionsContextProvider } from './contexts/NativeExtensionsContext'
import useGetPrintTypeSubItems, { generatePrintTypeSubItem } from './hooks/useGetPrintTypeSubItems'
import { loadRouteStateFromSessionStorage, useRouteState } from './hooks/useRouteState'
import UserLayout, { MenuItemProps } from './layouts/user'
import { MenuSubitemProps } from './layouts/user/MenuItem'
import agencyConfig from './lib/agencyConfig'
import { Booking } from './lib/api/booking'
import { Record } from './lib/api/record'
import { useSubscribeHubEvents } from './nativeExtension/hooks'
import OIDCCallback from './pages/OIDCCallback'
import OIDCLogin from './pages/OIDCLogin'
import Error from './pages/error'
import useCreateLineupFromBooking from './pages/lineups/useCreateLineupFromBooking'
import { useLineupSubscribeToBookingActions } from './pages/lineups/useLineupSubscribeToBookingActions'
import Login from './pages/login'
import RecordCreate from './pages/record/RecordCreate'
import RecordView from './pages/record/RecordView'
import ViewGroup from './pages/users/ViewGroup'
import { createSystemInfoState, useGetSystemInfo } from './utils/useGetSystemInfo'
import { authUserState } from './utils/usersUtils'

//lazy load these components
const Home = lazy(() => import('./pages/home/'))
const EditProfile = lazy(() => import('./pages/profile/EditProfile'))
const BookingCreate = lazy(() => import('./pages/booking/BookingCreate'))
const BookingView = lazy(() => import('./pages/booking/BookingView'))
const Search = lazy(() => import('./pages/search'))
const Transactions = lazy(() => import('./pages/transactions'))
const Lineups = lazy(() => import('./pages/lineups'))
const LineupGallery = lazy(() => import('./pages/lineups/LineupGallery'))
const LineupPreview = lazy(() => import('./pages/lineups/LineupPreview'))
const LineupView = lazy(() => import('./pages/lineups/LineupView'))
const Audit = lazy(() => import('./pages/audit'))
const Settings = lazy(() => import('./pages/settings'))
const UsersGroups = lazy(() => import('./pages/users'))
const UserInfo = lazy(() => import('./pages/users/UserInfo'))
const OIDC = lazy(() => import('./pages/oidc'))
const Identify = lazy(() => import('./pages/identify/BiographicSearch'))
const IdentifyResults = lazy(() => import('./pages/identify/IdentifyResults'))
const IdentifyViewFullSummary = lazy(() => import('./pages/identify/ViewFullSummary'))
// TODO: Uncomment when Mug Books are ready, Disabled by LENEXTGEN-1773
// const MugBooks = lazy(() => import('./pages/mugbooks/MugBooks'))
const Cards = lazy(() => import('./pages/cards/Cards'))
const Records = lazy(() => import('./pages/record'))
const Credentials = lazy(() => import('./pages/credentials/Credentials'))

// Load history stack from session
loadRouteStateFromSessionStorage()

interface ResourceAction<I> {
  iconMenu: React.ReactElement<any, string>
  iconAs: any
  text: string
  onClick: (resource: I, openItOnNewTab?: boolean) => void
  dialog?: JSX.Element
  isEnabled: (resource: I) => boolean
  disableMessage: (resource: I) => string
  permission: Permission
}

export type BookingAction = ResourceAction<Booking>
export type RecordAction = ResourceAction<Record>

interface RecordTypeGroupSubmenus {
  createSubmenus: MenuSubitemProps[]
  viewSubmenus: MenuSubitemProps[]
}

/**
 * Defines an object to hold callback declarations to subscribe to various booking operations.
 * These methods are called where the respective booking operation executes.
 * For instance, Lineups subscribe to booking update/delete/seal so that the lineups list is refreshed
 * after a booking content is modified or a booking is sealed/unsealed
 */
export interface BookingActionSubscriber {
  onCreate?: (booking?: Booking) => void
  onUpdate?: (booking?: Booking) => void
  onSeal?: (booking?: Booking, sealed?: boolean) => void
  onSubmit?: (booking?: Booking) => void
  onActivate?: (booking?: Booking, activated?: boolean) => void
  onDelete?: (booking?: Booking, deleted?: boolean) => void
}

export default function App() {
  const { data, loading } = useGetSystemInfo()
  const { me } = useSnapshot(authUserState)
  const history = useHistory()
  const { pushHistoryStack, popHistoryStack } = useRouteState()

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === 'PUSH') {
        if (location.key) {
          pushHistoryStack(location.key)
        }
      }
      if (history.action === 'POP') {
        if (location.key) {
          popHistoryStack(location.key)
        }
      }
    })
  }, [history, pushHistoryStack, popHistoryStack])

  useEffect(() => {
    if (!loading && data) {
      // Creates a global state for the system info given by the server.
      createSystemInfoState(data)
    }
  }, [data, loading])

  // Subscribe to NativeExtension Hub events
  useSubscribeHubEvents()

  const { backgroundChecks } = agencyConfig.features

  const displayRecordsModule = !agencyConfig.features.records?.hide
  const displayCardsModule = !agencyConfig.features.cards?.hide
  // TODO: Uncomment when Mug Books are ready, Disabled by LENEXTGEN-1773
  // const displayMugBooksModule = !agencyConfig.features.mugBooks?.hide

  // booking module subfields
  const bookingPrintTypeSubItems = useGetPrintTypeSubItems(
    agencyConfig.features.bookings.userFilteredPrintTypes(me),
    '/bookings/new/info/'
  )

  // record module subfields
  const recordPrintTypeSubItems = useGetPrintTypeSubItems(
    agencyConfig.features.records.userFilteredPrintTypes(me),
    '/records/new/info/'
  )

  // Record groups module with subfields
  const recordTypeGroupMenus = useMemo<RecordTypeGroupSubmenus>(() => {
    const { recordTypeGroups } = agencyConfig.features

    if (recordTypeGroups.length > 0) {
      return {
        createSubmenus: recordTypeGroups.map((submenu) => {
          return {
            label: `New ${submenu.name} Record`,
            subitems: submenu.printTypes.map((printType) =>
              // TODO uncomment to use the specified record type
              // generatePrintTypeSubItem(printType, `/${submenu.recordType}/new/info/`)
              generatePrintTypeSubItem(printType, `/bookings/new/info/`)
            ),
            permission: submenu.permissions.create
          }
        }),
        viewSubmenus: recordTypeGroups.map((submenu) => {
          // TODO implement dynamic view pages per record type.
          return {
            label: `Saved ${submenu.name} Records`,
            // TODO uncomment to use the specified record type
            // to: submenu.recordType === 'bookings' ? `/search?recordType=${submenu.name}` : 'records',
            to: `/search?recordType=${submenu.name}`,
            permission: submenu.permissions.create
          }
        })
      }
    }

    // Default options that covers most agencies
    return {
      createSubmenus: [
        ...(bookingPrintTypeSubItems.length > 0
          ? [
              {
                label: 'New Criminal Record',
                subitems: bookingPrintTypeSubItems,
                permission: BookingPermissions.BookingCreate
              }
            ]
          : []),

        ...(recordPrintTypeSubItems.length > 0
          ? [
              {
                label: 'New Civil Record',
                subitems: recordPrintTypeSubItems,
                permission: RecordPermissions.RecordCreate
              }
            ]
          : [])
      ],
      viewSubmenus: [
        {
          label: 'Saved Criminal Records',
          to: '/search',
          permission: BookingPermissions.BookingView
        },
        ...(displayRecordsModule
          ? [
              {
                label: 'Saved Civil Records',
                to: '/records',
                permission: RecordPermissions.RecordView
              }
            ]
          : [])
      ]
    }
  }, [displayRecordsModule, bookingPrintTypeSubItems, recordPrintTypeSubItems])

  const menuItems: MenuItemProps[] = [
    {
      label: 'Identify',
      icon: SearchCircleOutline,
      to: '/identify',
      permission: IdentifyPermissions.IdentifyView
    },
    {
      label: 'New Record',
      icon: ClipboardListOutline,
      subitems: recordTypeGroupMenus.createSubmenus
    },
    {
      label: 'Records',
      icon: DocumentSearchOutline,
      subitems: [
        ...recordTypeGroupMenus.viewSubmenus,
        ...(backgroundChecks
          ? [
              {
                label: 'Transactions',
                to: '/transactions',
                permission: TransactionsPermissions.TransactionsView
              }
            ]
          : [])
      ]
    },
    {
      label: 'Investigate',
      icon: UserGroupOutline,
      subitems: [
        {
          label: 'Lineups',

          to: '/lineups',
          permission: LineupPermissions.LineupView
        }
        // TODO: Uncomment when Mug Books are ready, Disabled by LENEXTGEN-1773
        /* ...(displayMugBooksModule
          ? [
              {
                label: 'Mug Books',
                permission: MugBookPermissions.MugBookView,
                to: '/mug-books'
              }
            ]
          : []) */
      ]
    },
    {
      label: 'Audit',
      icon: ClipboardCheckOutline,
      permission: AuditPermissions.AuditView,
      to: '/audit'
    },
    ...(displayCardsModule
      ? [
          {
            label: 'Cards',
            icon: CreditCardOutline,
            permission: CardPermissions.CardView,
            to: '/cards'
          }
        ]
      : []),
    {
      label: 'Settings',
      icon: CogOutline,
      subitems: [
        { label: 'Users and Groups', to: '/users', permission: SettingsPermissions.SettingsUsersGroups },
        { label: 'OIDC', to: '/oidc', permission: SettingsPermissions.SettingsOIDC }
      ]
    }
  ]

  const bookingActions: BookingAction[] = [
    useCreateLineupFromBooking({
      text: 'Create lineup',
      iconMenu: <UserGroupOutline />,
      iconAs: UserGroupOutline
    })
  ]

  // Lineups subscribe to booking update and seal so that the lineups list is refreshed
  // after a booking content is modified or a booking is sealed/unsealed
  const bookingActionSubscribers = [useLineupSubscribeToBookingActions()]

  return (
    <Switch>
      <Route path="/error">
        <Error />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/OIDCCallback">
        <OIDCCallback />
      </Route>
      <Route path="/OIDCLogin">
        <OIDCLogin />
      </Route>

      <UserLayout menuItems={menuItems}>
        <Suspense fallback={<LoadingContentPage />}>
          <Switch>
            <PrivateRoute exact path="/" permissions={[]}>
              <Home />
            </PrivateRoute>
            <PrivateRoute exact path="/profile" permissions={[]}>
              <EditProfile />
            </PrivateRoute>
            <PrivateRoute path="/bookings/new" permissions={[BookingPermissions.BookingCreate]}>
              <BookingCreate />
            </PrivateRoute>
            <PrivateRoute path="/records/new" permissions={[RecordPermissions.RecordCreate]}>
              <RecordCreate />
            </PrivateRoute>
            <PrivateRoute path="/bookings/:bookingNum" permissions={[BookingPermissions.BookingView]}>
              <BookingView bookingActions={bookingActions} bookingActionSubscribers={bookingActionSubscribers} />
            </PrivateRoute>
            <PrivateRoute path="/records/:recordNum" permissions={[RecordPermissions.RecordView]}>
              <NativeExtensionsContextProvider>
                <RecordView />
              </NativeExtensionsContextProvider>
            </PrivateRoute>

            <PrivateRoute path="/search" permissions={[BookingPermissions.BookingView]}>
              <Search bookingActions={bookingActions} />
            </PrivateRoute>
            <PrivateRoute path="/records" permissions={[RecordPermissions.RecordView]}>
              <NativeExtensionsContextProvider>
                <Records />
              </NativeExtensionsContextProvider>
            </PrivateRoute>
            {displayCardsModule && (
              <PrivateRoute path="/credentials" permissions={[CardPermissions.CardView]} exact>
                <NativeExtensionsContextProvider>
                  <Credentials />
                </NativeExtensionsContextProvider>
              </PrivateRoute>
            )}
            {backgroundChecks && (
              <PrivateRoute path="/transactions" permissions={[TransactionsPermissions.TransactionsView]}>
                <NativeExtensionsContextProvider>
                  <Transactions />
                </NativeExtensionsContextProvider>
              </PrivateRoute>
            )}
            <PrivateRoute path="/lineups" permissions={[LineupPermissions.LineupView]} exact>
              <Lineups />
            </PrivateRoute>
            <PrivateRoute path="/lineups/new" permissions={[LineupPermissions.LineupView]} exact>
              <LineupGallery />
            </PrivateRoute>
            <PrivateRoute path="/lineups/new/preview" permissions={[LineupPermissions.LineupView]}>
              <LineupPreview />
            </PrivateRoute>
            <PrivateRoute path="/lineups/:lineupNum" permissions={[LineupPermissions.LineupView]}>
              <LineupView />
            </PrivateRoute>
            <PrivateRoute path="/audit" permissions={[AuditPermissions.AuditView]}>
              <Audit />
            </PrivateRoute>
            <PrivateRoute
              path="/settings"
              permissions={[SettingsPermissions.SettingsUsersGroups, SettingsPermissions.SettingsOIDC]}
            >
              <Settings />
            </PrivateRoute>
            {/*  TODO: Uncomment when Mug Books are ready, Disabled by LENEXTGEN-1773 */}
            {/* <PrivateRoute path="/mug-books" permissions={[MugBookPermissions.MugBookView]}>
              <MugBooks />
            </PrivateRoute> */}
            <PrivateRoute path="/cards" permissions={[CardPermissions.CardView]} exact>
              <Cards />
            </PrivateRoute>
            <PrivateRoute path="/identify" permissions={[]} exact>
              <Identify />
            </PrivateRoute>
            <PrivateRoute path="/identify/results" permissions={[]} exact>
              <IdentifyResults />
            </PrivateRoute>
            <PrivateRoute path="/identify/summary" permissions={[]} exact>
              <IdentifyViewFullSummary bookingActions={bookingActions} />
            </PrivateRoute>
            <PrivateRoute path="/users" permissions={[SettingsPermissions.SettingsUsersGroups]}>
              <UsersGroups />
            </PrivateRoute>
            <PrivateRoute path="/user/info" permissions={[SettingsPermissions.SettingsUsersGroups]} exact={true}>
              <UserInfo />
            </PrivateRoute>
            <PrivateRoute path="/view-group" permissions={[SettingsPermissions.SettingsUsersGroups]}>
              <ViewGroup />
            </PrivateRoute>
            <PrivateRoute path="/oidc" permissions={[SettingsPermissions.SettingsOIDC]}>
              <OIDC />
            </PrivateRoute>
            <Route path="*" render={() => <PageNotFound />} />
          </Switch>
        </Suspense>
      </UserLayout>
    </Switch>
  )
}
