import { useQuery } from '@apollo/client'
import { Box, Heading, Table, Tbody, Td, Text, Tr } from '@chakra-ui/react'
import { LE2UsersType } from '@le2/common-types'
import {
  GenericJFDValues,
  GenericMultipleOccurrenceNoSubfieldsValue,
  GenericSingleOccurrenceNoSubfieldsValue,
  GenericSingleOccurrenceSubfieldsValue,
  JFD,
  JFDField,
  JFDValue
} from '@le2/jfd'
import DayJS from 'dayjs'
import { ReactNode, useCallback, useEffect, useState } from 'react'

import { ME } from '../../../apollo/Users'
import RecordAvatar from '../../../components/RecordAvatar'
import { Record } from '../../../lib/api/record'

interface SummaryRecordReportProps {
  jfd: JFD
  componentRef: React.RefObject<HTMLDivElement>
  records: Record[]
  printFields: string[]
  onChangeProgress: (progress: number) => void
  onFailed: () => void
}

type RecordData = { label: string; value: JFDValue } | undefined

export default function SummaryRecordReport({
  componentRef,
  records,
  jfd,
  printFields,
  onChangeProgress,
  onFailed
}: SummaryRecordReportProps) {
  const [recordsDownloaded, setRecordsDownloaded] = useState<number>(0)

  const { data } = useQuery(ME, {
    onError(error) {
      console.error(JSON.stringify(error))
    }
  })

  useEffect(() => {
    const progress = Math.round((recordsDownloaded * 100) / records.length)

    onChangeProgress(progress)
  }, [recordsDownloaded, onChangeProgress, records])

  const onDownloaded = useCallback(() => {
    setRecordsDownloaded((curr) => curr + 1)
  }, [])

  return (
    <Box display="none">
      <Box ref={componentRef}>
        {records.map((record, index) => (
          <SummaryRecordReportTable
            printFields={printFields}
            onDownloaded={onDownloaded}
            jfd={jfd}
            key={index}
            record={record}
            data={data}
          />
        ))}
      </Box>
    </Box>
  )
}

interface SummaryRecordReportTableProps {
  record: Record
  data: any
  jfd: JFD
  printFields: string[]
  onDownloaded: () => void
}

function SummaryRecordReportTable({ record, printFields, data, jfd, onDownloaded }: SummaryRecordReportTableProps) {
  const facefront = record.images.getLatestFaceFront()

  useEffect(() => {
    if (facefront === null || facefront.image.isDownloaded) {
      onDownloaded()
    }
  }, [facefront, onDownloaded])

  jfd.resetFields()
  jfd.setValues(record.biographics)

  // Get title
  const title = jfd.getValue('DisplayFullName') || 'Unknown'
  // Get JFD Values
  const recordData = jfd.getFieldAttr((jfdField: JFDField) => {
    if (!printFields.includes(jfdField.fullName)) {
      return undefined
    }

    if (jfdField.options.length) {
      return { label: jfdField.label, value: jfdField.options.find((opt) => opt[0] === jfdField.value)?.[1] || '' }
    } else {
      return { label: jfdField.label, value: jfdField.value }
    }
  })

  /* TODO Refactor when agency config ready to only allow lvmpd */
  let recordNumber = ''
  try {
    recordNumber = jfd.recordNumber
  } catch (e) {}

  return (
    <Table
      variant="unstyled"
      background="white"
      sx={{
        pageBreakAfter: 'always',
        '@media print': {
          '@page': {
            margin: '1.5cm'
          }
        }
      }}
      textTransform="uppercase"
    >
      <Tbody>
        <HeaderReport
          title={title}
          record={record}
          user={data?.me}
          recordNumber={recordNumber}
          createdAt={record.createdAt}
        />
        <BodyReport title="Biographic Info" jfd={jfd} recordData={recordData} />
      </Tbody>
    </Table>
  )
}

interface HeaderReportProps {
  title: string
  record: Record
  user: LE2UsersType | undefined
  recordNumber: string
  createdAt: Date | undefined
}

function HeaderReport({ title, record, user, recordNumber, createdAt }: HeaderReportProps) {
  const fullName = user?.firstName && user?.lastName ? `${user?.firstName} ${user?.lastName}` : ''
  return (
    <Tr>
      <Td w="45%">
        <RecordAvatar record={record} w="200px" h="200px" borderRadius="4px" />
      </Td>
      <Td pl={0}>
        <Heading as="h3" size="lg" mb={4}>
          {title}
        </Heading>
        <HeaderReportRow>Record Number: {recordNumber}</HeaderReportRow>
        {createdAt && <HeaderReportRow>Created on {DayJS(createdAt).format('YYYY-MM-DD')}</HeaderReportRow>}
        <HeaderReportRow>
          Printed on {DayJS(new Date()).format('YYYY-MM-DD')} {fullName && `by ${fullName}`}
        </HeaderReportRow>
      </Td>
    </Tr>
  )
}

function HeaderReportRow({ children }: { children: ReactNode }) {
  return (
    <Box mb={4}>
      <Text display="inline-block" color="gray.600">
        {children}
      </Text>
    </Box>
  )
}

function BodyReport({ title, jfd, recordData }: { title: string; jfd: JFD; recordData: GenericJFDValues<RecordData> }) {
  return (
    <>
      <Tr>
        <Td colSpan={2} px={0}>
          <Heading as="h4" size="sm" bg="gray.200" p={2} borderRadius="5px" m={0}>
            {title}
          </Heading>
        </Td>
      </Tr>
      {Object.keys(recordData).map((key, i) => {
        const fieldType = jfd.getFieldDef(key).getType()
        switch (fieldType) {
          case 'SONS':
            return (
              <BodyReportSONSRow key={i}>
                {recordData[key] as GenericSingleOccurrenceNoSubfieldsValue<RecordData>}
              </BodyReportSONSRow>
            )
          case 'SOS':
            return (
              <BodyReportSOSRow key={i}>
                {recordData[key] as GenericSingleOccurrenceSubfieldsValue<RecordData>}
              </BodyReportSOSRow>
            )
          case 'MONS':
            return (
              <BodyReportMONSRow key={i}>
                {recordData[key] as GenericMultipleOccurrenceNoSubfieldsValue<RecordData>}
              </BodyReportMONSRow>
            )
          case 'MOS':
            // MOS rows not implemented yet.
            return null
          default:
            return null
        }
      })}
    </>
  )
}

function BodyReportSONSRow({ children }: { children: GenericSingleOccurrenceNoSubfieldsValue<RecordData> }) {
  return <BodyReportRow label={children?.label}>{children?.value}</BodyReportRow>
}

function BodyReportSOSRow({ children }: { children: GenericSingleOccurrenceSubfieldsValue<RecordData> }) {
  const sosRows = Object.values(children).map((field, i) => (
    <BodyReportRow key={`SOS_${i}`} label={field?.label}>
      {field?.value}
    </BodyReportRow>
  ))

  return <>{sosRows}</>
}

function BodyReportMONSRow({ children }: { children: GenericMultipleOccurrenceNoSubfieldsValue<RecordData> }) {
  const label = children[0]?.label
  const values = children.map((e, i) => e?.value && <Text key={`MONS_${i}`}>{e?.value}</Text>).filter((e) => !!e)
  return <BodyReportRow label={label}>{values.length ? values : null}</BodyReportRow>
}

function BodyReportRow({ label, children }: { label?: string; children: ReactNode }) {
  if (!children || !label) {
    return null
  }

  return (
    <Tr w="45%">
      <Td py={1} pr={1} pl={2} fontWeight="bold" verticalAlign="top">
        {label}
      </Td>
      <Td py={1} pr={1} pl={0} color="gray.600">
        {children}
      </Td>
    </Tr>
  )
}
