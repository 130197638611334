import { Box, FormErrorMessage, Icon, InputGroup, InputRightElement } from '@chakra-ui/react'
import { Eye, EyeOff } from 'heroicons-react'
import { Ref, useState } from 'react'
import { DeepMap, FieldError, FieldValues, RegisterOptions } from 'react-hook-form'

import { PASSWORD_CRITERIA, PASSWORD_REGEXP } from '../utils/expressions'
import Input from './Input'

export interface PasswordFieldProps {
  errors: DeepMap<FieldValues, FieldError>
  register: (props: RegisterOptions) => Ref<HTMLInputElement> | undefined
}

export default function PasswordField(props: PasswordFieldProps) {
  const [showPassword, setShowPassword] = useState(false)
  const handlePasswordVisibility = () => setShowPassword(!showPassword)

  return (
    <>
      <InputGroup>
        <Input
          autoComplete="new-password"
          name="password"
          type={showPassword ? 'text' : 'password'}
          ref={props.register({
            required: 'Field is required',
            maxLength: { value: 32, message: 'Maximum length is 32.' },
            minLength: { value: 10, message: 'Minimum length is 10.' },
            pattern: {
              value: PASSWORD_REGEXP,
              message: PASSWORD_CRITERIA
            }
          })}
          maxLength={32}
          minLength={10}
        />
        <InputRightElement width={12}>
          <Box onClick={handlePasswordVisibility}>
            <Icon color="gray.500" as={showPassword ? EyeOff : Eye} w={5} h={5} />
          </Box>
        </InputRightElement>
      </InputGroup>
      <FormErrorMessage>{props.errors.password && props.errors.password.message}</FormErrorMessage>
    </>
  )
}
