import { Avatar, AvatarProps } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import { BioType, Booking, useDownloadImage } from '../lib/api/booking'

interface BookingAvatarProps extends AvatarProps {
  booking?: Booking
  bioType?: BioType
}

export default function BookingAvatar({ booking, bioType = BioType.FaceFront, ...props }: BookingAvatarProps) {
  const avatar = useBookingAvatar(bioType, booking)
  return <Avatar src={avatar} borderRadius="4px" {...props} />
}

function useBookingAvatar(bioType: BioType, booking?: Booking) {
  // get the latest FaceFront to be used as avatar
  // use a dummy placeholder to show an empty image while it loads
  const latestImage = booking?.images.getLatestImage(bioType)
  const [avatarSrc, setAvatarSrc] = useState(latestImage?.image.isURL ? '' : latestImage?.image.src)
  const callback = useCallback(() => setAvatarSrc(latestImage?.image.src || ''), [latestImage])
  useDownloadImage(latestImage, callback)
  return avatarSrc
}

/**
 * Small helper to easily include a key for <BookingAvatar>.
 * Note: this is needed due a bad implementation of `useBookingAvatar` that
 * relies on the `key` instead of using React correctly :(
 */
export function bookingAvatarProps(booking?: Booking, bioType?: BioType) {
  return {
    key: `${booking?.bookingNum}-${bioType}`,
    booking,
    bioType
  }
}
