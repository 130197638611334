import BioType from './BioType'
import Image from './Image'

export default class BookingImage<T extends BookingImageMetadata> {
  image: Image
  bioType: BioType
  metadata: T
  metadataProvider: (() => T) | null
  capturedAt: Date

  constructor(src: string, bioType: BioType, metadata: T, wsqSrc?: string, capturedAt?: string) {
    this.image = new Image(src, wsqSrc)
    this.bioType = bioType
    this.metadata = metadata
    this.capturedAt = capturedAt ? new Date(capturedAt) : new Date()
    this.metadataProvider = null
  }

  setMetadata() {
    if (this.metadataProvider) {
      this.metadata = this.metadataProvider()
    }
  }
}

export type BookingImageMetadata =
  | FaceCaptureMetadata
  | SMTMetadata
  | FingerprintMetadata
  | PalmprintMetadata
  | IrisMetadata

export interface FaceCaptureMetadata extends GroupDateTimeMetadata {
  // TODO: These two fields might save a bit of work when generating EBTS files
  ImageType?: string
  SubjectPose?: string
  PhotoDate?: string
}

export interface SMTMetadata extends GroupDateTimeMetadata {
  // TODO: This metadata field should be mandatory at some point
  ImageType?: string
  smtType: string
  PhotoDate?: string
}

/**
 * A single string is used for all fingerprints except the slaps.
 * The array of strings is used for the finger slaps.
 * The array of qualities maintains the following order:
 * RightFourSlap: [RightIndexFlat, RightMiddleFlat, RightRingFlat, RightLittleFlat]
 * LeftFourSlap: [LeftIndexFlat, LeftMiddleFlat, LeftRingFlat, LeftLittleFlat]
 * You can change this order modifying the expectedFingersRightFourSlap &
 * expectedFingersLeftFourSlap fingerprint constants.
 * If one finger is missing, the quality is set to -1.
 */
export interface FingerprintMetadata {
  quality: string | string[]
  width: string
  height: string
  minutiaeCount: string
}

export interface PalmprintMetadata {
  quality: string
  width: string
  height: string
}

export interface IrisMetadata {
  quality: string
  width: string
  height: string
  irisRadius: string
  irisFocus: string
  irisVisibility: string
  SNR: string
  irisPupilContrast: string
  scleraIrisContrast: string
  contactLensType: string
  contactLensScore: string
}

export interface GroupDateTimeMetadata {
  groupDateTime: Date
}

export type BookingImageGroupMetadata = FaceCaptureMetadata | SMTMetadata
export type FaceCaptureImage = BookingImage<FaceCaptureMetadata>
export type SMTImage = BookingImage<SMTMetadata>
export type FingerprintImage = BookingImage<FingerprintMetadata>
export type PalmprintImage = BookingImage<PalmprintMetadata>
export type IrisImage = BookingImage<IrisMetadata>
