import { Button, HStack, Heading, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { JFD, JFLGroup, getAllFieldNames } from '@le2/jfd'
import { DuplicateOutline, PencilOutline, PlusOutline, TrashOutline } from 'heroicons-react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { QuickActionButton } from '../QuickActionButton'
import { TableActionButton } from '../TableActionButton'
import { JFLRender } from './JFLRender'
import { useJFD } from './jfd-context'
import { getFieldName } from './utils'

/**
 * Container for explicit, multiple-occurrence JFL groups. Renders a table that
 * displays existing elements and a form to add and edit records.
 *
 * In the first render we don't show the table (state.hasInitialRecord is set
 * to false), we only show the form. When the user fills the inputs and clicks
 * on the "Add item" button we hide the form and show the table.
 *
 * From here, the user can add more elements by clicking on "Add item" and
 * filling the form; and also delete, edit and duplicate records by clicking
 * the action buttons in the table.
 */
export function JFLMultiOccurrence({ group }: { group: JFLGroup }) {
  const { jfd, readOnly } = useJFD()

  // we use the first field as a base to keep track of how many elements we have
  const baseField = getAllFieldNames(group)[0].name.split('.')[0]
  const maxOccurrences = jfd.getFieldDef(baseField).maxOccurrences
  const { state, outOfSync, onAdd, onEdit, onDelete, onApply, onApplyAndNext, onClear, onClose, onDuplicate } =
    useCallbacks(jfd, group, baseField, readOnly)

  const disabledAddButtons = useMemo(() => {
    return state.recordCount === maxOccurrences
  }, [state.recordCount, maxOccurrences])

  // skip this render if we detected an external change in the JFD
  if (outOfSync) {
    return null
  }

  const formKey = () => jfd.getField(getAllFieldNames(group)[0].name, state.currentIndex).id + `-${state.currentIndex}`

  return (
    <Fragment>
      <Fragment>
        {state.showTable && (
          <MultiOccurrenceTable
            {...state}
            onDelete={onDelete}
            onEdit={onEdit}
            onDuplicate={onDuplicate}
            group={group}
            maxOccurrences={maxOccurrences}
          />
        )}
        {!readOnly && (state.isAdding || state.isEditing) && (
          <div data-testid="jfl-multi-occurrence-form" key={formKey()}>
            <Heading textStyle="paragraph" fontWeight={500} color="primary" mb={5}>
              {state.isAdding ? group.options?.newOccurrenceLabel : group.options?.editOccurrenceLabel}
            </Heading>
            <JFLRender group={group} index={state.currentIndex} />
            {state.isAdding && (
              <HStack w="100%" justifyContent="flex-end">
                <Button variant="ghost" color="primary" onClick={onClear}>
                  Clear
                </Button>
                <Button variant="ghost" color="secondary" onClick={onApplyAndNext} isDisabled={disabledAddButtons}>
                  Apply and create next
                </Button>

                <Button variant="ghost" color="secondary" onClick={onApply}>
                  Apply
                </Button>
              </HStack>
            )}
            {/*// TODO After define buttons add them here */}
            {state.isEditing && (
              <Button variant="ghost" color="primary" onClick={onClose}>
                Close
              </Button>
            )}
          </div>
        )}
      </Fragment>
      {!(readOnly || state.isAdding || state.isEditing) && (
        <QuickActionButton
          onClick={onAdd}
          label={group.options?.addButtonLabel || ''}
          iconAs={PlusOutline}
          isDisabled={disabledAddButtons}
        />
      )}
    </Fragment>
  )
}

interface MultiOccurrenceGroupState {
  /**
   * The row that is currently being added or edited
   */
  currentIndex: number

  /**
   * How many elements we have. This needs to be kept in sync with the
   * corresponding JFDField, i.e.
   *
   * ```
   * recordCount === JFD.numEntries(baseField)
   * ```
   *
   * should always be true.
   */
  recordCount: number

  isAdding: boolean
  isEditing: boolean
  showTable: boolean
}

function useCallbacks(jfd: JFD, group: JFLGroup, baseField: string, readOnly: boolean) {
  // we can't use useReducer here since React expects the reducer function
  // to be pure (and it will test it by calling it multiple times!), and we
  // need to have side effects in the JFD instance when removing elements
  const maxOccurrences = jfd.getFieldDef(baseField).maxOccurrences
  const [state, setState] = useState(getInitialState(jfd.numEntries(baseField)))
  const { trigger, errors, clearErrors } = useFormContext()

  // make sure that we have the same number of entries in `recordCount` and in
  // the JFD instance
  let outOfSync = false
  if (state.recordCount !== jfd.numEntries(baseField)) {
    setState(getInitialState(jfd.numEntries(baseField)))
    outOfSync = true
  }

  // make sure to update `isAdding` and `isEditing` as necessary when `readOnly`
  // changes
  useEffect(() => {
    if (readOnly) {
      // when in read-only mode, set both `isAdding` and `isEditing` to false to
      // hide the form and show all records in the table
      setState((state) => ({
        ...state,
        isAdding: false,
        isEditing: false
      }))
    }
  }, [readOnly])

  function onAdd() {
    if (state.recordCount < maxOccurrences) {
      const nextIndex = state.currentIndex + 1

      // Create new occurrence
      getAllFieldNames(group)
        .map((fieldDesc) => fieldDesc.name)
        .forEach((fieldName) => jfd.createOccurrence(fieldName, nextIndex))

      // Update state
      setState((state) => {
        return {
          ...state,
          isAdding: true,
          isEditing: false,
          currentIndex: nextIndex,
          recordCount: state.recordCount + 1
        }
      })
    } else {
      console.debug(`The ${group.label} JFL group reached its max occurrences limit: ${maxOccurrences} `)
    }
  }

  function onEdit(index: number) {
    // set `isEditing` to true to show the form and `currentIndex` to the
    // selected row to display the correct values
    setState((state) => ({
      ...state,
      isAdding: false,
      isEditing: true,
      currentIndex: index
    }))
  }

  function onDelete(index: number) {
    // we need this since React may call `setState` multiple times (see https://github.com/facebook/react/issues/12856)
    // which causes issues since we need side-effects on the JFD
    let deletedFromJFD = false

    const fieldNames = getAllFieldNames(group).map((fieldDesc) => fieldDesc.name)
    if (!deletedFromJFD) {
      jfd.removeFields(fieldNames, index)
      deletedFromJFD = true
    }

    setState((state) => {
      // If there is only one row and it will be deleted,
      // after delete the data, form should be visible
      if (state.recordCount === 1) {
        // Recreate 0 occurrence as always should exist 1 record
        getAllFieldNames(group).forEach((fieldDesc) => {
          jfd.createOccurrence(fieldDesc.name, state.currentIndex)
        })
        return {
          ...state,
          isAdding: true,
          isEditing: false
        }
      }

      // When other operation is being perfomed at the same time (for example: add or edit)
      if (state.isEditing || state.isAdding) {
        let isEditing = state.isEditing
        let isAdding = state.isAdding
        const newRecordCount = state.recordCount - 1
        let newCurrentIndex = state.currentIndex

        // if index of the element to be deleted is less than the index of the element being edited,
        // then we should decrease currentIndex which in this case indicates the index of the element being edited.
        if (index < state.currentIndex) {
          newCurrentIndex = state.currentIndex - 1
        }

        // if element to be deleted is the same being edited, decrease currentIndex and hide the form
        if (index === state.currentIndex) {
          newCurrentIndex = state.currentIndex - 1
          isEditing = false
          isAdding = false
        }

        return {
          ...state,
          recordCount: newRecordCount,
          currentIndex: newCurrentIndex,
          isEditing: isEditing,
          isAdding: isAdding
        }
      }

      // When no other operation (except the delete),  update recordCount
      // and currentIndex to point to the last record and hide the form
      const newRecordCount = state.recordCount - 1
      return {
        ...state,
        recordCount: newRecordCount,
        currentIndex: newRecordCount - 1,
        isAdding: false,
        isEditing: false
      }
    })
  }

  function onClose() {
    // If operation is isEditing update the currentIndex to point to the last element
    let currentIndex = state.currentIndex
    if (state.isEditing) {
      currentIndex = state.recordCount - 1
    }
    setState((state) => {
      return {
        ...state,
        isAdding: false,
        isEditing: false,
        currentIndex: currentIndex
      }
    })
  }

  async function onApplyAndNext() {
    const applied = await onApply()

    if (applied) {
      onAdd()
    }
  }

  async function onApply() {
    const groupFieldNames = getFieldNamesByIndex(jfd, group, state.currentIndex)
    // Trigger fields validation
    await trigger(groupFieldNames)

    // Get only errors from the group current index
    const groupErrors = baseField in errors ? errors[baseField][state.currentIndex] : {}

    // Update state if no errors
    if (Object.keys(groupErrors).length === 0) {
      setState((state) => {
        return {
          ...state,
          isAdding: false,
          isEditing: false
        }
      })

      return true
    }

    return false
  }

  function onClear() {
    // Clear field errors
    const groupFieldNames = getFieldNamesByIndex(jfd, group, state.currentIndex)
    clearErrors(groupFieldNames)

    // Set values to default values
    getAllFieldNames(group)
      .map((fieldDesc) => fieldDesc.name)
      .forEach((fieldName) => {
        const fieldDefaultValue = jfd.getField(fieldName, state.currentIndex).defaultValue
        jfd.setValue(fieldName, fieldDefaultValue, state.currentIndex)
      })
  }

  function onDuplicate(index: number) {
    const newRecordCount = state.recordCount + 1
    const newCurrentIndex = state.currentIndex + 1

    // set all values from the selected occurrence to the new index
    getAllFieldNames(group).forEach((fieldDesc) => {
      jfd.createOccurrence(fieldDesc.name, newCurrentIndex)
      jfd.setValue(fieldDesc.name, jfd.getValue(fieldDesc.name, index), newCurrentIndex)
    })

    setState((state) => {
      // Increment counters and hide form
      return {
        ...state,
        isAdding: false,
        isEditing: false,
        recordCount: newRecordCount,
        currentIndex: newCurrentIndex
      }
    })
    // Display new element
    onEdit(newCurrentIndex)
  }

  return { state, outOfSync, onAdd, onEdit, onDelete, onApply, onApplyAndNext, onClear, onClose, onDuplicate }
}

function getInitialState(initialEntries: number): MultiOccurrenceGroupState {
  return {
    currentIndex: initialEntries - 1,
    recordCount: initialEntries,
    isAdding: initialEntries > 1 ? false : true,
    isEditing: false,
    showTable: true
  }
}

interface MultiOccurrenceTableProps {
  group: JFLGroup
  currentIndex: number
  recordCount: number
  isAdding: boolean
  maxOccurrences: number
  onDelete: (index: number) => void
  onEdit: (index: number) => void
  onDuplicate: (index: number) => void
}

function MultiOccurrenceTable(props: MultiOccurrenceTableProps) {
  const { jfd, readOnly } = useJFD()

  // Consider table as empty (with no data) when group only contains one occurrence
  // and the values of the occurrence are the default values (meaning it is empty)
  // NOTE: This will not work when values are set within the jdf rules.
  const isEmptyTable =
    readOnly &&
    props.recordCount === 1 &&
    getAllFieldNames(props.group)
      .map((fieldDesc) => {
        const fieldName = fieldDesc.name
        return jfd.getField(fieldName, props.currentIndex)
      }, [])
      .every((jfdField) => jfdField.defaultValue === jfdField.value)

  return (
    <Table variant="simple" mb={12}>
      {props.group.options?.tableHeaders && (
        <Thead>
          <Tr>
            {props.group.options?.tableFields?.map((field, colIndex) => (
              <Th key={colIndex}>{jfd.getFieldDef(field).label}</Th>
            ))}
          </Tr>
        </Thead>
      )}
      <Tbody>
        {/* This will be displayed only for readOnly mode */}
        {isEmptyTable && (
          <Tr>
            <Td py={3} colSpan={(props.group.options?.tableFields || []).length} borderBottom={0}>
              No data
            </Td>
          </Tr>
        )}
        {[...Array(props.recordCount)].map((_, rowIndex) => {
          // don't show the element that is being added
          if (props.isAdding && rowIndex === props.currentIndex) {
            return null
          }
          return <OccurrenceRow key={rowIndex} {...props} rowIndex={rowIndex} />
        })}
      </Tbody>
    </Table>
  )
}

interface OccurrenceRowProps extends MultiOccurrenceTableProps {
  rowIndex: number
}

function OccurrenceRow(props: OccurrenceRowProps) {
  const { jfd, readOnly } = useJFD()

  return (
    <Tr key={`${props.rowIndex}`}>
      {props.group.options?.tableFields?.map((field, colIndex) => (
        <Td
          key={`${field}-${colIndex}`}
          textStyle="paragraph"
          color={colIndex === 0 ? 'primary' : 'gray.500'}
          pb={2}
          pt={3}
          textTransform={jfd.getField(field, props.rowIndex).convertToUpperCase ? 'uppercase' : undefined}
        >
          {getDisplayValue(jfd, field, props.rowIndex)}
        </Td>
      ))}
      <Td width="1%" pb={2} pt={3}>
        <HStack spacing={3}>
          <TableActionButton
            onClick={() => props.onDelete(props.rowIndex)}
            ariaLabel="Delete row"
            iconAs={TrashOutline}
            isDisabled={readOnly}
          />
          <TableActionButton
            onClick={() => props.onEdit(props.rowIndex)}
            ariaLabel="Edit row"
            iconAs={PencilOutline}
            isDisabled={readOnly}
          />
          <TableActionButton
            onClick={() => props.onDuplicate(props.rowIndex)}
            isDisabled={props.recordCount === props.maxOccurrences || readOnly}
            ariaLabel="Duplicate row"
            iconAs={DuplicateOutline}
          />
        </HStack>
      </Td>
    </Tr>
  )
}

/**
 * Returns:
 * - if the given `fieldName` doesn't have a value: the "--" string
 * - if the given `fieldName` has `options`: the related label for the current value
 * - otherwise: the field value
 */
function getDisplayValue(jfd: JFD, fieldName: string, index: number) {
  const field = jfd.getField(fieldName, index)

  if (!field.value) {
    return '--'
  }

  if (field.options?.length > 0) {
    const opt = field.options.find((opt) => opt[0] === field.value)
    if (opt) {
      return opt[1]
    } else {
      return `[${field.value}]`
    }
  } else {
    return field.value
  }
}

function getFieldNamesByIndex(jfd: JFD, group: JFLGroup, index: number) {
  return getAllFieldNames(group).map((fieldDesc) => {
    const fieldName = fieldDesc.name
    const jfdField = jfd.getField(fieldName, index)
    const name = getFieldName(jfdField, fieldName, index)
    return name
  }, [])
}
