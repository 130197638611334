import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface ConfirmDialogProps {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
  header: ReactNode
  body?: ReactNode
  cancelText?: string
  confirmText?: string
  justifyContent?: string
}

/**
 * Renders a simple dialog with a "Yes" and "Cancel" buttons.
 *
 * The header, body and button labels are customizable.
 */
export default function ConfirmDialog(props: ConfirmDialogProps) {
  function confirmAndClose() {
    props.onConfirm()
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} isCentered>
      <ModalOverlay />
      <ModalContent justifyContent={props.justifyContent}>
        <ModalHeader>{props.header}</ModalHeader>
        {props.body && <ModalBody>{props.body}</ModalBody>}
        <ModalFooter display="flex" justifyContent={props.justifyContent}>
          {props.cancelText && (
            <Button variant="secondary" mr={3} onClick={props.onClose}>
              {props.cancelText}
            </Button>
          )}
          {props.confirmText && (
            <Button variant="primary" onClick={confirmAndClose}>
              {props.confirmText}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
