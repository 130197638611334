/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Center, Flex } from '@chakra-ui/react'

interface PanTiltWheelProps {
  handleRightMovement?: () => void
  handleRightUpMovement?: () => void
  handleRightDownMovement?: () => void
  handleLeftMovement?: () => void
  handleLeftUpMovement?: () => void
  handleLeftDownMovement?: () => void
  handleDownMovement?: () => void
  handleUpMovement?: () => void

  handleCenterClick?: () => void

  handleMousePressedRightMovement?: (event?: any) => void
  handleMousePressedRightUpMovement?: (event?: any) => void
  handleMousePressedRightDownMovement?: (event?: any) => void
  handleMousePressedLeftMovement?: (event?: any) => void
  handleMousePressedLeftUpMovement?: (event?: any) => void
  handleMousePressedLeftDownMovement?: (event?: any) => void
  handleMousePressedDownMovement?: (event?: any) => void
  handleMousePressedUpMovement?: (event?: any) => void

  handleMousePressedZoomIn?: (event?: any) => void
  handleMousePressedZoomOut?: (event?: any) => void

  handleMouseWheel?: (event?: any) => void

  handleKeyPressed?: (event?: any) => void
}

export default function PanTiltControlWheel(props: PanTiltWheelProps) {
  return (
    <Box>
      <Flex>
        <Box
          sx={{
            svg: {
              '& .selectableArea': {
                cursor: 'pointer',
                '&:hover': {
                  // Filable child of nested component
                  '& * > .fillable': {
                    fill: '#03b4d9'
                  },
                  // Filable inside nested component
                  // Center circule style
                  '& > .fillable': {
                    fill: '#03b4d9'
                  }
                },
                '&:active': {
                  // Filable child of nested component
                  '& * > .fillable': {
                    fill: '#03b4d9'
                  },
                  '& > g': {
                    opacity: 0.5
                  },
                  // Filable direct child
                  // Center circle style
                  '& > .fillable': {
                    fill: '#03b4d9',
                    opacity: 0.7
                  }
                }
              }
            }
          }}
        >
          <svg width="137" height="137" viewBox="0 0 137 137" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_8997_149497)">
              <a
                href="#"
                className="selectableArea leftUp"
                onMouseDown={props.handleMousePressedLeftUpMovement}
                onMouseUp={props.handleMousePressedLeftUpMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="leftUp"
                    className="fillable"
                    opacity="0.8"
                    d="M52.8924 46.3741L52.901 46.3686L52.9093 46.3628C53.0524 46.2631 53.2094 46.1693 53.3854 46.0674C53.4193 46.0477 53.4542 46.0276 53.4898 46.0071C53.6334 45.9244 53.7884 45.8351 53.9416 45.7397L54.3488 45.4861L54.1123 45.0688L39.8244 19.8525L39.5784 19.4183L39.1437 19.6635C38.321 20.1275 37.5025 20.6325 36.7285 21.1338C29.1684 25.9392 23.2945 32.4314 19.2777 39.7999L19.0377 40.2402L19.4787 40.4789L44.9713 54.2788L45.4055 54.5139L45.646 54.0826C47.3322 51.0586 49.7689 48.3661 52.8924 46.3741Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <circle id="leftUpArrow" cx="38" cy="38" r="2" fill="#FEFEFE" />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedLeftMovement}
                onMouseUp={props.handleMousePressedLeftMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="left"
                    className="fillable"
                    d="M45.4611 54.414L45.689 53.9769L45.2553 53.7428L19.7648 39.9846L19.323 39.7461L19.0863 40.1888C11.028 55.2635 10.6981 74.0016 19.6241 89.7493L19.8706 90.1843L20.3056 89.9378L45.5219 75.6499L45.9561 75.4039L45.7109 74.9692C42.0419 68.4646 42.1817 60.7035 45.4611 54.414Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <path
                  id="leftArrow"
                  d="M26.5 65.134C25.8333 65.5189 25.8333 66.4811 26.5 66.866L32.5 70.3301C33.1667 70.715 34 70.2339 34 69.4641V62.5359C34 61.7661 33.1667 61.285 32.5 61.6699L26.5 65.134Z"
                  fill="#FEFEFE"
                />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedUpMovement}
                onMouseUp={props.handleMousePressedUpMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="up"
                    className="fillable"
                    d="M74.5161 45.4564L74.9534 45.6866L75.1887 45.252L88.9886 19.7594L89.2278 19.3176L88.7848 19.0806C73.7954 11.0597 55.226 10.7221 39.5114 19.4348L39.0709 19.679L39.3184 20.1177L53.5667 45.3778L53.8076 45.8048L54.2393 45.5724C60.7573 42.0632 68.3554 42.2141 74.5161 45.4564Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <path
                  id="upArrow"
                  d="M63.134 25.5C63.5189 24.8333 64.4811 24.8333 64.866 25.5L68.3301 31.5C68.715 32.1667 68.2339 33 67.4641 33H60.5359C59.7661 33 59.285 32.1667 59.6699 31.5L63.134 25.5Z"
                  fill="#FEFEFE"
                />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedRightUpMovement}
                onMouseUp={props.handleMousePressedRightUpMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="rightUp"
                    className="fillable"
                    opacity="0.8"
                    d="M107.881 36.72C103.034 29.163 96.5428 23.2893 89.2162 19.2704L88.7754 19.0285L88.536 19.4707L74.7361 44.9633L74.5025 45.3948L74.9296 45.6365C77.9103 47.3233 80.6007 49.757 82.5921 52.8754C82.6879 53.0443 82.7928 53.2107 82.8904 53.3656L82.8935 53.3705C82.9967 53.5343 83.0921 53.6858 83.1767 53.8358L83.4223 54.2713L83.8578 54.0257L109.118 39.7773L109.563 39.5265L109.303 39.0871C109.189 38.896 109.075 38.7002 108.959 38.5019C108.615 37.9138 108.258 37.3028 107.881 36.72Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <circle id="rightUpArrow" cx="91" cy="38" r="2" fill="#FEFEFE" />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedLeftDownMovement}
                onMouseUp={props.handleMousePressedLeftDownMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="leftDown"
                    className="fillable"
                    opacity="0.8"
                    d="M46.38 76.0809L46.3706 76.0661L46.3601 76.052C46.2053 75.8432 46.0831 75.6286 45.9515 75.3596L45.7186 74.8832L45.2567 75.1437L19.9967 89.3921L19.5612 89.6378L19.8068 90.0733C20.223 90.8111 20.637 91.5066 21.0945 92.2412L21.0944 92.2412L21.098 92.2468C25.9446 99.8041 32.4362 105.678 39.763 109.697L40.2038 109.939L40.4432 109.497L54.2431 84.0041L54.4766 83.5726L54.0496 83.3309C51.0654 81.6422 48.3722 79.2047 46.38 76.0809Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <circle id="leftDownArrow" cx="38" cy="89" r="2" fill="#FEFEFE" />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedRightMovement}
                onMouseUp={props.handleMousePressedRightMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="right"
                    className="fillable"
                    d="M83.4759 74.5042L83.2444 74.9419L83.6799 75.1777L109.172 88.9776L109.615 89.217L109.852 88.7732C117.87 73.7427 118.206 55.1313 109.451 39.4183L109.206 38.9792L108.768 39.2261L83.5083 53.4745L83.0771 53.7178L83.3161 54.1514C86.9052 60.6628 86.7574 68.2999 83.4759 74.5042Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <path
                  id="rightArrow"
                  d="M103.5 64.134C104.167 64.5189 104.167 65.4811 103.5 65.866L97.5 69.3301C96.8333 69.715 96 69.2339 96 68.4641V61.5359C96 60.7661 96.8333 60.285 97.5 60.6699L103.5 64.134Z"
                  fill="#FEFEFE"
                />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedRightDownMovement}
                onMouseUp={props.handleMousePressedRightDownMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="rightDown"
                    className="fillable"
                    opacity="0.8"
                    d="M75.4208 82.9896L74.9853 83.2353L75.231 83.6708L89.4793 108.931L89.7308 109.377L90.1705 109.115L92.2386 107.884L92.2458 107.88L92.2528 107.875C99.8101 103.029 105.684 96.5371 109.703 89.2104L109.945 88.7695L109.503 88.5302L84.0101 74.7303L83.5786 74.4967L83.337 74.9237C81.6502 77.9044 79.2165 80.5948 76.0981 82.5862C75.9732 82.657 75.8593 82.7267 75.753 82.7918L75.7501 82.7936C75.6378 82.8623 75.5337 82.926 75.4208 82.9896Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <circle id="rightDownArrow" cx="91" cy="89" r="2" fill="#FEFEFE" />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedDownMovement}
                onMouseUp={props.handleMousePressedDownMovement}
              >
                {/* Arrow area */}
                <g opacity="0.25">
                  <path
                    id="down"
                    className="fillable"
                    d="M54.4207 83.4723L53.9827 83.2401L53.7467 83.676L39.9468 109.169L39.7078 109.61L40.1505 109.847C55.2699 117.946 74.0062 118.233 89.793 109.264L90.2264 109.017L89.9815 108.583L75.7331 83.3233L75.4845 82.8825L75.0467 83.1364C68.5074 86.9296 60.7523 86.8297 54.4207 83.4723Z"
                    fill="#EFF0F3"
                    stroke="#FEFEFE"
                  />
                </g>
                {/* Arrow */}
                <path
                  id="downArrow"
                  d="M63.1342 103.5C63.5191 104.167 64.4814 104.167 64.8663 103.5L68.3304 97.5001C68.7153 96.8335 68.2341 96.0001 67.4643 96.0001H60.5361C59.7663 96.0001 59.2852 96.8335 59.6701 97.5001L63.1342 103.5Z"
                  fill="#FEFEFE"
                />
              </a>

              {/* Center circle */}
              <a href="#" className="selectableArea" onClick={props.handleCenterClick}>
                <circle className="fillable" opacity="0.5" cx="64.5" cy="64.5" r="17.5" fill="#EFF0F3" />
              </a>

              <circle opacity="0.1" cx="64.5" cy="64.5" r="46" stroke="#EFF0F3" />
              <circle opacity="0.5" cx="64.5" cy="64.5" r="52" stroke="#EFF0F3" />
            </g>
            <defs>
              <filter
                id="filter0_d_8997_149497"
                x="0"
                y="0"
                width="137"
                height="137"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dx="4" dy="4" />
                <feGaussianBlur stdDeviation="8" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.419608 0 0 0 0 0.447059 0 0 0 0 0.501961 0 0 0 0.35 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8997_149497" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8997_149497" result="shape" />
              </filter>
            </defs>
          </svg>
        </Box>
        <Center>
          <Box
            sx={{
              svg: {
                '& .selectableArea': {
                  cursor: 'pointer',
                  '&:hover': {
                    // Filable child of nested component
                    '& * > .fillable': {
                      fill: '#03b4d9'
                    },
                    // Filable inside nested component
                    // Center circle style
                    '& > .fillable': {
                      fill: '#03b4d9'
                    }
                  },
                  '&:active': {
                    // Filable child of nested component
                    '& * > .fillable': {
                      fill: '#03b4d9'
                    },
                    '& > g': {
                      opacity: 0.5
                    },
                    // Filable direct child
                    // Center circule style
                    '& > .fillable': {
                      fill: '#03b4d9',
                      opacity: 0.7
                    }
                  }
                }
              }
            }}
          >
            <svg width="26" height="64" viewBox="0 0 26 64" fill="none" xmlns="http://www.w3.org/2000/svg">
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedZoomIn}
                onMouseUp={props.handleMousePressedZoomIn}
              >
                <circle className="fillable" opacity="0.5" cx="13" cy="13" r="13" fill="#FEFEFE" />
                <path
                  d="M13 8.125V13M13 13V17.875M13 13H17.875M13 13H8.125"
                  stroke="#3C3C3B"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </a>
              <a
                href="#"
                className="selectableArea"
                onMouseDown={props.handleMousePressedZoomOut}
                onMouseUp={props.handleMousePressedZoomOut}
              >
                <circle className="fillable" opacity="0.5" cx="13" cy="51" r="13" fill="#FEFEFE" />
                <path
                  d="M17.875 51H8.125"
                  stroke="#3C3C3B"
                  stroke-width="2.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </a>
            </svg>{' '}
          </Box>
        </Center>
      </Flex>
    </Box>
  )
}
