import { gql } from '@apollo/client'

const CREDENTIAL_FIELDS = gql`
  fragment CredentialDetails on Credential {
    id
    displayName
    fileName
    type
    dgnType
    dgn
    version
    fieldMapping
    createdAt
    createdBy
    updatedAt
    updatedBy
    deletedAt
    deletedBy
  }
`

const GET_CREDENTIAL_FIELDS = gql`
  fragment GetCredentialDetails on GetCredential {
    id
    displayName
    fileName
    type
    dgnType
    dgn
    version
    fieldMapping
    createdAt
    createdBy
    CreatedBy {
      firstName
      lastName
    }
    updatedAt
    updatedBy
    deletedAt
    deletedBy
  }
`

export const CREATE_CREDENTIAL = gql`
  ${CREDENTIAL_FIELDS}
  mutation CreateCredential($credentialInput: CredentialInput!) {
    createCredential(credentialInput: $credentialInput) {
      ...CredentialDetails
    }
  }
`

export const GET_ALL_CREDENTIALS = gql`
  ${GET_CREDENTIAL_FIELDS}
  query CredentialManagement($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    credentialsAggregate(where: $where, aggregate: { count: true }) {
      count
    }
    credentials(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
      ...GetCredentialDetails
    }
  }
`

export const GET_CREDENTIAL = gql`
  ${GET_CREDENTIAL_FIELDS}
  query getCredential($id: Int!) {
    getCredential(credentialId: $id) {
      ...GetCredentialDetails
    }
  }
`

export const UPDATE_CREDENTIAL = gql`
  ${CREDENTIAL_FIELDS}
  mutation UpdateCredential($credentialId: Int!, $credentialUpdateInput: CredentialUpdateInput!) {
    updateCredential(credentialId: $credentialId, credentialUpdateInput: $credentialUpdateInput) {
      ...CredentialDetails
    }
  }
`

export const DELETE_CREDENTIAL = gql`
  mutation DeleteCredential($credentialId: Int!) {
    deleteCredential(credentialId: $credentialId)
  }
`
