import { useApolloClient } from '@apollo/client'
import {
  Avatar,
  Box,
  Button,
  MenuItem as CMenuItem,
  Divider,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Portal,
  Skeleton,
  SkeletonCircle,
  Stack,
  StackDivider,
  useTheme
} from '@chakra-ui/react'
import { LE2UsersType } from '@le2/common-types'
import { createJFD } from '@le2/jfd'
import { Menu as BurgerMenu, LogoutOutline, UserCircleOutline } from 'heroicons-react'
import * as React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import PermissionWrapper from '../../components/PermissionWrapper'
import { routeState } from '../../hooks/useRouteState'
import agencyConfig from '../../lib/agencyConfig'
import { useEBTSToJsonHotKey } from '../../lib/useEbtsToJsonHotkey'
import auth from '../../services/auth'
import { blackLogo as tech5BlackLogo, whiteLogo as tech5WhiteLogo } from '../../utils/logoUtils'
import useAutomaticLogoutOnPageLeave from '../../utils/useAutomaticLogoutOnPageLeave'
import { systemInfoState } from '../../utils/useGetSystemInfo'
import { authUserState } from '../../utils/usersUtils'
import { MenuItem, MenuItemProps } from './MenuItem'
import SessionTimeoutDialog from './components/SessionTimeoutDialog'
import { useGetMeData } from './hooks/useGetMeData'
import { SideNavProvider, useSideNav } from './sideNavContext'

export interface UserLayoutProps {
  children?: React.ReactNode
  menuItems: ReadonlyArray<MenuItemProps>
}

interface SideNavProps {
  menuItems: ReadonlyArray<MenuItemProps>
  user: LE2UsersType
}

const viewportHeight = '100vh'
const viewportWidth = '100vw'
export const topNavHeight = '73px' // 72px for <TopNav> content + 1 px for the <Divider>
export const contentPadding = '8px'
const sideNavCollapsedWidth = '80px'
const sideNavExpandedWidth = '260px'
export const contentMinusTopNavHeight = `calc(${viewportHeight} - ${topNavHeight})`
const contentMaxWidthExpanded = `calc(${viewportWidth} - ${sideNavExpandedWidth})`
const contentMaxWidthCollapsed = `calc(${viewportWidth} - ${sideNavCollapsedWidth})`
const sideNavBoxShadow = '4px 4px 16px 0px rgba(107, 114, 128, .35)'
const translateToOrigin = 'translate(0)'
const translateOutOfPage = 'translate(-500px)'
const sideNavTransitions = 'transform 0.3s, width 0.3s'

const jfdInst = createJFD(agencyConfig.jfd)

export function UserLayout(props: UserLayoutProps) {
  const history = useHistory()
  const client = useApolloClient()
  const theme = useTheme()
  const layoutRef = React.useRef(null)
  const [sessionTimeoutEnabled, setSessionTimeoutEnabled] = React.useState(true)
  const { me } = useSnapshot(authUserState)

  const { loading } = useGetMeData()

  const { data: systemInfo, loading: loadingSystemInfo } = useSnapshot(systemInfoState)

  // register hotkey to upload and transform an EBTS file to a JSON object
  useEBTSToJsonHotKey(jfdInst)

  async function handleLogout() {
    try {
      await auth.logout()
    } catch (error) {}

    // clear Apollo cache
    client.clearStore()

    // Clean history stack
    routeState.reset()

    // redirect to login
    history.push('/login')
  }

  React.useEffect(() => {
    setSessionTimeoutEnabled(systemInfo.sessionTimeoutEnabled)
  }, [systemInfo])

  return (
    <>
      <Flex ref={layoutRef} bg="background" flexDirection="column">
        <SideNavProvider>
          <Box position="sticky" top={0} zIndex={theme.zIndices.sticky}>
            <TopNav user={me} loading={loading} handleLogout={handleLogout} />
            <Divider bg="gray.100" boxShadow="0px 1px 1px #E5E5E5" />
          </Box>
          <Flex flexDirection="row" justifyContent="flex-start">
            <SideNav menuItems={props.menuItems} user={me} />
            <Content>{props.children}</Content>
          </Flex>
        </SideNavProvider>
      </Flex>
      {sessionTimeoutEnabled && !loadingSystemInfo && (
        <SessionTimeoutDialog contentRef={layoutRef} onLoggedOff={handleLogout} />
      )}
    </>
  )
}

function TopNav({ user, loading, handleLogout }: { user: LE2UsersType; loading: boolean; handleLogout: () => void }) {
  const history = useHistory()
  const fullName = `${user?.firstName} ${user?.lastName}`
  const department = user?.department || ''
  useAutomaticLogoutOnPageLeave(handleLogout)

  const { togglePinSideNav } = useSideNav()
  const theme = useTheme()

  function editProfile() {
    history.push('/profile')
  }

  return (
    <Flex justifyContent="space-between" alignItems="center" p={4} bgColor="white">
      <HStack h={38} spacing={10}>
        <Button
          data-cy="toggleSideNav"
          variant="secondary"
          size="sm"
          border="none"
          onClick={togglePinSideNav}
          p={0}
          ml={2}
        >
          <BurgerMenu width="24px" height="24px" />
        </Button>
        <Box display={{ base: 'none', xl: 'block' }}>
          <Link to="/">
            <Image src={tech5BlackLogo} h="70px" />
          </Link>
        </Box>
      </HStack>
      <HStack spacing={4} divider={<StackDivider borderColor="gray.300" />}>
        <Menu>
          <MenuButton data-cy="userMenu">
            <HStack spacing={5}>
              <Skeleton isLoaded={!loading}>
                <Box color="text.subtitle" textStyle="subtitle" fontWeight="normal" textAlign="right">
                  {fullName}
                </Box>
                <Box color="gray.500" textStyle="details" fontWeight="normal" textAlign="right">
                  {department}
                </Box>
              </Skeleton>
              <Divider orientation="vertical" w="1px" h={10} color="gray.300" />
              <SkeletonCircle isLoaded={!loading} w={10} h={10}>
                <Avatar w={10} h={10} name={fullName} />
              </SkeletonCircle>
            </HStack>
          </MenuButton>
          <Portal>
            <Box position="absolute" top={0} zIndex={theme.zIndices.popover}>
              <MenuList>
                <CMenuItem data-cy="profile" icon={<UserCircleOutline size={20} />} onClick={editProfile}>
                  Edit Profile
                </CMenuItem>
                <CMenuItem data-cy="logout" icon={<LogoutOutline size={20} />} onClick={() => handleLogout()}>
                  Logout
                </CMenuItem>
              </MenuList>
            </Box>
          </Portal>
        </Menu>
      </HStack>
    </Flex>
  )
}

function SideNav(props: SideNavProps) {
  const theme = useTheme()
  const { isSideNavOpen, isSideNavPinned, openSideNav, closeSideNav, togglePinSideNav } = useSideNav()
  return (
    <>
      {/* gray, transparent background behind the SideNav*/}
      <Box
        width={viewportWidth}
        height={viewportHeight}
        bgColor="rgba(55,65,81,0.2)"
        position="fixed"
        top={0}
        left={0}
        zIndex={theme.zIndices.overlay + 1}
        display={{ base: isSideNavOpen ? 'block' : 'none', xl: 'none' }}
        onClick={togglePinSideNav}
      />
      {/* actual side nav */}
      <Flex
        display="flex"
        overflowY="auto"
        flexDirection="column"
        transition={sideNavTransitions}
        zIndex={theme.zIndices.modal}
        flexShrink={0}
        bgColor="primary"
        whiteSpace="nowrap"
        borderBottomRightRadius={24}
        onMouseEnter={openSideNav}
        onMouseLeave={closeSideNav}
        height={{ base: viewportHeight, xl: contentMinusTopNavHeight }}
        width={{ base: 'auto', xl: isSideNavOpen ? sideNavExpandedWidth : sideNavCollapsedWidth }}
        px={{ base: isSideNavOpen ? 4 : 0, xl: 4 }}
        top={{ base: 0, xl: topNavHeight }}
        position="fixed"
        transform={{
          base: isSideNavOpen ? translateToOrigin : translateOutOfPage,
          xl: translateToOrigin
        }}
        boxShadow={{
          base: sideNavBoxShadow,
          xl: isSideNavPinned ? 'none' : isSideNavOpen ? sideNavBoxShadow : 'none'
        }}
      >
        <Flex color="white" p={15} pl={1} alignItems="center" display={{ base: 'flex', xl: 'none' }}>
          <Button
            variant="primary"
            size="sm"
            bg="none"
            _hover={{
              bg: 'none'
            }}
            onClick={togglePinSideNav}
            p={0}
            ml={1}
          >
            <BurgerMenu width="24px" height="24px" />
          </Button>
          <Box ml={2}>
            <Link to="/" onClick={togglePinSideNav}>
              <Image src={tech5WhiteLogo} h="50px" />
            </Link>
          </Box>
        </Flex>
        <Stack mt={6} spacing={2}>
          {props.menuItems.map((item) => (
            <PermissionWrapper permission={item.permission} key={item.label}>
              <MenuItem {...item} />
            </PermissionWrapper>
          ))}
        </Stack>
      </Flex>
      {/* Spacing for the side nav, required since it has position=fixed */}
      <Box
        w={isSideNavPinned ? sideNavExpandedWidth : sideNavCollapsedWidth}
        flexShrink={0}
        visibility="hidden"
        display={{
          base: 'none',
          xl: 'block'
        }}
      />
    </>
  )
}

function Content({ children }: { children: React.ReactNode }) {
  const { isSideNavPinned, isScreenLargerThanXL } = useSideNav()

  let maxWidth: string
  if (isScreenLargerThanXL) {
    if (isSideNavPinned) {
      maxWidth = contentMaxWidthExpanded
    } else {
      maxWidth = contentMaxWidthCollapsed
    }
  } else {
    maxWidth = viewportWidth
  }

  return (
    <Box minHeight={contentMinusTopNavHeight} maxWidth={maxWidth} flex={1}>
      {children}
    </Box>
  )
}
