import { Button, ButtonProps, Flex, Icon, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

export interface QuickActionButtonProps {
  onClick: () => void
  label: string
  isDisabled?: boolean
  iconAs: any
  iconPadding?: number | string
  iconSize?: number
  color?: string
  visibility?: ButtonProps['visibility']
}

export function QuickActionButton(props: QuickActionButtonProps) {
  const theme = useTheme()
  const iconPadding = props.iconPadding || 1
  const iconSize = props.iconSize || 4
  const color = props.color || 'secondary'

  return (
    <Button
      onClick={props.onClick}
      isDisabled={props.isDisabled}
      color={color}
      backgroundColor="transparent"
      border="none"
      px={2}
      visibility={props.visibility}
      _hover={{
        backgroundColor: transparentize(color, 0.1)(theme)
      }}
      leftIcon={
        <Flex
          padding={iconPadding}
          borderRadius={4}
          backgroundColor={props.isDisabled ? 'none' : transparentize(color, 0.15)(theme)}
        >
          <Icon w={iconSize} h={iconSize} as={props.iconAs} />
        </Flex>
      }
    >
      {props.label}
    </Button>
  )
}
