import { useMediaQuery, useTheme } from '@chakra-ui/react'
import { ReactNode, createContext, useContext, useState } from 'react'

interface SideNavContextProps {
  isSideNavOpen: boolean
  isSideNavPinned: boolean
  isScreenLargerThanXL: boolean
  togglePinSideNav: () => void
  openSideNav: () => void
  closeSideNav: () => void
}

const SideNavContext = createContext<SideNavContextProps | undefined>(undefined)
SideNavContext.displayName = 'SideNavContext'

export function SideNavProvider({ children }: { children: ReactNode }) {
  const theme = useTheme()
  const [isScreenLargerThanXL] = useMediaQuery(`(min-width: ${theme.breakpoints.xl})`)

  const [isSideNavOpen, setSideNavOpen] = useState(isScreenLargerThanXL)
  const [isSideNavPinned, setSideNavPinned] = useState(isScreenLargerThanXL)

  const togglePinSideNav = () => {
    setSideNavPinned(!isSideNavPinned)
    setSideNavOpen(!isSideNavPinned)
  }

  const openSideNav = () => setSideNavOpen(isSideNavPinned || true)
  const closeSideNav = () => setSideNavOpen(isSideNavPinned || false)

  return (
    <SideNavContext.Provider
      value={{
        isSideNavOpen,
        isSideNavPinned,
        isScreenLargerThanXL,
        togglePinSideNav,
        openSideNav,
        closeSideNav
      }}
    >
      {children}
    </SideNavContext.Provider>
  )
}

export function useSideNav() {
  const context = useContext(SideNavContext)

  if (!context) {
    throw new Error('useSideNav must be used within <SideNavProvider /> component')
  }

  return context
}
