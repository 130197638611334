import { useApolloClient } from '@apollo/client'
import { LE2UsersType } from '@le2/common-types'
import { useCallback } from 'react'

import { PAGE_SIZE_GROUPS_USERS } from '../../../lib/api/constants'
import { OrderBy } from '../../../lib/useTableSort'
import { mapUser } from '../../../utils/usersUtils'
import { GET_USERS_SORTED, UsersResponse } from './useGetUsers'

const searchableColumns = ['firstName', 'lastName', 'email', 'username', 'department']

export default function useFetchUsers(): (search: string) => Promise<LE2UsersType[]> {
  const client = useApolloClient()

  const getVariables = useCallback((search: string) => {
    let variables
    const extra = {
      skip: 0,
      take: PAGE_SIZE_GROUPS_USERS,
      orderBy: [{ firstName: OrderBy.asc }]
    }

    // Extracts all the words from the search to apply a deep query.
    const queryWithAndCondition = search.split(' ').map((fragment) => ({
      OR: searchableColumns.map((item) => ({
        [item]: {
          contains: fragment,
          mode: 'insensitive'
        }
      }))
    }))

    if (queryWithAndCondition.length > 0 && search.length > 0) {
      variables = {
        where: {
          AND: queryWithAndCondition
        },
        ...extra
      }
    } else {
      variables = { ...extra }
    }
    return variables
  }, [])

  return useCallback(
    async (search: string) => {
      const result = await client.query<UsersResponse>({
        query: GET_USERS_SORTED,
        variables: getVariables(search)
      })

      const users = result.data.users || []

      return users.map((user) => mapUser(user), [])
    },
    [client, getVariables]
  )
}
