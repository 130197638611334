import { ref } from 'valtio'
import { proxyWithComputed } from 'valtio/utils'

import { DeviceManager } from './DeviceManager'
import { FPCardPrintManager } from './FPCardPrintManager'
import { HubStatus } from './IWNativeExtDeviceDefines'
import { PrintManager } from './PrintManager'

export interface NativeExtensionsState {
  deviceManager: DeviceManager
  deviceHubStatus: HubStatus
  fpCardPrintManager: FPCardPrintManager
  fpCardPrintHubStatus: HubStatus
  printManager: PrintManager
  printHubStatus: HubStatus
}

const initialState: NativeExtensionsState = {
  deviceManager: ref(new DeviceManager()),
  deviceHubStatus: HubStatus.DisConnected,
  fpCardPrintManager: ref(new FPCardPrintManager()),
  fpCardPrintHubStatus: HubStatus.DisConnected,
  printManager: ref(new PrintManager()),
  printHubStatus: HubStatus.DisConnected
}

export const nativeExtensionsState = proxyWithComputed(initialState, {
  isLoading: (snap) => {
    return (
      snap.deviceHubStatus === HubStatus.Connecting &&
      snap.fpCardPrintHubStatus === HubStatus.Connecting &&
      snap.printHubStatus === HubStatus.Connecting
    )
  }
})
