import { Badge, HStack, Icon, Image, Text } from '@chakra-ui/react'

export interface StatusControlProps {
  label: string
  variant?: string
  variantColor?: string
  iconAs?: any
  imageSrc?: string
  iconSize?: number
  fontSize?: string | number
}

const getImage = (props: StatusControlProps) => {
  const iconSize = props.iconSize || 4
  if (props.iconAs) {
    return <Icon w={iconSize} h={iconSize} as={props.iconAs} />
  } else {
    if (props.imageSrc) {
      return <Image w={iconSize} h={iconSize} src={props.imageSrc} />
    }
  }
  return null
}

/**
 *
 * @param props Properties describing the content of the status control:
 *                label is mandatory
 *                optional properties can specify whether to include an icon or image
 *                as well as image size, color scheme for the badge etc
 *
 * @returns Badge containing text and optionally icon or image
 */
export default function StatusControl(props: StatusControlProps) {
  const fontSize = props.fontSize || '0.8em'
  const variant = props.variant || 'solid'
  const variantColor = props.variantColor || 'gray'

  // see if control props were specified
  return (
    <Badge ml="0" fontSize={fontSize} variant={variant} colorScheme={variantColor}>
      <HStack spacing={1}>
        {getImage(props)}
        <Text>{props.label}</Text>
      </HStack>
    </Badge>
  )
}
