import { FormControl, FormErrorMessage, FormLabel, InputGroup, InputRightElement, Tooltip } from '@chakra-ui/react'
import { JFDField, JFDFieldDef } from '@le2/jfd'
import DayJS from 'dayjs'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import React, { ReactNode, memo, useMemo } from 'react'
import { Control, Controller, useFormContext } from 'react-hook-form'
import Select from 'react-select/async'

import Input from '../Input'
import ReactSelectControl from '../ReactSelectControl'
import { useJFD } from './jfd-context'

/**
 * Low level HTML elements
 * 1. Input - supports both string, date, number, etc
 * 2. Select - basic select and options for HTML
 */

export interface JFDFormFieldProps {
  name: string
  jfdField: JFDField
  hasLabel?: boolean

  /**
   * A node that can be appended to the right of the rendered <Input>.
   *
   * Note that this is only used in `<JFDInputField>` (i.e. JFD fields with no
   * options and type different from "date"), because:
   * - fields with options are rendered as a dropdown, so having something else
   *   in the right would overlap with the dropdown arrow.
   * - fields with type=date are rendered as `<input type="date" />`, which
   *   renders a small calendar icon on the right (at least on Chrome) so,
   *   again, the passed node would be overlapped.
   */
  appendRight?: ReactNode
}

export function JFDFormField(props: JFDFormFieldProps) {
  // Note that we need to explicitly call the used fields, in this case
  // formState.dirtyFields, see https://react-hook-form.com/api#formState
  //
  // Also note that we use formState.dirtyFields instead of formState.isDirty
  // since we want to limit the render to specific fields
  const {
    register,
    errors,
    formState: { dirtyFields },
    control
  } = useFormContext()
  const { readOnly, editable } = useJFD()

  switch (determineInputType(props.jfdField)) {
    case 'SelectField':
      return (
        <MemoizedJFDSelectField
          {...props}
          register={register}
          error={get(errors, props.name)}
          dirtyFields={dirtyFields}
          control={control}
          readOnly={readOnly}
          requiredIndicator={editable}
        />
      )
    case 'DateInputField':
      return (
        <MemoizedJFDDateField
          {...props}
          register={register}
          error={get(errors, props.name)}
          dirtyFields={dirtyFields}
          control={control}
          readOnly={readOnly}
          requiredIndicator={editable}
        />
      )
    case 'InputField':
      return (
        <MemoizedJFDInputField
          {...props}
          register={register}
          error={get(errors, props.name)}
          dirtyFields={dirtyFields}
          control={control}
          readOnly={readOnly}
          requiredIndicator={editable}
        />
      )
    default:
      return <div>Unknown Field Type</div>
  }
}

const MemoizedJFDInputField = memo(JFDInputField, isEqual)
const MemoizedJFDSelectField = memo(JFDSelectField, isEqual)
const MemoizedJFDDateField = memo(JFDInputDateField, isEqual)

function isEqual(prev: InternalJFDFormFieldProps, next: InternalJFDFormFieldProps) {
  // not 100% sure why, but if we compare prev.dirtyFields with next.dirtyFields
  // the validation errors are not immediately displayed in the browser :(
  if (get(prev.dirtyFields, prev.name)) {
    return false
  }

  if (prev.name !== next.name) {
    return false
  }

  if (prev.readOnly !== next.readOnly) {
    return false
  }

  if (prev.error !== next.error) {
    return false
  }

  // re-render only when jfdField.isDirty is true, then reset the isDirty flag
  // this flag is set to true in a ProxyHandler when something changes in the
  // field
  const result = !next.jfdField.isDirty
  next.jfdField.isDirty = false
  return result
}

interface InternalJFDFormFieldProps extends JFDFormFieldProps {
  register: Function
  error: string | undefined
  dirtyFields: Record<string, any>
  control: Control
  readOnly: boolean
  requiredIndicator: boolean
}

function JFDInputField(props: InternalJFDFormFieldProps) {
  const { type, placeholder, readOnly, disabled, value, convertToUpperCase, minLength, maxLength, regex } =
    props.jfdField
  const fieldType = type === 'string' ? 'text' : type
  const [validValue, setValidValue] = React.useState<string>(value)

  const input = (
    <Controller
      name={props.name}
      defaultValue={value || ''}
      control={props.control}
      render={({ onChange, value }) => {
        function validateInput(value: string) {
          if (value && regex && value.length >= minLength) {
            // Validates the current value with the JFD Field regex, and saves as the last valid value
            // -- if it applies the rules. Also, Changes to uppercase the value for validation purposes.
            const isValid = new RegExp(regex).test(convertToUpperCase ? value.toUpperCase() : value)
            if (isValid) {
              setValidValue(value)
            }
          } else {
            // Changes the field value if there isn't any regex rule defined, or the value doesn't have the minimum
            // -- lengh to validate
            setValidValue(value)
          }

          onChange(value)
        }

        // Sets the last valid value if the current value doesn't applies the JFD field rules when the user
        // -- leaves the input.
        function setLastValidValue() {
          if (!readOnly && !props.readOnly) {
            if (value.length < minLength) {
              onChange('')
            } else if (validValue !== value) {
              onChange(validValue)
            }
          }
        }

        return (
          <Input
            onBlur={setLastValidValue}
            value={value}
            variant="jfd"
            name={props.name}
            type={fieldType}
            isReadOnly={readOnly || props.readOnly}
            isDisabled={disabled}
            minLength={minLength}
            maxLength={maxLength > 0 ? maxLength : undefined}
            placeholder={placeholder}
            textTransform={convertToUpperCase ? 'uppercase' : undefined}
            onChange={(e) => {
              const { value } = e.currentTarget
              if (maxLength === 0 || maxLength >= value.length) {
                validateInput(value)
              }
            }}
          />
        )
      }}
    />
  )

  return (
    <JFDBaseField {...props}>
      {props.appendRight ? (
        <InputGroup>
          {input}
          <InputRightElement children={props.appendRight} />
        </InputGroup>
      ) : (
        input
      )}
    </JFDBaseField>
  )
}

function JFDInputDateField(props: InternalJFDFormFieldProps) {
  // so far string and date are the only fields I have seen in JFD
  const {
    placeholder,
    readOnly,
    value,
    convertToUpperCase,
    minDate: minDateValue,
    maxDate: maxDateValue
  } = props.jfdField

  const minDate: Date = new Date(minDateValue)
  const maxDate: Date = new Date(maxDateValue)

  const inputProps: any = {}

  if (placeholder) {
    inputProps.placeholder = placeholder
  }

  return (
    <JFDBaseField {...props}>
      <Controller
        name={props.name}
        defaultValue={value}
        control={props.control}
        render={({ onChange, value }) => {
          function handleOnChange(value: string) {
            // Extract the year from the value.
            const year = parseInt(value.split('-')[0]).toString()

            if (DayJS(value).isValid() && year.length >= 4) {
              if (maxDate.getTime() < new Date(value).getTime()) {
                onChange(DayJS(maxDate).format('YYYY-MM-DD'))
              } else if (minDate.getTime() > new Date(value).getTime()) {
                onChange(DayJS(minDate).format('YYYY-MM-DD'))
              } else {
                onChange(value)
              }
            } else {
              onChange(value)
            }
          }

          return (
            <Input
              onChange={(e) => handleOnChange(e.target.value)}
              variant="jfd"
              value={value}
              name={props.name}
              type="date"
              isReadOnly={readOnly || props.readOnly}
              {...inputProps}
              textTransform={convertToUpperCase ? 'uppercase' : undefined}
            />
          )
        }}
      ></Controller>
    </JFDBaseField>
  )
}

/**
 * How many options to display in react-select.
 */
export const MAX_OPTIONS = 300

/**
 * Debounce delay for the async Select input.
 */
const DEBOUNCE_DELAY = 200

interface OptionType {
  value: string
  label: string
}

function JFDSelectField(props: InternalJFDFormFieldProps) {
  // Here we use the async version of react-select because the regular sync one
  // causes some performance issues with fields with many options e.g. for
  // "ARS Charges" opening the select takes around 300+ ms. and there's some
  // lag when typing something
  //
  // Other alternatives like react-windowed-select also have limitations, see:
  // - https://github.com/jacobworrel/react-windowed-select/issues/39
  // - https://github.com/JedWatson/react-select/issues/2850#issuecomment-505980122

  const { options, loadOptions } = useSelectOptions(props.jfdField)
  const { readOnly, placeholder, value } = props.jfdField

  const inputProps: any = {}

  if (placeholder) {
    inputProps.placeholder = placeholder
  }

  return (
    <JFDBaseField {...props}>
      <Controller
        name={props.name}
        control={props.control}
        defaultValue={value}
        render={({ value, onChange }) => (
          <Select<OptionType>
            // react-select works with objects like {value, label} and we need
            // only the value for JFD, so we map between these in `value` and
            // `onChange`
            {...inputProps}
            inputId={props.name}
            // Set null when it is not found to clear the Select
            value={options.find((opt) => opt.value === value) || null}
            onChange={(opt) => onChange(opt?.value || '')}
            isDisabled={readOnly || props.readOnly}
            defaultOptions={options}
            loadOptions={debounce(loadOptions, DEBOUNCE_DELAY)}
            components={{ Control: ReactSelectControl }}
            isClearable
            aria-invalid={!!props.error}
            classNamePrefix={props.name}
            instanceId={props.name}
          />
        )}
      />
    </JFDBaseField>
  )
}

function useSelectOptions(jfdField: JFDField) {
  // map options from [val, label] to { value, label } for react-select
  const allOptions = useMemo(
    () => jfdField.options.map((opt) => ({ value: opt[0], label: opt[1] })),
    [jfdField.options] //
  )

  const slicedOptions = allOptions.slice(0, MAX_OPTIONS)

  // check if the field's current value is included in the result, if it's
  // not then manually append it at the end so that it can be displayed
  // in the <select>
  if (jfdField.value) {
    const found = slicedOptions.find((e) => e.value === jfdField.value)
    if (!found) {
      const selectedOption = allOptions.find((e) => e.value === jfdField.value)
      if (selectedOption) {
        slicedOptions.push(selectedOption)
      } else {
        // we didn't find the current value...
        slicedOptions.push({ value: jfdField.value, label: `[${jfdField.value}]` })
        console.warn(`Selected option doesn't exist. field=${jfdField.name} value=${jfdField.value}`)
      }
    }
  }

  // filter all options with the given user input
  function loadOptions(inputValue: string, callback: Function) {
    callback(
      allOptions
        .filter((e) => e.label.toLowerCase().includes(inputValue.toLowerCase())) //
        .slice(0, MAX_OPTIONS)
    )
  }

  return { options: slicedOptions, loadOptions }
}

interface JFDBaseFieldProps extends InternalJFDFormFieldProps {
  children: ReactNode
}

export function JFDBaseField(props: JFDBaseFieldProps) {
  let hasLabel
  if (props.hasLabel === undefined) {
    hasLabel = true
  } else {
    hasLabel = props.hasLabel
  }

  if (!props.jfdField.isApplicable()) {
    // this shouldn't happen, but just in case...
    throw new Error('Trying to render a non-applicable field.')
  }

  let isRequired: boolean
  if (props.requiredIndicator) {
    // assert boolean since we check for `applicable` above
    isRequired = props.jfdField.required as boolean
  } else {
    isRequired = false
  }

  return (
    <FormControl id={props.name} isRequired={isRequired} isInvalid={!!props.error} mb={6}>
      {hasLabel && (
        <FormLabel textStyle="details" color="gray.500" fontWeight={props.jfdField.required ? 'bold' : 'normal'}>
          {/* here we set shouldWrapChildren=false AND wrap the label inside a
            <span> so that the tooltip doesn't take focus while navigating with
            the keyboard */}
          <Tooltip label={props.jfdField.desc} openDelay={300} shouldWrapChildren={false}>
            <span>{props.jfdField.label}</span>
          </Tooltip>
        </FormLabel>
      )}
      {props.children}
      <FormErrorMessage
        position="absolute"
        display="block"
        width="100%"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
      >
        <Tooltip label={props.error} openDelay={300} shouldWrapChildren={false}>
          <span>{props.error}</span>
        </Tooltip>
      </FormErrorMessage>
    </FormControl>
  )
}

/**
 * This function determines the Input type, so the form knows what to render.
 */
function determineInputType(fieldDef: JFDFieldDef) {
  if (fieldDef.options.length) {
    return 'SelectField'
  } else if (fieldDef.type === 'date') {
    return 'DateInputField'
  } else {
    return 'InputField'
  }
}
