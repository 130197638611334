import { theme } from '@chakra-ui/react'
import merge from 'lodash/merge'

const Input = {
  baseStyle: {
    field: {
      border: '1px solid',
      borderColor: 'inherit',
      bg: 'inherit'
    }
  },
  variants: {
    jfd: (props: any) =>
      merge(theme.components.Input.variants.outline(props), {
        field: {
          _readOnly: {
            color: 'gray.400',
            userSelect: 'all',
            // by default the 'outline' variant removes the boxShadow, so here
            // we "un-remove" it so that the focus looks the same as the
            // non-read-only ones
            boxShadow: null
          }
        }
      })
  }
}

export default Input
