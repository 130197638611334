import {
  Button,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from '@chakra-ui/react'
import DayJS from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useSnapshot } from 'valtio'

import agencyConfig from '../../../lib/agencyConfig'
import Auth from '../../../services/auth'

interface SessionTimeoutDialogProps {
  onLoggedOff: () => void
  contentRef: React.MutableRefObject<HTMLDivElement | null>
}

export default function SessionTimeoutDialog({ onLoggedOff, contentRef }: SessionTimeoutDialogProps) {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [remaining, setRemaining] = useState(0)
  const { lastRefresh } = useSnapshot(Auth.state)

  const { minutesForSessionTimeout = 15 } = agencyConfig.features

  const { reset, isPrompted, getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * minutesForSessionTimeout, // The minutes for timeout in miliseconds.
    promptTimeout: 1000 * 60, // 60 seconds
    onIdle: onLoggedOff,
    onAction: activeAction,
    onActive: activeAction,
    onPrompt,
    startOnMount: true,
    eventsThrottle: 3000, // 3 seconds
    element: contentRef.current || undefined,
    crossTab: true,
    syncTimers: 1000, // The timers will be on sync every 1 second
    events: ['keydown', 'wheel', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchend']
  })

  async function activeAction() {
    const expiryTimeForRefresh = DayJS(lastRefresh).add(1, 'minute').valueOf()

    if (expiryTimeForRefresh < new Date().getTime()) {
      await Auth.refreshToken()
    }

    setRemaining(0)
    onClose()
  }

  function handleStillActive() {
    reset()
    activeAction()
  }

  async function onPrompt() {
    onOpen()
    await Auth.refreshToken()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [getRemainingTime, isPrompted])

  return (
    <Modal onClose={onClose} isOpen={isOpen} closeOnEsc={false} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Session Timeout</ModalHeader>
        <ModalBody>
          <p>Your session is being timed out due to inactivity.</p>
          <p>If you do not select to stay logged in, you will be logged off automatically.</p>
        </ModalBody>
        <Divider />
        <ModalFooter>
          <Button variant="secondary" mr={1} onClick={onLoggedOff}>
            Log off
          </Button>
          <Button w={180} onClick={handleStillActive}>
            Stay logged in ({remaining})
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
