import { useMutation } from '@apollo/client'
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Switch,
  useToast
} from '@chakra-ui/react'
import { Group, LE2UsersType } from '@le2/common-types'
import { useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

import { ADD_USER } from '../../../apollo/Users'
import Input from '../../../components/Input'
import InputCreateSelectable from '../../../components/InputCreateSelectable'
import PasswordField from '../../../components/PasswordField'
import agencyConfig from '../../../lib/agencyConfig'
import { OrderBy } from '../../../lib/useTableSort'
import { EMAIL_REGEXP, USERNAME_CRITERIA, USERNAME_REGEXP } from '../../../utils/expressions'
import { handleLE2Error } from '../../Errors'
import useGetAllDepartments from '../hooks/useGetAllDepartments'
import { useGetAllGroups } from '../hooks/useGetGroups'
import { UpdateUserType } from '../types'

interface AddUserProps {
  isOpen: boolean
  onClose: () => void
  showGroups?: boolean
  group?: Group
}

interface GroupsSelectOption {
  value: number
  label: string
}

export default function AddUser({ isOpen, onClose, showGroups = true, group }: AddUserProps) {
  const { handleSubmit, errors, register, formState, watch, setError, control } = useForm()
  const watchOIDC = watch('isOidcLogin', false)
  const history = useHistory()
  const toast = useToast()
  const { groups: allGroups, loading: allGroupsLoading } = useGetAllGroups({ name: OrderBy.asc })
  const { departments: allDepartments, loading: deparmentLoading } = useGetAllDepartments()

  const groupsOptions = useMemo(
    () =>
      allGroups.map(({ id, name }) => {
        return {
          label: name,
          value: id
        }
      }, [] as GroupsSelectOption[]),
    [allGroups]
  )

  const [createUser] = useMutation<{ createUser: LE2UsersType }, { user: UpdateUserType }>(ADD_USER, {
    update(cache, { data }) {
      const newUser = data?.createUser ?? ({} as LE2UsersType)
      if (Object.keys(newUser).length !== 0) {
        cache.modify({
          fields: {
            users(_, { DELETE }) {
              return DELETE
            },
            usersAggregate(_, { DELETE }) {
              return DELETE
            },
            groups(_, { DELETE }) {
              return DELETE
            },
            groupsAggregate(_, { DELETE }) {
              return DELETE
            }
          }
        })
      }
    },
    onCompleted() {
      toast({
        title: 'Success',
        description: 'User successfully created',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    },
    onError(error) {
      console.error(JSON.stringify(error))
      handleLE2Error(error, history, toast)
    }
  })

  function onSubmit(values: UpdateUserType) {
    // Make sure that username and password do not match.
    // Please note that with the current password and username policies matching of these values is unlikely to happen.
    // Also note that LE2UsersType has isOidcLogin declared as string; however, values.isOidcLogin is a boolean
    //   because the latter is associated with a checkbox control.
    if (
      values &&
      ('false' === values.isOidcLogin?.toString().toLowerCase() ||
        'password' === values.isOidcLogin?.toString().toLowerCase()) &&
      values.username?.toLowerCase() === values.password?.toLowerCase()
    ) {
      setError('password', { message: 'Username and password cannot match', shouldFocus: true })
      return
    }
    // If user is created from a group section
    if (group) {
      values.groups = [group.id as number]
    }
    createUser({ variables: { user: values } })
    onClose()
  }

  const hideOIDCUsers = agencyConfig.features?.oidc?.users?.hide || false

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="fit-content">
        <ModalHeader textStyle="title" pt={12} color="gray.700">
          Add User
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form id="usersForm" onSubmit={handleSubmit(onSubmit)}>
            <SimpleGrid columns={1} spacing={6}>
              <SimpleGrid columns={2} spacing={6}>
                <FormControl isInvalid={errors.firstName} w={242} isRequired>
                  <FormLabel textStyle="details" color="text.details">
                    First Name
                  </FormLabel>
                  <Input
                    name="firstName"
                    type="text"
                    ref={register({ required: true, maxLength: 32, minLength: 1 })}
                    maxLength={32}
                    minLength={1}
                  />
                  <FormErrorMessage>{errors.firstName && errors.firstName.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.lastName} w={242} isRequired>
                  <FormLabel textStyle="details" color="text.details">
                    Last Name
                  </FormLabel>
                  <Input
                    name="lastName"
                    key="lastName"
                    type="text"
                    ref={register({ required: true, maxLength: 32, minLength: 1 })}
                    maxLength={32}
                    minLength={1}
                  />
                  <FormErrorMessage>{errors.lastName && errors.lastName.message}</FormErrorMessage>
                </FormControl>
                <FormControl w={242}>
                  <FormLabel textStyle="details" color="text.details">
                    Department
                  </FormLabel>
                  <InputCreateSelectable
                    options={allDepartments}
                    name="department"
                    key="department"
                    control={control}
                    loading={deparmentLoading}
                  />
                </FormControl>
                <FormControl isInvalid={errors.email} w={242} isRequired>
                  <FormLabel textStyle="details" color="text.details">
                    Email
                  </FormLabel>
                  <Input
                    name="email"
                    type="text"
                    ref={register({
                      required: 'Field is required.',
                      pattern: { value: EMAIL_REGEXP, message: 'Please enter valid email.' }
                    })}
                    maxLength={32}
                    minLength={1}
                  />
                  <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.isAdmin} w={242} isRequired>
                  <FormLabel textStyle="details" color="text.details">
                    Is Administrator?
                  </FormLabel>
                  <Controller
                    name="isAdmin"
                    control={control}
                    defaultValue={false}
                    render={({ value, onChange }) => (
                      <Switch
                        textAlign="right"
                        id="isAdmin"
                        name="isAdmin"
                        isChecked={value}
                        onChange={(e) => {
                          onChange(e.target.checked)
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.role && errors.role.message}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
              {showGroups && (
                <Stack spacing={3}>
                  <Heading size="md">Groups</Heading>
                  <FormControl>
                    <FormLabel textStyle="details" color="text.details">
                      Groups
                    </FormLabel>
                    <Controller
                      name="groups"
                      key="groups"
                      control={control}
                      defaultValue={[]}
                      render={({ value, onChange }) => (
                        <Select
                          isMulti
                          inputId="groups"
                          value={groupsOptions.filter((opt) => value.includes(opt.value) ?? [])}
                          onChange={(opt) => {
                            let groupValue = opt.map((val) => val.value)
                            onChange(groupValue)
                          }}
                          options={groupsOptions}
                          placeholder="Add groups"
                          loading={allGroupsLoading}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    />
                    <FormErrorMessage>{errors.groups && errors.groups.message}</FormErrorMessage>
                  </FormControl>
                </Stack>
              )}
              <Stack hidden={hideOIDCUsers} spacing={3}>
                <Heading size="md" noOfLines={1}>
                  Security
                </Heading>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-alerts" mb="0">
                    Active OIDC
                  </FormLabel>
                  <input
                    hidden={hideOIDCUsers}
                    type="checkbox"
                    id="isOidcLogin"
                    name="isOidcLogin"
                    key="isOidcLogin"
                    ref={register}
                  ></input>
                </FormControl>
              </Stack>
              <SimpleGrid columns={2} spacing={6}>
                {!watchOIDC && (
                  <>
                    <FormControl isInvalid={errors.username} mb={9} w={242} isRequired>
                      <FormLabel textStyle="details" color="text.details">
                        Username
                      </FormLabel>
                      <Input
                        autoComplete="off"
                        name="username"
                        key="username"
                        type="text"
                        ref={register({
                          minLength: { value: 2, message: 'Minimum length 2.' },
                          maxLength: { value: 32, message: 'Maximum length of 32.' },
                          required: 'Field is required.',
                          pattern: { value: USERNAME_REGEXP, message: USERNAME_CRITERIA }
                        })}
                        maxLength={32}
                        minLength={2}
                      />
                      <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
                    </FormControl>
                    <FormControl isInvalid={errors.password} mb={9} w={242} isRequired>
                      <FormLabel textStyle="details" color="text.details">
                        Password
                      </FormLabel>
                      <PasswordField errors={errors} register={register} />
                    </FormControl>
                  </>
                )}
              </SimpleGrid>
            </SimpleGrid>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="secondary" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" form="usersForm" isLoading={formState.isSubmitting} type="submit">
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
