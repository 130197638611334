import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider
} from '@chakra-ui/react'
import { JFLGroup, JFLSimpleFieldDef } from '@le2/jfd'
import React from 'react'

import { JFDExplicitFieldArray } from './ExplicitFieldArray'
import { GroupHeader } from './GroupHeader'
import { JFDFieldArray } from './JFDFieldArray'
import { JFLMultiOccurrence } from './JFLMultiOccurrence'
import { JFLRender } from './JFLRender'
import { useJFD } from './jfd-context'
import { isEmptyGroup } from './utils'

/**
 * The main driver of logic. Here we are grouping our fields and determine what to render.
 * File contains following grouping controls:
 * 1. Single fields - these are first order fields in JFD and we are just creating single input after single input
 * 2. Field Arrays - these are fields that can have many instances of themselves. This follows the react hook form pattern.
 */

interface JFLGroupRenderProps {
  group: JFLGroup
  index?: number
}

export function JFLGroupRender({ group, index }: JFLGroupRenderProps) {
  const { jfd, onlyMandatory } = useJFD()

  if (isEmptyGroup(jfd, onlyMandatory, group)) {
    return null
  }

  switch (group.type) {
    case 'singleFields':
      return (
        <GroupContainer group={group}>
          <JFLRender group={group} />
        </GroupContainer>
      )

    case 'fieldArray':
      const fieldName = (group.fields[0] as JFLSimpleFieldDef).field
      const fieldDef = jfd.getFieldDef(fieldName)
      return (
        <GroupContainer group={group}>
          <JFDFieldArray group={group} fieldDef={fieldDef} />
        </GroupContainer>
      )

    case 'explicitFieldArray':
      return (
        <GroupContainer group={group}>
          <JFDExplicitFieldArray group={group} />
        </GroupContainer>
      )

    case 'explicitMultipleOccurrence':
      return (
        <GroupContainer group={group}>
          <JFLMultiOccurrence group={group} />
        </GroupContainer>
      )

    case 'explicitMultipleOccurrenceItem':
      if (index === undefined) {
        throw new Error('Missing index.')
      }
      return (
        <>
          <GroupHeader group={group} />
          <JFLRender group={group} index={index} />
        </>
      )

    default:
      throw new Error('Invalid group type: ' + group.type)
  }
}

interface GroupContainerProps {
  group: JFLGroup
  children: React.ReactNode
}

function GroupContainer({ group, children }: GroupContainerProps) {
  const containerProps = {
    mb: 2,
    padding: 4,
    bgColor: 'white',
    borderRadius: 4
  }

  const { jflGroupRefProvider } = useJFD()

  if (!group.options?.isCollapsible) {
    return (
      <Box ref={(el) => jflGroupRefProvider?.set(group, el)} {...containerProps} sx={{ ...group.options?.groupStyles }}>
        <GroupHeader group={group} />
        <Divider mt={1} mb={6} borderColor="gray.100" opacity={1} />
        {children}
      </Box>
    )
  }

  return (
    <Box ref={(el) => jflGroupRefProvider?.set(group, el)} {...containerProps} sx={{ ...group.options?.groupStyles }}>
      <Accordion
        allowToggle
        py={0}
        // small hack: pass `reduceMotion`, otherwise Chakra wraps the `AccordionPanel`
        // in a `Collapse` component which has `overflow: hidden`, which in turn causes
        // issues with dropdowns by hiding part of the dropdown menu (e.g. in Booking
        // Advanced Search)
        reduceMotion
        defaultIndex={group.options?.defaultExpanded ? 0 : undefined}
      >
        <AccordionItem border="none">
          <AccordionButton p={0}>
            <AccordionIcon />
            <GroupHeader group={group} />
          </AccordionButton>
          <Divider mt={1} mb={6} borderColor="gray.200" opacity={1} />
          <AccordionPanel p={0}>{children}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  )
}
