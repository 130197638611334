import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'

import { HubStatus } from '../IWNativeExtDeviceDefines'
import { PrintManager } from '../PrintManager'
import { nativeExtensionsState } from '../nativeextState'
import { usePrintDeviceEvents } from './usePrintEvents'

/*
 * This hook exposes the methods and variables of the usePrintDeviceEvents hook.
 * The usePrintDeviceEvents hook is not intended to be called directly. It should be used by calling
 * this hook (usePrintManager).
 */

export function usePrintDeviceManager() {
  const snap = useSnapshot(nativeExtensionsState)
  const printManager = snap.printManager as PrintManager
  const hubStatus = snap.printHubStatus
  const [isPrintManagerConnected, setPrintManagerConnected] = useState(false)

  const printEventsProps = usePrintDeviceEvents(printManager)

  useEffect(() => {
    if (hubStatus === HubStatus.Connected || hubStatus === HubStatus.Reconnected) {
      setPrintManagerConnected(true)
    } else {
      setPrintManagerConnected(false)
    }
  }, [hubStatus])

  return {
    ...printEventsProps,
    isPrintManagerConnected
  }
}
