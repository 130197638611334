import { Rect } from 'face-api.js'
import { useEffect, useRef } from 'react'

interface ImageGuidelinesPaintingCanvasProps {
  width: number
  height: number
  minWidth: number
  minHeight: number
  loading: boolean
  canvasStyle?: object
}

export function ImageGuidelinesPaintingCanvas(props: ImageGuidelinesPaintingCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  function drawGuidelines(ctx: any) {
    ctx.beginPath()
    // The guideline aspect ratio must be 4:5, they are calculated based on height
    // and aspect ratio and crop area must be align to the center
    const cropRectHeight = props.height
    let cropRectWidth = (cropRectHeight * 4) / 5
    console.debug(`drawGuidelines, initial cropRectWidth: ${cropRectWidth}, initial cropRectHeight: ${cropRectHeight}`)
    if (cropRectWidth < props.minWidth) {
      cropRectWidth = props.minWidth
    }
    if (cropRectWidth > props.width) {
      cropRectWidth = props.width
    }
    console.debug(`drawGuidelines, final cropRectWidth: ${cropRectWidth}, final cropRectHeight: ${cropRectHeight}`)
    const cropRectLeft = (props.width - cropRectWidth) / 2
    const cropRectRight = (props.width + cropRectWidth) / 2
    const cropRectTop = 0
    const cropRect = new Rect(cropRectLeft, cropRectTop, cropRectWidth, cropRectHeight)

    // Draw outside crop area rectangles with opacity
    ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'
    const outsideWidth = props.width - cropRectWidth
    const outsideWidthPerSide = outsideWidth / 2
    ctx.fillRect(0, 0, outsideWidthPerSide, cropRect.height)
    ctx.fillRect(cropRectRight, 0, outsideWidthPerSide, cropRect.height)

    // Draw eye line. The eye line should be at 55% of the image height
    ctx.strokeStyle = '#d1d5db'
    ctx.lineWidth = 2
    ctx.strokeRect(cropRectLeft, cropRectHeight * 0.45, cropRectWidth, 0)

    // Draw border face lines. The width of the head should be 50% of the image,
    // that means lines should be 25% each side
    ctx.strokeRect(cropRectLeft + cropRectWidth * 0.25, 0, 0, cropRectHeight)
    ctx.strokeRect(cropRectRight - cropRectWidth * 0.25, 0, 0, cropRectHeight)

    // Draw crop area
    ctx.strokeStyle = '#3e90a6'
    ctx.lineWidth = 3
    ctx.strokeRect(cropRect.x, cropRect.y, cropRect.width, cropRect.height)
  }

  useEffect(() => {
    if (canvasRef == null || canvasRef.current == null) {
      return
    }

    if (props.loading) {
      return
    }

    const canvas = canvasRef.current
    const context = canvas.getContext('2d')

    if (!context) {
      console.error(`cannot generate a context`)
      return
    }

    drawGuidelines(context)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.width, props.height])

  return <canvas width={props.width} height={props.height} ref={canvasRef} style={{ ...props.canvasStyle }} />
}
