import { BioType } from '../../../lib/api/booking'
import { activeCaptureAnnotationCode } from '../../../utils/captureAnnotations'

export enum Hand {
  Right = 'right',
  Left = 'left'
}

export enum Finger {
  Thumb = 'thumb',
  Index = 'index',
  Middle = 'middle',
  Ring = 'ring',
  Little = 'little'
}

export type ScanStep = FingerprintDefinitionType | BioType | BioType[]
export type ScanOrder = Array<ScanStep>

export const fourSlapFingers = [Finger.Index, Finger.Middle, Finger.Ring, Finger.Little]
export const expectedFingersRightFourSlap = [
  BioType.RightIndexFlat,
  BioType.RightMiddleFlat,
  BioType.RightRingFlat,
  BioType.RightLittleFlat
]
export const expectedFingersLeftFourSlap = [
  BioType.LeftIndexFlat,
  BioType.LeftMiddleFlat,
  BioType.LeftRingFlat,
  BioType.LeftLittleFlat
]

export enum FingerprintScanType {
  Roll = 'roll',
  Flat = 'flat',
  Slap = 'slap'
}

export type FingerprintDefinitionType = { key: BioType; hand: Hand; type: FingerprintScanType; scan: Finger | Finger[] }

export type FingerprintDefinitionsType = {
  [key: string]: FingerprintDefinitionType
}

export function isFingerprintDefinition(value: ScanStep): value is FingerprintDefinitionType {
  return typeof value === 'object' && 'key' in value && 'hand' in value && 'type' in value && 'scan' in value
}

export const FingerprintDefinitions: FingerprintDefinitionsType = {
  [BioType.RightThumbRoll]: {
    key: BioType.RightThumbRoll,
    hand: Hand.Right,
    type: FingerprintScanType.Roll,
    scan: Finger.Thumb
  },
  [BioType.RightIndexRoll]: {
    key: BioType.RightIndexRoll,
    hand: Hand.Right,
    type: FingerprintScanType.Roll,
    scan: Finger.Index
  },
  [BioType.RightMiddleRoll]: {
    key: BioType.RightMiddleRoll,
    hand: Hand.Right,
    type: FingerprintScanType.Roll,
    scan: Finger.Middle
  },
  [BioType.RightRingRoll]: {
    key: BioType.RightRingRoll,
    hand: Hand.Right,
    type: FingerprintScanType.Roll,
    scan: Finger.Ring
  },
  [BioType.RightLittleRoll]: {
    key: BioType.RightLittleRoll,
    hand: Hand.Right,
    type: FingerprintScanType.Roll,
    scan: Finger.Little
  },
  [BioType.LeftThumbRoll]: {
    key: BioType.LeftThumbRoll,
    hand: Hand.Left,
    type: FingerprintScanType.Roll,
    scan: Finger.Thumb
  },
  [BioType.LeftIndexRoll]: {
    key: BioType.LeftIndexRoll,
    hand: Hand.Left,
    type: FingerprintScanType.Roll,
    scan: Finger.Index
  },
  [BioType.LeftMiddleRoll]: {
    key: BioType.LeftMiddleRoll,
    hand: Hand.Left,
    type: FingerprintScanType.Roll,
    scan: Finger.Middle
  },
  [BioType.LeftRingRoll]: {
    key: BioType.LeftRingRoll,
    hand: Hand.Left,
    type: FingerprintScanType.Roll,
    scan: Finger.Ring
  },
  [BioType.LeftLittleRoll]: {
    key: BioType.LeftLittleRoll,
    hand: Hand.Left,
    type: FingerprintScanType.Roll,
    scan: Finger.Little
  },

  [BioType.RightThumbFlat]: {
    key: BioType.RightThumbFlat,
    hand: Hand.Right,
    type: FingerprintScanType.Flat,
    scan: Finger.Thumb
  },
  [BioType.LeftThumbFlat]: {
    key: BioType.LeftThumbFlat,
    hand: Hand.Left,
    type: FingerprintScanType.Flat,
    scan: Finger.Thumb
  },

  [BioType.RightFourSlap]: {
    key: BioType.RightFourSlap,
    hand: Hand.Right,
    type: FingerprintScanType.Slap,
    scan: fourSlapFingers
  },
  [BioType.LeftFourSlap]: {
    key: BioType.LeftFourSlap,
    hand: Hand.Left,
    type: FingerprintScanType.Slap,
    scan: fourSlapFingers
  },
  [BioType.FBICard]: {
    key: BioType.FBICard,
    hand: Hand.Right,
    type: FingerprintScanType.Slap,
    scan: fourSlapFingers
  }
}

export const fingerprintCoordinates = {
  [Finger.Thumb]: { x: -1, y: 45, width: 16, height: 16 },
  [Finger.Index]: { x: 23, y: 5, width: 16, height: 16 },
  [Finger.Middle]: { x: 44, y: -1, width: 16, height: 16 },
  [Finger.Ring]: { x: 59, y: 8, width: 16, height: 16 },
  [Finger.Little]: { x: 71, y: 29, width: 16, height: 16 }
}

export const fingerprintModalCoordinates = {
  [Finger.Thumb]: { x: 98, y: 82, width: 24, height: 24 },
  [Finger.Index]: { x: 143, y: 8, width: 24, height: 24 },
  [Finger.Middle]: { x: 181, y: -1, width: 24, height: 24 },
  [Finger.Ring]: { x: 208, y: 15, width: 24, height: 24 },
  [Finger.Little]: { x: 230, y: 52, width: 24, height: 24 },
  slap: { x: 133, y: -4, width: 130, height: 90 }
}

export const fingerprintFirstRowOrder: FingerprintDefinitionType[] = [
  FingerprintDefinitions[BioType.LeftFourSlap],
  FingerprintDefinitions[BioType.LeftThumbFlat],
  FingerprintDefinitions[BioType.RightThumbFlat],
  FingerprintDefinitions[BioType.RightFourSlap]
]

export const fingerprintSecondRowOrder: FingerprintDefinitionType[] = [
  FingerprintDefinitions[BioType.LeftThumbRoll],
  FingerprintDefinitions[BioType.LeftIndexRoll],
  FingerprintDefinitions[BioType.LeftMiddleRoll],
  FingerprintDefinitions[BioType.LeftRingRoll],
  FingerprintDefinitions[BioType.LeftLittleRoll],
  FingerprintDefinitions[BioType.RightThumbRoll],
  FingerprintDefinitions[BioType.RightIndexRoll],
  FingerprintDefinitions[BioType.RightMiddleRoll],
  FingerprintDefinitions[BioType.RightRingRoll],
  FingerprintDefinitions[BioType.RightLittleRoll]
]

export const fingerprintLabel: { [key: string]: string } = {
  [BioType.RightThumbRoll]: 'Right Thumb Roll',
  [BioType.RightIndexRoll]: 'Right Index Roll',
  [BioType.RightMiddleRoll]: 'Right Middle Roll',
  [BioType.RightRingRoll]: 'Right Ring Roll',
  [BioType.RightLittleRoll]: 'Right Little Roll',
  [BioType.LeftThumbRoll]: 'Left Thumb Roll',
  [BioType.LeftIndexRoll]: 'Left Index Roll',
  [BioType.LeftMiddleRoll]: 'Left Middle Roll',
  [BioType.LeftRingRoll]: 'Left Ring Roll',
  [BioType.LeftLittleRoll]: 'Left Little Roll',

  [BioType.RightThumbFlat]: 'Right Thumb Slap',
  [BioType.LeftThumbFlat]: 'Left Thumb Slap',

  [BioType.RightFourSlap]: 'Right Four Slap',
  [BioType.LeftFourSlap]: 'Left Four Slap',

  [BioType.FBICard]: 'FBI Fingerprint Card'
}

export const fingerPositionNumber = {
  [`${Hand.Right}_${Finger.Thumb}`]: '01',
  [`${Hand.Right}_${Finger.Index}`]: '02',
  [`${Hand.Right}_${Finger.Middle}`]: '03',
  [`${Hand.Right}_${Finger.Ring}`]: '04',
  [`${Hand.Right}_${Finger.Little}`]: '05',
  [`${Hand.Left}_${Finger.Thumb}`]: '06',
  [`${Hand.Left}_${Finger.Index}`]: '07',
  [`${Hand.Left}_${Finger.Middle}`]: '08',
  [`${Hand.Left}_${Finger.Ring}`]: '09',
  [`${Hand.Left}_${Finger.Little}`]: '10'
}

export const fingerPosition = {
  [`${Hand.Left}_${Finger.Thumb}`]: 'LeftThumb',
  [`${Hand.Left}_${Finger.Index}`]: 'LeftIndex',
  [`${Hand.Left}_${Finger.Middle}`]: 'LeftMiddle',
  [`${Hand.Left}_${Finger.Ring}`]: 'LeftRing',
  [`${Hand.Left}_${Finger.Little}`]: 'LeftLittle',
  [`${Hand.Right}_${Finger.Thumb}`]: 'RightThumb',
  [`${Hand.Right}_${Finger.Index}`]: 'RightIndex',
  [`${Hand.Right}_${Finger.Middle}`]: 'RightMiddle',
  [`${Hand.Right}_${Finger.Ring}`]: 'RightRing',
  [`${Hand.Right}_${Finger.Little}`]: 'RightLittle'
}

export const slapPosition = {
  [`${Hand.Left}_${FingerprintScanType.Slap}`]: 'LeftFourSlap',
  [`${Hand.Right}_${FingerprintScanType.Slap}`]: 'RightFourSlap'
}

export const scanFingersInitialState: Record<string, string> = {
  [`${Hand.Left}_${Finger.Thumb}`]: activeCaptureAnnotationCode,
  [`${Hand.Left}_${Finger.Index}`]: activeCaptureAnnotationCode,
  [`${Hand.Left}_${Finger.Middle}`]: activeCaptureAnnotationCode,
  [`${Hand.Left}_${Finger.Ring}`]: activeCaptureAnnotationCode,
  [`${Hand.Left}_${Finger.Little}`]: activeCaptureAnnotationCode,
  [`${Hand.Right}_${Finger.Thumb}`]: activeCaptureAnnotationCode,
  [`${Hand.Right}_${Finger.Index}`]: activeCaptureAnnotationCode,
  [`${Hand.Right}_${Finger.Middle}`]: activeCaptureAnnotationCode,
  [`${Hand.Right}_${Finger.Ring}`]: activeCaptureAnnotationCode,
  [`${Hand.Right}_${Finger.Little}`]: activeCaptureAnnotationCode
}

// Typical livescan uses default fingerprint device/plugin; captures one biotype per step
export const scanFingerprintOrder = [
  FingerprintDefinitions[BioType.RightFourSlap],
  FingerprintDefinitions[BioType.LeftFourSlap],

  FingerprintDefinitions[BioType.RightThumbFlat],
  FingerprintDefinitions[BioType.LeftThumbFlat],

  FingerprintDefinitions[BioType.RightThumbRoll],
  FingerprintDefinitions[BioType.RightIndexRoll],
  FingerprintDefinitions[BioType.RightMiddleRoll],
  FingerprintDefinitions[BioType.RightRingRoll],
  FingerprintDefinitions[BioType.RightLittleRoll],

  FingerprintDefinitions[BioType.LeftThumbRoll],
  FingerprintDefinitions[BioType.LeftIndexRoll],
  FingerprintDefinitions[BioType.LeftMiddleRoll],
  FingerprintDefinitions[BioType.LeftRingRoll],
  FingerprintDefinitions[BioType.LeftLittleRoll]
]

// Alternate workflow for fingerprint card scanning; captures up to 14 biotypes in a single step
export const scanFingerprintCard = [FingerprintDefinitions[BioType.FBICard]]
