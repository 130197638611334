import { extendTheme } from '@chakra-ui/react'

import Button from './Button'
import Checkbox from './Checkbox'
import FormError from './FormError'
import Input from './Input'
import Progress from './Progress'
import Radio from './Radio'
import Select from './Select'
import Table from './Table'

export default extendTheme({
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'Roboto, sans-serif'
  },
  textStyles: {
    heading: {
      fontSize: '30px',
      fontWeight: 'bold',
      lineHeight: '150%'
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      lineHeight: '150%'
    },
    subtitle: {
      fontSize: '20px',
      fontWeight: 'bold',
      lineHeight: '150%'
    },
    paragraph: {
      fontSize: '16px',
      lineHeight: '150%'
    },
    emphasis: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '150%'
    },
    details: {
      fontSize: '12px',
      fontWeight: 'normal',
      lineHeight: '150%'
    }
  },
  colors: {
    primary: '#2A2E35',
    secondary: '#03B4D9',
    secondaryLight: '#d8f3f9',
    bgLogin: '#F0F2F8',
    background: '#E5E5E5',
    black: '#000000',
    white: '#ffffff',
    blueWhale: '#003148',
    text: {
      heading: '#2A2E35', // gray.700
      title: '#2A2E35', // gray.700
      subtitle: '#2A2E35', // gray.700
      paragraph: '#6B7280', // gray.500
      emphasis: '#2A2E35', // gray.700
      details: '#9CA3AF' // gray.400
    },
    gray: {
      700: '#2A2E35',
      500: '#6B7280',
      400: '#9CA3AF',
      300: '#D1D5DB',
      200: '#E5E7EB',
      100: '#F7F8FC'
    },
    red: {
      500: '#F42C24'
    },
    green: {
      600: '#3F91A6',
      200: '#E6F7F2'
    },
    orange: {
      500: '#FF570D'
    },
    yellow: {
      500: '#D9A319'
    }
  },
  components: {
    Button,
    Checkbox,
    Progress,
    Input,
    Select,
    FormError,
    Table,
    Radio
  }
})
