import {
  Avatar,
  Box,
  Flex,
  HStack,
  Heading,
  Spacer,
  Tab,
  TabList,
  Tabs,
  Tooltip,
  VStack,
  useTheme
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import * as React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

import { contentPadding, topNavHeight } from '../user'

// header height + padding
// includes the TopHeader height + TabsNav
// defined like this so that it can be used like `calc(${headerSize} + 10px)`
export const headerHeight = '129px'
const topHeaderHeight = '67px'

// topSpace includes everything above the content, including the blank space
// note: 2px to compensate the padding-top in BookingSection
export const topSpace = `${topNavHeight} + ${headerHeight} + ${contentPadding} + 2px`

export interface ResourceLayoutProps extends HeaderProps {
  /**
   * Can be a <BookingContentsContainer> to keep the sticky submenu and shadow.
   */
  children?: React.ReactNode

  menuItems: ReadonlyArray<MenuItemProps>

  menuItemsAppend?: React.ReactNode

  footer?: React.ReactNode
}

export interface MenuItemProps {
  label: string
  to?: string
  statusIcon?: React.ReactNode
  isDisabled?: boolean
  disabledMessage?: string
}

interface SideNavProps {
  menuItems: ReadonlyArray<MenuItemProps>
  append?: React.ReactNode
}

interface HeaderProps {
  avatar?: string
  details: React.ReactNode
  header?: string
  actions: React.ReactNode
  status?: React.ReactNode
}

export function ResourceLayout(props: ResourceLayoutProps) {
  const theme = useTheme()

  return (
    <>
      <Flex direction="column" px={contentPadding} pb={contentPadding} height="100%">
        <Box
          position="sticky"
          top={topNavHeight}
          zIndex={theme.zIndices.sticky}
          height={headerHeight}
          pt={contentPadding}
          bgColor="background"
        >
          <Box bgColor="white" borderRadius={4} px={4} py={2}>
            <TopHeader {...props} />
            <TabsNav menuItems={props.menuItems} append={props.menuItemsAppend} />
          </Box>
        </Box>
        {props.children}
        {props.footer && (
          <Box position="fixed" left={0} bottom={0} right={0} bgColor="white">
            {props.footer}
          </Box>
        )}
      </Flex>
    </>
  )
}

function TopHeader(props: HeaderProps) {
  return (
    <HStack spacing={4} pb={2} height={topHeaderHeight} borderBottom="1px solid" borderColor="gray.200">
      {props.avatar && <Avatar src={props.avatar} w="58px" h="58px" borderRadius={4} />}
      <VStack spacing={1} align="flex-start">
        <Heading color="text.heading" textStyle="subtitle">
          <HStack spacing={4}>
            <Box>{props.header}</Box>
            <Box>{props.status}</Box>
          </HStack>
        </Heading>
        {props.details}
      </VStack>
      <Spacer />
      <HStack alignSelf="flex-end">{props.actions}</HStack>
    </HStack>
  )
}

function TabsNav(props: SideNavProps) {
  // used mark the current route as selected
  const location = useLocation()
  const defaultIndex = props.menuItems.findIndex((e) => e.to === location.pathname)

  return (
    <Tabs defaultIndex={defaultIndex}>
      <TabList pt={2} border="none">
        {props.menuItems.map((item) => (
          <Tooltip key={item.label} shouldWrapChildren label={item.disabledMessage} isDisabled={!item.isDisabled}>
            <Tab
              isDisabled={item.isDisabled}
              data-cy={item.label.replace(/ /g, '')}
              {...getExtraProps(item)}
              px={4}
              py={1}
              border="none"
              borderRadius={8}
              color="gray.700"
              fontWeight={500}
              _activeLink={{
                color: 'secondary',
                // @ts-ignore
                background: transparentize('secondary', 0.05)
              }}
              _active={{
                color: 'secondary'
              }}
            >
              <HStack spacing={1}>
                {item.statusIcon}
                <Box>{item.label}</Box>
              </HStack>
            </Tab>
          </Tooltip>
        ))}
        {props.append && (
          <>
            <Spacer />
            {props.append}
          </>
        )}
      </TabList>
    </Tabs>
  )
}

function getExtraProps(item: MenuItemProps) {
  if (item.to) {
    return {
      as: NavLink,
      to: item.to
    }
  } else {
    return {}
  }
}
