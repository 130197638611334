import { HubConnectionState } from '@microsoft/signalr'

import { DGNType } from '../pages/cards/types'

export enum ManagerEvents {
  // hub events
  hubIsConnecting = 'hubIsConnecting',
  hubConnected = 'hubConnected',
  hubDisConnected = 'hubDisconnected',
  hubIsDisConnecting = 'hubIsDisconnecting',
  hubIsReconnecting = 'hubIsReconnecting',
  hubReconnected = 'hubReconnected',
  hubClosed = 'hubClosed', // connection permanently closed

  // device events
  GetPluginsList = 'GetPluginsList',
  GetDevicesList = 'GetDevicesList',
  OpenDevice = 'OpenDevice',
  CloseDevice = 'CloseDevice',
  DeviceInserted = 'DeviceInserted',
  DeviceRemoved = 'DeviceRemoved',
  Capture = 'Capture',
  CancelCapture = 'CancelCapture',
  StartCaptureStream = 'StartCaptureStream',
  StopCaptureStream = 'StopCaptureStream',
  PauseCaptureStream = 'PauseCaptureStream',
  ResumeCaptureStream = 'ResumeCaptureStream',
  StreamImagesAsync = 'StreamImagesAsync',
  Process = 'Process',
  DeviceEvent = 'DeviceEvent',
  StreamCaptureEvents = 'StreamCaptureEvents',
  GetDeviceOptions = 'GetDeviceOptions',
  SetDeviceOptions = 'SetDeviceOptions',
  GetConfigurationOptions = 'GetConfigurationOptions',
  SetConfigurationOptions = 'SetConfigurationOptions',

  // fp cardprint events
  Print = 'Print',
  AvailablePrinters = 'AvailablePrinters',

  // print events
  PrinterStatus = 'GetPrinterStatus',
  PrintPreview = 'PrintPreview',
  Printers = 'GetPrinters',
  FieldNames = 'GetFieldNames',
  PageSetup = 'PageSetup'
}

export enum PluginIDDefinitions {
  LOAD_FROM_FILE_PLUGIN_ID = '90CC6E8A-7A62-4FF7-B19A-7A93F0B62673'
}

export enum StatusEvents {
  deviceMgrConnected = 0,
  deviceMgrDisConnected = 1,
  deviceMgrReConnecting = 2,
  deviceMgrReConnected = 3,
  deviceMgrConnectionClosed = 4,
  deviceMgrOperationStatus = 5,
  deviceMgrDeviceEvent = 6,
  deviceMgrCaptureEvent = 7
}

export enum ModalityDefinitions {
  Biographic = 0,
  Face = 1,
  Fingerprint = 2,
  Palm = 3,
  Iris = 4,
  Signature = 5,
  Document = 6,
  Voice = 7,
  SMT = 8,
  PalmVein = 9,
  FullBody = 10
}

export function getModalityDescription(id: ModalityDefinitions) {
  return ModalityDefinitions[id] || 'Unknown'
}

export enum CaptureProfiles {
  ImpressionTypeFingerFlat = 0,
  ImpressionTypeFingerRolled = 1,
  PositionTypeFingerSingle = 2,
  PositionTypeFingerFourSlap = 3,
  PositionTypeFingerTwoSlap = 4,
  PositionTypeFingerTwoThumbSlap = 5,
  PositionTypePalmFull = 6,
  PositionTypePalmLower = 7,
  PositionTypePalmUpper = 8,
  PositionTypePalmHypotenar = 9
}

export function getCaptureProfileDescription(id: CaptureProfiles) {
  return CaptureProfiles[id] || 'Unknown'
}

export enum QualityMethod {
  NFIQ = 'NFIQ',
  IWS = 'IWS',
  Vendor = 'Vendor'
}

export enum ApplicationMode {
  Criminal = 'Criminal',
  Civil = 'Civil',
  Applicant = 'Applicant',
  Identify = 'Identify'
}

export enum QualityMapImageFingerRectangles {
  None = 'None',
  Rotated = 'Rotated',
  Plain = 'Plain'
}

export enum CompressedImages {
  YES = 'true',
  NO = 'false'
}

export enum SearchOption {
  Modality = 'Modality',
  ModalityProfile = 'ModalityProfile'
}

export enum ImageFormat {
  PNG = 'png',
  JPEG = 'jpeg',
  BMP = 'bmp'
}

export enum CaptureRequestOption {
  CaptureImageType = 'CaptureImageType', // e.g. list of fingers by image position type (aka FingerImageType), face position, etc
  MissingDigits = 'MissingDigits', // e.g. missing finger, missing eye, missing palm, etc
  ImageFormat = 'ImageFormat',
  ImageResolution = 'ImageResolution',
  ImageHeight = 'ImageHeight', // pixels
  ImageWidth = 'ImageWidth', // pixels
  ImageDepth = 'ImageDepth', // bpp
  ImagePixelFormat = 'ImagePixelFormat', // corresponds to the formats defined in the C# graphics libraries
  MinAttempts = 'MinAttempts',
  MaxAttempts = 'MaxAttempts',
  DoQualityChecking = 'DoQualityChecking',
  QualityMethod = 'QualityMethod', // e.g. NFIQ, IWS, or Vendor specific
  QualityThresholdMinQuality = 'QualityThresholdMinQuality',
  QualityThresholdMinSeqMatch = 'QualityThresholdMinSeqMatch',
  QualityThresholdMaxDupMatch = 'QualityThresholdMaxDupMatch',
  AutoAdvance = 'AutoAdvance',
  Timeout = 'Timeout',
  File = 'File',
  FileName = 'FileName',
  ReturnSequencedImages = 'ReturnSequencedImages',
  ReturnCompressedImages = 'ReturnCompressedImages',
  ReturnQualityMapImage = 'ReturnQualityMapImage', // true (default) / false
  QualityMapImageFingerRectangles = 'QualityMapImageFingerRectangles', // None (default), Rotated, Plain
  CompressionRate = 'CompressionRate',
  ApplicationMode = 'ApplicationMode' // e.g. Criminal, Civil, etc; see enum ApplicationMode
}

export enum CaptureResultOption {
  CaptureImageType = 'CaptureImageType', // e.g. list of fingers by image position type (aka FingerImageType), face position, etc
  ImageFormat = 'ImageFormat',
  ImageResolution = 'ImageResolution',
  ImageHorizontalResolution = 'ImageHorizontalResolution', // dpi/ppi
  ImageVerticalResolution = 'ImageVerticalResolution', // dpi/ppi
  ImageHeight = 'ImageHeight',
  ImageWidth = 'ImageWidth',
  ImageSize = 'ImageSize', // bytes
  ImageDepth = 'ImageDepth', // bpp
  ImagePixelFormat = 'ImagePixelFormat', // corresponds to the formats defined in the C# graphics libraries
  ScanCaptureQuality = 'ScanCaptureQuality', // vendor quality score
  ScanCaptureQualityDescription = 'ScanCaptureQualityDescription', // vendor quality desription
  ScanCaptureQualityScore = 'ScanCaptureQualityScore', // vendor quality score
  SequencerQualityMethod = 'SequencerQualityMethod', // NFIQ/NBIS, IWS, or Vendor
  SequencerQuality = 'SequencerQuality',
  SequencerCheckScore = 'SequencerCheckScore',
  SequencerDuplicateCheckScore = 'SequencerDuplicateCheckScore',
  SequencerResultCode = 'SequencerResultCode',
  SequencerResultCodeDescription = 'SequencerResultCodeDescription',
  MinutiaeCount = 'MinutiaeCount',
  Pattern = 'Pattern',
  PatternConfidence = 'PatternConfidence',
  QualityMapImage = 'QualityMapImage',
  DetectedFingerTypes = 'DetectedFingerTypes',
  DuplicateImageTypes = 'DuplicateImageTypes',
  CompressedImage = 'CompressedImage'
}

export enum ProcessAction {
  CompressImage = 'CompressImage',
  CreateTemplate = 'CreateTemplate'
  // Other process methods TBD
}

export enum PluginCapabilitiesDefinitions {
  MultipleDevices = 0x00000001,
  SingleCapture = 0x00010000,
  EnumerableStream = 0x00020000,
  AsyncEnumerableStream = 0x00040000,
  MultipleCapture = 0x00080000,
  LivePreview = 0x00100000,
  AuditPreview = 0x00200000,
  AuditImage = 0x00400000,
  CaptureUI = 0x01000000,
  LoadFromFile = 0x02000000,
  FingerprintCard = 0x04000000
}

export enum DeviceCapabilitiesDefinitions {
  SingleCapture = 0x00010000,
  EnumerableStream = 0x00020000,
  AsyncEnumerableStream = 0x00040000,
  MultipleCapture = 0x00080000,
  LivePreview = 0x00100000,
  AuditPreview = 0x00200000,
  AuditImage = 0x00400000,
  CaptureUI = 0x01000000,
  LoadFromFile = 0x02000000
}

export enum Finger {
  Invalid = -1,
  RightThumb = 0,
  RightIndex = 1,
  RightMiddle = 2,
  RightRing = 3,
  RightLittle = 4,
  LeftThumb = 5,
  LeftIndex = 6,
  LeftMiddle = 7,
  LeftRing = 8,
  LeftLittle = 9
}

export enum FingerImageType {
  RightThumbRoll = 0,
  RightIndexRoll = 1,
  RightMiddleRoll = 2,
  RightRingRoll = 3,
  RightLittleRoll = 4,
  LeftThumbRoll = 5,
  LeftIndexRoll = 6,
  LeftMiddleRoll = 7,
  LeftRingRoll = 8,
  LeftLittleRoll = 9,
  RightThumbFlat = 10,
  LeftThumbFlat = 11,
  RightFourSlap = 12,
  LeftFourSlap = 13,
  RightIndexFlat = 14,
  RightMiddleFlat = 15,
  RightRingFlat = 16,
  RightLittleFlat = 17,
  LeftIndexFlat = 18,
  LeftMiddleFlat = 19,
  LeftRingFlat = 20,
  LeftLittleFlat = 21,
  RightFullPalm = 22,
  LeftFullPalm = 23,
  RightHypothenar = 24,
  LeftHypothenar = 25,
  RightLowerPalm = 26,
  LeftLowerPalm = 27,
  RightUpperPalm = 28,
  LeftUpperPalm = 29,
  TwoThumbSlap = 30
}

export const PalmDefinitions = {
  [FingerImageType.RightFullPalm]: 22,
  [FingerImageType.LeftFullPalm]: 23,
  [FingerImageType.RightHypothenar]: 24,
  [FingerImageType.LeftHypothenar]: 25,
  [FingerImageType.RightLowerPalm]: 26,
  [FingerImageType.LeftLowerPalm]: 27,
  [FingerImageType.RightUpperPalm]: 28,
  [FingerImageType.LeftUpperPalm]: 29
}

export enum FingerMissing {
  Unknown = 0,
  Present = 1,
  PresentNotRolled = 2,
  Missing = 3
}

export enum FingerPattern {
  Unknown = 0,
  Arch = 1,
  LeftLoop = 2,
  RightLoop = 3,
  Scar = 4,
  TentedArch = 5,
  Whorl = 6
}

export enum SequencerReturnCode {
  Success = 0,
  General = 1,
  NotImplemented = 2,
  NullPointer = 3,
  InvalidFingerType = 4,
  InvalidFinger = 5,
  InvalidRowsVal = 6,
  InvalidColsVal = 7,
  InvalidImage = 8,
  NoEncodingData = 9,
  NoImageData = 10,
  OutOfMemmory = 11,
  FileRead = 12,
  ImageConvert = 13,
  ImageLowQuality = 14,
  FingerImagesNoMatch = 15,
  FingerImagesDuplicate = 16,
  FingerFailedToEnroll = 17
}

export enum DeviceError {
  // let's repurpose some of the Windows native error codes; it's possible that we might need to wrap unmanaged devices eventually anyway
  ERROR_SUCCESS = 0,
  ERROR_INVALID_FUNCTION = 1,
  ERROR_FILE_NOT_FOUND = 2,
  ERROR_PATH_NOT_FOUND = 3,
  ERROR_TOO_MANY_OPEN_FILES = 4,
  ERROR_ACCESS_DENIED = 5,
  ERROR_INVALID_HANDLE = 6,
  ERROR_BAD_ENVIRONMENT = 10,
  ERROR_BAD_FORMAT = 11,
  ERROR_INVALID_ACCESS = 12,
  ERROR_INVALID_DATA = 13,
  ERROR_OUTOFMEMORY = 14,
  ERROR_BAD_UNIT = 20,
  ERROR_NOT_READY = 21,
  ERROR_WRITE_FAULT = 29,
  ERROR_READ_FAULT = 30,
  ERROR_GEN_FAILURE = 31,
  ERROR_NOT_SUPPORTED = 50,
  ERROR_DEV_NOT_EXIST = 55,
  ERROR_CALL_NOT_IMPLEMENTED = 120,
  ERROR_INSUFFICIENT_BUFFER = 122,
  ERROR_INVALID_NAME = 123,
  ERROR_MOD_NOT_FOUND = 126,
  ERROR_PROC_NOT_FOUND = 127,
  ERROR_WAIT_NO_CHILDREN = 128,
  ERROR_BAD_ARGUMENTS = 160,
  ERROR_NO_DATA = 232,
  ERROR_NO_MORE_ITEMS = 259,
  ERROR_OPERATION_IN_PROGRESS = 329,
  ERROR_UNHANDLED_EXCEPTION = 574,
  ERROR_NOINTERFACE = 632,
  ERROR_PROCESS_ABORTED = 1067,
  ERROR_DEVICE_NOT_CONNECTED = 1167,
  ERROR_NOT_FOUND = 1168,
  ERROR_CANCELLED = 1223,
  ERROR_NOT_AUTHENTICATED = 1244,
  ERROR_UNIDENTIFIED_ERROR = 1287,
  ERROR_LOGON_FAILURE = 1326,
  ERROR_TIMEOUT = 1460,
  ERROR_UNKNOWN_PROPERTY = 1608,
  ERROR_INVALID_HANDLE_STATE = 1609,
  ERROR_FUNCTION_NOT_CALLED = 1626,
  ERROR_FUNCTION_FAILED = 1627,
  ERROR_UNSUPPORTED_TYPE = 1630,
  ERROR_INVALID_DATATYPE = 1804,
  ERROR_EMPTY = 4306,
  ERROR_NOT_EMPTY = 4307,
  ERROR_OBJECT_NOT_FOUND = 4312,
  ERROR_INVALID_OPERATION = 4317,
  ERROR_DEVICE_NOT_AVAILABLE = 4319,
  ERROR_INVALID_STATE = 5023
}

export enum Diagnostic {
  // common definitions for diagnostic codes that might be received during scan
  // for now, based off of the GreenBit device
  // will add more as we add devices
  DIAGNOSTIC_CODE_SCANNER_SURFACE_PROBLEM = 0x00000001,
  DIAGNOSTIC_CODE_SCANNER_FAILURE = 0x00000002,
  DIAGNOSTIC_CODE_COMPOSITION_SLOW = 0x00000004,
  DIAGNOSTIC_CODE_FLAT_FINGER_SLIDING = 0x00000008,
  DIAGNOSTIC_CODE_EXT_LIGHT_TOO_STRONG = 0x00000010,
  DIAGNOSTIC_CODE_FLAT_FINGER_OUT_OF_REGION_LEFT = 0x00000020,
  DIAGNOSTIC_CODE_FLAT_FINGER_OUT_OF_REGION_RIGHT = 0x00000040,
  DIAGNOSTIC_CODE_FLAT_FINGER_OUT_OF_REGION_TOP = 0x00000080,
  DIAGNOSTIC_CODE_FLAT_FINGER_DISPLACED_DOWN = 0x00000100,
  DIAGNOSTIC_CODE_FAKE_FINGER_DETECTED = 0x00000200,
  DIAGNOSTIC_CODE_IMPROPER_ROLL = 0x00000400,
  DIAGNOSTIC_CODE_ROLL_TOO_FAST = 0x00000800,
  DIAGNOSTIC_CODE_ROLL_TOO_NARROW = 0x00001000,
  DIAGNOSTIC_CODE_ROLL_OUTSIDE_BORDER_LEFT = 0x00002000,
  DIAGNOSTIC_CODE_ROLL_OUTSIDE_BORDER_RIGHT = 0x00004000,
  DIAGNOSTIC_CODE_ROLL_OUTSIDE_BORDER_TOP = 0x00008000,
  DIAGNOSTIC_CODE_ROLL_DISPLACED_DOWN = 0x00010000,
  DIAGNOSTIC_CODE_ROLL_DIRECTION_RIGHT = 0x00020000,
  DIAGNOSTIC_CODE_ROLL_DIRECTION_LEFT = 0x00040000,
  DIAGNOSTIC_CODE_ROLL_DIRECTION_DOWN = 0x00080000,
  DIAGNOSTIC_CODE_ROLL_DIRECTION_UP = 0x00100000,
  DIAGNOSTIC_CODE_TOO_SHORT_VERTICAL_ROLL = 0x00200000,
  DIAGNOSTIC_CODE_DRY_FINGER = 0x01000000,
  DIAGNOSTIC_CODE_WET_FINGER = 0x02000000,

  DIAGNOSTIC_CODE_UNDEFINED = 0xffffffff
}

export enum DeviceEventCodes {
  // hardware related events
  DeviceConnected = 1, // device has been plugged in
  DeviceDisconnected = 2, // device has been removed
  DeviceAcquired = 3, // device has been exclusively acquired
  DeviceReleased = 4, // device has been released
  DeviceStatus = 5, // device has generated some status event
  DeviceException = 6, // device exception occurred
  // capture related events
  CaptureSessionStarted = 7, // scan session has started; this session could consist of preview portion and actual acquisition portion
  CaptureSessionClosed = 8, // scan session has ended
  CapturePreviewStarted = 9, // preview portion of the capture has started
  CapturePreviewEnded = 10, // preview portion of the capture has started
  CapturePreviewImageReceived = 11, // preview portion of the capture has acquired an image frame
  CapturePreviewException = 12, // preview portion of the capture has generated some an exception
  CaptureAcquisitionStarted = 13, // main portion of the capture has started
  CaptureAcquisitionEnded = 14, // main portion of the capture has started
  CaptureAcquisitionImageReceived = 15, // main portion of the capture has acquired an image frame
  CaptureAcquisitionException = 16, // main portion of the capture has generated some an exception
  CaptureDiagnosticMessageReceived = 17, // preview portion of the capture has generated some diagnostic event
  CaptureFakeImageDetected = 18, // fake finger, face, etc detection
  SequenceCheckStarted = 19, // started sequence processing of the image
  SequenceCheckCompleted = 20, // image sequencing completed
  // Other device events TBD
  CaptureSessionCancelled = 21, // scan session has been cancelled
  CaptureSessionTimedOut = 22, // scan session has timed out
  FootPedalShortPress = 23, // foot pedal event: short press
  FootPedalLongPress = 24 // foot pedal event: long press
}

export enum DeviceOptions {
  LED = 1, // LED indicators, on/off
  ScanArea, // Scan area
  VisualFeedback, // Visual feedback on the device, on/off
  AudioFeedback, // Audio feedback (sound) on the device, on/off
  Pedal, // pedal, or maybe other such HW attachment that can trigger capture, on/off
  AutoCapture, // auto-capture, on/off
  Preview, // capture preview, on/off
  CameraBrightness, // camera brightness
  CameraContrast, // camera contrast
  CameratHue, // camera hue
  CameraSaturation, // saturation
  CameraAdjustBrightnessRelative, // adjust camera brightness by a relative value
  CameraAdjustBrightnessAbsolute, // adjust camera brightness to an absolute value
  CameraAdjustContrastRelative, // adjust camera contrast by a relative value
  CameraAdjustContrastAbsolute, // adjust camera contrast to an absolute value
  CameraAdjustHueRelative, // adjust camera hue by a relative value
  CameraAdjustHueAbsolute, // adjust camera hue to an absolute value
  CameraAdjustSaturationRelative, // adjust camera saturation by a relative value
  CameraAdjustSaturationAbsolute, // adjust camera saturation to an absolute value
  CameraFloodlights, // camera floodlights, on/off
  CameraFocusIn, // adjust camera focus-in by a relative value
  CameraFocusOut, // adjust camera focus-out  by a relative value
  CameraFocusAuto, // set camera focus to auto
  CameraFocusAbsolute, // set camera focus to absolute position
  CameraFocusManual, // set camera focus to manual
  CameraFocusStop, // stop camera focus
  CameraPanTiltLeft, // adjust camera pan-left by a relative value
  CameraPanTiltRight, // adjust camera pan-right by a relative value
  CameraPanTiltUp, // adjust camera tilt-down by a relative value
  CameraPanTiltDown, // adjust camera tilt-up by a relative value
  CameraPanTiltUpLeft, // adjust camera pan- up-left by a relative value
  CameraPanTiltUpRight, // adjust camera pan- up-right by a relative value
  CameraPanTiltDownLeft, // adjust camera tilt- down-left by a relative value
  CameraPanTiltDownRight, // adjust camera tilt- down-right by a relative value
  CameraPanTiltAbsolute, // set camera pan-tilt to an absolute value
  CameraPanTiltRelative, // adjust camera pan-tilt
  CameraPanTiltStop, // stop camera pan-tilt
  CameraPanTiltHome, // set camera pan-tilt home
  CameraPanTiltReset, // reset camera pan-tilt
  CameraUseFlash, // camera flash, on/off
  CameraZoomIn, // adjust camera zoom-in by a relative value
  CameraZoomOut, // adjust camera zoom-out by a relative value
  CameraZoomAbsolute, // adjust camera zoom to an absolute value
  CameraZoomStop, // stop camera zoom-in/out
  CameraPowerOn, // power camera on
  CameraPowerOff, // power camera off
  CameraClear, // clear camera buffers
  CameraCommandCancel, // cancel any ongoing operations
  CameraSettingReset, // reset factory setting
  CameraVersionInquiry, // get the camera version
  CameraPowerInquiry, // get the camera power state
  CameraPanTiltPositionInquiry, // get the camera pan/tilt position
  CameraZoomPositionInquiry, // get the camera zoom position
  CameraFocusPositionInquiry, // get the camera focus position
  CameraFocusModeInquiry, // get the camera focus mode
  CameraVideoFormatInquiry, // get the camera video formats supported
  CameraPositionHome, // move the camera to the preset home position
  CameraMemoryReset, // reset a given memory number
  CameraMemorySet, // store the current camera position to a given memory number
  CameraMemoryRecall // set the camera position to what is stored in the given memory number
  // Other device options affecting capture TBD
}

// Camera command options (these can be specified as a dictionary of name-value pairs).
// Each command implemented in the NativeExts plugin knows what to look for.
// For the speed options (Speed/PanSpeed/TiltSpeed), expects a value as a percentage in the range 0-lowest to 100-highest
export enum CameraCommandOption {
  Speed, // camera operation speed
  PanSpeed, // pan speed (some commands require both pan and tilt speed)
  TiltSpeed, // tilt speed (some commands require both pan and tilt speed)
  PanPosition, // zoom position; can be relative (0x01 (low) to 0x18(high)) or absolute
  TiltPosition, // focus position; can be relative (0x01 (low) to 0x14(high)) or absolute
  StepCount, // 0 - continuous, start the command and let it run until the stop command is sent;
  // > 0 - number of times to perform, each step lasts for the amount of time specified in StepTime
  // (default is 1)
  StepTime, // miliseconds to run each step. Considered only if Step > 0; defaults to 500 msec if not specified but Step > 0
  ZoomPosition, // zoom position; can be relative (0x0 (low) to 0xF(high)) or absolute
  FocusPosition, // focus position; can be relative (0x0 (low) to 0xF(high)) or absolute
  Position // position; could be relative or absolute depending on the command
}

// Counterpart to the NativeExt Server IWResult class
// Generally, the Device Manager interfaces return a result object consisting of:
//      ErrorCode - numerical error code, and ErrorDescription- textual description of the error
export class IWResult {
  ErrorCode: DeviceError
  ErrorDescription: string

  constructor(deviceError: DeviceError, errorDesc: string) {
    this.ErrorCode = deviceError
    this.ErrorDescription = errorDesc

    // bind the methods to this object
    this.IsSuccessful = this.IsSuccessful.bind(this)
    this.IsAborted = this.IsAborted.bind(this)
    this.IsCancelled = this.IsCancelled.bind(this)
  }

  IsSuccessful() {
    return DeviceError.ERROR_SUCCESS === this.ErrorCode
  }

  IsAborted() {
    return DeviceError.ERROR_PROCESS_ABORTED === this.ErrorCode
  }

  IsCancelled() {
    return DeviceError.ERROR_CANCELLED === this.ErrorCode
  }
}

export enum ScanStatus {
  Idle = 'Idle',
  Scanning = 'Scanning',
  Rescanning = 'Rescanning',
  Cancelling = 'Cancelling',
  Done = 'Done',
  Error = 'Error'
}

export enum ScanResultStatus {
  Default = 'Default',
  NoQualityScore = '',
  GoodQuality = 'Good Quality',
  AcceptableQuality = 'Acceptable Quality',
  PoorQuality = 'Poor Quality',
  SequenceCheckError = 'Sequence Check Error',
  ScanError = 'Scanning Error',
  DeviceError = 'Device Error'
}

export enum HubStatus {
  Connecting = 'Connecting',
  Connected = 'Connected',
  Reconnected = 'Reconnected',
  DisConnecting = 'Disconnecting',
  DisConnected = 'Disconnected',
  Closed = 'Closed'
}

export const sequencerResultCodeMessages: { [x: number]: string } = {
  0: 'Capture Successful',
  1: 'Sequencer error: General',
  2: 'Sequencer error: Not Implemented',
  3: 'Sequencer error: Null Pointer',
  4: 'Sequencer error: Invalid Finger Type',
  5: 'Sequencer error: Invalid Finger',
  6: 'Sequencer error: Invalid Rows Value',
  7: 'Sequencer error: Invalid Cols Value',
  8: 'Sequencer error: Invalid Image',
  9: 'Sequencer error: No Encoding Data',
  10: 'Sequencer error: No Image Data',
  11: 'Sequencer error: Out Of Memmory',
  12: 'Sequencer error: File Read',
  13: 'Sequencer error: Image Convert',
  14: 'Quality too low',
  15: 'Fingers do not match',
  16: 'Duplicate fingers detected',
  17: 'Did not detect fingerprint',
  18: 'Upper and lower palm impressions do not match',
  19: 'Upper palm and four-slap do not match',
  20: 'Hypothenar does not match corresponding palm',
  21: 'Sequencer error: Invalid upper palm',
  22: 'Sequencer error: Invalid four slap',
  23: 'Incorrect hand presented',
  24: 'Too few fingers detected',
  25: 'Too many fingers detected'
}

export interface IWResultServer {
  ErrorCode: DeviceError
  ErrorDescription: string
}

export interface DeviceEventArgs {
  PluginID: string
  DeviceInstance: number
  EventCode: number
  EventMetaData: { [key: string]: string }
  EventTime: string
  ImageList: IWImage[]
  StatusCode: number
  StatusMessage: string
}

export interface PluginInfo {
  Version: number //PluginDefs
  PluginID: string
  Name: string
  DisplayName: string
  Description: string
  Modalities: number[] // Modalities
  PluginCapabilities: number[]
  InstancePath: string
  InstanceConfigPath: string
}

export interface DeviceInfo {
  Version: number
  DeviceID: string
  DeviceInstance: number
  PluginID: string
  Manufacturer: string
  Model: string
  SerialNo: string
  Description: string
  ToolTip: string
  Location: string
  HardwareID: string
  Modalities: number[] // Modalities
  Capabilities: number[] // Capabilities
  CaptureProfiles: CaptureProfiles[] // CaptureProfile
}

export interface IWImage {
  imageData: string // base64 byte[]
  metaData: { [key: string]: string }
}

export interface IWData {
  data: string // base64 byte[]
  metaData: { [key: string]: string }
}

export enum ScanElementState {
  Active = 'active',
  Amputation = 'XX',
  UnableToPrint = 'UP',
  Missing = 'MA',
  UnableToCapture = 'UC'
}

// Defined customEvent details to maintain a consistency with react
export type EmptyEventDetail = null

export interface DeviceEventDetail {
  deviceEventArgs: DeviceEventArgs
}

export interface HubIsConnectingEventDetail {
  hubUrl: string
}

export interface HubIsDisConnectingEventDetail {
  hubUrl: string
}

export interface HubConnectedEventDetail {
  hubUrl: string
}

export interface HubDisConnectedEventDetail {
  disconnectedReason: HubConnectionState | Error | undefined
  hubUrl: string
}

export interface HubIsReconnectingEventDetail {
  reconnectingStatus: Error | undefined
  hubUrl: string
}

export interface HubReconnectedEventDetail {
  connectionId: string | undefined
  hubUrl: string
}

export interface HubClosedEventDetail {
  error: Error | undefined
  hubUrl: string
}

export interface GetPluginsListEventDetail {
  result: IWResult
  list: PluginInfo[] | null
}

export interface GetDevicesListEventDetail {
  pluginID: string
  result: IWResult
  list: DeviceInfo[] | null
}

export interface OpenDeviceEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface CloseDeviceEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface CaptureEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
  list: IWImage[]
}

export interface CancelCaptureEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface StartCaptureStreamEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface StopCaptureStreamEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface PauseCaptureStreamEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface ResumeCaptureStreamEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface StreamImagesAsyncEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
  item?: IWImage | null
}

export interface StreamCaptureEventsEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}

export interface ProcessEventDetail {
  pluginID: string
  deviceInstance: number
  processAction: string
  result: IWResult
  list: IWData[] | null
}

export interface GetDeviceOptionsEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
  list: Map<string, string> | null // TODO: If not working, then maybe try using { [key: string]: string }
}

export interface SetDeviceOptionsEventDetail {
  pluginID: string
  deviceInstance: number
  result: IWResult
}
export interface GetConfigurationOptionsEventDetail {
  result: IWResult
  list: Map<string, string> | null // TODO: If not working, then maybe try using { [key: string]: string }
}

export interface SetConfigurationOptionsEventDetail {
  result: IWResult
}

export interface PrintEventDetail {
  list: IWImage[] | null
  result: IWResult
}

export interface DgnPrintEventDetail {
  list: IWImage[][] | null
  result: IWResult
}

export interface AvailablePrintersEventDetail {
  list: string[] | null
  result: IWResult
}

export interface PrinterStatus {
  Description: string
}

export interface PrinterInfo {
  Name: string
  FullName: string
  Status: PrinterStatus[]
  PrintCapabilities: { [key: string]: string }
}

export interface PrintersEventDetail {
  list: string[] | null
  result: IWResult
}

export interface PrinterStatus {
  code: StatusCode
  description: string
}

export enum StatusCode {
  PRINTER_STATUS_READY = 0x00000000,
  PRINTER_STATUS_PAUSED = 0x00000001,
  PRINTER_STATUS_ERROR = 0x00000002,
  PRINTER_STATUS_PENDING_DELETION = 0x00000004,
  PRINTER_STATUS_PAPER_JAM = 0x00000008,
  PRINTER_STATUS_PAPER_OUT = 0x00000010,
  PRINTER_STATUS_MANUAL_FEED = 0x00000020,
  PRINTER_STATUS_PAPER_PROBLEM = 0x00000040,
  PRINTER_STATUS_OFFLINE = 0x00000080,
  PRINTER_STATUS_IO_ACTIVE = 0x00000100,
  PRINTER_STATUS_BUSY = 0x00000200,
  PRINTER_STATUS_PRINTING = 0x00000400,
  PRINTER_STATUS_OUTPUT_BIN_FULL = 0x00000800,
  PRINTER_STATUS_NOT_AVAILABLE = 0x00001000,
  PRINTER_STATUS_WAITING = 0x00002000,
  PRINTER_STATUS_PROCESSING = 0x00004000,
  PRINTER_STATUS_INITIALIZING = 0x00008000,
  PRINTER_STATUS_WARMING_UP = 0x00010000,
  PRINTER_STATUS_TONER_LOW = 0x00020000,
  PRINTER_STATUS_NO_TONER = 0x00040000,
  PRINTER_STATUS_PAGE_PUNT = 0x00080000,
  PRINTER_STATUS_USER_INTERVENTION = 0x00100000,
  PRINTER_STATUS_OUT_OF_MEMORY = 0x00200000,
  PRINTER_STATUS_DOOR_OPEN = 0x00400000,
  PRINTER_STATUS_SERVER_UNKNOWN = 0x00800000,
  PRINTER_STATUS_POWER_SAVE = 0x01000000,
  PRINTER_STATUS_SERVER_OFFLINE = 0x02000000,
  PRINTER_STATUS_DRIVER_UPDATE_NEEDED = 0x04000000
}

export function getPrinterStatusDescription(code: StatusCode): string {
  try {
    const type = code.constructor as { name: string }
    const name = StatusCode[code]
    if (name) {
      const field = type.name + '_' + name
      const attr = (field as any)['description']
      return attr || '<unknown>'
    }
  } catch (ex) {
    console.error(ex)
  }
  return '<unknown>'
}

export interface PrinterStatusEventDetail {
  list: string[] | null
  result: IWResult
}

export interface PrintFormatInfo {
  version: number
  dgnFileName: string
  dgnDisplayName: string
  dgnData: string
  dgnType: DGNType
  options?: { [key: string]: string }
}

export interface FieldNamesEventDetail {
  list: string[] | null
  result: IWResult
}
