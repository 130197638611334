const Progress = {
  baseStyle: {
    track: {
      bg: 'gray.300',
      borderRadius: '8px'
    },
    filledTrack: {
      bg: 'secondary'
    }
  }
}

export default Progress
