import { useQuery } from '@apollo/client'
import { useToast } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { GET_ALL_CREDENTIALS } from '../../../apollo/Credentials'
import { OrderBy, OrderByDict } from '../../../lib/useTableSort'
import { CardsType } from '../types'

/**
 * Credentials data, as returned by the API with the GET_ALL_CREDENTIALS query.
 */
export interface CredentialsResponse {
  credentials: CardsType[]
  credentialsAggregate: { count: number }
}

interface GetCredentialsResult {
  loading: boolean

  error: boolean

  credentials: CardsType[]

  count: number

  pages: number

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: string

  /**
   * Contains the status as string. Instantly updated. To be passed as value to a select.
   */
  status: string

  /**
   * A function to pass to an input's `onChange` prop.
   */
  handleSearch: (e?: ChangeEvent<HTMLInputElement>) => void

  /**
   * A function to pass to an select's `onChange` prop.
   */
  handleStatusFilter: (e: ChangeEvent<HTMLSelectElement>) => void
  /**
   * @description Function to refresh the data
   * @returns void
   */
  refresh: () => void
}

interface SearchParams {
  searchString?: string
  // role?: string
  isActive?: boolean
}

interface SearchVars {
  /**
   * Contains the search string. Updates are debounced to 500ms. To be sent to the server.
   */
  debouncedSearch: string

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: SearchParams

  /**
   * A function to pass to `onChange`
   */
  handleSearch: (e?: ChangeEvent<HTMLInputElement>) => void
  /**
   * A function to pass to `onChange`
   */
  handleStatusFilter: (e: ChangeEvent<HTMLSelectElement>) => void
}

function getOrderBy(orderBy: OrderByDict) {
  const entries = Object.entries(orderBy).filter(([, sortOrder]) => sortOrder !== OrderBy.none)

  if (entries.length > 0) {
    return [Object.fromEntries(entries)]
  } else {
    return [{ createdAt: 'desc' }]
  }
}

function getDebounced(setSearch: (val: string) => void) {
  return debounce((value: string) => {
    setSearch(value)
  }, 500)
}

function useSearchVars(): SearchVars {
  // here we use two `search` variables:
  // - one that is immediately updated that can be used as an input's value
  // - one that is updated each 500ms (debounced) that can be used to make
  //   API calls

  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [search, setSearch] = useState<SearchParams>({})
  const debouncedSetSearch = useMemo(() => getDebounced(setDebouncedSearch), [])

  const handleSearch = useCallback(
    (e?: ChangeEvent<HTMLInputElement>) => {
      // update the search string immediately
      setSearch({ ...search, searchString: e?.target.value })
      // update the debounced search after 500ms
      debouncedSetSearch(e?.target.value || '')
    },
    [search, debouncedSetSearch]
  )

  const handleStatusFilter = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      // update the role filter immediately
      setSearch({
        ...search,
        isActive: e.target.value === '' ? undefined : e.target.value === 'enabled' ? true : false
      })
    },
    [search]
  )
  return { debouncedSearch, search, handleSearch, handleStatusFilter }
}

export default function useGetCardCredentials(
  currentPage: number,
  PAGE_SIZE: number,
  orderBy: OrderByDict,
  options?: { useErrorAlert?: boolean }
): GetCredentialsResult {
  const searchableColumns = ['displayName']
  const { debouncedSearch, search, handleSearch, handleStatusFilter } = useSearchVars()
  const toast = useToast()
  const extra = {
    skip: PAGE_SIZE * (currentPage - 1),
    take: PAGE_SIZE,
    orderBy: getOrderBy(orderBy),
    pageSize: PAGE_SIZE
  }
  const queryWithAndCondition = []

  const contains = !!debouncedSearch ? debouncedSearch : undefined
  if (contains) {
    queryWithAndCondition.push({
      OR: searchableColumns.map((item) => ({
        [item]: {
          contains,
          mode: 'insensitive'
        }
      }))
    })
  }

  let status = ''
  const { isActive } = search
  if (typeof isActive === 'boolean') {
    queryWithAndCondition.push({ isActive })
    status = search.isActive ? 'enabled' : 'disabled'
  }

  let variables
  if (queryWithAndCondition.length > 0) {
    variables = {
      where: {
        AND: queryWithAndCondition
      },
      ...extra
    }
  } else {
    variables = { ...extra }
  }

  const queryResult = useQuery<CredentialsResponse>(GET_ALL_CREDENTIALS, { variables })
  const pages = Math.ceil((queryResult.data?.credentialsAggregate?.count || 0) / PAGE_SIZE)
  const credentials = useMemo(() => {
    return queryResult.data?.credentials || []
  }, [queryResult.data])

  // Refresh function
  const refresh = useCallback(() => {
    queryResult.refetch()
  }, [queryResult])

  useEffect(() => {
    if (queryResult.error && options?.useErrorAlert) {
      toast({
        title: 'Error',
        description:
          'There was a problem retrieving the credentials. Please contact your system administrator if the error persists.',
        status: 'error',
        duration: 9000,
        position: 'top-right',
        isClosable: true
      })
    }
  }, [queryResult.error, toast, options?.useErrorAlert])

  return {
    loading: queryResult.loading,
    error: !!queryResult.error,
    credentials,
    count: queryResult.data?.credentialsAggregate?.count || 0,
    pages,
    search: search.searchString || '',
    status,
    handleSearch,
    handleStatusFilter,
    refresh
  }
}
