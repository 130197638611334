import { Box, Button, Center, Flex, Icon, Image, Stack, Text, useColorModeValue } from '@chakra-ui/react'
import { EyeOffOutline, PhotographOutline } from 'heroicons-react'
import { DragEvent, useRef, useState } from 'react'

type CardFileUploadProps = {
  operation: 'edit' | 'upload' | 'preview'
  images?: string[]
  handleFileUpload: (file: File | null) => void
  isPrintManagerConnected?: boolean
  hasPrintFormatInfo?: boolean
}

type UploadCardProps = {
  handleFileUpload: (file: File | null) => void
  hasnativeEextensionMessage?: boolean
}

type PreviewEditCardProps = {
  images?: string[]
}

export default function CardFileUpload({
  handleFileUpload,
  operation,
  images,
  isPrintManagerConnected,
  hasPrintFormatInfo
}: CardFileUploadProps) {
  return (
    <Flex align={'center'} justify={'center'} py={3}>
      {operation === 'upload' && !images?.length ? (
        <UploadCard
          handleFileUpload={handleFileUpload}
          hasnativeEextensionMessage={!isPrintManagerConnected && hasPrintFormatInfo}
        />
      ) : (
        <PreviewEditCard images={images ?? []} />
      )}
    </Flex>
  )
}

function UploadCard({ handleFileUpload, hasnativeEextensionMessage }: UploadCardProps) {
  const [dragActive, setDragActive] = useState(false)
  const fileRef = useRef(null)

  function handleDrop(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    const droppedFile = e.dataTransfer.files[0]
    if (droppedFile) {
      handleFileUpload(droppedFile)
    }
  }

  function handleDrag(e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.preventDefault()
    handleFileUpload(e.target.files && e.target.files[0])
  }

  return (
    <Stack
      minH={'295px'}
      width={'full'}
      bg={useColorModeValue(dragActive ? '#808080' : '#3C3C3B', dragActive ? '#808080' : 'gray.700')}
      rounded={'xl'}
      p={10}
      spacing={2}
      align={'center'}
      justify={'center'}
      onDragEnter={handleDrag}
      onDragLeave={handleDrag}
      onDragOver={handleDrag}
      onDrop={handleDrop}
      position="relative"
    >
      {dragActive && (
        <Stack
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          border="2px dashed #FEFEFE"
          borderRadius="md"
          display="flex"
          height="95%"
          width="98%"
          margin="auto"
          pointerEvents={'none'}
        ></Stack>
      )}

      <Icon color="#FEFEFE" w={21} h={21} as={hasnativeEextensionMessage ? EyeOffOutline : PhotographOutline} />
      <Stack align={'center'} spacing={2}>
        <Text
          fontSize={'sm'}
          fontWeight={400}
          color={'white'}
          maxWidth={hasnativeEextensionMessage ? '55%' : 'auto'}
          textAlign="center"
        >
          {hasnativeEextensionMessage
            ? 'Native Extensions are required to preview the card design.'
            : 'Upload or drag and drop your file here.'}
        </Text>
      </Stack>

      {!hasnativeEextensionMessage && (
        <Stack py={4} direction={{ base: 'column', md: 'row' }}>
          <Button
            as="label"
            htmlFor="file-input"
            rounded={'md'}
            variant="outline"
            color={'#A3C1DB'}
            borderColor={'#A3C1DB'}
            flex={'1 0 auto'}
            _hover={{ bg: 'none' }}
            _focus={{ bg: 'none' }}
            cursor="pointer"
          >
            Select File{' '}
          </Button>
          <input
            id="file-input"
            type="file"
            accept=".dgn"
            style={{ display: 'none' }}
            ref={fileRef}
            onChange={handleFileChange}
          />
        </Stack>
      )}
    </Stack>
  )
}

function PreviewEditCard({ images }: PreviewEditCardProps) {
  return (
    <Center>
      {images?.map((image, idx) => (
        <Box mx={2} key={`key_img_${idx}`}>
          <Image rounded={'sm'} height="auto" width="auto" maxHeight="300px" objectFit={'cover'} src={image} alt="#" />
        </Box>
      ))}
    </Center>
  )
}
