import { useApolloClient } from '@apollo/client'

import BioType from './BioType'
import { BookingsResponse, GET_BOOKINGS, getAPIQueryParams, mapBookingResponse } from './utils'

export default function useFetchBookings(bioTypes?: BioType[], isSealed?: boolean) {
  const client = useApolloClient()

  return async (search: string, moreFilters?: {}) => {
    const result = await client.query<BookingsResponse>({
      query: GET_BOOKINGS,
      variables: getAPIQueryParams({
        type: 'simple',
        search,
        bioTypes,
        isSealed,
        moreFilters: moreFilters || {}
      })
    })
    return result.data.bookings.map(mapBookingResponse)
  }
}
