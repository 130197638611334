import { Icon } from '@chakra-ui/react'
import { CheckCircleOutline, ExclamationCircleOutline, RefreshOutline } from 'heroicons-react'

import { Status } from '../pages/booking/interfaces'

export default function StatusIcon({ status }: { status: Status }) {
  return status === Status.Loading ? (
    <Icon w={18} h={18} as={RefreshOutline} />
  ) : status === Status.Error ? (
    <Icon w={18} h={18} color="red.500" as={ExclamationCircleOutline} />
  ) : status === Status.Complete ? (
    <Icon w={18} h={18} color="green.500" as={CheckCircleOutline} />
  ) : null
}
