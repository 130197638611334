import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { BioType } from '../../lib/api/booking'
import { FileExtension } from '../../lib/fileUtils'
// TODO: Refactor Fingerprint constants
import { ScanOrder } from '../../pages/booking/fingerprint/constants'
import { DeviceManager } from '../DeviceManager'
import { HubStatus, IWImage, ScanElementState, ScanResultStatus, ScanStatus } from '../IWNativeExtDeviceDefines'
import { ModalityConfiguration } from '../configuration'
import { nativeExtensionsState } from '../nativeextState'
import { useDeviceEvents } from './useDeviceEvents'
import { ScanSteps } from './useScanStepsGenerator'

interface GetDeviceManagerProps {
  startScan?: () => void
  recaptureScan?: () => void
  abortScanDirty?: () => void
  abortScanClean?: () => void
  finishScan?: () => void
  removeAllScans?: () => void
  startDevice?: () => void
  stopDevice?: () => void
  sendDeviceCommand?: () => void
}

export interface DeviceManagerProps {
  startScan: (scanOrder: ScanOrder) => void
  recaptureScan: (scanOrder: ScanOrder) => void
  abortScan: () => void
  finishScan: () => void
  removeAllScans: () => void
  scan: () => void
  cancelScan: () => void
  acceptScan: () => void
  startDevice: () => void
  stopDevice: () => void
  sendDeviceCommand: (commandOptions: Map<string, string>) => void
  getElementStatus: (element: BioType) => string
  pluginCapabilities: number[]
  deviceCapabilities: number[]
  isDeviceOpen: boolean
  isRecapture: boolean
  scanSteps: ScanSteps
  globalScanStatus: ScanStatus
  inputFileRef: React.RefObject<HTMLInputElement>
  acceptedFileExtensions: FileExtension[]
  scanFramesCanvasRef: React.RefObject<HTMLCanvasElement>
  scanFramesCanvasRefs: React.RefObject<HTMLCanvasElement>[]
  isLoadFromFile: boolean
  isMultipleCaptureAvailable: boolean
  scanStatus: ScanStatus
  scanProgress: number
  messageStatus: string
  scanResultStatus: ScanResultStatus
  scannedImageList: IWImage[]
}

/*
 * This hook exposes the methods and variables of the useDeviceEvents hook.
 * The useDeviceEvents hook is not intended to be called directly. It should be used by calling
 * this hook (useDeviceManager).
 */

export function useDeviceManager(
  modalityConfiguration: ModalityConfiguration,
  scanOrder: ScanOrder,
  scanElements: { [x: string]: ScanElementState | string },
  finishScanCallback?: () => void
) {
  const snap = useSnapshot(nativeExtensionsState)
  const deviceManager = snap.deviceManager as DeviceManager
  const hubStatus = snap.deviceHubStatus
  const { searchOptions } = modalityConfiguration

  const deviceEventsProps = useDeviceEvents(
    deviceManager,
    modalityConfiguration,
    scanOrder,
    scanElements,
    finishScanCallback
  )

  useEffect(() => {
    if (hubStatus === HubStatus.Connected || hubStatus === HubStatus.Reconnected) {
      deviceManager?.GetPluginsList(searchOptions)
    }
  }, [deviceManager, hubStatus, searchOptions])

  function getDeviceManagerProps(callbacks?: GetDeviceManagerProps) {
    if (!callbacks) {
      return deviceEventsProps
    }

    const {
      startScan,
      recaptureScan,
      abortScanDirty,
      abortScanClean,
      finishScan,
      removeAllScans,
      startDevice,
      stopDevice,
      sendDeviceCommand
    } = callbacks
    return {
      ...deviceEventsProps,
      startScan: (scanOrder: ScanOrder) => deviceEventsProps.startScan(scanOrder, startScan),
      recaptureScan: (scanOrder: ScanOrder) => deviceEventsProps.recaptureScan(scanOrder, recaptureScan),
      abortScan: () => deviceEventsProps.abortScan(abortScanDirty, abortScanClean),
      finishScan: () => deviceEventsProps.finishScan(finishScan),
      removeAllScans: () => deviceEventsProps.removeAllScans(removeAllScans),
      startDevice: () => deviceEventsProps.startDevice(startDevice),
      stopDevice: () => deviceEventsProps.stopDevice(stopDevice),
      sendDeviceCommand: (commandOptions: Map<string, string>) =>
        deviceEventsProps.sendDeviceCommand(commandOptions, sendDeviceCommand)
    }
  }

  return getDeviceManagerProps
}
