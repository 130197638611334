import { chakra, useMultiStyleConfig } from '@chakra-ui/react'
import { ControlProps, OptionTypeBase, components } from 'react-select'

// wrap react-select Control with chakra, to enable the `sx` prop.
const CControl = chakra(components.Control)

/**
 * A custom `Control` component for react-select that inherits styles from
 * Chakra. It can be used like this:
 *
 * ```
 * <Select
 *   components={{ Control: ReactSelectControl }}
 * />
 * ```
 *
 * An error indicator (i.e. a red border) can be set by passing `aria-invalid`
 * as a prop to the <Select> component.
 */
export default function ReactSelectControl<OptionType extends OptionTypeBase, IsMulti extends boolean>({
  children,
  ...rest
}: ControlProps<OptionType, IsMulti>) {
  // use chakra's style for the <select>, mainly the border
  const style = useMultiStyleConfig('Select', {})
  let sx = style.field
  if (rest.selectProps['aria-invalid']) {
    Object.assign(sx, style.field._invalid)
  }
  if (rest.isFocused) {
    Object.assign(sx, style.field._focus)
  }
  return (
    <CControl sx={sx} {...rest}>
      {children}
    </CControl>
  )
}
