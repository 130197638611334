import { Box, Button, HStack, VStack } from '@chakra-ui/react'
import * as faceapi from 'face-api.js'
import { Rect } from 'face-api.js'
import { Download } from 'heroicons-react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { convertBase64ToBlob } from '../lib/imageUtils'

export interface FacePaintingCanvasProps {
  imageSrc: string | undefined
  croppedArea: Rect
  eyeAverageY: number
  eyePoints: faceapi.Point[] | undefined
  faceBoundsLeft: number
  faceBoundsRight: number
}

export function FacePaintingCanvas(props: FacePaintingCanvasProps) {
  const [rawImage, setRawImage] = useState<HTMLImageElement | null>(null)
  const [descriptorImage, setDescriptorImage] = useState<ImageBitmap>()

  function exportRawImage() {
    if (rawImage?.src) {
      exportImage('rawimage.jpeg', convertBase64ToBlob(rawImage?.src) ?? '')
    }
  }

  async function exportDescriptorImage() {
    if (descriptorImage) {
      let canvas = new OffscreenCanvas(descriptorImage?.width, descriptorImage?.height)
      let context = canvas.getContext('2d')

      if (context) {
        context.drawImage(descriptorImage, 0, 0, descriptorImage.width, descriptorImage.height)
      }

      let imageBlob = await canvas.convertToBlob()
      exportImage('descriptorimage.jpeg', imageBlob)
    }
  }

  function exportImage(filename: string, image: Blob) {
    const anchor = window.document.createElement('a')
    anchor.href = URL.createObjectURL(image)
    anchor.download = filename
    // Append anchor to body and start download
    document.body.appendChild(anchor)
    anchor.click()
    // Remove anchor from body
    document.body.removeChild(anchor)
  }

  const setUpImageCallback = useCallback(() => {
    function getPaintedImage(image: HTMLImageElement): ImageBitmap | undefined {
      if (image) {
        const offscreenCanvas = new OffscreenCanvas(image.width, image.height)
        const offscreenContext = offscreenCanvas.getContext('2d')

        if (!offscreenContext) {
          return
        }

        offscreenContext.drawImage(image, 0, 0)

        let cropRect = props.croppedArea
        if (cropRect) {
          console.log(`renderingCropAreaPixels(${JSON.stringify(cropRect)})`)
          offscreenContext.strokeStyle = '#00ff00'
          offscreenContext.strokeRect(cropRect.x, cropRect.y, cropRect.width, cropRect.height)
        }

        let eyeAverageY = props.eyeAverageY
        if (eyeAverageY) {
          offscreenContext.strokeStyle = '#ff0000'
          offscreenContext.strokeRect(0, eyeAverageY, image.width, 0)
        }

        let allPoints = props.eyePoints
        if (allPoints) {
          offscreenContext.fillStyle = '#0000ff'

          allPoints.forEach((point) => {
            offscreenContext.fillRect(point.x, point.y, 3, 3)
          })
        }

        // draw a center dot
        offscreenContext.fillStyle = '#ff00ff'
        offscreenContext.fillRect(image.width / 2 - 2, image.height / 2 - 2, 4, 4)

        offscreenContext.strokeStyle = '#ffffff'
        offscreenContext.strokeRect(props.faceBoundsLeft, 0, 0, image.height)
        offscreenContext.strokeRect(props.faceBoundsRight, 0, 0, image.height)

        return offscreenCanvas.transferToImageBitmap()
      }
    }

    async function setUpImage() {
      if (props.imageSrc != null) {
        if (rawImage == null || (rawImage != null && props.imageSrc !== rawImage.src)) {
          let image = new Image()
          image.src = props.imageSrc

          await image.decode()

          setRawImage(image)
          setDescriptorImage(getPaintedImage(image))
        }
      }
    }

    setUpImage()
  }, [props, rawImage])

  useEffect(() => {
    setUpImageCallback()
  }, [setUpImageCallback])

  return (
    <Box id="face_painting_canvas_box" width="100%" height="100%">
      <VStack>
        <HStack position="absolute">
          <Button leftIcon={<Download />} onClick={exportRawImage}>
            Download raw
          </Button>
          <Button leftIcon={<Download />} onClick={exportDescriptorImage}>
            Download with descriptors
          </Button>
        </HStack>
        <InternalFacePaintingCanvas {...props} descriptorImage={descriptorImage} />
      </VStack>
    </Box>
  )
}

interface InternalFacePaintingCanvasProps extends FacePaintingCanvasProps {
  descriptorImage: ImageBitmap | undefined
}

function InternalFacePaintingCanvas(props: InternalFacePaintingCanvasProps) {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  useEffect(() => {
    if (canvasRef == null || canvasRef.current == null) {
      return
    }

    const drawImageActualSize = () => {
      if (canvasRef == null || canvasRef.current == null) {
        console.log('canvas is null')
        return
      }

      const canvas = canvasRef.current
      const context = canvas.getContext('2d')

      if (!context) {
        console.log(`cannot generate a context`)
        return
      }

      if (props.descriptorImage) {
        context.drawImage(props.descriptorImage, 0, 0, canvas.width, canvas.height)
      }
    }

    drawImageActualSize()
  }, [props.descriptorImage])

  return <canvas width={props.descriptorImage?.width} height={props.descriptorImage?.height} ref={canvasRef} />
}
