import { JFD, JFDValues, JFL, JFLGroup, Modality } from '@le2/jfd'

import { JFLGroupRefProvider } from '../../components/JFDRender/utils'
import { Record as RecordAPI } from '../../lib/api/record'
import { RecordEnrollmentStatus, RecordImages } from '../../lib/api/record'

export enum Status {
  Idle = 'Idle',
  Loading = 'Loading',
  Error = 'Error',
  Complete = 'Complete'
}

export interface ValidationResult {
  status: Status
  message: string
}

export function newValidationResult(status: Status, message: string = ''): ValidationResult {
  return { status, message }
}

export interface Record {
  recordNumber: string
  enrollmentStatus: RecordEnrollmentStatus
  createdAt: Date | undefined
  updatedAt: Date | undefined
  submittedAt: Date | undefined
  isActive: boolean
  isSealed: boolean
  jfd: JFD
  originalJFD: {
    values: JFDValues
    errors: JFDValues
  }
  recordJFL: JFL
  jflGroupRefProvider: JFLGroupRefProvider
  recordImages: RecordImages
  hasSaveButtonBeenClicked: boolean
  saveStatus: Status
  submitStatus: Status
  isLoadFromFile: boolean
  isOnlyMandatory: boolean

  /**
   * Indicates if we reached the max number of images for the Modality (they key)
   */
  maxImages: {
    [Modality.Face]: boolean
  }

  validation: {
    biographics: ValidationResult
    [Modality.Face]: ValidationResult
  }

  setValidationStatus: (modality: keyof Record['validation'], result: ValidationResult) => void

  /**
   * If we should allow the user to submit a booking even if it has some errors.
   */
  bypassSubmitValidations: boolean

  /**
   * The Mapped Record from the API to object.
   */
  mappedRecord: RecordAPI

  /**
   * Used to determine if we should ask confirmation before leaving the page.
   * Returns `true` when we have unsaved data.
   */
  isDirty: () => boolean
}

export interface BiographicsSectionValidationResult {
  status: Status
  errorCount: number
}

export function newBiographicsSectionValidationResult(
  status: Status,
  errorCount: number = 0
): BiographicsSectionValidationResult {
  return { status, errorCount }
}

export interface BiographicSection extends JFLGroup, BiographicsSectionValidationResult {
  label: string
  jflGroup: JFLGroup
}

export interface BiographicsState {
  sections: BiographicSection[]
}

export interface BiographicsStateComputedValues {
  errorCount: number
  errorCountLabel: string
}

export interface RecordComputedValues {
  isLoading: boolean
  isSubmitEnabled: boolean
}

export interface GenerateRecordNumResponse {
  recordNum: string
}

export enum RecordViewMode {
  view = 'view',
  edit = 'edit'
}
