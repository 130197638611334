import { Box, Center, Image, Text } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

import Background from '../../assets/background.svg'
import { blackLogo as tech5 } from '../../utils/logoUtils'

export default function Error() {
  let error
  if (window.location.search) {
    error = new URLSearchParams(window.location.search).get('error')
  }
  return (
    <Box position="relative" width="100vw" height="100vh">
      <Box w="376px" position="fixed" left="50%" top="50%" transform="translate(-50%, -50%)" align="left" zIndex="2">
        <Text align="center" textStyle="heading">
          That's an error...
        </Text>
        <Text align="center">{error}</Text>
        <Center>
          <Link to="/">Home</Link>
        </Center>
      </Box>
      <Image
        fit="cover"
        src={Background}
        position="absolute"
        width="100%"
        height="100%"
        top={0}
        bottom={0}
        left={0}
        right={0}
      />
      <Image src={tech5} position="absolute" top={14} left={90} h="70px" />
    </Box>
  )
}
