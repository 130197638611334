import { useQuery } from '@apollo/client'
import { Group } from '@le2/common-types'
import { debounce } from 'lodash'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import { PAGE_SIZE_GROUPS_USERS } from '../../../lib/api/constants'
import { OrderBy, OrderByDict } from '../../../lib/useTableSort'
import { GroupResponse } from '../types'
import { GET_GROUPS, mapGroup } from '../utils'

interface GetGroupsResult {
  loading: boolean

  error: boolean

  groups: Group[]

  count: number

  pages: number

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: string

  /**
   * A function to pass to an input's `onChange` prop.
   */
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
}

interface GetAllGroupsResult {
  loading: boolean

  error: boolean

  groups: Group[]
}

interface GetGroupWithNameResult {
  loading: boolean

  error: boolean

  groups: Group[]
}

/**
 * Groups data, as returned by the API with the GET_GROUPS query.
 */
export interface GroupsResponse {
  groups: GroupResponse[]
  groupsAggregate: { count: number }
}

interface SearchParams {
  searchString?: string
  isActive?: boolean
}
interface SearchVars {
  /**
   * Contains the search string. Updates are debounced to 500ms. To be sent to the server.
   */
  debouncedSearch: string

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: SearchParams

  /**
   * A function to pass to `onChange`
   */
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
}

function getDebounced(setSearch: (val: string) => void) {
  return debounce((value: string) => {
    setSearch(value)
  }, 500)
}

export function useSearchVars(): SearchVars {
  // here we use two `search` variables:
  // - one that is immediately updated that can be used as an input's value
  // - one that is updated each 500ms (debounced) that can be used to make
  //   API calls

  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [search, setSearch] = useState<SearchParams>({})
  const debouncedSetSearch = useMemo(() => getDebounced(setDebouncedSearch), [])

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // update the search string immediately
      setSearch({ ...search, searchString: e.target.value })
      // update the debounced search after 500ms
      debouncedSetSearch(e.target.value)
    },
    [search, debouncedSetSearch]
  )

  return { debouncedSearch, search, handleSearch }
}

export default function useGetGroups(currentPage: number, orderBy: OrderByDict): GetGroupsResult {
  const { debouncedSearch, search, handleSearch } = useSearchVars()

  const extra = {
    skip: PAGE_SIZE_GROUPS_USERS * (currentPage - 1),
    take: PAGE_SIZE_GROUPS_USERS,
    orderBy: orderBy
  }

  const queryWithAndCondition = []

  const contains = !!debouncedSearch ? debouncedSearch : undefined
  if (contains) {
    queryWithAndCondition.push({
      OR: [
        {
          name: {
            contains,
            mode: 'insensitive'
          }
        }
      ]
    })
  }

  let variables
  if (queryWithAndCondition.length > 0) {
    variables = {
      where: {
        AND: queryWithAndCondition
      },
      ...extra
    }
  } else {
    variables = { ...extra }
  }

  const queryResult = useQuery<GroupsResponse>(GET_GROUPS, { variables })
  const pages = Math.ceil((queryResult.data?.groupsAggregate?.count || 0) / PAGE_SIZE_GROUPS_USERS)
  const groups = useMemo(() => {
    return queryResult.data?.groups || []
  }, [queryResult.data?.groups])

  return {
    loading: queryResult.loading,
    error: !!queryResult.error,
    groups: groups.map((group) => mapGroup(group)),
    count: queryResult.data?.groupsAggregate?.count || 0,
    pages,
    search: search.searchString || '',
    handleSearch
  }
}

export function useGetAllGroups(orderBy: OrderByDict): GetAllGroupsResult {
  const extra = {
    orderBy: orderBy
  }

  let variables = { ...extra, where: { isActive: true } }

  const { loading, error, data } = useQuery<GroupsResponse>(GET_GROUPS, { variables })

  return {
    loading: loading,
    error: !!error,
    groups: data?.groups.map((group) => mapGroup(group)) || []
  }
}

export function useFindGroupWithName(name: string): GetGroupWithNameResult {
  const extra = {
    skip: 0,
    take: PAGE_SIZE_GROUPS_USERS,
    orderBy: { name: OrderBy.asc }
  }

  const { loading, data, error } = useQuery<GroupsResponse>(GET_GROUPS, {
    variables: {
      ...extra,
      where: {
        name
      }
    },
    fetchPolicy: 'no-cache'
  })

  return {
    groups: data?.groups.map((group) => mapGroup(group)) || [],
    error: !!error,
    loading
  }
}
