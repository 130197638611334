import { Box, FormLabel, HStack } from '@chakra-ui/react'
import { JFD, JFDFieldDef, JFLGroup } from '@le2/jfd'
import { PlusOutline, TrashOutline } from 'heroicons-react'
import { useState } from 'react'

import { QuickActionButton } from '../QuickActionButton'
import { TableActionButton } from '../TableActionButton'
import { JFDFormField } from './JFDFormField'
import { useJFD } from './jfd-context'
import { getFieldName } from './utils'

interface FieldArrayOptions {
  fieldDef: JFDFieldDef
  index: number
  hasFieldLabels: boolean
}

/**
 * This creates a new object in the field array
 *  fieldDef - JFD Field definition
 *  item - for RHF use
 *  register - RHF register
 *  errors - RHF error
 */
function ObjectFieldArray({ fieldDef, index, hasFieldLabels }: FieldArrayOptions) {
  const { jfd } = useJFD()

  return (
    <>
      {Object.keys(fieldDef.subFields.fields).map((subField) => {
        const fieldName = `${fieldDef.name}.${subField}`
        const name = getFieldName(fieldDef, fieldName, index)
        const jfdField = jfd.getField(fieldName, index)

        return <JFDFormField key={name} name={name} jfdField={jfdField} hasLabel={hasFieldLabels} />
      })}
    </>
  )
}

/**
 * Single Field in Array
 *  fieldDef - JFD Field definition
 *  item - for RHF use
 *  register - RHF register
 *  errors - RHF errors
 */

function ScalarFieldArray({ fieldDef, index, hasFieldLabels }: FieldArrayOptions) {
  const { jfd } = useJFD()
  const name = getFieldName(fieldDef, fieldDef.name, index)
  const jfdField = jfd.getField(fieldDef.name, index)

  return <JFDFormField name={name} jfdField={jfdField} hasLabel={hasFieldLabels} />
}

/**
 * Get the `id` of the first JFDField for the given JFDFieldDef
 */
function getRowKey(jfd: JFD, fieldDef: JFDFieldDef, index: number): string {
  let fieldName
  if (fieldDef.hasSubFields()) {
    const firstSubfield = Object.keys(fieldDef.subFields.fields)[0]
    fieldName = `${fieldDef.name}.${firstSubfield}`
  } else {
    fieldName = fieldDef.name
  }
  return jfd.getField(fieldName, index).id
}

/**
 * Main Field Array driver
 * jfdField - The field from jfd, may or may not have subfields
 * register - RHF register
 * control = ref for RHF
 */
export function JFDFieldArray({ group, fieldDef }: { group: JFLGroup; fieldDef: JFDFieldDef }) {
  const { jfd, readOnly } = useJFD()
  const initialEntries = jfd.numEntries(fieldDef.name)
  const [recordCount, setRecordCount] = useState(initialEntries)

  // make sure that we have the same number of entries in `recordCount` and in
  // the JFD instance
  if (recordCount !== initialEntries) {
    setRecordCount(initialEntries)
  }

  function onAdd() {
    jfd.createOccurrence(fieldDef.name, recordCount)
    setRecordCount((current) => current + 1)
  }

  function onDelete(index: number) {
    jfd.removeField(fieldDef.name, index)
    setRecordCount((current) => current - 1)
  }

  function hasFieldLabels(rowIndex: number) {
    switch (group.options?.fieldLabels) {
      case 'first':
        return rowIndex === 0
      case 'none':
        return false
      case 'all':
      default:
        return true
    }
  }

  return (
    <>
      {[...Array(Math.min(initialEntries, recordCount))].map((_, index) => {
        return (
          <HStack mt={2} key={getRowKey(jfd, fieldDef, index)} alignItems="flex-start" data-testid="field-array-row">
            {fieldDef.hasSubFields() && (
              <ObjectFieldArray fieldDef={fieldDef} index={index} hasFieldLabels={hasFieldLabels(index)} />
            )}
            {!fieldDef.hasSubFields() && (
              <ScalarFieldArray fieldDef={fieldDef} index={index} hasFieldLabels={hasFieldLabels(index)} />
            )}
            <Box>
              {/* hidden label, so that the button is aligned with the inputs */}
              {hasFieldLabels(index) && (
                <FormLabel textStyle="details" visibility="hidden" mx={0} w={1}>
                  h
                </FormLabel>
              )}
              <Box pt={1}>
                <TableActionButton
                  onClick={() => onDelete(index)}
                  ariaLabel="Remove item"
                  iconAs={TrashOutline}
                  isDisabled={readOnly}
                />
              </Box>
            </Box>
          </HStack>
        )
      })}
      <QuickActionButton
        label={group.options?.addButtonLabel || 'Add'}
        isDisabled={readOnly || recordCount >= fieldDef.maxOccurrences}
        onClick={onAdd}
        iconAs={PlusOutline}
      />
    </>
  )
}
