import { JFLDef, JFLGroup } from '@le2/jfd'
import React from 'react'

import { CheckboxArray } from './CheckboxArray'
import { JFDFormField } from './JFDFormField'
import { JFDFormRangeField } from './JFDFormRangeField'
import JFLTableLookupField from './JFLTableLookupField'
import { useJFD } from './jfd-context'
import { getFieldName, shouldRenderField } from './utils'

// Note: this is a hook because we return null in some cases, and we check for
// that case in JFL.tsx to completely skip the item. We can't do that  if we
// return a regular Component
export function useJFLField(jflDef: JFLDef, index?: number, group?: JFLGroup) {
  const { jfd, onlyMandatory } = useJFD()
  const fieldLabels = group?.options?.fieldLabels || 'all'

  function hasFieldLabels(rowIndex: number | undefined) {
    switch (fieldLabels) {
      case 'first':
        return rowIndex === 0
      case 'none':
        return false
      case 'all':
      default:
        return true
    }
  }

  switch (jflDef.control) {
    case 'formField':
      const jfdField = jfd.getField(jflDef.field, index)
      const name = getFieldName(jfdField, jflDef.field, index)

      if (!shouldRenderField(jfdField, onlyMandatory)) {
        return null
      } else {
        return <JFDFormField name={name} jfdField={jfdField} hasLabel={hasFieldLabels(index)} />
      }

    case 'checkboxArray':
      return <CheckboxArray jflDef={jflDef} index={index} />

    case 'range': {
      const jfdField1 = jfd.getField(jflDef.field[0], index)
      const jfdField2 = jfd.getField(jflDef.field[1], index)

      if (!shouldRenderField(jfdField1, onlyMandatory) || !shouldRenderField(jfdField2, onlyMandatory)) {
        return null
      } else {
        return <JFDFormRangeField jflDef={jflDef} index={index} hasLabel={hasFieldLabels(index)} />
      }
    }

    case 'tableLookup': {
      const jfdField = jfd.getField(jflDef.field, index)
      if (!shouldRenderField(jfdField, onlyMandatory)) {
        return null
      } else {
        return <JFLTableLookupField jflDef={jflDef} index={index} />
      }
    }

    default:
      throw new Error('Invalid control: ' + jflDef.control)
  }
}
