import { useEffect } from 'react'

/**
 * Display a confirmation dialog if the user tries to leave the page somehow:
 * via a page refresh or closing the window.
 *
 * Note that this doesn't prevent internal navigation via React Router. To do
 * that you need to use <Prompt> from that lib.
 */
export default function usePreventUnload(isDirtyFunc: () => boolean, enabled = true) {
  useEffect(() => {
    if (!enabled) {
      return
    }

    const listener = getBeforeUnloadListener(isDirtyFunc)
    window.addEventListener('beforeunload', listener)
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [isDirtyFunc, enabled])
}

// see https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#example
function getBeforeUnloadListener(isDirtyFunc: () => boolean) {
  return function (e: BeforeUnloadEvent) {
    if (isDirtyFunc()) {
      e.preventDefault()
      e.returnValue = ''
    }
  }
}
