import { useMemo } from 'react'

export enum ThemeModeEnum {
  OLD,
  NEW
}

export function useThemeMode(themeMode?: ThemeModeEnum) {
  const variantColor = useMemo(
    () => ({
      primary: themeMode === ThemeModeEnum.OLD ? 'primary' : 'newPrimary',
      secondary: themeMode === ThemeModeEnum.OLD ? 'secondary' : 'newSecondary'
    }),
    [themeMode]
  )

  const color = useMemo(
    () => ({
      secondary: themeMode === ThemeModeEnum.OLD ? 'secondary' : 'blueWhale'
    }),
    [themeMode]
  )

  return {
    variantColor,
    color
  }
}
