import agencyConfig from '../lib/agencyConfig'

const agencyName = agencyConfig.features.agencyName

function getAgencyLogos() {
  // Verify if agency logo folder path exists, if not set to the default
  // NOTE: Even when this can be simplified, react webpack does not accept dynamic imports,
  // then we need to specify each path (default and agency)
  try {
    const blackLogoPath = require(`../assets/logo/${agencyName}/tech5-black-logo.svg`)
    const whiteLogoPath = require(`../assets/logo/${agencyName}/tech5-white-logo.svg`)
    return { blackLogoPath, whiteLogoPath }
  } catch (err) {
    // set default
    const blackLogoPath = require(`../assets/logo/tech5-black-logo.svg`)
    const whiteLogoPath = require(`../assets/logo/tech5-white-logo.svg`)
    return { blackLogoPath, whiteLogoPath }
  }
}

export const { blackLogoPath, whiteLogoPath } = getAgencyLogos()
export const blackLogo = blackLogoPath.default
export const whiteLogo = whiteLogoPath.default
