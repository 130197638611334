import { ImageMimeType } from '../../lib/imageUtils'
import { DeviceManager } from '../DeviceManager'
import {
  CancelCaptureEventDetail,
  CaptureEventDetail,
  DeviceCapabilitiesDefinitions,
  DeviceError,
  DeviceEventCodes,
  DeviceEventDetail,
  DeviceInfo,
  GetConfigurationOptionsEventDetail,
  GetDeviceOptionsEventDetail,
  GetDevicesListEventDetail,
  GetPluginsListEventDetail,
  IWImage,
  ModalityDefinitions,
  OpenDeviceEventDetail,
  PluginCapabilitiesDefinitions,
  PluginIDDefinitions,
  ScanResultStatus,
  ScanStatus,
  SequencerReturnCode,
  sequencerResultCodeMessages
} from '../IWNativeExtDeviceDefines'
import { calculateProgress, drawCanvas, getScanCaptureQualityResult, getSequencerQualityResult } from './utils'

export function openDeviceEventHandlerWrapper(
  setDeviceOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setMessageStatus: React.Dispatch<React.SetStateAction<string>>,
  setScanResultStatus: React.Dispatch<React.SetStateAction<ScanResultStatus>>,
  setScanStatus: React.Dispatch<React.SetStateAction<ScanStatus>>
) {
  return function (eventCtx: CustomEvent<OpenDeviceEventDetail>) {
    const result = eventCtx.detail.result
    const pluginID = eventCtx.detail.pluginID
    const deviceInstance = eventCtx.detail.deviceInstance

    console.log(`openDeviceEventHandler() entered. pluginID: "${pluginID}", deviceInstance: "${deviceInstance}"`)

    if (result) {
      console.log('openDeviceEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

      if (DeviceError.ERROR_SUCCESS === result.ErrorCode) {
        console.log('openDeviceEventHandler(), device successfully opened')
        setDeviceOpen(true)
        setMessageStatus('')
      } else {
        setScanResultStatus(ScanResultStatus.DeviceError)
        setMessageStatus('Could not open the device. Check if another capture instance is in progress and try again.')
        setScanStatus(ScanStatus.Error)
      }
    } else {
      console.log('openDeviceEventHandler(), undefined result received')
    }
  } as EventListener
}

export function closeDeviceEventHandlerWrapper(setDeviceOpen: React.Dispatch<React.SetStateAction<boolean>>) {
  return function (eventCtx: CustomEvent<OpenDeviceEventDetail>) {
    const result = eventCtx.detail.result
    const pluginID = eventCtx.detail.pluginID
    const deviceInstance = eventCtx.detail.deviceInstance

    console.log(`closeDeviceEventHandler() entered. pluginID: "${pluginID}", deviceInstance: "${deviceInstance}"`)

    if (result) {
      console.log('closeDeviceEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

      if (DeviceError.ERROR_SUCCESS === result.ErrorCode) {
        console.log('closeDeviceEventHandler(), device successfully closed')
        setDeviceOpen(false)
      }
    } else {
      console.log('closeDeviceEventHandler(), undefined result received')
    }
  } as EventListener
}

export function getPluginsListEventHandlerWrapper(
  setPluginID: React.Dispatch<React.SetStateAction<string | undefined>>,
  setFPCardPluginID: React.Dispatch<React.SetStateAction<string | undefined>>,
  setPluginCapabilities: React.Dispatch<React.SetStateAction<number[]>>,
  setIsLoadFromFile: React.Dispatch<React.SetStateAction<boolean>>,
  deviceManager: DeviceManager
) {
  return function (eventCtx: CustomEvent<GetPluginsListEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log('getPluginsListEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

    if (list?.length) {
      const pluginID = list[0].PluginID.toUpperCase()

      console.log('getPluginsListEventHandler(), setting the pluginID: ' + pluginID)
      setPluginID(pluginID)

      const pluginCapabilities = list[0].PluginCapabilities
      const pluginInfo = pluginCapabilities
        .map((capability) => {
          return PluginCapabilitiesDefinitions[capability]
        })
        .join()
      console.log('getPluginsListEventHandler(), plugin Capabilities: ' + pluginInfo)
      setPluginCapabilities(pluginCapabilities)

      const isLoadFromFile = pluginID === PluginIDDefinitions.LOAD_FROM_FILE_PLUGIN_ID
      console.log('getPluginsListEventHandler(), is pluginID LoadFromFile: ' + isLoadFromFile)
      setIsLoadFromFile(isLoadFromFile)

      // Check plugin list for PluginCapabilitiesDefinitions 'FingerprintCard'
      const fpCardPlugin = list.find((x) =>
        x.PluginCapabilities.includes(PluginCapabilitiesDefinitions.FingerprintCard)
      )
      if (fpCardPlugin) {
        setFPCardPluginID(fpCardPlugin.PluginID.toUpperCase())
        pluginCapabilities.push(PluginCapabilitiesDefinitions.FingerprintCard)
        setPluginCapabilities(pluginCapabilities)
      }

      deviceManager.GetDevicesList(pluginID)
    } else {
      console.log('getPluginsListEventHandler(), no list received')
    }
  } as EventListener
}

export function getDevicesListEventHandlerWrapper(
  setDeviceInstance: React.Dispatch<React.SetStateAction<number>>,
  setDeviceCapabilities: React.Dispatch<React.SetStateAction<number[]>>,
  setIsMultipleCaptureAvailable: React.Dispatch<React.SetStateAction<boolean>>,
  setDeviceInfo: React.Dispatch<React.SetStateAction<DeviceInfo | undefined>>
) {
  return function (eventCtx: CustomEvent<GetDevicesListEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log('getDevicesListEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

    if (list?.length) {
      const deviceInstance = list[0].DeviceInstance

      console.log('getDevicesListEventHandler(), deviceInstance: ' + deviceInstance)
      setDeviceInstance(deviceInstance)

      // Save the DeviceInfo for further reference; we'll use it for the capture device info fields in the jfd
      setDeviceInfo(list[0])

      let isMultipleCaptureAvailable = false
      const deviceCapabilities = list[0].Capabilities
      const deviceInfo = deviceCapabilities
        .map((capability) => {
          if (capability === DeviceCapabilitiesDefinitions.MultipleCapture) {
            isMultipleCaptureAvailable = true
          }
          return DeviceCapabilitiesDefinitions[capability]
        })
        .join()
      console.log('getDevicesListEventHandler(), device Capabilities: ' + deviceInfo)
      setDeviceCapabilities(deviceCapabilities)

      console.log('getDevicesListEventHandler(), is MultipleCapture available: ' + isMultipleCaptureAvailable)
      setIsMultipleCaptureAvailable(isMultipleCaptureAvailable)
    } else {
      console.log('getDevicesListEventHandler(), no list received')
    }
  } as EventListener
}

export function deviceEventHandlerWrapper(
  setMessageStatus: React.Dispatch<React.SetStateAction<string>>,
  setScanProgress: React.Dispatch<React.SetStateAction<number>>,
  modality: ModalityDefinitions,
  scanStatus: ScanStatus,
  canvasRefs: React.RefObject<HTMLCanvasElement>[]
) {
  return function (eventCtx: CustomEvent<DeviceEventDetail>) {
    // Don't do anything when the operation is canceled
    if (scanStatus === ScanStatus.Cancelling) {
      console.log(`deviceEventHandler(): The operation was canceled`)
      return
    }

    const deviceEventArgs = eventCtx.detail.deviceEventArgs
    const pluginID = deviceEventArgs.PluginID
    const deviceInstance = deviceEventArgs.DeviceInstance
    const eventCode = deviceEventArgs.EventCode
    const statusCode = deviceEventArgs.StatusCode
    const statusMessage = deviceEventArgs.StatusMessage
    const listImages = deviceEventArgs.ImageList
    const eventTime = deviceEventArgs.EventTime

    const info = `deviceEventHandler() entered. pluginID: "${pluginID}",
        deviceInstance: "${deviceInstance}", eventCode: ${eventCode},
        statusCode: "${statusCode}", statusMessage: "${statusMessage}", eventTime: "${eventTime}", (${
      eventCode && 0 !== eventCode ? DeviceEventCodes[eventCode] : ''
    })`

    console.log(info)

    if (statusMessage) {
      setMessageStatus(statusMessage)
    }

    const progress = calculateProgress(modality, eventCode)
    console.log(`deviceEventHandler() progress: ${progress}`)
    if (progress !== null) {
      setScanProgress(progress)
    }

    if (listImages && listImages.length) {
      drawCanvas(canvasRefs[0], listImages[0].imageData, ImageMimeType.BMP)
    }

    // Actuate button on foot pedal events
    //     TODO - Move this block to where hardware events are processed
    //     See Jira LENEXTGEN-1010 for details
    switch (eventCode) {
      case DeviceEventCodes.FootPedalLongPress:
        console.log('***** Foot Pedal: Long press')
        break
      case DeviceEventCodes.FootPedalShortPress:
        console.log('***** Foot Pedal: Short press')
        break
    }
  } as EventListener
}

export function captureEventHandlerWrapper(
  setScannedImageList: React.Dispatch<IWImage[]>,
  setScanResultStatus: React.Dispatch<React.SetStateAction<ScanResultStatus>>,
  setMessageStatus: React.Dispatch<React.SetStateAction<string>>,
  setScanStatus: React.Dispatch<React.SetStateAction<ScanStatus>>,
  clearScanStep: () => void,
  modality: ModalityDefinitions,
  isLoadFromFile: boolean,
  isMultipleCaptureAvailable: boolean,
  scanStatus: ScanStatus,
  canvasRefs: React.RefObject<HTMLCanvasElement>[]
) {
  return function (eventCtx: CustomEvent<CaptureEventDetail>) {
    // Don't do anything when the operation is canceled
    if (scanStatus === ScanStatus.Cancelling) {
      console.log(`captureEventHandler(): The operation was canceled`)
      clearScanStep()
      return
    }

    const result = eventCtx.detail.result
    const list = eventCtx.detail.list
    const pluginID = eventCtx.detail.pluginID
    const deviceInstance = eventCtx.detail.deviceInstance

    console.log(`captureEventHandler() entered. pluginID: "${pluginID}", deviceInstance: "${deviceInstance}"`)

    if (result) {
      console.log('captureEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)
    }

    if (DeviceError.ERROR_SUCCESS !== result.ErrorCode) {
      setScanResultStatus(ScanResultStatus.ScanError)

      // Magic message alert: we want to display errors received from Native Exts that have descriptions
      // starting with 'Capture Error:', instead of the generic 'Capture failed' message.
      // The NativeExts plugin will sent error descriptions that start with 'Capture Error:'
      // if the error is to be displayed to the end user.
      // Otherwise, the standard 'Capture failed' catch-all error will be displayed as before
      const getNativeExtsErrorMessage = () => {
        const captureError: string = 'Capture Error:'
        let idx: number = result?.ErrorDescription?.indexOf(captureError)
        if (0 <= idx) {
          idx += captureError.length
          let message: string = result.ErrorDescription.substring(idx)?.trim()
          if (0 < message?.length) {
            // make sure the error message ends with a period, in case the NE plugin missed to include it
            if (!message.endsWith('.')) message += '.'
            return message
          }
        }
        return null
      }

      const errorMessage = getNativeExtsErrorMessage() ?? 'Capture failed.'
      setMessageStatus(`${errorMessage} Please try again`)
      setScanStatus(ScanStatus.Error)
      return
    }

    let messageStatus: string, scanResultStatus: ScanResultStatus

    if (list && list.length) {
      console.log('captureEventHandler(), images list received, list length: ' + list.length)

      if (modality === ModalityDefinitions.Fingerprint || modality === ModalityDefinitions.Palm) {
        const sequencerCodeStr = list[0].metaData?.SequencerResultCode
        const sequencerCode = parseInt(sequencerCodeStr)
        const sequencerMessage = sequencerResultCodeMessages[sequencerCode]
        messageStatus = sequencerMessage

        if (sequencerCode !== SequencerReturnCode.Success && sequencerCode !== SequencerReturnCode.ImageLowQuality) {
          scanResultStatus = ScanResultStatus.SequenceCheckError
        } else if (modality === ModalityDefinitions.Palm) {
          scanResultStatus =
            sequencerCode === SequencerReturnCode.ImageLowQuality
              ? ScanResultStatus.PoorQuality
              : ScanResultStatus.GoodQuality
        } else {
          const scanSequencerQualityScores = list.map(({ metaData }) => metaData?.SequencerQuality || '-1')
          const sequencerQualityResult = getSequencerQualityResult(scanSequencerQualityScores)
          scanResultStatus = sequencerQualityResult
        }
      } else if (modality === ModalityDefinitions.Iris) {
        messageStatus = result.ErrorDescription
        const scanCaptureQualityScores = list.map(({ metaData }) => metaData?.ScanCaptureQualityScore || '-1')
        const scanCaptureQualityResult = getScanCaptureQualityResult(scanCaptureQualityScores)
        scanResultStatus = scanCaptureQualityResult
      } else {
        messageStatus = result.ErrorDescription
        scanResultStatus = ScanResultStatus.NoQualityScore
      }

      setScannedImageList(list)

      if (
        isLoadFromFile ||
        ![ScanResultStatus.GoodQuality, ScanResultStatus.AcceptableQuality, ScanResultStatus.NoQualityScore].includes(
          scanResultStatus
        )
      ) {
        drawCanvas(canvasRefs[0], list[0].imageData, ImageMimeType.BMP)
        if (modality === ModalityDefinitions.Iris && isMultipleCaptureAvailable && list[1]) {
          drawCanvas(canvasRefs[1], list[1].imageData, ImageMimeType.BMP)
        }
      }

      setScanStatus(ScanStatus.Done)
      setMessageStatus(messageStatus)
      setScanResultStatus(scanResultStatus)
    } else {
      console.log('captureEventHandler(), no images received')
    }
  } as EventListener
}

export function cancelCaptureEventHandlerWrapper() {
  return function (eventCtx: CustomEvent<CancelCaptureEventDetail>) {
    let result = eventCtx.detail.result
    let pluginID = eventCtx.detail.pluginID
    let deviceInstance = eventCtx.detail.deviceInstance

    console.log(`cancelCaptureEventHandler() entered. pluginID: "${pluginID}", deviceInstance: "${deviceInstance}"`)

    if (result) {
      console.log('cancelCaptureEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

      if (DeviceError.ERROR_SUCCESS === result.ErrorCode) {
        console.log('cancelCaptureEventHandler(), the operation is canceled successfully')
      }
    } else {
      console.log('cancelCaptureEventHandler(), undefined result received')
    }
  } as EventListener
}

export function getDeviceOptionsEventHandlerWrapper(setDeviceOptionsMap: React.Dispatch<Map<string, string> | null>) {
  return function (eventCtx: CustomEvent<GetDeviceOptionsEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list as Map<string, string>

    console.log(
      'getDeviceOptionsEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription
    )

    // This should be a map of options
    if (0 < list?.size) {
      // TODO: Use as you see fit
      setDeviceOptionsMap(list)
    } else {
      console.log('getDeviceOptionsEventHandlerWrapper(), no list received')
      setDeviceOptionsMap(null)
    }
  } as EventListener
}

export function getConfigurationOptionsEventHandlerWrapper() {
  // TODO: parameters
  return function (eventCtx: CustomEvent<GetConfigurationOptionsEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log(
      'getConfigurationOptionsEventHandlerWrapper(), result received: ' +
        result.ErrorCode +
        ', ' +
        result.ErrorDescription
    )

    // This should be a map of options
    if (list?.size) {
      // TODO: Use as you see fit
    } else {
      console.log('getConfigurationOptionsEventHandlerWrapper(), no list received')
    }
  } as EventListener
}
