/////////////////////////////////////////////////////////////////////////////////
//
//  This class provides methods to manipulate the Device Events
//
/////////////////////////////////////////////////////////////////////////////////
import { HubManager } from './HubManager'
import {
  CancelCaptureEventDetail,
  CaptureEventDetail,
  CloseDeviceEventDetail,
  DeviceError,
  DeviceEventArgs,
  DeviceEventDetail,
  DeviceInfo,
  GetConfigurationOptionsEventDetail,
  GetDeviceOptionsEventDetail,
  GetDevicesListEventDetail,
  GetPluginsListEventDetail,
  IWData,
  IWImage,
  IWResult,
  IWResultServer,
  ManagerEvents,
  OpenDeviceEventDetail,
  PauseCaptureStreamEventDetail,
  PluginInfo,
  ProcessEventDetail,
  ResumeCaptureStreamEventDetail,
  SetConfigurationOptionsEventDetail,
  SetDeviceOptionsEventDetail,
  StartCaptureStreamEventDetail,
  StopCaptureStreamEventDetail,
  StreamCaptureEventsEventDetail,
  StreamImagesAsyncEventDetail
} from './IWNativeExtDeviceDefines'

export class DeviceManager extends HubManager {
  constructor(eventsCallback?: () => void) {
    const scanningDeviceManagerUrl = 'http://localhost:5995/deviceMgr'
    super(scanningDeviceManagerUrl, eventsCallback)

    // bind the class methods to this instance
    this.GetPluginsList = this.GetPluginsList.bind(this)
    this.GetDevicesList = this.GetDevicesList.bind(this)
    this.OpenDevice = this.OpenDevice.bind(this)
    this.CloseDevice = this.CloseDevice.bind(this)
    this.Capture = this.Capture.bind(this)
    this.CancelCapture = this.CancelCapture.bind(this)
    this.StartCaptureStream = this.StartCaptureStream.bind(this)
    this.StopCaptureStream = this.StopCaptureStream.bind(this)
    this.PauseCaptureStream = this.PauseCaptureStream.bind(this)
    this.ResumeCaptureStream = this.ResumeCaptureStream.bind(this)
    this.StreamImagesAsync = this.StreamImagesAsync.bind(this)
    this.StreamCaptureEvents = this.StreamCaptureEvents.bind(this)
    this.GetDeviceOptions = this.GetDeviceOptions.bind(this)
    this.SetDeviceOptions = this.SetDeviceOptions.bind(this)
    this.OnDeviceEvent = this.OnDeviceEvent.bind(this)
    this.GetConfigurationOptions = this.GetConfigurationOptions.bind(this)
    this.SetConfigurationOptions = this.SetConfigurationOptions.bind(this)
  }
  //
  // Methods that call the device manager server interfaces
  //
  GetPluginsList(
    searchOptions: { [key: string]: string },
    callbackMethod?: (iwResult: IWResult, resultList: PluginInfo[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: PluginInfo[] | null = null

    try {
      console.log('DeviceManager::GetPluginsList() entered')

      this.hubConnection
        ?.invoke('GetPluginsList', searchOptions)
        .then(function (list: PluginInfo[] | null) {
          console.log('connection.GetPluginsList.then() entered, list: ' + list)

          if (list?.length) {
            // begin test print to console
            for (let i = 0; i < list.length; i++) {
              console.log(list[i])
            }
            // end test print to console

            resultList = list

            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
          } else {
            iwResult = new IWResult(DeviceError.ERROR_NOT_FOUND, 'Not found')
          }
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
          //    if ((undefined != callbackMethod) && (null != callbackMethod)) {
          //        callbackMethod(iwResult, resultList, callbackContext);
          //    }
        })
        .catch(function (err: Error) {
          console.log('connection.GetPluginsList.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //if ((undefined != callbackMethod) && (null != callbackMethod)) {
          //    callbackMethod(iwResult, null, callbackContext);
          //}
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.GetPluginsList.finally() entered')

          callbackMethod?.(iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<GetPluginsListEventDetail>(ManagerEvents.GetPluginsList, {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: resultList }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::GetPluginsList() : ' + e)
    }
  }

  GetDevicesList(
    pluginID: string,
    callbackMethod?: (
      pluginID: string,
      iwResult: IWResult,
      resultList: DeviceInfo[] | null,
      callbackContext?: any
    ) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: DeviceInfo[] | null = null

    try {
      console.log('DeviceManager::GetDevicesList() entered, pluginID: ' + pluginID)

      this.hubConnection
        ?.invoke('GetDevicesList', pluginID)
        .then(function (list: DeviceInfo[] | null) {
          console.log('connection.GetDevicesList.then() entered, list: ' + list)

          if (list?.length) {
            // begin test print to console
            for (let i = 0; i < list.length; i++) {
              console.log(list[i])
            }
            // end test print to console
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')

            resultList = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_NOT_FOUND, 'Not found')
          }
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
        })
        .catch(function (err: Error) {
          console.log('connection.GetDevicesList.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //alert("error: " + err.toString());
        })
        .finally(() => {
          console.log('connection.GetDevicesList.finally() entered')

          callbackMethod?.(pluginID, iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<GetDevicesListEventDetail>(ManagerEvents.GetDevicesList, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, result: iwResult, list: resultList }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::GetDevicesList() : ' + e)
    }
  }

  OpenDevice(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::OpenDevice() entered')

      this.hubConnection
        ?.invoke('OpenDevice', pluginID, deviceInstance)
        .then(function (result: IWResultServer) {
          console.log('connection.OpenDevice.then() entered, result: ' + result)
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.OpenDevice.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.OpenDevice.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<OpenDeviceEventDetail>(ManagerEvents.OpenDevice, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::OpenDevice() : ' + e)
    }
  }

  CloseDevice(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::CloseDevice() entered')

      this.hubConnection
        ?.invoke('CloseDevice', pluginID, deviceInstance)
        .then(function (result: IWResultServer) {
          console.log('connection.CloseDevice.then() entered, result: ' + result)
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.CloseDevice.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.log('connection.CloseDevice.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<CloseDeviceEventDetail>(ManagerEvents.CloseDevice, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::CloseDevice() : ' + e)
    }
  }

  Capture(
    pluginID: string,
    deviceInstance: number,
    captureOptions: { [key: string]: string },
    listCurrentImages: IWImage[] | null, // TODO: check if allows null
    callbackMethod?: (
      pluginID: string,
      deviceInstance: number,
      iwResult: IWResult,
      resultList: IWImage[],
      callbackContext?: any
    ) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: IWImage[]

    try {
      console.log('DeviceManager::Capture() entered')

      this.hubConnection
        ?.invoke('Capture', pluginID, deviceInstance, captureOptions, listCurrentImages)
        .then(function (list: IWImage[]) {
          // if successful, we should be getting a list of images
          console.log('connection.Capture.then() entered, result list: ' + list)

          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            resultList = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No images')
          }

          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
        })
        .catch(function (err: Error) {
          console.log('connection.Capture.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.Capture.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<CaptureEventDetail>(ManagerEvents.Capture, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult, list: resultList }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::Capture() : ' + e)
    }
  }

  CancelCapture(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::CancelCapture() entered')

      this.hubConnection
        ?.invoke('CancelCapture', pluginID, deviceInstance)
        .then(function (result) {
          console.log('connection.CancelCapture.then() entered, result: ' + result)
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.CancelCapture.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.CancelCapture.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<CancelCaptureEventDetail>(ManagerEvents.CancelCapture, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::CancelCapture() : ' + e)
    }
  }

  StartCaptureStream(
    pluginID: string,
    deviceInstance: number,
    captureOptions: { [key: string]: string },
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::StartCaptureStream() entered')

      this.hubConnection
        ?.invoke('StartCaptureStream', pluginID, deviceInstance, captureOptions)
        .then(function (result: IWResultServer) {
          // if successful, we should be getting a list of images
          console.log('connection.StartCaptureStream.then() entered, result: ' + result)
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
        })
        .catch(function (err: Error) {
          console.log('connection.StartCaptureStream.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.StartCaptureStream.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<StartCaptureStreamEventDetail>(ManagerEvents.StartCaptureStream, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::StartCaptureStream() : ' + e)
    }
  }

  StopCaptureStream(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::StopCaptureStream() entered')

      this.hubConnection
        ?.invoke('StopCaptureStream', pluginID, deviceInstance)
        .then(function (result: IWResultServer) {
          // if successful, we should be getting a list of images
          console.log('connection.StopCaptureStream.then() entered, result: ' + result)
          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.StopCaptureStream.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.StopCaptureStream.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<StopCaptureStreamEventDetail>(ManagerEvents.StopCaptureStream, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::StopCaptureStream() : ' + e)
    }
  }

  PauseCaptureStream(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      this.hubConnection
        ?.invoke('PauseCaptureStream', pluginID, deviceInstance)
        .then(function (result: IWResultServer) {
          // if successful, we should be getting a list of images
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.PauseCaptureStream.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<PauseCaptureStreamEventDetail>(ManagerEvents.PauseCaptureStream, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::PauseCaptureStream() : ' + e)
    }
  }

  ResumeCaptureStream(
    pluginID: string,
    deviceInstance: number,
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      this.hubConnection
        ?.invoke('ResumeCaptureStream', pluginID, deviceInstance)
        .then(function (result: IWResultServer) {
          // if successful, we should be getting a list of images
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err: Error) {
          console.log('connection.ResumeCaptureStream.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<ResumeCaptureStreamEventDetail>(ManagerEvents.ResumeCaptureStream, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID, deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::ResumeCaptureStream() : ' + e)
    }
  }

  StreamImagesAsync(
    pluginID: string,
    deviceInstance: number,
    captureOptions: { [key: string]: string },
    listCurrentImages: IWImage[] | null, // TODO: check if allows null
    callbackMethod?: (
      pluginID: string,
      deviceInstance: number,
      iwResult: IWResult,
      item: IWImage | null,
      callbackContext?: any
    ) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      console.log('DeviceManager::StreamImagesAsync() entered')
      this.hubConnection
        ?.stream('StreamImagesAsync', pluginID, deviceInstance, captureOptions, listCurrentImages)
        .subscribe({
          next: (item: IWImage | null) => {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')

            callbackMethod?.(pluginID, deviceInstance, iwResult, item, callbackContext)

            // always fire the events in case there might be subscribers
            document.dispatchEvent(
              new CustomEvent<StreamImagesAsyncEventDetail>(ManagerEvents.StreamImagesAsync, {
                bubbles: true,
                cancelable: true,
                detail: { pluginID, deviceInstance, result: iwResult, item }
              })
            )
          },
          complete: () => {
            console.log('DeviceManager::StreamImagesAsync(), stream completed')
            iwResult = new IWResult(DeviceError.ERROR_NO_MORE_ITEMS, 'Stream completed')

            callbackMethod?.(pluginID, deviceInstance, iwResult, null, callbackContext)

            // always fire the events in case there might be subscribers
            document.dispatchEvent(
              new CustomEvent<StreamImagesAsyncEventDetail>(ManagerEvents.StreamImagesAsync, {
                bubbles: true,
                cancelable: true,
                detail: { pluginID, deviceInstance, result: iwResult }
              })
            )
          },
          error: (err: Error) => {
            console.log('DeviceManager::StreamImagesAsync(), error: ' + err.toString())
            iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())

            callbackMethod?.(pluginID, deviceInstance, iwResult, null, callbackContext)

            // always fire the events in case there might be subscribers
            document.dispatchEvent(
              new CustomEvent<StreamImagesAsyncEventDetail>(ManagerEvents.StreamImagesAsync, {
                bubbles: true,
                cancelable: true,
                detail: { pluginID, deviceInstance, result: iwResult }
              })
            )
          }
        })
    } catch (e) {
      console.log('Exception in DeviceManager::StreamImagesAsync() : ' + e)
    }
  }

  StreamCaptureEvents(
    pluginID: string,
    deviceInstance: number,
    captureOptions: { [key: string]: string },
    listCurrentImages: IWImage[] | null, // TODO: check if allows null
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      this.hubConnection
        ?.invoke('StreamCaptureEvents', pluginID, deviceInstance, captureOptions, listCurrentImages)
        .then(function (result) {
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err) {
          console.log('connection.StreamCaptureEvents.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document?.dispatchEvent(
            new CustomEvent<StreamCaptureEventsEventDetail>(ManagerEvents.StreamCaptureEvents, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID: pluginID, deviceInstance: deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::StreamCaptureEvents() : ' + e)
    }
  }

  Process(
    pluginID: string,
    deviceInstance: number,
    processAction: string,
    requestedOptions: { [key: string]: string },
    listDataIn: IWData[] | null, // TODO: check if allows null
    callbackMethod?: (
      pluginID: string,
      deviceInstance: number,
      processAction: string,
      iwResult: IWResult,
      resultList: IWData[] | null,
      callbackContext?: any
    ) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: IWData[] | null = null

    try {
      console.log('DeviceManager::Process() entered')

      this.hubConnection
        ?.invoke('Process', pluginID, deviceInstance, processAction, requestedOptions, listDataIn)
        .then(function (list: IWData[] | null) {
          // if successful, we should be getting a list of images
          console.log('connection.Process.then() entered, result list: ' + list)

          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            resultList = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No result data')
          }

          //alert("result: " + result.ErrorCode + ", " + result.ErrorDescription);
        })
        .catch(function (err: Error) {
          console.log('connection.Process.catch() entered, exception: ' + err.toString())
          //alert("error: " + err.toString());
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
          //return console.error(err.toString());
        })
        .finally(() => {
          console.log('connection.Process.finally() entered')

          callbackMethod?.(pluginID, deviceInstance, processAction, iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<ProcessEventDetail>(ManagerEvents.Process, {
              bubbles: true,
              cancelable: true,
              detail: {
                pluginID,
                deviceInstance,
                processAction,
                result: iwResult,
                list: resultList
              }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::Process() : ' + e)
    }
  }

  GetDeviceOptions(
    pluginID: string,
    deviceInstance: number,
    requestedOptions: string[],
    callbackMethod?: (
      pluginID: string,
      deviceInstance: number,
      iwResult: IWResult,
      resultList: Map<string, string> | null,
      callbackContext?: any
    ) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: Map<string, string> | null = null

    try {
      this.hubConnection
        ?.invoke('GetDeviceOptions', pluginID, deviceInstance, requestedOptions)
        .then(function (list) {
          // if successful, we should be getting a dictionary of options and their values as strings
          if (list) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            resultList = new Map(Object.entries(list))
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No result data')
          }
        })
        .catch(function (err) {
          console.log('connection.GetDeviceOptions.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(pluginID, deviceInstance, iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document?.dispatchEvent(
            new CustomEvent<GetDeviceOptionsEventDetail>(ManagerEvents.GetDeviceOptions, {
              bubbles: true,
              cancelable: true,
              detail: {
                pluginID: pluginID,
                deviceInstance: deviceInstance,
                result: iwResult,
                list: resultList
              }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::GetDeviceOptions() : ' + e)
    }
  }

  SetDeviceOptions(
    pluginID: string,
    deviceInstance: number,
    options: Map<string, string>, // if a problem, try using { [key: string]: string } instead of Map
    callbackMethod?: (pluginID: string, deviceInstance: number, iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      this.hubConnection
        ?.invoke('SetDeviceOptions', pluginID, deviceInstance, Object.fromEntries(options))
        .then(function (result) {
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err) {
          console.log('connection.SetDeviceOptions.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(pluginID, deviceInstance, iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document?.dispatchEvent(
            new CustomEvent<SetDeviceOptionsEventDetail>(ManagerEvents.SetDeviceOptions, {
              bubbles: true,
              cancelable: true,
              detail: { pluginID: pluginID, deviceInstance: deviceInstance, result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::SetDeviceOptions() : ' + e)
    }
  }

  GetConfigurationOptions(
    requestedOptions: string[],
    callbackMethod?: (iwResult: IWResult, resultList: Map<string, string> | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let resultList: Map<string, string> | null = null

    try {
      this.hubConnection
        ?.invoke<Record<string, string>>('GetConfigurationOptions', requestedOptions)
        .then(function (list) {
          // if successful, we should be getting a dictionary (map) of options and their values as strings
          if (list) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            resultList = new Map(Object.entries(list))
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No result data')
          }
        })
        .catch(function (err) {
          console.log('connection.GetConfigurationOptions.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(iwResult, resultList, callbackContext)

          // always fire the events in case there might be subscribers
          document?.dispatchEvent(
            new CustomEvent<GetConfigurationOptionsEventDetail>(ManagerEvents.GetConfigurationOptions, {
              bubbles: true,
              cancelable: true,
              detail: {
                result: iwResult,
                list: resultList
              }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::GetConfigurationOptions() : ' + e)
    }
  }

  SetConfigurationOptions(
    options: Map<string, string>, // if a problem, try using { [key: string]: string } instead of Map
    callbackMethod?: (iwResult: IWResult, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult

    try {
      this.hubConnection
        ?.invoke('SetConfigurationOptions', options)
        .then(function (result) {
          iwResult = new IWResult(result.ErrorCode, result.ErrorDescription)
        })
        .catch(function (err) {
          console.log('connection.SetConfigurationOptions.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          callbackMethod?.(iwResult, callbackContext)

          // always fire the events in case there might be subscribers
          document?.dispatchEvent(
            new CustomEvent<SetConfigurationOptionsEventDetail>(ManagerEvents.SetConfigurationOptions, {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult }
            })
          )
        })
    } catch (e) {
      console.log('Exception in DeviceManager::SetConfigurationOptions() : ' + e)
    }
  }

  // TODO: This method is NEVER called
  OnDeviceEvent(deviceEventArgs: DeviceEventArgs) {
    try {
      console.log('DeviceManager::OnDeviceEvent() entered')

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        new CustomEvent<DeviceEventDetail>(ManagerEvents.DeviceEvent, {
          bubbles: true,
          cancelable: true,
          detail: { deviceEventArgs }
        })
      )
    } catch (e) {
      console.log('Exception in DeviceManager::OnDeviceEvent() : ' + e)
    }
  }
}
