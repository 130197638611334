import { Icon, IconButton, Tooltip, TooltipProps } from '@chakra-ui/react'

/**
 * interface TableActionProps contains the main properties to set for the IconButton control
 *
 * To display a tooltip associated with the button, set the tooltip entry.
 * The tooltip entry is an object that expects the keys and values to correspond to the attributes
 * supported by the Chakra-UI Tooltip control (https://chakra-ui.com/docs/components/overlay/tooltip)
 */
export interface TableActionButtonProps {
  onClick: () => void
  isDisabled?: boolean
  ariaLabel: string
  iconAs: any
  buttonSize?: string
  iconSize?: number
  tooltip?: Omit<TooltipProps, 'children'>
}

/**
 * TableActionButton is a method to generate an IconButton control with, optionally, a tooltip.
 *
 * @param props TableActionButtonProps - see the interface description above
 * @returns ReactNode control
 *
 *
 * For the tooltip to display, the 'tooltip' object in the TableActionButtonProps must be set
 *   as well as the 'label' entry inside the 'tooltip' object must be valid. For the list of
 *   supported tooltip attributes, look at the Chakra-UI Tooltip control documentation.
 *   Note that if shouldWrapChildren is not specified by the caller, it would get added and set to true by default
 *
 * For instance:
 *
 *        <TableActionButton
 *           onClick={() => {
 *             history.push(`/bookings/${booking.bookingNum}/info?mode=view`)
 *           }}
 *           ariaLabel="View booking"
 *           iconAs={EyeOutline}
 *           tooltip={{
 *                 label: 'View booking',
 *                 key: `view_tooltip`,
 *                 shouldWrapChildren: false,
 *                 gutter: 10,
 *                 placement: 'top',
 *                 hasArrow: true
 *               }}
 *
 */
export function TableActionButton(props: TableActionButtonProps) {
  const iconSize = props.iconSize || 3
  const hasTooltip: boolean = !!props.tooltip?.label

  const getIconButton = () => {
    return (
      <IconButton
        onClick={props.onClick}
        isDisabled={props.isDisabled}
        aria-label={props.ariaLabel}
        variant="outline"
        color="gray.400"
        borderRadius={4}
        size={props.buttonSize || 'xs'}
        icon={<Icon w={iconSize} h={iconSize} as={props.iconAs} />}
      />
    )
  }

  if (hasTooltip) {
    // if tooltip with valid label specified
    // add any default values, such as shouldWrapChildren=true, before inserting the tooltip props passed
    return (
      <Tooltip shouldWrapChildren {...props.tooltip}>
        {getIconButton()}
      </Tooltip>
    )
  }

  // default
  return getIconButton()
}
