import { JFD, JFL, getAllFieldNames } from '@le2/jfd'
import hotkeys from 'hotkeys-js'
import React, { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSnapshot } from 'valtio'

import { LE2Hotkeys, systemInfoState } from '../../utils/useGetSystemInfo'
import { JFLGroupRender } from './JFLGroupRender'
import { JFDProvider } from './jfd-context'
import { ErrorCountProvider, JFLGroupRefProvider, MapFormValue, jfdResolver, setJFDValue } from './utils'

/*
There's complex dance that's happening between the react-hook-form and JFD.

RHF's claim to fame is managing fields at ref level and not cause expensive re-renders.
Of course, JFD rules can cause fields changes out of band and needs a re-render.

The Form-JFD sync happens as follows:

Step 1. When a form field is changed, Resolver gets called for validation purposes
Step 2. JFD instance is updated with latest form values in the resolver function
Step 3. Rules gets run because of (2) and instance MAY have new computed values
Step 4: Only upon a new set of JFD computed values, force a re-render.
Step 5. In Rerender, newly computed values are updated in the useEffect() hook by
        calling RHF setValue()

Rerender are expensive and makes form input look laggy.  Avoid creating
rules when there are equivalent ones are available in HTML (ex: UpperCase).
*/

export interface JFDFormProps {
  jfd: JFD
  jfl: JFL
  onlyMandatory?: boolean

  readOnly?: boolean

  /**
   * Notes:
   * - Here we don't differentiate between `view` and `edit`.
   * - We use this prop to change the behavior in `JFLMultiOccurrence` a bit
   */
  action?: 'create' | 'view'

  /**
   * Maps to `FeaturesConfig.bookings.edit`.
   *
   * When `false` we don't render the _is required_ indicator (i.e. the red
   * asterisk) next to each field's label.
   */
  editable?: boolean

  /**
   * Optional. Only used when the given JFL has a group with type `explicitMultipleOccurrenceItem`.
   */
  index?: number

  /**
   * Optional. Custom map for Form values.
   */
  mapFormValue?: MapFormValue

  /**
   * Optional. If we should call RHF's `trigger` function. This function highlights the
   * errors in the form.
   */
  shouldTriggerValidation?: boolean

  /**
   * A function that can be called to get the number of errors for a given JFLGroup.
   * When this function returns a value greater than 0 we show a "XX missing fields"
   * tab next to the group header.
   */
  errorCountProvider?: ErrorCountProvider

  /**
   * A map-like object to use to store the HTML DOM element for all `<GroupContainer>`s.
   * We set them via a callback ref in `JFLGroupRender.tsx`.
   *
   * Note that we render one `<GroupContainer>` for each `JFLGroup` in `JFLGroupRender.tx`
   * so, one of these HTML DOM elements roughly map to one JFLGroup.
   *
   * See https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
   */
  jflGroupRefProvider?: JFLGroupRefProvider
}

export default function JFDForm({
  jfd,
  jfl,
  onlyMandatory,
  readOnly,
  action,
  editable,
  index,
  mapFormValue,
  shouldTriggerValidation,
  errorCountProvider,
  jflGroupRefProvider
}: JFDFormProps) {
  // trick function when called forces a re-render
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0)

  const methods = useForm<any>({
    mode: 'onChange',
    resolver: jfdResolver(jfd, forceUpdate, mapFormValue)
  })

  useEffect(() => {
    jfd.getFields().forEach(setJFDValue(methods.setValue))
  })

  const { trigger } = methods
  useEffect(() => {
    if (shouldTriggerValidation) {
      trigger()
    }
  }, [shouldTriggerValidation, trigger])

  // Register hotkey to fill mock data
  useFillMockHotKey(jfd, forceUpdate, jfl)

  return (
    <JFDProvider
      value={{
        jfd,
        onlyMandatory: onlyMandatory || false,
        readOnly: readOnly || false,
        action: action,
        editable: editable === undefined ? true : editable,
        forceUpdate: forceUpdate,
        errorCountProvider: errorCountProvider,
        jflGroupRefProvider: jflGroupRefProvider
      }}
    >
      <FormProvider {...methods}>
        <form autoComplete="off">
          {jfl.groups.map((group, idx) => (
            <JFLGroupRender key={idx} group={group} index={index} />
          ))}
        </form>
      </FormProvider>
    </JFDProvider>
  )
}

function useFillMockHotKey(jfd: JFD, forceUpdate: () => void, jfl: JFL) {
  const systemInfoSnap = useSnapshot(systemInfoState)

  useEffect(() => {
    const hotkey = LE2Hotkeys.FillMockData

    if (systemInfoSnap.isHotkeyAvailable(hotkey)) {
      hotkeys(hotkey, function (event) {
        // Prevent the default action
        event.preventDefault()

        // Get JFL field names
        const jflFields = getAllFieldNames(jfl).map(({ name }) => name)

        // Fill-in mock data
        jfd.fillInMockData(jflFields)

        // Update this component
        forceUpdate()
      })
    }

    // Unbind hotkeys so they don't get re-bound on next useEffect run
    return () => {
      hotkeys.unbind(hotkey)
    }
  }, [jfd, jfl, forceUpdate, systemInfoSnap])
}
