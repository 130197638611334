import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Spacer,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure
} from '@chakra-ui/react'
import { GroupUserRelation, LE2UsersType } from '@le2/common-types'
import { ExclamationCircleOutline, InformationCircleOutline, SearchOutline, TrashOutline } from 'heroicons-react'
import { Fragment, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useSnapshot } from 'valtio'

import useFetchUsers from '../hooks/useFetchUsers'
import { groupState } from '../utils'
import { FormRefI } from './GroupUpsertDialog'

interface UsersSelectionProps {
  formRef: React.RefObject<FormRefI>
}

export default function UsersSelection({ formRef }: UsersSelectionProps) {
  const { users: usersSnap, operation: operationSnap } = useSnapshot(groupState)
  const [errorMessage, setErrorMessage] = useState<string | undefined>('')

  function clearUser(index: number) {
    const { users } = groupState

    users.splice(index, 1)

    groupState.users = [...users]
  }

  function clearAll() {
    groupState.users = []
  }

  useImperativeHandle(formRef, () => ({
    // Returns if the form is valid.
    submit() {
      return new Promise((resolve) => {
        if (usersSnap.length === 0 && operationSnap !== 'edit') {
          setErrorMessage('The group requires a minimum of one user')
          resolve(false)
        } else {
          resolve(true)
        }
      })
    }
  }))

  return (
    <Box>
      <Heading as="h5" size="sm" mb={3}>
        Add Users
      </Heading>
      <UserSelector value={usersSnap} onChange={(users) => (groupState.users = [...users])} />

      {errorMessage && (
        <Flex my={3}>
          <Icon w={6} h={6} color="red.500" as={ExclamationCircleOutline} />
          <Text ml={1} color="red.400">
            {errorMessage}
          </Text>
        </Flex>
      )}
      <Flex my={5}>
        <Heading alignSelf="center" as="h5" size="sm">
          Selected Users
        </Heading>
        <Spacer />
        {usersSnap.length !== 0 && (
          <Box>
            <Button
              onClick={clearAll}
              alignSelf="center"
              variant="outline"
              bgColor="gray.200"
              color="gray.500"
              size="sm"
            >
              Remove all
            </Button>
          </Box>
        )}
      </Flex>

      <Table size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Username</Th>
            <Th>Department</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {usersSnap.map((user, index) => (
            <Tr mb={5} key={index}>
              <UserInfo user={user} />

              <Td>
                <IconButton
                  color="gray.400"
                  size="xs"
                  variant="outline"
                  data-cy={`delete-user-${user.username}`}
                  aria-label={`delete-user-${index}`}
                  onClick={() => clearUser(index)}
                  icon={<Icon w={4} h={4} as={TrashOutline} />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {usersSnap.length === 0 && (
        <Flex alignItems="center" px="220px" py="120px">
          <InformationCircleOutline color="gray" />{' '}
          <Text color="gray.500" ml={2}>
            No users selected
          </Text>
        </Flex>
      )}
    </Box>
  )
}

interface UserInfoProps {
  user: LE2UsersType
}

function UserInfo({ user }: UserInfoProps) {
  const { firstName, lastName, email, username, department } = user

  return (
    <Fragment>
      <Td>
        <Tooltip label={`name: ${firstName} ${lastName}`}>
          <Text minWidth="130px" maxWidth="130px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {firstName} {lastName}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={`email: ${email}`}>
          <Text minWidth="120px" maxWidth="120px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {email}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={`username: ${username}`}>
          <Text minWidth="90px" maxW="90px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {username}
          </Text>
        </Tooltip>
      </Td>

      <Td>
        {department ? (
          <Tooltip label={`deparment: ${department}`}>
            <Text>{department}</Text>
          </Tooltip>
        ) : (
          <Text>No department</Text>
        )}
      </Td>
    </Fragment>
  )
}

interface UserSelectorProps {
  onChange: (users: GroupUserRelation[]) => void
  value: Readonly<GroupUserRelation[]>
}

interface UserSelectorOptionType {
  user: LE2UsersType
  selected: boolean
}

interface ResultGroup {
  label?: string
  users: LE2UsersType[]
}

function UserSelector({ onChange, value }: UserSelectorProps) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [results, setResults] = useState<ResultGroup[]>([])

  const fetch = useFetchUsers()

  useEffect(() => {
    const init = async () => {
      const users = await fetch('')

      setResults([
        {
          users
        }
      ])
    }

    init()
  }, [fetch])

  async function handleSearch(search: string) {
    try {
      setIsLoading(true)
      const users = await fetch(search)
      if (search === '') {
        setResults([
          {
            users
          }
        ])
      } else {
        setResults(groupUsersByDepartment(users, search))
      }
    } catch (err) {
      // TODO the app should display a warning alert.
      if (err instanceof Error) {
        console.warn(err.message)
        throw err.message
      }

      throw err
    } finally {
      setIsLoading(false)
    }
  }

  function toggleCheck(user: LE2UsersType, isChecked: boolean) {
    const selected = [...value]

    if (isChecked) {
      selected.push({
        ...user,
        addedAt: new Date()
      })
    } else {
      const index = selected.findIndex((option) => user.id === option.id)

      if (index >= 0) {
        selected.splice(index, 1)
      }
    }

    onChange(selected)
  }

  // TODO Still requires the modification for the unselect all users from a group.
  function toggleSelectAll(isCheckedAll: boolean, users: LE2UsersType[]) {
    if (isCheckedAll) {
      const selected = [...value]
      users.forEach((user) => {
        const index = value.findIndex((option) => user.id === option.id)

        selected.splice(index, 1)
      }, [] as GroupUserRelation[])

      onChange(selected)
    } else {
      const selectedIds: number[] = value.map(({ id }) => id)
      const newUsers = users.reduce((users, user) => {
        if (!selectedIds.includes(user.id)) {
          users.push({
            ...user,
            addedAt: new Date()
          })
        }

        return users
      }, [] as GroupUserRelation[])

      const newSelected = [...value, ...newUsers]

      onChange(newSelected)
    }
  }

  return (
    <Popover closeOnEsc={true} autoFocus={false} isOpen={isOpen} placement={'bottom-start'}>
      <PopoverTrigger>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchOutline color="gray.700" size={14} />} />
          <Input
            data-cy="add-users"
            placeholder="Search"
            onClick={(e) => {
              e.preventDefault()
              onOpen()
            }}
            onChange={(e) => handleSearch(e.currentTarget.value)}
          />
        </InputGroup>
      </PopoverTrigger>
      <Flex>
        <Box zIndex="popover">
          <PopoverContent w={650} maxH={560} overflow="auto" _focus={{ outline: 'none', boxShadow: 'none' }}>
            <PopoverBody>
              {results.map((result, key) => (
                <UserOptionsTable
                  selected={value}
                  key={key}
                  header={result.label}
                  options={result.users}
                  isLoading={isLoading}
                  onToggleCheck={toggleCheck}
                  onToggleSelectAll={toggleSelectAll}
                />
              ))}
            </PopoverBody>
            <PopoverFooter display="flex" alignItems="center" justifyContent="end" pb={4}>
              <Button size="sm" variant="outline" onClick={onClose}>
                Close
              </Button>
            </PopoverFooter>
          </PopoverContent>
        </Box>
      </Flex>
    </Popover>
  )
}

interface UserOptionsTableProps {
  header?: string
  selected: readonly GroupUserRelation[]
  options: LE2UsersType[]
  isLoading: boolean
  onToggleCheck: (user: LE2UsersType, isChecked: boolean) => void
  onToggleSelectAll: (isCheckAll: boolean, users: LE2UsersType[]) => void
}

function UserOptionsTable({
  header,
  options,
  isLoading,
  selected,
  onToggleCheck,
  onToggleSelectAll
}: UserOptionsTableProps) {
  const predicate = useCallback((user: LE2UsersType) => user.id, [])
  const selectedIds = selected.map((item) => predicate(item))

  const allChecked = useMemo(() => {
    return options.every((user) => selectedIds.includes(predicate(user)))
  }, [selectedIds, options, predicate])

  const mapedOptions = useMemo<UserSelectorOptionType[]>(() => {
    return options.map((user) => ({
      user,
      selected: selectedIds.includes(predicate(user))
    }))
  }, [selectedIds, options, predicate])

  function handleToggleSelectAll() {
    onToggleSelectAll(allChecked, options)
  }

  return (
    <>
      {header && (
        <Heading my={3} as={'h3'} fontWeight="700" fontSize={16}>
          {header}
        </Heading>
      )}
      {options.length > 1 && (
        <Box px={4} py={2}>
          <Checkbox fontSize={14} fontWeight={'500'} isChecked={allChecked} onChange={() => handleToggleSelectAll()}>
            Select all
          </Checkbox>
        </Box>
      )}
      <Table size="sm">
        {!isLoading && options.length === 0 && <TableCaption>No users results</TableCaption>}
        {isLoading && <TableCaption>Loading results</TableCaption>}
        <Thead bgColor="#F7F8FC">
          <Tr fontWeight={700} fontSize={12}>
            <Th>Name</Th>
            <Th>Email</Th>
            <Th>Username</Th>
            <Th>Department</Th>
          </Tr>
        </Thead>
        <Tbody>
          {!isLoading && (
            <>
              {mapedOptions.map((option, key) => (
                <UserOptionRow option={option} key={key} onToggleCheck={onToggleCheck} />
              ))}
            </>
          )}
        </Tbody>
      </Table>
    </>
  )
}

interface SelectOptionRowProps {
  option: UserSelectorOptionType
  onToggleCheck: (user: LE2UsersType, isChecked: boolean) => void
}

function UserOptionRow({ option, onToggleCheck }: SelectOptionRowProps) {
  const { firstName, lastName, email, username, department } = option.user

  return (
    <Tr>
      <Td data-cy="userSelectionColumn">
        <HStack>
          <Checkbox
            data-cy={`add-user-${username}`}
            isChecked={option.selected}
            onChange={(e) => onToggleCheck(option.user, e.currentTarget.checked)}
          />
          <Tooltip label={`${firstName} ${lastName}`}>
            <Text
              fontWeight="700"
              fontSize={12}
              minWidth="130px"
              maxWidth="130px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {firstName} {lastName}
            </Text>
          </Tooltip>
        </HStack>
      </Td>
      <Td>
        <Tooltip label={email}>
          <Text minWidth="120px" maxWidth="120px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {email}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        <Tooltip label={username}>
          <Text minWidth="90px" maxW="90px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
            {username}
          </Text>
        </Tooltip>
      </Td>
      <Td>
        {department ? (
          <Tooltip label={department}>
            <Text>{department}</Text>
          </Tooltip>
        ) : (
          <Text>No department</Text>
        )}
      </Td>
    </Tr>
  )
}

/**
 * Handles the department grouping if satisfies the requirements.
 */
function groupUsersByDepartment(users: LE2UsersType[], searchCriteria: string): ResultGroup[] {
  // Separate the fragments of the search criteria.
  const fragments = searchCriteria.toLowerCase().split(' ')

  const predicate = (user: LE2UsersType) => user.department

  // Verify if an user's department matches with the search criteria.
  const canDoGrouping =
    users.filter((user) => fragments.some((frag) => predicate(user)?.toLowerCase().includes(frag))).length > 0

  // Does the grouping if there is a match with one or more result's department.
  if (canDoGrouping) {
    return users.reduce((results, user) => {
      const departmentKey = predicate(user)

      const group = results.find((group) => group.label === departmentKey)

      if (group) {
        group.users.push(user)
      } else {
        results.push({
          label: departmentKey,
          users: [user]
        })
      }

      return results
    }, [] as ResultGroup[])
  } else {
    // Continue with the regular grouping of all results
    return [
      {
        users: users
      }
    ]
  }
}
