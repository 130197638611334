import { Checkbox, FormControl, FormLabel, useTheme } from '@chakra-ui/react'
import { getColor, transparentize } from '@chakra-ui/theme-tools'
import { JFDField, JFLFieldGroupDef, OPTION_NO, OPTION_YES } from '@le2/jfd'
import uniqueId from 'lodash/uniqueId'
import { useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Select, { OptionProps, Styles, components } from 'react-select'

import ReactSelectControl from '../ReactSelectControl'
import { useJFD } from './jfd-context'

export const CHECKBOX_ARRAY_IGNORE = 'CHECKBOX_ARRAY_IGNORE_'

export interface CheckboxArrayProps {
  jflDef: JFLFieldGroupDef
  index?: number
}

type OptionsType = ReadonlyArray<OptionType>

interface OptionType {
  value: string
  label: string
  jfdField: JFDField
}

/**
 * Renders a <Select> from react-select in which each option is rendered as a
 * checkbox. Each checkbox represents a single JFD field with options set to
 * JFD's OPTIONS_YES_NO.
 *
 * Sample JFD
 * ```
 * {
 *   fields: {
 *     IsMinor: {
 *       options: OPTIONS_YES_NO
 *     },
 *     IsVulnerableAdult: {
 *       options: OPTIONS_YES_NO
 *     }
 *   }
 * }
 * ```
 *
 * Sample JFL:
 * ```
 * {
 *   groups: [
 *     {
 *       label: 'Test',
 *       type: 'singleFields',
 *       fields: [
 *         {
 *           fields: [
 *             'IsMinor',
 *             'IsVulnerableAdult',
 *           ],
 *           control: 'checkboxArray'
 *         }
 *       ]
 *     }
 *   ]
 * }
 * ```
 */
export function CheckboxArray(props: CheckboxArrayProps) {
  // The JFD integration is like this:
  //
  // The current state (i.e. the selected options) is given by the current
  // JFD values (see `useOptions`). We then use the `onChange` prop in the
  // <Select> to pass the selection from react-select to JFD.
  //
  // Note that the previous logic doesn't interact with react-hook-form. For
  // that we use <Controller> to register a "virtual" field with a dynamic name.
  // We do this so that we can use the `onChange` parameter passed to the
  // `render` prop. We call it each time, which triggers RHF's validation logic
  // and then our resolver is called. In that moment the related rules/errors
  // are executed.
  //
  // Extra notes:
  // - these "virtual" fields are ignored when passing data from RHF to JFD
  //   see `getJFDValues`
  // - the checkboxes are not handled by RHF (i.e. we don't pass ref={register()})
  //   because they are rendered only when the <Select> menu is opened. This
  //   means that the values are not included in the resolver values.

  const name = useRef(uniqueId(CHECKBOX_ARRAY_IGNORE))
  const { control } = useFormContext()
  const { options, selected, setSelected } = useOptions(props.jflDef, props.index)
  const styles = useStyles()
  const { readOnly } = useJFD()

  // ToDo error messages?

  return (
    <>
      <FormControl id={name.current} mb={6}>
        <FormLabel textStyle="details" color="text.details">
          {props.jflDef.label}
        </FormLabel>
        <Controller
          name={name.current}
          control={control}
          defaultValue=""
          render={({ onChange }) => (
            <Select
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              options={options}
              value={selected}
              onChange={(selected: OptionsType) => {
                setSelected(selected)
                onChange('')
              }}
              styles={styles}
              components={{
                Option,
                Control: ReactSelectControl
              }}
              inputId={name.current}
              isDisabled={readOnly}
            />
          )}
        />
      </FormControl>
    </>
  )
}

function useOptions(jflDef: JFLFieldGroupDef, index?: number) {
  // map the fields defined in jflDef to an object { label, value, jfdField }
  // we use these options for the <Select>
  const { jfd } = useJFD()
  const options: OptionsType = jflDef.field.map((fieldName) => {
    const jfdField = jfd.getField(fieldName, index)
    return {
      label: jfdField.label,
      value: fieldName,
      jfdField: jfdField,
      isDisabled: jfdField.readOnly
    }
  })

  // current selection, i.e. those with jfdField.value == OPTION_YES
  const selected = options.filter((opt) => opt.jfdField.value === OPTION_YES[0])

  // pass values from react-select to JFD
  function setSelected(selected: OptionsType) {
    options.forEach((opt) => {
      if (selected.includes(opt)) {
        opt.jfdField.value = OPTION_YES[0]
      } else {
        opt.jfdField.value = OPTION_NO[0]
      }
    })
  }

  return { options, selected, setSelected }
}

function useStyles() {
  const theme = useTheme()

  return {
    multiValueLabel: (provided) => ({
      ...provided,
      color: getColor(theme, 'primary'),
      fontWeight: 500,
      fontSize: '12px'
    }),
    multiValue: (provided) => ({
      ...provided,
      color: getColor(theme, 'primary'),
      backgroundColor: transparentize('primary', 0.1)(theme)
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? getColor(theme, 'gray.100') : 'transparent',
      ':active': {
        backgroundColor: getColor(theme, 'gray.200')
      }
    })
  } as Styles<OptionType, true>
}

function Option(props: OptionProps<OptionType, true>) {
  return (
    <components.Option {...props}>
      <Checkbox
        color="gray.700"
        textStyle="paragraph"
        isChecked={props.isSelected}
        isDisabled={props.data.jfdField.readOnly}
        onChange={() => null}
      >
        {props.label}
      </Checkbox>
    </components.Option>
  )
}
