import {
  AspectRatio,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import { CameraOutline } from 'heroicons-react'
import { Fragment, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Measure, { ContentRect } from 'react-measure'
import Webcam from 'react-webcam'

import { useKeyDownListener, useKeyUpListener } from '../hooks/useKeyListener'
import { useRotationStyle } from '../hooks/useRotationStyle'
import { ThemeModeEnum, useThemeMode } from '../hooks/useThemeMode'
import { BioType } from '../lib/api/booking'
import { CameraCommandOption, DeviceOptions } from '../nativeExtension/IWNativeExtDeviceDefines'
import { ptzCameraConfiguration } from '../nativeExtension/configuration'
import { DeviceManagerProps } from '../nativeExtension/hooks'
import {
  cameraCommandsPanTiltPositionMove,
  defaultCameraCommandOptionsMaxSpeed,
  getCameraStopCommandForCommand,
  mapKeyboardKeyToCameraCommandName
} from '../nativeExtension/hooks/utils'
import { getFileData } from '../utils/getFileData'
import { ImageGuidelinesPaintingCanvas } from './ImageGuidelinesPaintingCanvas'
import PanTiltControlWheel from './PanTiltControlWheel'

export const videoConstraints = {
  width: { min: 480, max: 3840 },
  height: { min: 480, ideal: 720, max: 2160 },
  facingMode: 'user'
}

export const cropConstraints = { minWidth: 480, minHeight: 600 }

export interface TakePhotoModalProps {
  webcamRef: RefObject<Webcam>
  canvasRef?: RefObject<HTMLCanvasElement>
  onDismiss: () => void
  goToEditScreen: (imageRotationdAngle: number, imageSrc?: string | null) => void
  goToTakePhotoScreen?: () => void
  bioType?: BioType
  deviceManagerProps: DeviceManagerProps
  finishButtonText?: string
  themeMode?: ThemeModeEnum
  prompt?: string
  showUploadPhotoButton?: boolean
  cameraScreenshotQuality?: number
  cameraImageSmoothing?: boolean
  displayImageGuidelines?: boolean
  isHidden?: boolean
}

export interface PTZCommandInfo {
  commandName: string
  commandSpeedTimeoutId: null | NodeJS.Timeout
}

export default function TakePhotoModalContents({
  webcamRef,
  onDismiss,
  goToEditScreen,
  deviceManagerProps,
  themeMode,
  prompt,
  showUploadPhotoButton,
  cameraScreenshotQuality,
  cameraImageSmoothing,
  displayImageGuidelines,
  isHidden = false
}: TakePhotoModalProps) {
  const [webcamAccessible, setWebcamAccessible] = useState(true)
  const [videoLoading, setVideoLoading] = useState(true)
  const [videoSize, setVideoSize] = useState({ width: 0, height: 0 })
  const { rotationAngle, rotationStyle, setRotateImageStyle } = useRotationStyle()

  const { variantColor } = useThemeMode(themeMode)

  // set the initial state
  const [executingPTZCommand, setExecutingPTZCommand] = useState<PTZCommandInfo | null>(null)

  const parentRef = useRef<HTMLDivElement>(null)

  // use this to keep track of key commands in order to ignore repeated calls if one keeps pressing a key down
  const [lastKeyCommand, setLastKeyCommand] = useState('')

  useEffect(() => {
    // do any initializations here

    return () => {
      // This function should invoke at the time of component's unmount.
      // Do any cleanup/stop of any ongoing PTZ commands or timers
      console.debug('TakePhotoModalContents closing')
      // check for an executing PTZ command and cancel it
      try {
        // clean up any executing PTZ command
        cleanUpExecutingPTZCommand()
      } catch (ex) {
        console.error(`TakePhotoModalContents, useEffect, return exception: ${ex}`)
      }
    }
    // ignore the dependencies, we want to call `handleClose` once as it does clean up
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function handleNoWebcam(error: string | DOMException) {
    setWebcamAccessible(false)
  }

  function handleUserMedia() {
    setVideoLoading(false)
  }

  function tryAgain() {
    // re-renders, prompting the webcam to try to connect again
    setWebcamAccessible(true)
  }

  const onResize = useCallback(
    (rect: ContentRect) => {
      // Check that the entry from the ResizeObserver is available.
      // The entry is an object containing the new size of the observed element when the callback is run.
      if (!rect.entry) {
        return
      }

      console.debug(`onResize() entered, rect: ${JSON.stringify(rect)}`)

      const width = (90 === rotationAngle || 270 === rotationAngle ? rect.entry.height : rect.entry.width) || 0
      const height = (90 === rotationAngle || 270 === rotationAngle ? rect.entry.width : rect.entry.height) || 0

      // resize the parent container
      const container = parentRef.current
      if (container) {
        container.style.height = `${height}px`
      } else {
        console.debug(`onResize(), parent is invalid`)
      }

      console.debug(`onResize() final, width: ${width}, height: ${height}`)

      setVideoSize({ width: width, height: height })
    },
    [rotationAngle]
  )

  async function uploadFile() {
    const srcData = await getFileData('image/*')

    if (srcData) {
      goToEditScreen(0, srcData)
    }
  }

  const commandOptions: Map<string, string> = useMemo(() => {
    return new Map<string, string>([
      [
        CameraCommandOption[CameraCommandOption.StepCount],
        false === ptzCameraConfiguration.useDiscreteCommandMode ? '0' : '1'
      ]
    ])
  }, [])

  const cmdSettings = JSON.stringify(Object.fromEntries(commandOptions))

  const adjustPtzMoveCommand = useCallback(
    (commandName: string) => {
      try {
        console.debug(`Adjust PTZ move command: ${commandName}, rotationAngle: ${rotationAngle}`)
        // check the rotation angle
        if (0 < rotationAngle) {
          const steps: number = (rotationAngle / 90) % 4
          // find the index of the desired command
          const oldCommand: DeviceOptions = DeviceOptions[commandName as keyof typeof DeviceOptions]
          const idxOld: number = cameraCommandsPanTiltPositionMove.indexOf(oldCommand)
          if (-1 < idxOld) {
            let idxNew: number = idxOld - 2 * steps // double the steps to account for the diagonal commands

            // Commenting out the line below and replacing it with the if-statement and adjustments of the index calculation
            // since the targeted TypeScript version doesn't support the Array.at() method.
            // To enable the at() method we would need to set "target": "es2022" or "esnext" in the compiler options in tsconfig.json
            //const newCommand: DeviceOptions | undefined = cameraCommandsPanTiltPositionMove.at(idxNew)

            if (0 > idxNew) {
              idxNew = idxNew + cameraCommandsPanTiltPositionMove.length
            }
            console.debug(
              `Adjust PTZ move command: ${commandName}, rotationAngle: ${rotationAngle}, steps: ${steps}, old command index: ${idxOld}, new command index: ${idxNew}`
            )
            if (0 <= idxNew && idxNew < cameraCommandsPanTiltPositionMove.length) {
              // now move as many steps counterclockwise as necessary
              const newCommand: DeviceOptions | undefined = cameraCommandsPanTiltPositionMove[idxNew]
              if (newCommand) {
                console.debug(
                  `Adjust PTZ move command: ${commandName}, rotationAngle: ${rotationAngle}, new command: ${DeviceOptions[newCommand]}`
                )
                return DeviceOptions[newCommand]
              }
            }
          }
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, adjustPtzMoveCommand, exception: ${ex}`)
      }
      return commandName
    },
    [rotationAngle]
  )

  const clearExecutingPTZCommandTimeout = useCallback(
    (commandName: string) => {
      try {
        console.debug(
          `clearExecutingPTZCommandTimeout entered, command: ${commandName}, executing commandName: ${executingPTZCommand?.commandName}`
        )
        // Matters only if we have something in the array
        if (null === executingPTZCommand) {
          console.debug(`clearExecutingPTZCommandTimeout: ${commandName}, no executing command`)
          return
        }

        // See if command already in list and clear its timer if needed
        // If we diligently maintain the state, we shouldn't have more than one instance of the same command name present.
        if (executingPTZCommand.commandName === commandName) {
          try {
            if (null !== executingPTZCommand.commandSpeedTimeoutId) {
              clearTimeout(executingPTZCommand.commandSpeedTimeoutId)
              executingPTZCommand.commandSpeedTimeoutId = null
            }
          } catch (ex) {
            console.error(`TakePhotoModalContents, clearExecutingPTZCommandTimeout, clear exception: ${ex}`)
          }
          // update state
          setExecutingPTZCommand({ commandName: commandName, commandSpeedTimeoutId: null })
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, clearExecutingPTZCommandTimeout, exception: ${ex}`)
      }
    },
    [executingPTZCommand]
  )

  const cleanUpExecutingPTZCommand = useCallback(() => {
    try {
      console.debug(`cleanUpExecutingPTZCommand entered, executing commandName: ${executingPTZCommand?.commandName}`)
      // Matters only if we have something in the array
      if (null === executingPTZCommand) {
        console.debug(`cleanUpExecutingPTZCommand, no executing command`)
        return
      }

      // Clear the timer if needed.
      // Send the respective stop command.
      // Use getCameraStopCommandForCommand to get the respective command

      // we should check for any running timers and stop them
      // clear any Timeout
      try {
        if (null !== executingPTZCommand.commandSpeedTimeoutId) {
          clearTimeout(executingPTZCommand.commandSpeedTimeoutId)
          executingPTZCommand.commandSpeedTimeoutId = null
        }
      } catch (ex) {
        console.error(
          `TakePhotoModalContents, cleanUpExecutingPTZCommand, clear Timeout for command: ${executingPTZCommand.commandName}, exception: ${ex}`
        )
      }

      // send the stop command
      if (deviceManagerProps?.isDeviceOpen) {
        try {
          if (executingPTZCommand.commandName) {
            console.debug(
              `TakePhotoModalContents, cleanUpExecutingPTZCommand, Send PTZ move stop command for: ${executingPTZCommand.commandName}`
            )
            const commandName: DeviceOptions =
              DeviceOptions[executingPTZCommand.commandName as keyof typeof DeviceOptions]
            if (commandName) {
              console.debug(
                `TakePhotoModalContents, cleanUpExecutingPTZCommand, commandName is: ${DeviceOptions[commandName]}`
              )
              const stopCommand: DeviceOptions | null = getCameraStopCommandForCommand(commandName)
              if (stopCommand) {
                console.debug(
                  `TakePhotoModalContents, cleanUpExecutingPTZCommand, commandName: ${DeviceOptions[commandName]}, stop command is: ${DeviceOptions[stopCommand]}`
                )
                const controlOptions: Map<string, string> = new Map<string, string>()
                controlOptions.set(DeviceOptions[stopCommand], '{}')
                deviceManagerProps.sendDeviceCommand(controlOptions)
              } else {
                console.error(
                  `TakePhotoModalContents, cleanUpExecutingPTZCommand, could not find stop command for: ${DeviceOptions[commandName]}`
                )
              }
            } else {
              console.error(
                `TakePhotoModalContents, cleanUpExecutingPTZCommand, could not validate command name: ${executingPTZCommand.commandName}`
              )
            }
          }
        } catch (ex) {
          console.error(
            `TakePhotoModalContents, cleanUpExecutingPTZCommand, send stop for command: ${executingPTZCommand.commandName}, exception: ${ex}`
          )
        }
      }

      // clear the list
      setExecutingPTZCommand(null)
    } catch (ex) {
      console.error(`TakePhotoModalContents, removeExecutingPTZCommand, exception: ${ex}`)
    }
  }, [deviceManagerProps, executingPTZCommand])

  const ptzSpeedUp = useCallback(
    (command: any) => {
      try {
        console.debug(
          `ptzSpeedUp entered, command: ${command as string}, executing commandName: ${
            executingPTZCommand?.commandName
          }`
        )

        const commandName: DeviceOptions = DeviceOptions[command as keyof typeof DeviceOptions]

        if (!commandName) {
          console.debug(`ptzSpeedUp, command: ${command} is invalid, commandName: ${commandName}`)
        }

        // clear the Timeout
        clearExecutingPTZCommandTimeout(DeviceOptions[commandName])

        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send PTZ speed up command for: ${DeviceOptions[commandName]}`)

          // set the speed to max
          const cmdOptions: Map<string, string> = defaultCameraCommandOptionsMaxSpeed
          const controlCommand: Map<string, string> = new Map<string, string>()
          controlCommand.set(DeviceOptions[commandName], JSON.stringify(Object.fromEntries(cmdOptions)))
          deviceManagerProps.sendDeviceCommand(controlCommand)
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, ptzSpeedUp, exception: ${ex}`)
      }
    },
    [clearExecutingPTZCommandTimeout, deviceManagerProps, executingPTZCommand?.commandName]
  )

  // add the command to the list of executing commands and start the speed-up timer of 1 sec
  const addExecutingPTZCommand = useCallback(
    (commandName: string) => {
      try {
        console.debug(
          `TakePhotoModalContents, addExecutingPTZCommand entered, commandName: ${commandName}, executing command: ${executingPTZCommand?.commandName}`
        )

        // If command already in list, we're good
        if (null !== executingPTZCommand) {
          if (commandName === executingPTZCommand.commandName) {
            return
          } else {
            // if different command is going on, we need to clear it
            cleanUpExecutingPTZCommand()
          }
        }

        const newCommand: PTZCommandInfo = {
          commandName: commandName,
          commandSpeedTimeoutId: setTimeout(ptzSpeedUp, 1000, commandName)
        }

        // update the state to the changed list
        setExecutingPTZCommand(newCommand)
      } catch (ex) {
        console.error(`TakePhotoModalContents, addExecutingPTZCommand, exception: ${ex}`)
      }
    },
    [cleanUpExecutingPTZCommand, executingPTZCommand, ptzSpeedUp]
  )

  const removeExecutingPTZCommand = useCallback(
    (commandName: string) => {
      try {
        console.debug(
          `removeExecutingPTZCommand entered, command: ${commandName}, executing commandName: ${executingPTZCommand?.commandName}`
        )

        // Matters only if we have something going on
        if (null === executingPTZCommand) {
          console.debug(`removeExecutingPTZCommand: ${commandName}, no executing command`)
          return
        }

        if (commandName !== executingPTZCommand.commandName) {
          console.debug(
            `removeExecutingPTZCommand: ${commandName}, is not the executing command (${executingPTZCommand.commandName})`
          )
          return
        }

        if (null !== executingPTZCommand.commandSpeedTimeoutId) {
          clearTimeout(executingPTZCommand.commandSpeedTimeoutId)
          executingPTZCommand.commandSpeedTimeoutId = null
        }

        // update the state
        setExecutingPTZCommand(null)
      } catch (ex) {
        console.error(`TakePhotoModalContents, removeExecutingPTZCommand, exception: ${ex}`)
      }
    },
    [executingPTZCommand]
  )

  // This is just a plain sending of a PTZ command. Intended to be used by the Home command
  const onSendPtzCommand = useCallback(
    (commandName: string) => {
      try {
        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send PTZcommand entered: ${commandName}`)
          const controlOptions: Map<string, string> = new Map<string, string>()

          controlOptions.set(commandName, cmdSettings)
          deviceManagerProps.sendDeviceCommand(controlOptions)
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onSendPtzCommand, exception: ${ex}`)
      }
    },
    [cmdSettings, deviceManagerProps]
  )

  const onSendPtzMoveCommand = useCallback(
    (commandName: string) => {
      try {
        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send PTZ move command entered: ${commandName}`)
          const controlOptions: Map<string, string> = new Map<string, string>()

          const executeCommand: string = adjustPtzMoveCommand(commandName)
          console.debug(
            `Send PTZ move command: ${commandName}, adjusted to: ${executeCommand}, rotation angle: ${rotationAngle}`
          )

          controlOptions.set(executeCommand, cmdSettings)
          console.debug(`move command content: ${JSON.stringify(Object.fromEntries(controlOptions))}`)
          deviceManagerProps.sendDeviceCommand(controlOptions)
          // add the command to the list that tracks whether we should speed it up
          addExecutingPTZCommand(executeCommand)
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onSendPtzMoveCommand, exception: ${ex}`)
      }
    },
    [addExecutingPTZCommand, adjustPtzMoveCommand, cmdSettings, deviceManagerProps, rotationAngle]
  )

  const onSendZoomCommand = useCallback(
    (isZoomIn: boolean, forceDiscrete?: boolean) => {
      // this command could be forced to execute as discrete (always when using the wheel)
      try {
        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send Zoom command, isZoomIn: ${isZoomIn}`)
          const controlOptions: Map<string, string> = new Map<string, string>()
          const tempOptions: Map<string, string> = new Map<string, string>(commandOptions)

          if (true === forceDiscrete) {
            // we have to ensure that the step is at least 1
            tempOptions.set(
              CameraCommandOption[CameraCommandOption.StepCount],
              undefined !== ptzCameraConfiguration.stepCount && 0 < ptzCameraConfiguration.stepCount
                ? ptzCameraConfiguration.stepCount.toString()
                : '1'
            )
          }
          const commandName: string = isZoomIn
            ? DeviceOptions[DeviceOptions.CameraZoomIn]
            : DeviceOptions[DeviceOptions.CameraZoomOut]
          controlOptions.set(commandName, JSON.stringify(Object.fromEntries(tempOptions)))
          console.debug(`zoom command content: ${JSON.stringify(Object.fromEntries(controlOptions))}`)
          deviceManagerProps.sendDeviceCommand(controlOptions)
          // add the command to the list that tracks whether we should speed it up
          if (!forceDiscrete) {
            addExecutingPTZCommand(commandName)
          }
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onSendZoomCommand, exception: ${ex}`)
      }
    },
    [addExecutingPTZCommand, commandOptions, deviceManagerProps]
  )

  const onSendPtzMoveStopCommand = useCallback(
    (commandName: string) => {
      try {
        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send PTZ move stop command entered for: ${commandName}`)
          const executeCommand: string = adjustPtzMoveCommand(commandName)
          console.debug(
            `Send PTZ move stop command for: ${commandName}, adjusted to: ${executeCommand}, rotation angle: ${rotationAngle}`
          )
          const controlOptions: Map<string, string> = new Map<string, string>()
          controlOptions.set(DeviceOptions[DeviceOptions.CameraPanTiltStop], '{}')
          deviceManagerProps.sendDeviceCommand(controlOptions)
          // remove the command from the list that tracks whether we should speed it up
          removeExecutingPTZCommand(executeCommand)
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onSendPtzMoveStopCommand, exception: ${ex}`)
      }
    },
    [adjustPtzMoveCommand, deviceManagerProps, removeExecutingPTZCommand, rotationAngle]
  )

  const onSendZoomStopCommand = useCallback(
    (isZoomIn: boolean) => {
      try {
        if (deviceManagerProps?.isDeviceOpen) {
          console.debug(`Send PTZ zoom stop command`)
          const controlOptions: Map<string, string> = new Map<string, string>()
          controlOptions.set(DeviceOptions[DeviceOptions.CameraZoomStop], '{}')
          deviceManagerProps.sendDeviceCommand(controlOptions)
          const commandName: string = isZoomIn
            ? DeviceOptions[DeviceOptions.CameraZoomIn]
            : DeviceOptions[DeviceOptions.CameraZoomOut]
          removeExecutingPTZCommand(commandName)
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onSendZoomCommand, exception: ${ex}`)
      }
    },
    [deviceManagerProps, removeExecutingPTZCommand]
  )

  // TODO: Revisit later
  // Leaving the handling of the onSendFocusStopCommand()
  // as commented-out for now.
  // We'll need them when the respective UI controls get implemented
  //
  // function onSendFocusStopCommand() {
  //   try {
  //     if (deviceManagerProps?.isDeviceOpen) {
  //       console.debug(`Send PTZ focus stop command`)
  //       const controlOptions: Map<string, string> = new Map<string, string>()
  //       controlOptions.set(DeviceOptions[DeviceOptions.CameraFocusStop], '{}')
  //       deviceManagerProps.sendDeviceCommand(controlOptions)
  //     }
  //   } catch (ex) {
  //     console.error(`TakePhotoModalContents, onSendFocusStopCommand, exception: ${ex}`)
  //   }
  // }

  const handleMouseWheel = useCallback(
    (event?: any) => {
      try {
        console.debug(
          `mouse wheel event: ${event?.toString()}, event deltaY: ${event?.deltaY}, event deltaX: ${event?.deltaX}`
        )
        onSendZoomCommand(0 > event?.deltaY, true) // when event?.deltaY is negative, the mouse wheel has been spun forward
      } catch (ex) {
        console.error(`TakePhotoModalContents, handleMouseWheel, exception: ${ex}`)
      }
    },
    [onSendZoomCommand]
  )

  const onKeyDown = useCallback(
    (event) => {
      try {
        if (!event) {
          console.debug(`onKeyDown, event is not set`)
          return
        }

        if (event.defaultPrevented) {
          console.debug(`onKeyDown, default action has been cancelled`)
          return // Should do nothing if the default action has been cancelled
        }

        if (!deviceManagerProps?.isDeviceOpen) {
          // not connected to a PTZ camera
          return
        }

        // Home is a special case
        if ('Home' === event.key && 'keydown' === event.type) {
          // Get the command that corresponds to the key pressed
          // Call the respective move command or move stop command
          // The Home command is a special case
          onSendPtzCommand(DeviceOptions[DeviceOptions.CameraPositionHome])
        } else {
          const keyCommand: string = true === event?.shiftKey ? `Shift${event?.key}` : event?.key
          console.debug(`onKeyDown, keyCommand: ${keyCommand}, lastKeyCommand: ${lastKeyCommand}`)
          const ptzCommandName: string | undefined = mapKeyboardKeyToCameraCommandName.get(keyCommand)
          if (ptzCommandName) {
            console.debug(`onKeyDown callback, ptzCommand: ${ptzCommandName}`)

            if (keyCommand === lastKeyCommand) {
              // still same key - ignore it as it should have already been handled
              console.debug(`onKeyDown, already in process`)
            } else {
              console.debug(`onKeyDown, need to process`)
              setLastKeyCommand(keyCommand)
              if (true === event.shiftKey) {
                // if the Shift key is pressed, then it's one of the zoom commands
                onSendZoomCommand('ArrowUp' === event.key)
              } else {
                onSendPtzMoveCommand(ptzCommandName)
              }
            }
            // Suppress "double action" if event handled
            event.preventDefault()
            event.stopPropagation()
          }
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onKeyDown(), exception: ${ex}`)
      }
    },
    [deviceManagerProps?.isDeviceOpen, lastKeyCommand, onSendPtzCommand, onSendPtzMoveCommand, onSendZoomCommand]
  )

  const onKeyUp = useCallback(
    (event) => {
      try {
        if (!event) {
          console.debug(`onKeyUp, event is not set`)
          return
        }

        if (event.defaultPrevented) {
          console.debug(`onKeyUp, default action has been cancelled`)
          return // Should do nothing if the default action has been cancelled
        }

        if (!deviceManagerProps?.isDeviceOpen) {
          // not connected to a PTZ camera
          return
        }

        if (!lastKeyCommand) {
          // we don't have a corresponding keydown, so ignore it
          // most likely it's not a key that we care about
          return
        }

        const keyCommand: string = true === event?.shiftKey ? `Shift${event?.key}` : event?.key
        console.debug(`onKeyUp, keyCommand: ${keyCommand}, lastKeyCommand: ${lastKeyCommand}`)
        const ptzCommandName: string | undefined = mapKeyboardKeyToCameraCommandName.get(keyCommand)
        if (ptzCommandName) {
          console.debug(`onKeyUp, ptzCommand: ${ptzCommandName}`)
          if (false === ptzCameraConfiguration.useDiscreteCommandMode) {
            console.debug(`key up: ${event.key}, shift pressed: ${event.shiftKey}, ptzCommandName: ${ptzCommandName}`)
            if (true === event.shiftKey) {
              // if the Shift key is pressed, then it's one of the zoom commands
              onSendZoomStopCommand('ArrowUp' === event.key)
            } else {
              onSendPtzMoveStopCommand(ptzCommandName)
            }
          }

          // Suppress "double action" if event handled
          event.preventDefault()
          event.stopPropagation()
          setLastKeyCommand('')
        }
      } catch (ex) {
        console.error(`TakePhotoModalContents, onKeyUp(), exception: ${ex}`)
      }
    },
    [deviceManagerProps?.isDeviceOpen, lastKeyCommand, onSendPtzMoveStopCommand, onSendZoomStopCommand]
  )

  useKeyDownListener(onKeyDown)
  useKeyUpListener(onKeyUp)

  function rotate() {
    const newRotation = (rotationAngle + 90) % 360
    setRotateImageStyle(newRotation)

    // Every time we rotate, we switch the video sizes
    console.debug(
      `rotate() entered, current videoSize.width: ${videoSize.width}, videoSize.height: ${videoSize.height}`
    )
    const width = videoSize.height
    const height = videoSize.width

    // resize the parent container
    const container = parentRef.current
    if (container) {
      container.style.height = `${height}px`
    } else {
      console.debug(`rotate(), parent is invalid`)
    }

    console.debug(`rotate(), newRotation: ${newRotation}, switch video sizes, width: ${width}, height: ${height}`)
    setVideoSize({ width: width, height: height })
  }

  return (
    <Box style={{ display: isHidden ? 'none' : 'block' }}>
      <ModalHeader>Take photo</ModalHeader>
      <ModalCloseButton />
      {prompt && <ModalBody>{prompt}</ModalBody>}
      {webcamAccessible ? (
        <Box>
          <Flex position="relative" justifyContent="center" onWheel={handleMouseWheel} ref={parentRef}>
            <Center>
              <Measure onResize={onResize}>
                {({ measureRef }) => (
                  <Box ref={measureRef} style={{ ...rotationStyle }}>
                    <Webcam
                      audio={false}
                      ref={webcamRef}
                      forceScreenshotSourceSize
                      screenshotFormat="image/jpeg"
                      videoConstraints={videoConstraints}
                      onUserMediaError={handleNoWebcam}
                      onUserMedia={handleUserMedia}
                      screenshotQuality={cameraScreenshotQuality}
                      imageSmoothing={cameraImageSmoothing}
                    />
                  </Box>
                )}
              </Measure>
            </Center>
            {true === displayImageGuidelines && (
              <ImageGuidelinesPaintingCanvas
                width={videoSize.width}
                height={videoSize.height}
                minWidth={cropConstraints.minWidth}
                minHeight={cropConstraints.minHeight}
                loading={videoLoading}
                canvasStyle={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%,-50%)'
                }}
              ></ImageGuidelinesPaintingCanvas>
            )}
            <Box
              hidden={videoLoading || !deviceManagerProps || false === deviceManagerProps?.isDeviceOpen}
              style={{ position: 'absolute', bottom: 0, right: 16 }}
            >
              <PanTiltControlWheel
                handleCenterClick={() => {
                  console.debug('center click')
                  onSendPtzCommand(DeviceOptions[DeviceOptions.CameraPositionHome])
                }}
                handleMousePressedRightMovement={(event) => {
                  console.debug(`right, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltRight])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltRight])
                  }
                }}
                handleMousePressedRightUpMovement={(event) => {
                  console.debug(`right up, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltUpRight])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltUpRight])
                  }
                }}
                handleMousePressedRightDownMovement={(event) => {
                  console.debug(`right down, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltDownRight])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltDownRight])
                  }
                }}
                handleMousePressedLeftMovement={(event) => {
                  console.debug(`left, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltLeft])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltLeft])
                  }
                }}
                handleMousePressedLeftUpMovement={(event) => {
                  console.debug(`left up, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltUpLeft])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltUpLeft])
                  }
                }}
                handleMousePressedLeftDownMovement={(event) => {
                  console.log(`left down, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltDownLeft])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltDownLeft])
                  }
                }}
                handleMousePressedDownMovement={(event) => {
                  console.debug(`down, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltDown])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltDown])
                  }
                }}
                handleMousePressedUpMovement={(event) => {
                  console.debug(`up, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendPtzMoveCommand(DeviceOptions[DeviceOptions.CameraPanTiltUp])
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendPtzMoveStopCommand(DeviceOptions[DeviceOptions.CameraPanTiltUp])
                  }
                }}
                handleMousePressedZoomIn={(event) => {
                  console.debug(`Zoom In, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendZoomCommand(true)
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendZoomStopCommand(true)
                  }
                }}
                handleMousePressedZoomOut={(event) => {
                  console.debug(`Zoom Out, mouse event: event type: ${event?.type}`)
                  if (event?.type === 'mousedown') {
                    onSendZoomCommand(false)
                  }
                  if (event?.type === 'mouseup' && false === ptzCameraConfiguration.useDiscreteCommandMode) {
                    onSendZoomStopCommand(false)
                  }
                }}
              />
            </Box>
          </Flex>
        </Box>
      ) : (
        <AspectRatio ratio={16 / 9} background="black">
          <VStack align="center">
            <CameraOutline color="white" width={66} height={66} />
            <Text color="white" fontWeight="bold">
              We can't find your camera
            </Text>
            <Text color="white">Check to make sure it is connected and installed properly.</Text>
            <Button variant="secondary" onClick={tryAgain}>
              Try again
            </Button>
          </VStack>
        </AspectRatio>
      )}
      <ModalFooter>
        <HStack width="100%" spacing={1}>
          <Button variant={variantColor.secondary} mr={3} onClick={onDismiss}>
            Cancel
          </Button>

          {true === showUploadPhotoButton && (
            <Fragment>
              <Spacer />

              <Button data-cy="uploadPhoto" variant="secondary" onClick={uploadFile}>
                Upload photo
              </Button>
            </Fragment>
          )}
          <Spacer />
          <Button variant={variantColor.secondary} mr={3} onClick={rotate}>
            {`Rotate ${rotationAngle + 90} degrees`}
          </Button>
          <Button
            data-cy="takePhoto"
            variant={variantColor.primary}
            onClick={() => {
              goToEditScreen(rotationAngle)
            }}
            disabled={!webcamAccessible}
          >
            Take photo
          </Button>
        </HStack>
      </ModalFooter>
    </Box>
  )
}
