import RecordImage, { RecordImageMetadata } from './RecordImage'

/**
 * This class keeps track of all the images that might be deleted.
 */
export default class RecordImagesToBeDeleted {
  recordImagesIdsOriginal: number[]
  recordImages: RecordImage<RecordImageMetadata>[]

  constructor(recordImagesIdsOriginal: number[], recordImages: RecordImage<RecordImageMetadata>[] = []) {
    this.recordImagesIdsOriginal = recordImagesIdsOriginal
    this.recordImages = recordImages
  }

  /**
   * If the image received is not in the recordImagesIdsOriginal array, this image is not
   * added to the recordImages array, and the Image.delete method is executed to remove
   * the image from the database.
   * Otherwise, if the image received is in the recordImagesIdsOriginal array, this image is
   * added to the recordImages array to be removed later.
   */
  add(recordImage: RecordImage<RecordImageMetadata>) {
    if (this.isRecordImageLinked(recordImage.capturedAt.getTime())) {
      this.recordImages.push(recordImage)
    } else {
      recordImage.image.delete()
    }
  }

  /**
   * It executes the Image.delete method of all the images in the recordImages array.
   * It requires the updated recordImagesIdsOriginal.
   * It empties the recordImages array.
   */
  deleteAll(recordImagesIdsOriginal: number[]) {
    this.recordImages.forEach((recordImage) => {
      recordImage.image.delete()
    })
    this.recordImages = []
    this.recordImagesIdsOriginal = recordImagesIdsOriginal
  }

  /**
   * It returns a boolean that indicates if the image exists in the recordImagesIdsOriginal array
   */
  isRecordImageLinked(recordImageId: number): boolean {
    return this.recordImagesIdsOriginal.findIndex((imageId) => imageId === recordImageId) !== -1
  }
}
