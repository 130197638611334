import { useCallback, useState } from 'react'

// Handles the history state and contains helper functions to manipulate it.
export function useHistoryState<T>() {
  const [state, setState] = useState<Record<string, T>>(window.history.state || {})

  // Sets an state value inside the history state, but keeping the rest of their values.
  const setValue = useCallback((index: string, value: Partial<T>) => {
    const currentState = window.history.state

    setState((curr) => {
      const newState = {
        ...currentState,
        [index]: {
          ...curr[index],
          ...value
        }
      }

      window.history.replaceState(newState, '')

      return newState
    })
  }, [])

  // Gets an state value from the current history state with an provided index.
  const getValue = useCallback(
    (index: string) => {
      return state[index]
    },
    [state]
  )

  // Returns if the history state has an state value.
  const hasValue = useCallback(
    (index: string) => {
      return state[index] !== undefined && state[index] !== null
    },
    [state]
  )

  return {
    state,
    setValue,
    hasValue,
    getValue
  }
}
