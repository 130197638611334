import { Box, Flex, Grid, GridItem, useBreakpointValue } from '@chakra-ui/react'
import { JFD, JFLDef, JFLGroup } from '@le2/jfd'

import { useJFLField } from './JFLField'
import { useJFD } from './jfd-context'

export interface JFLRenderProps {
  group: JFLGroup
  index?: number
}

export function JFLRender(props: JFLRenderProps) {
  switch (props.group.layout) {
    case 'flex':
      return <JFLFlex {...props} />
    case 'grid':
    default:
      return <JFLGrid {...props} />
  }
}

// gutter = spacing between elements
const COL_GUTTER = 4
const ROW_GUTTER = 2

/**
 * JFL that uses CSS grid with a pre-defined amount of columns and three
 * control sizes. Both of these vary with the screen size:
 *
 * --------------------------------------
 * | screen size  | control size | cols |
 * --------------------------------------
 * | lg (3 cols)  | lg           | 3    |
 * |              | md           | 2    |
 * |              | sm           | 1    |
 * --------------------------------------
 * | md (2 cols)  | lg           | 2    |
 * |              | md           | 1    |
 * |              | sm           | 1    |
 * --------------------------------------
 * | sm (1 col)   | lg           | 1    |
 * |              | md           | 1    |
 * |              | sm           | 1    |
 * --------------------------------------
 *
 * @param props
 */
function JFLGrid(props: JFLRenderProps) {
  const { jfd } = useJFD()
  const cols = useGridCols()
  return (
    <Grid templateColumns={`repeat(${cols}, 1fr)`} gap={COL_GUTTER} rowGap={ROW_GUTTER}>
      {props.group.fields.map((jflDef) => (
        <JFLGridItem
          key={getFlexItemKey(jfd, jflDef, props.index)}
          jflDef={jflDef}
          index={props.index}
          group={props.group}
        />
      ))}
    </Grid>
  )
}

function JFLGridItem({ jflDef, index, group }: { jflDef: JFLDef; index?: number; group: JFLGroup }) {
  const colSpans = useColSpans()
  const formField = useJFLField(jflDef, index, group)
  const size = jflDef.size || 'sm'

  if (!(size in colSpans)) {
    throw new Error('Invalid size: ' + size)
  }

  if (formField) {
    return <GridItem colSpan={colSpans[size]}>{formField}</GridItem>
  } else {
    return null
  }
}

/**
 * Get the amount of columns for the current screen size.
 */
function useGridCols(): number {
  const r = useBreakpointValue<number>({
    base: 1,
    md: 2,
    lg: 3
  })

  return r || 1
}

/**
 * Get a map {controlSize: colSpan} for the current screen size.
 */
function useColSpans(): Record<string, number> {
  const r = useBreakpointValue<Record<string, number>>({
    base: {
      sm: 1,
      md: 1,
      lg: 1
    },
    md: {
      sm: 1,
      md: 1,
      lg: 2
    },
    lg: {
      sm: 1,
      md: 2,
      lg: 3
    }
  })

  return (
    r || {
      sm: 1,
      md: 1,
      lg: 1
    }
  )
}

/**
 * JFL that uses CSS Flexbox with seven control sizes that vary with the screen
 * size, e.g. for 2xl:
 *
 * ------------------------
 * | control size | width |
 * ------------------------
 * | 1            | 1/6   |
 * | 2            | 1/5   |
 * | 3            | 1/4   |
 * | 4            | 1/3   |
 * | 5            | 1/2   |
 * | 6            | 2/3   |
 * | full         | 100%  |
 * ------------------------
 *
 * See `useSizesMap` for the complete mapping for all screen sizes.
 *
 * By default, each row can have up to 100% (e.g. six controls with size=1).
 * If the sum is not exact (e.g. only four controls with size=1) the remaining
 * space in the row is divided equally between the controls. You can specify a
 * control with a fixed size with: `size: "1 fix"` that means "always use 1/6,
 * even if there's extra space available".
 *
 * @param props
 */
function JFLFlex(props: JFLRenderProps) {
  const { jfd } = useJFD()
  const containerGutter = { mt: -ROW_GUTTER, ml: -COL_GUTTER }
  return (
    <Flex wrap="wrap" {...containerGutter}>
      {props.group.fields.map((jflDef) => (
        <JFLFlexItem
          key={getFlexItemKey(jfd, jflDef, props.index)}
          jflDef={jflDef}
          index={props.index}
          group={props.group}
        />
      ))}
    </Flex>
  )
}

function JFLFlexItem({ jflDef, index, group }: { jflDef: JFLDef; index?: number; group: JFLGroup }) {
  const itemGutter = { pt: ROW_GUTTER, pl: COL_GUTTER }
  const sizesMap = useSizesMap()
  const [size, grow] = parseFlexSize(jflDef)
  const formField = useJFLField(jflDef, index, group)

  if (!(size in sizesMap)) {
    throw new Error('Invalid size: ' + size)
  }

  if (formField) {
    return (
      <Box flexBasis={sizesMap[size]} flexGrow={grow} {...itemGutter}>
        {formField}
      </Box>
    )
  } else {
    return null
  }
}

/**
 * Get a map {controlSize: width} for the current screen size.
 */
function useSizesMap(): Record<string, string> {
  const r = useBreakpointValue<Record<string, string>>({
    base: {
      1: '100%',
      2: '100%',
      3: '100%',
      4: '100%',
      5: '100%',
      6: '100%',
      full: '100%'
    },
    md: {
      1: '33.333%',
      2: '33.333%',
      3: '33.333%',
      4: '50%',
      5: '50%',
      6: '50%',
      full: '100%'
    },
    lg: {
      1: '20%', // 1/5
      2: '20%', // 1/5
      3: '25%', // 1/3
      4: '33.333%', // 1/3
      5: '50%', // 1/2
      6: '66.666%', // 2/3
      full: '100%'
    },
    '2xl': {
      1: '16.666%', // 1/6
      2: '20%', // 1/5
      3: '25%', // 1/4
      4: '33.333%', // 1/3
      5: '50%', // 1/2
      6: '66.666%', // 2/3
      full: '100%'
    }
  })

  return (
    r || {
      1: '100%',
      2: '100%',
      3: '100%',
      4: '100%',
      5: '100%',
      6: '100%',
      full: '100%'
    }
  )
}

/**
 * Parse the `size` for the given JFLDef. It accepts:
 * - only the size, e.g. `size: "2"`
 * - size + fixed, e.g. `size: "3 fix"`
 *
 * @param jflDef
 */
function parseFlexSize(jflDef: JFLDef): [string, number] {
  if (!jflDef.size) {
    return ['4', 1]
  }
  const [defSize, defGrow] = jflDef.size.split(' ')
  const grow = defGrow === 'fix' ? 0 : 1
  return [defSize, grow]
}

function getFlexItemKey(jfd: JFD, jflDef: JFLDef, index?: number) {
  switch (jflDef.control) {
    case 'formField':
    case 'tableLookup':
      return jfd.getField(jflDef.field, index).id
    case 'checkboxArray':
    case 'range':
      return jflDef.field.map((field) => jfd.getField(field, index).id).join('-')
  }
}
