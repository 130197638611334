import {
  AspectRatio,
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  ResponsiveValue,
  Spacer,
  Text,
  Tooltip,
  useDisclosure
} from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import {
  CameraOutline,
  DotsHorizontal,
  DownloadOutline,
  EyeOutline,
  RefreshOutline,
  TrashOutline
} from 'heroicons-react'
import { useEffect, useState } from 'react'

import theme from '../theme'
import UploadStatusIndicator from './UploadStatusIndicator'

export interface PhotoDisplayProps {
  /**
   * Source for the image.
   */
  src?: string

  /**
   * Source to use when `src` is not set.
   */
  defaultSrc?: string

  /**
   * Passed to Chakra's <AspectRatio>.
   */
  aspectRatio?: ResponsiveValue<number>

  /**
   * An image that is rendered on top of `src`.
   */
  overlay?: string

  /**
   * Renders a red border around the image when `true`.
   */
  hasError?: boolean

  /**
   * Text to display below the image.
   */
  label?: string

  /**
   * Disables the Camera and Trash buttons when `true`.
   */
  isReadOnly?: boolean

  /**
   * Disables the Camera buttton when `true`
   */
  isCameraDisabled?: boolean

  /**
   * Used with `label`. It displays a red asterisk next to the label.
   */
  isRequired?: boolean

  /**
   * Shows a spinner on top of the image when `true`.
   */
  isUploading?: boolean

  /**
   * Shows an error message and a "Try again" button when `true`.
   */
  hasUploadError?: boolean

  /**
   * Used with `hasUploadError`. Called when clicking on the "Try again" button.
   */
  handleUpload?: () => void

  /**
   * Text to display when the image failed to be uploaded.
   */
  uploadErrorMessage?: string

  /**
   * Called when clicking on the Camera button.
   */
  onTakePhoto?: () => void

  /**
   * Called when clicking on the Trash option.
   */
  onDelete?: () => void

  /**
   * Called when clicking on the View Image option
   */
  onView?: () => void

  /**
   * Called when clicking on the Retake Image option
   */
  onRetakeImage?: () => void

  /**
   * Called when clicking on the Download Image option
   */
  onDownload?: () => void

  /**
   * Force the height of the image to contain the box instead of cropping the image to fill the image box.
   */
  fitHeight?: boolean
}

/**
 * Renders an <Image>, the source is selected in this order:
 * - the `src` prop
 * - the `defaultSrc` prop
 * - an empty `gray.200` background
 *
 * The image includes two buttons:
 * - a camera button: triggers `onTakePhoto`
 * - a trash button: triggers `onDelete`; only visible when `src` is set and
 *   the user hovers over the component.
 *
 * Optionally, it can render an "overlay" i.e. a different image to place on
 * top of the `src`.
 *
 * Finally, it can display a loading indicator via these props:
 * - `isUploading`: shows a spinner when `true`
 * - `hasUploadError`: shows an error message and a "Try again" button which
 *   calls `handleUpload`.
 */
export default function PhotoDisplay(props: PhotoDisplayProps) {
  const [showingImageActionsButton, setShowingImageActionsButton] = useState(false)
  const [isImageActionsMenuOpen, setIsImageActionsMenuOpen] = useState(false)
  const [isImageOnHover, setIsImageOnHover] = useState(false)
  const border = props.hasError ? '2px solid #E45C5C' : 'none'
  const showingCameraButton = !props.isUploading && !props.hasUploadError && !props.src

  useEffect(() => {
    // ImageActionsButton will be shown when image exists
    // and image component is on hover or the options menu is open,
    // otherwise it will be hidden
    if (props.src && !showingCameraButton) {
      if (isImageOnHover || isImageActionsMenuOpen) {
        setShowingImageActionsButton(true)
        return
      }
    }

    setShowingImageActionsButton(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageActionsMenuOpen, isImageOnHover])

  return (
    <Box
      onMouseEnter={() => setIsImageOnHover(true)}
      onMouseLeave={() => setIsImageOnHover(false)}
      data-testid="photo-display"
    >
      <Box position="relative" borderRadius="8px" overflow="hidden" border={border}>
        <UploadStatusIndicator {...props} />
        <ImageInternal {...props} />
        {props.overlay && <Image src={props.overlay} position="absolute" top="0" h="100%" w="100%" />}
        {showingImageActionsButton && <ImageActionsButton {...props} setMenuOpen={setIsImageActionsMenuOpen} />}
        {showingCameraButton && <CameraButton {...props} />}
      </Box>
      {props.label && <Label {...props} />}
    </Box>
  )
}

function ImageInternal({ src, defaultSrc, aspectRatio, fitHeight = false }: PhotoDisplayProps) {
  const hasImage = !!src

  return (
    <AspectRatio ratio={aspectRatio} bg={hasImage ? 'black' : 'gray.200'}>
      {fitHeight ? (
        <Box d="flex" justifyContent="center">
          <Image alignSelf="center" height="100%" width="auto" src={src || defaultSrc} />
        </Box>
      ) : (
        <Image src={src || defaultSrc} />
      )}
    </AspectRatio>
  )
}

interface ImageActionsButtonProps extends PhotoDisplayProps {
  setMenuOpen: (isOpen: boolean) => void
}

function ImageActionsButton(props: ImageActionsButtonProps) {
  const { onView, onDelete, onRetakeImage, onDownload } = props
  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    props.setMenuOpen(isOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <HStack position="absolute" top={4} w="100%">
      <Menu onOpen={onOpen} onClose={onClose}>
        <Spacer />
        <MenuButton
          aria-label="Image actions"
          as={IconButton}
          icon={<DotsHorizontal />}
          borderRadius="50%"
          border="none"
          color="gray.700"
          mr={4}
          bgColor={transparentize('#FEFEFE', 0.5)(theme)}
          _hover={{
            background: transparentize('#FEFEFE', 0.7)(theme)
          }}
          position="relative"
          zIndex="1"
        />
        <Portal>
          <MenuList>
            <MenuItem icon={<EyeOutline size={20} />} onClick={onView}>
              View image
            </MenuItem>
            <MenuItem icon={<DownloadOutline size={20} />} onClick={onDownload}>
              Download
            </MenuItem>
            {!props.isReadOnly && (
              <>
                <MenuItem icon={<RefreshOutline size={20} />} onClick={onRetakeImage}>
                  Retake image
                </MenuItem>
                <MenuItem icon={<TrashOutline size={20} />} onClick={onDelete}>
                  Delete image
                </MenuItem>
              </>
            )}
          </MenuList>
        </Portal>
      </Menu>
    </HStack>
  )
}

function CameraButton({ onTakePhoto, isReadOnly, isCameraDisabled }: PhotoDisplayProps) {
  return (
    <HStack position="absolute" bottom={4} w="100%">
      <Spacer />
      <Tooltip label="You have reached the maximum number of images" placement="auto" isDisabled={!isCameraDisabled}>
        <Box>
          <IconButton
            aria-label="Take photo"
            icon={<CameraOutline />}
            data-cy={'savePhoto'}
            borderRadius="50%"
            border="none"
            color="gray.700"
            mr={4}
            onClick={onTakePhoto}
            bgColor={transparentize('#FEFEFE', 0.5)(theme)}
            _hover={{
              background: transparentize('#FEFEFE', 0.7)(theme)
            }}
            isDisabled={isReadOnly || isCameraDisabled}
          />
        </Box>
      </Tooltip>
    </HStack>
  )
}

function Label({ label, isRequired }: PhotoDisplayProps) {
  return (
    <Center w="100%">
      <Text pt="10px" pb="10px" textStyle="subtitle" color="gray.700">
        {label}
        {isRequired && (
          <Text as="span" color="red.500">
            {' '}
            *
          </Text>
        )}
      </Text>
    </Center>
  )
}
