import { BioType, JFD, Modality } from '@le2/jfd'
import mime from 'mime-types'

import { BookingActionSubscriber } from '../../App'
import agencyConfig from '../../lib/agencyConfig'
import { Booking, BookingImage } from '../../lib/api/booking'
import { BookingImageMetadata, FaceCaptureImage, SMTImage } from '../../lib/api/booking/BookingImage'
import { getMIMETypeFromDataURI } from '../../lib/imageUtils'
import { ModalityDefinitions } from '../../nativeExtension/IWNativeExtDeviceDefines'
import { activeCaptureAnnotationCode } from '../../utils/captureAnnotations'
import { bookingState, jfdInst } from './state'

export const onlyMandatoryCheckbox = agencyConfig.features.bookings.onlyMandatoryCheckbox

/**
 * Function to validate the number of images based on the JFD-azdps definitions
 * @return boolean True if the modality reached the limit rate.
 */
export function validateImagesLimitRate(modality: Modality): boolean {
  const { jfd, bookingImages } = bookingState

  const { def, sharedDefs } = jfd.getModalityDef(modality)

  let images: BookingImage<BookingImageMetadata>[] = bookingImages.getImagesByModality([modality])

  if (typeof sharedDefs === 'object') {
    const { modalities, defs } = sharedDefs

    const modalitiesImages = bookingImages.getImagesByModality(modalities)

    images = images.concat(modalitiesImages)

    if (defs.maxOccurrences > 0) {
      return images.length >= defs.maxOccurrences
    }
  }

  if (def.maxOccurrences > 0) {
    return images.length >= def.maxOccurrences
  }

  return false
}

export const masterKeyFieldName = jfdInst.jfdDef.masterKeyFieldName

const jfdFingerProps = {
  missing: 'AmputatedOrBandaged',
  position: 'FingerPosition',
  code: 'AmputatedOrBandagedCode'
}

const jfdPalmProps = {
  missing: 'AmputatedOrBandagedPalm',
  position: 'BioType',
  code: 'Code'
}

const jfdIrisProps = {
  missing: 'MissingIris',
  position: 'BioType',
  code: 'Code'
}

export function getJFDProperties(modality: ModalityDefinitions) {
  if (modality === ModalityDefinitions.Fingerprint) {
    return jfdFingerProps
  } else if (modality === ModalityDefinitions.Palm) {
    return jfdPalmProps
  } else {
    return jfdIrisProps
  }
}

export function mapJFDtoElementsState(
  jfdSnap: JFD,
  modality: ModalityDefinitions,
  positionNumber: { [x: string]: string },
  elementsInitialState: { [x: string]: string }
): { [x: string]: string } {
  const prop = getJFDProperties(modality)
  const missingDigits = jfdSnap.getValues()[prop.missing] as Record<string, string>[]
  const jfdMissingDigits: { [x: string]: string } = {}
  missingDigits.forEach((missingDigit) => {
    const digitKey = Object.keys(positionNumber).find((key) => positionNumber[key] === missingDigit[prop.position])
    if (digitKey) {
      jfdMissingDigits[digitKey] = missingDigit[prop.code] as string
    }
  })
  return { ...elementsInitialState, ...jfdMissingDigits }
}

export function setMissingElementJFD(
  jfd: JFD,
  modality: ModalityDefinitions,
  positionNumber: { [x: string]: string },
  element: BioType | string,
  scanFingerState: string | string
): void {
  const prop = getJFDProperties(modality)
  const missingDigits = jfd.getValues()[prop.missing] as Record<string, string>[]
  const digitPositionNum = positionNumber[element]
  let digitMissingIndex = missingDigits.findIndex(
    (item) => !item[prop.position] || item[prop.position] === digitPositionNum
  )

  if (scanFingerState === activeCaptureAnnotationCode) {
    if (digitMissingIndex !== -1) {
      jfd.removeField(prop.missing, digitMissingIndex)
      if (missingDigits.length - 1 === 0) {
        jfd.createOccurrence(prop.missing, 0)
      }
    }
    return
  }

  if (digitMissingIndex === -1) {
    digitMissingIndex = missingDigits.length
    jfd.createOccurrence(prop.missing, digitMissingIndex)
  }

  jfd.setValue(`${prop.missing}.${prop.position}`, digitPositionNum, digitMissingIndex)
  jfd.setValue(`${prop.missing}.${prop.code}`, scanFingerState, digitMissingIndex)
}

/**
 * Function to call all the subscribers to a particular booking operation
 *
 * @param mode - the booking operation definition
 * @param booking - booking (optional)
 * @param bookingActionSubscribers - list of subscribers (optional)
 * @param params - additional parameters to pass to the subscribers' callbacks (variable, optional)
 */
export function callBookingActionSubscribers(
  mode: 'create' | 'update' | 'submit' | 'seal' | 'delete' | 'activate',
  booking?: Booking,
  bookingActionSubscribers?: BookingActionSubscriber[],
  ...params: any
) {
  try {
    if (bookingActionSubscribers) {
      bookingActionSubscribers.forEach((subscriber) => {
        let func
        switch (mode) {
          case 'create':
            func = subscriber.onCreate
            break
          case 'update':
            func = subscriber.onUpdate
            break
          case 'submit':
            func = subscriber.onSubmit
            break
          case 'seal':
            func = subscriber.onSeal
            break
          case 'delete':
            func = subscriber.onDelete
            break
          case 'activate':
            func = subscriber.onActivate
            break
        }
        if (func) {
          func(booking, ...params)
        }
      })
    }
  } catch (e) {
    console.warn(`Booking subscribers exception: ${e}`)
  }
}

export function getExportedFilename(
  bookingNumber: string,
  image?: FaceCaptureImage | SMTImage | null
): string | undefined {
  const defaultExtension = 'jpeg'
  if (!image) {
    return `${bookingNumber}_image.${defaultExtension}`
  }
  const mimeType = getMIMETypeFromDataURI(image.image.src || '')
  const format = mimeType ? mime.extension(mimeType) : defaultExtension
  const capturedAt = image.capturedAt.toISOString().replace(/[:.]/g, '-')
  let imageType = `${image.bioType}`
  if (image.bioType === BioType.SMT) {
    imageType = `${imageType}_${(image as SMTImage)?.metadata?.smtType}`
  }

  return `${bookingNumber}_${imageType}_${capturedAt}.${format}`
}
