import { Modality } from '@le2/jfd'
import { useRouteMatch } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import StatusIcon from '../../../components/StatusIcon'
import { useExtraJFDValues } from '../../../hooks/useExtraJFDValues'
import { MenuItemProps } from '../../../layouts/resource'
import { jfdInst, recordState } from '../state'

export function useRecordMenuItems(): MenuItemProps[] {
  const { url } = useRouteMatch()
  const snap = useSnapshot(recordState)
  const extraJFDValues = useExtraJFDValues()

  const modalitiesAvailable = jfdInst.getModalitesAvailable()

  const menuItems: MenuItemProps[] = [
    {
      label: 'Biographics',
      to: `${url}/info`,
      statusIcon: <StatusIcon status={snap.validation.biographics.status} />
    }
  ]

  const modalitiesMenuItems: ModalityMenuItemConfig[] = [
    {
      modality: Modality.Face,
      label: 'Face',
      to: `${url}/face`,
      statusIcon: <StatusIcon status={snap.validation[Modality.Face].status} />
    }
  ]

  modalitiesMenuItems.forEach((item) => {
    if (modalitiesAvailable.includes(item.modality)) {
      menuItems.push(item)
    }
  })

  return menuItems.map((item) => ({ ...item, to: `${item.to}?${extraJFDValues}` }))
}

interface ModalityMenuItemConfig extends MenuItemProps {
  modality: Modality
}
