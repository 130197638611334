import { Box, Flex, Icon, useTheme } from '@chakra-ui/react'
import { ChevronRight } from 'heroicons-react'
import * as React from 'react'

import { topNavHeight } from '../user'
import { headerHeight } from './ResourceLayout'

const submenuHeight = '48px'

interface BookingContentsProps {
  submenu?: React.ReactNode
  children: React.ReactNode
  bgAndPadding?: boolean
}

export default function BookingSection({ submenu, children, bgAndPadding = true }: BookingContentsProps) {
  const zIndices = useZIndices()

  return (
    <>
      <Box
        pt="2px"
        bgColor="background"
        position="sticky"
        top={`calc(${topNavHeight} + ${headerHeight})`}
        zIndex={zIndices.header}
      >
        {submenu && (
          <Box bgColor="white" borderRadius={4}>
            {submenu || <DefaultSubmenu />}
          </Box>
        )}
      </Box>
      {submenu && <BoxShadow zIndex={zIndices.shadow} submenu={submenu} />}
      <Box mt={2} borderRadius={4} bgColor={bgAndPadding ? 'white' : ''} position="relative" minHeight="75vh">
        {submenu && <BoxShadowCover zIndex={zIndices.shadow} />}
        <Box padding={bgAndPadding ? 4 : 0} zIndex={zIndices.content}>
          {children}
        </Box>
      </Box>
    </>
  )
}

function DefaultSubmenu() {
  return (
    <Flex alignItems="center" px={4} py={3} color="gray.500" textStyle="paragraph">
      Main
      <Icon as={ChevronRight} ml={2} />
    </Flex>
  )
}

// Box shadow trick from:
// https://medium.com/walmartglobaltech/activatable-drop-shadow-on-sticky-elements-d0c12f1ebfdf
// https://jsfiddle.net/parkjoon/tp1e9yad/
//
// We render a shadow with sticky position and then cover it with a white
// background. The effect is:
// - when the sticky is not "triggered" we show the white background
// - when the user scrolls the shadow appears (because the white cover is scrolled)

interface BoxShadowProps {
  zIndex: number
  submenu: BookingContentsProps['submenu']
}

function BoxShadow({ zIndex, submenu }: BoxShadowProps) {
  let top
  if (submenu) {
    top = `calc(${topNavHeight} + ${headerHeight} + ${submenuHeight})`
  } else {
    top = `calc(${topNavHeight} + ${headerHeight})`
  }

  return (
    <Box overflowX={'clip' as any} zIndex={zIndex} position="sticky" top={top}>
      <Box boxShadow="0px 0px 16px 16px rgba(229, 231, 235, 0.5)" />
    </Box>
  )
}

function BoxShadowCover({ zIndex }: { zIndex: number }) {
  return (
    <Box position="absolute" top={0} left={0} width="100%" height="16px" bg="white" zIndex={zIndex} borderRadius={4} />
  )
}

function useZIndices() {
  const theme = useTheme()
  return {
    header: theme.zIndices.sticky,
    shadow: theme.zIndices.sticky - 1,
    content: theme.zIndices.sticky - 1
  }
}
