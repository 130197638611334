import { useToast } from '@chakra-ui/react'

import agencyConfig from '../../../lib/agencyConfig'
import { Record } from '../../../lib/api/record'
import { difference } from '../../../utils/resourceUtils'
import { recordState } from '../state'

export function useHandleRecordError() {
  const toast = useToast()

  return function (record: Record, e: Error) {
    toast({
      title: 'An error occurred when loading the record',
      description: `${e.message}. See more details in the console.`,
      status: 'error',
      position: 'top',
      isClosable: true
    })

    console.error(`An error occurred when loading the record ${record.recordNum}: "${e.message}"`)
    console.error('Loaded record:', record.biographics)
    console.error(
      `Fields that exist in the record and not in the current JFD (${agencyConfig.features.agencyName})`,
      difference(record.biographics, recordState.jfd.getValues())
    )
  }
}
