import { Avatar, AvatarProps } from '@chakra-ui/react'
import { useCallback, useState } from 'react'

import { BioType, Record, useDownloadImage } from '../lib/api/record'

interface RecordAvatarProps extends AvatarProps {
  record?: Record
  bioType?: BioType
}

export default function RecordAvatar({ record, bioType = BioType.FaceFront, ...props }: RecordAvatarProps) {
  const avatar = useRecordAvatar(bioType, record)
  return <Avatar key={`${record?.recordNum}-${bioType}`} src={avatar} borderRadius="4px" {...props} />
}

function useRecordAvatar(bioType: BioType, record?: Record) {
  // get the latest FaceFront to be used as avatar
  // use a dummy placeholder to show an empty image while it loads
  const latestImage = record?.images.getLatestImage(bioType)
  const [avatarSrc, setAvatarSrc] = useState(latestImage?.image.isURL ? '' : latestImage?.image.src)
  const callback = useCallback(() => setAvatarSrc(latestImage?.image.src || ''), [latestImage])
  useDownloadImage(latestImage, callback)
  return avatarSrc
}
