import { InMemoryCache, makeVar } from '@apollo/client'

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userSearch: {
          read() {
            return userSearchVar()
          }
        }
      }
    }
  }
})

export const userSearchVar = makeVar<any>({ isActive: '', role: '', search: '' })
