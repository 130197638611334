import { Text, TextProps } from '@chakra-ui/react'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'

type AccountLockoutTimeoutProps = {
  lockoutTimeout?: Date
  onFinish: () => void
}

type CustomTextProps = TextProps & { children?: ReactNode }
// Using a custom text component to prevent expression produces a union type
const CustomText = Text as React.ComponentType<CustomTextProps>

function AccountLockoutTimeout({ lockoutTimeout, onFinish }: AccountLockoutTimeoutProps) {
  const [remainingTime, setRemainingTime] = useState<string | undefined>()
  const timeoutInterval = useRef<NodeJS.Timeout | null>(null)

  const padTo2Digits = useCallback((value: number) => {
    return value.toString().padStart(2, '0')
  }, [])

  const handleTimer = useCallback(
    (lockoutTimeout: Date) => {
      // Calculates the diff between the timeout date and current date.
      const diff = lockoutTimeout.getTime() - new Date().getTime()

      // Extracts the remaining minutes and seconds for timeout.
      let seconds = Math.floor(diff / 1000)
      let minutes = Math.floor(seconds / 60)

      seconds = seconds % 60
      minutes = minutes % 60

      // The timeout finishes.
      if (minutes <= 0 && seconds <= 0) {
        setRemainingTime(undefined)
        if (timeoutInterval.current) {
          clearInterval(timeoutInterval.current)
        }
        onFinish()
      } else {
        // Maps and sets the value to a regular time format.
        setRemainingTime(`${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`)
      }
    },
    [padTo2Digits, onFinish]
  )

  useEffect(() => {
    if (lockoutTimeout) {
      if (timeoutInterval.current) {
        clearInterval(timeoutInterval.current)
      }

      handleTimer(lockoutTimeout)

      timeoutInterval.current = setInterval(() => handleTimer(lockoutTimeout), 1000)
    }

    return () => {
      if (timeoutInterval.current) {
        clearInterval(timeoutInterval.current)
      }
    }
  }, [lockoutTimeout, handleTimer])

  if (!lockoutTimeout) {
    return null
  }

  return (
    <CustomText mt={3} fontSize="sm">
      Please retry in{' '}
      <CustomText as="span" fontWeight="500">
        {remainingTime}
      </CustomText>
    </CustomText>
  )
}

export default AccountLockoutTimeout
