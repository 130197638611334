import { Dispatch, SetStateAction } from 'react'

import {
  AvailablePrintersEventDetail,
  DgnPrintEventDetail,
  FieldNamesEventDetail,
  IWImage,
  PrinterStatusEventDetail,
  PrintersEventDetail
} from '../IWNativeExtDeviceDefines'

export function availablePrintersEventHandlerWrapper(setPrintersList: Dispatch<SetStateAction<string[]>>) {
  return function (eventCtx: CustomEvent<AvailablePrintersEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.debug(
      'availablePrintersEventHandler(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription
    )

    if (list?.length) {
      setPrintersList(list)
    } else {
      console.debug('availablePrintersEventHandler(), no list received')
    }
  } as EventListener
}

export function printEventHandlerWrapper(setErrorMessage: Dispatch<SetStateAction<string | null>>) {
  return function (eventCtx: CustomEvent<DgnPrintEventDetail>) {
    const result = eventCtx.detail.result

    console.log('printEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

    if (result.ErrorCode === 0) {
      console.log('printEventHandlerWrapper(), success')

      setErrorMessage(null)
    } else {
      setErrorMessage(result.ErrorDescription)
    }
  } as EventListener
}

export function printersEventHandlerWrapper(
  setPrintersList: Dispatch<SetStateAction<string[]>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>
) {
  return function (eventCtx: CustomEvent<PrintersEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log('printersEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription)

    if (list?.length) {
      console.log('printersEventHandlerWrapper(), images list received, list length: ' + list.length)

      setPrintersList(list)
    } else {
      console.log('printersEventHandlerWrapper(), no list received')

      setErrorMessage(result.ErrorDescription)
    }
  } as EventListener
}

export function printerStatusEventHandlerWrapper(
  setPrinterStatus: Dispatch<SetStateAction<string[] | null>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>
) {
  return function (eventCtx: CustomEvent<PrinterStatusEventDetail>) {
    const result = eventCtx.detail.result
    const status = eventCtx.detail.list

    console.log(
      'printerStatusEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription
    )

    if (status) {
      console.log('printerStatusEventHandlerWrapper(), status received: ' + status)

      setPrinterStatus(status)
    } else {
      console.log('printerStatusEventHandlerWrapper(), no status received')

      setErrorMessage(result.ErrorDescription)
    }
  } as EventListener
}

export function fieldNamesEventHandlerWrapper(
  setFieldNames: Dispatch<SetStateAction<string[]>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>
) {
  return function (eventCtx: CustomEvent<FieldNamesEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log(
      'fieldNamesEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription
    )

    if (list?.length) {
      console.log('fieldNamesEventHandlerWrapper(), images list received, list length: ' + list.length)

      setFieldNames(list)
    } else {
      console.log('fieldNamesEventHandlerWrapper(), no list received')

      setErrorMessage(result.ErrorDescription)
    }
  } as EventListener
}

export function printPreviewEventHandlerWrapper(
  setImagesList: Dispatch<SetStateAction<IWImage[][]>>,
  setErrorMessage: Dispatch<SetStateAction<string | null>>
) {
  return function (eventCtx: CustomEvent<DgnPrintEventDetail>) {
    const result = eventCtx.detail.result
    const list = eventCtx.detail.list

    console.log(
      'printPreviewEventHandlerWrapper(), result received: ' + result.ErrorCode + ', ' + result.ErrorDescription
    )

    if (list?.length) {
      console.log('printPreviewEventHandlerWrapper(), images list received, list length: ' + list.length)

      setImagesList(list)
    } else {
      console.log('printPreviewEventHandlerWrapper(), no list received')

      setErrorMessage(result.ErrorDescription)
    }
  } as EventListener
}
