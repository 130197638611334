import { useState } from 'react'

export enum OrderBy {
  none,
  asc = 'asc',
  desc = 'desc'
}

export interface TableSortResult {
  /**
   * Maps {columnName: orderBy}, e.g.
   *
   * ```
   * {
   *   PCN: OrderBy.asc,
   *   Race: OrderBy.none
   * }
   * ```
   */
  orderBy: OrderByDict

  /**
   * Whether or not the given column is sortable.
   */
  hasColumn: (col: string) => boolean

  /**
   * Sets all columns to `OrderBy.none` and toggles the given column between
   * [OrderBy.none -> OrderBy.asc -> OrderBy.desc].
   */
  toggleColumn: (col: string) => void

  /**
   * Gets the current orderBy or the given default orderBy if it's empty, use
   * for querying in GraphQL.
   */
  getOrderBy: (defaultOrder: Record<string, OrderBy>) => OrderByDict
}

export type OrderByDict = Record<string, OrderBy>

export default function useTableSort(columns: string[]): TableSortResult {
  const initialState = Object.fromEntries(columns.map((col) => [col, OrderBy.none]))
  const [orderBy, setOrderBy] = useState<OrderByDict>(initialState)

  return {
    orderBy,
    hasColumn: (col: string) => columns.includes(col),
    toggleColumn: (col: string) => {
      // set all columns to OrderBy.none, except the given one, which is
      // toggled between [none -> asc -> desc].
      switch (orderBy[col]) {
        case OrderBy.none:
          setOrderBy({
            ...initialState,
            [col]: OrderBy.asc
          })
          return
        case OrderBy.asc:
          setOrderBy({
            ...initialState,
            [col]: OrderBy.desc
          })
          return
        case OrderBy.desc:
          setOrderBy({
            ...initialState,
            [col]: OrderBy.none
          })
          return
      }
    },
    getOrderBy: (defaultOrder: Record<string, OrderBy>): OrderByDict => {
      const entries = Object.entries(orderBy || {}).filter(([, sortOrder]) => sortOrder !== OrderBy.none)

      if (entries.length > 0) {
        return Object.fromEntries(entries)
      } else {
        return defaultOrder
      }
    }
  }
}
