import {
  Box,
  Button,
  Center,
  Image as ChakraImage,
  Grid,
  GridItem,
  HStack,
  Icon,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import * as faceapi from 'face-api.js'
import { FaceLandmarks68, Rect, TinyFaceDetectorOptions } from 'face-api.js'
import { Reply, ZoomIn } from 'heroicons-react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useMemo } from 'react'
import Cropper from 'react-easy-crop'
import { Size } from 'react-easy-crop/types'

import goalposts from '../assets/goalposts.svg'
import { ThemeModeEnum, useThemeMode } from '../hooks/useThemeMode'
import { useToggleDebugPreview } from '../hooks/useToggleDebugPreview'
import { BioType } from '../lib/api/booking'
import { PixelCrop, getCroppedImgFromCanvasSource, getCroppedImgFromImage, resizeImage } from '../lib/imageUtils'
import { FacePaintingCanvas } from './FacePaintingCanvas'
import CropIcon from './icons/CropIcon'
import RotateIcon from './icons/RotateIcon'

export const faceCaptureModalPrompt =
  'The width of the head should be 50% of the image, and it should be centered on the guideline.'
const minWidth = 480
const minHeight = 600
const offsetTolerance = 1.0 // (percent) The standards say "about" percentage,
//so we define some margin to apply a range that we're willing to tolerate

enum FaceImageStatus {
  loading = 'Loading',
  cropping = 'Cropping',
  facePerfectText = 'Perfect',
  faceTooCloseError = 'Face is too close',
  faceTooFarError = 'Face is too far',
  faceTooLeftError = 'Face is too left',
  faceTooRightError = 'Face is too right',
  faceTooHighError = 'Face is too high',
  faceTooLowError = 'Face is too low',
  facePositionError = 'Face is not well positioned',
  faceNotFoundError = 'No face found. Crop manually or retake the photo to continue.',
  faceAboveMinPixelsError = 'Photo is smaller than minimum size. Retake photo with subject closer to continue.'
}

// detectInputSize to pass to face-api
// it's the size at which image is processed, the smaller the faster,
// but less precise in detecting smaller faces, must be divisible
// by 32, common sizes are 128, 160, 224, 320, 416, 512, 608,
// for face tracking via webcam I would recommend using smaller sizes,
// e.g. 128, 160, for detecting smaller faces use larger sizes, e.g. 512, 608
// default: 416
export const detectInputSize = 320
export const detectThreshold = 0.5

function faceImageStatusIsFaceError(status: FaceImageStatus | undefined) {
  return (
    status === FaceImageStatus.faceTooCloseError ||
    status === FaceImageStatus.faceTooFarError ||
    status === FaceImageStatus.faceNotFoundError
  )
}

export interface EditPhotoModalProps {
  imageSrc?: string
  onDismiss: () => void
  onFinishCapture: (imageSrc: string) => void
  goToTakePhotoScreen?: () => void
  bioType?: BioType
  finishButtonText?: string
  themeMode?: ThemeModeEnum
  faceDetectionEnabled?: boolean
  isHidden?: boolean
}
export default function EditPhotoModalContents({
  imageSrc,
  onDismiss,
  onFinishCapture,
  goToTakePhotoScreen,
  bioType,
  finishButtonText = 'Save',
  themeMode = ThemeModeEnum.OLD,
  faceDetectionEnabled = true,
  isHidden = false
}: EditPhotoModalProps) {
  // cropper state management
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [croppedAreaPixels, setCropAreaPixels] = useState<PixelCrop>()
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)
  const [cropSize, setCropSize] = useState<Size>()
  const [cropping, setCropping] = useState(false)
  const cropperRef = useRef<Cropper>(null)

  // face detection and UI management
  const [faceapiModelsLoaded, setFaceapiModelsLoaded] = useState(false)
  const [faceImageStatus, setFaceImageStatus] = useState<FaceImageStatus>()
  const [eyeAverageY, setEyeAverageY] = useState<number>(0)
  const [allPoints, setAllPoints] = useState<faceapi.Point[] | undefined>()
  const [faceBoundLeft, setFaceBoundLeft] = useState(0)
  const [faceBoundRight, setFaceBoundRight] = useState(0)
  const [detectedFaceRect, setDetectedFaceRect] = useState<Rect>(new Rect(0, 0, 0, 0))
  const [resizedImageSrc, setResizedImageSrc] = useState<string | undefined>(undefined)
  const [debugPreviewSettings, setDebugPreviewSettings] = useState({ previewEnabled: false })
  const [manualCropping, setManualCropping] = useState(!faceDetectionEnabled)

  const { variantColor, color } = useThemeMode(themeMode)

  const shouldSaveButtonBeEnabled = useMemo(() => {
    if (!faceDetectionEnabled) {
      return true
    }

    return (
      bioType !== BioType.FaceFront ||
      (bioType === BioType.FaceFront && faceImageStatus === FaceImageStatus.facePerfectText) ||
      manualCropping
    )
  }, [bioType, faceImageStatus, manualCropping, faceDetectionEnabled])

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCropAreaPixels(croppedAreaPixels)
  }, [])

  useToggleDebugPreview(() => {
    setDebugPreviewSettings({ previewEnabled: !debugPreviewSettings.previewEnabled })

    if (!debugPreviewSettings.previewEnabled && bioType === BioType.FaceFront) {
      performCropForResize()
    }
  })

  const euclideanDist = (point1: faceapi.Point, point2: faceapi.Point): number => {
    const result: number = Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2))
    return result
  }

  const performCropForResize = useCallback(() => {
    async function doAutoCrop() {
      setCropping(true)
      setManualCropping(false)

      // set screen as "loading" until everything is prepped and we're cropping
      setFaceImageStatus(FaceImageStatus.loading)

      if (!faceapiModelsLoaded) {
        await faceapi.loadTinyFaceDetectorModel('/models')
        await faceapi.loadFaceLandmarkTinyModel('/models')
        setFaceapiModelsLoaded(true)
      }

      // this looks weird but saves a boatload of complication, basically the image used by the cropper is scaled down
      // to match the browser's internal zoom and the size of the crop. This leads to confusing and difficult issues
      // translating between like 6 different scales of browser, internal to the crop tool, external to the crop tool,
      // and the original image.We get around that by doing all of our math and face detection on the cropper's scaled
      // down version
      let imageRef = cropperRef.current?.imageRef

      if (!imageRef) {
        return
      }

      console.debug(`Initial imageRef.width: ${imageRef.width}, imageRef.height: ${imageRef.height}`)

      let sourceImage = new Image()
      let resizedImageSource = await resizeImage(imageRef, imageRef.width, imageRef.height)

      console.debug(`End imageRef.width: ${imageRef.width}, imageRef.height: ${imageRef.height}`)

      if (!resizedImageSource) {
        return
      }

      sourceImage.src = resizedImageSource
      await sourceImage.decode()

      console.debug(`doAutoCrop(), sourceImage.width: ${sourceImage.width}, sourceImage.height: ${sourceImage.height}`)

      // reset rotation for autocrop
      setRotation(0)
      setFaceImageStatus(FaceImageStatus.cropping)

      // need to find the highest-scored face (if any)
      let detectedFaceBounds = await faceapi.tinyFaceDetector(
        sourceImage,
        new TinyFaceDetectorOptions({
          inputSize: detectInputSize,
          scoreThreshold: detectThreshold
        })
      )

      console.debug(`doAutoCrop(), tinyFaceDetector called, detectedFaceBounds.length: ${detectedFaceBounds.length}`)

      if (detectedFaceBounds.length === 0) {
        setFaceImageStatus(FaceImageStatus.faceNotFoundError)
      }

      if (detectedFaceBounds && detectedFaceBounds.length) {
        // get the face with the highest score
        let highestScoreFaceBounds = detectedFaceBounds.sort((element1, element2) => {
          return element1.score - element2.score
        })[0]

        const faceBoundsForLandmarkDetection = {
          x: highestScoreFaceBounds.box.left,
          y: highestScoreFaceBounds.box.top,
          width: highestScoreFaceBounds.box.width,
          height: highestScoreFaceBounds.box.height
        }

        console.debug(`doAutoCrop(), face bounds rect: ${JSON.stringify(faceBoundsForLandmarkDetection)}`)

        // pull the bounding box out of that image so we can do landmark detection
        const boxCroppedImage = await getCroppedImgFromCanvasSource(sourceImage, faceBoundsForLandmarkDetection, 0)

        const croppedFaceForLandmarkDetection = new Image()
        croppedFaceForLandmarkDetection.src = boxCroppedImage!
        const faceLandmarks = (await faceapi.detectFaceLandmarksTiny(
          croppedFaceForLandmarkDetection
        )) as FaceLandmarks68

        if (!faceLandmarks) {
          // can't detect face, maybe user is goofing around, like being too close or not quite inside the frame,
          // or face-api just couldn't process for whatever other reason
          setFaceImageStatus(FaceImageStatus.faceNotFoundError)
          return
        }

        //         The “Head and Shoulders” photo composition
        // The composition consists of a subject’s head, partial shoulders, and plain background. For a
        // frontal-facing pose, the width of the subject’s head shall occupy approximately 50% of the
        // width of the captured image. This width shall be the horizontal distance between the midpoints
        // of two imaginary vertical lines.
        // December 2015 ANSI/NIST-ITL 1-2011 Update:2015 Page 531
        //
        // ANSI/NIST-ITL 1-2011: UPDATE 2015
        //
        // • The approximate horizontal mid-points of the mouth and of the bridge of the nose
        // shall lie on an imaginary vertical straight line positioned at the horizontal center of
        // the image.
        // • An imaginary horizontal line through the center of the subject’s eyes shall be located
        // at approximately the 55% point of the vertical distance up from the bottom edge of
        // the captured image.
        // • The width of the subject’s head shall occupy approximately 50% of the width of the
        // captured image. This width shall be the horizontal distance between the mid-points
        // of two imaginary vertical lines. Each imaginary line shall be drawn between the
        // upper and lower lobes of each ear and shall be positioned where the external ear
        // connects to the head.

        // points grabbed from https://pyimagesearch.com/wp-content/uploads/2017/04/facial_landmarks_68markup.jpg

        // Get the ear points
        const leftEarMidpoint: faceapi.Point = new faceapi.Point(
          (faceLandmarks.positions[0].x + faceLandmarks.positions[1].x) / 2,
          (faceLandmarks.positions[0].y + faceLandmarks.positions[1].y) / 2
        )
        const rightEarMidpoint: faceapi.Point = new faceapi.Point(
          (faceLandmarks.positions[15].x + faceLandmarks.positions[16].x) / 2,
          (faceLandmarks.positions[15].y + faceLandmarks.positions[16].y) / 2
        )

        const faceBoundLeft = Math.round(leftEarMidpoint.x + highestScoreFaceBounds.box.left)
        const faceBoundRight = Math.round(rightEarMidpoint.x + highestScoreFaceBounds.box.left)

        console.debug(
          `doAutoCrop(), leftEarMidpoint: ${JSON.stringify(leftEarMidpoint)}, rightEarMidpoint: ${JSON.stringify(
            rightEarMidpoint
          )}, faceBoundLeft: ${faceBoundLeft}, faceBoundRight: ${faceBoundRight}`
        )

        const faceWidth: number = Math.round(euclideanDist(leftEarMidpoint, rightEarMidpoint))

        console.debug(`doAutoCrop(), faceWidth: ${faceWidth}`)

        // // next, we calculate the average center of both eyes
        let allPoints = faceLandmarks.getLeftEye()
        allPoints.push(...faceLandmarks.getRightEye())
        const totalY = allPoints.reduce(function (accumulator, currentValue, initialValue) {
          return accumulator + currentValue.y
        }, 0)
        const eyeAverageY = Math.round(totalY / allPoints.length + highestScoreFaceBounds.box.top)

        // calculate the margin that we're willing to tolerate
        const widthMargin = (minWidth * offsetTolerance) / 100
        const heightMargin = (minHeight * offsetTolerance) / 100

        // now we have enough information to calculate the ideal crop rect, knowing that the face width should take up
        // half the crop width, that the eye line should be at 55% of the image height, and that the image must be 4:5

        // We need to account for the cases where the image size can be very close or at the minSize requirement,
        // such as with the Sony camera

        // get the ideal width first, and check if face is optimal enough
        let idealCropRectWidth = Math.round(2 * faceWidth)
        if (idealCropRectWidth > sourceImage.width) {
          console.debug(
            `doAutoCrop(), crop rect too Wide: ${idealCropRectWidth}, sourceImage.width: ${sourceImage.width}, widthMargin: ${widthMargin}`
          )
          if (idealCropRectWidth - sourceImage.width > widthMargin) {
            // too close
            setFaceImageStatus(FaceImageStatus.faceTooCloseError)
            return
          } else {
            idealCropRectWidth = sourceImage.width
            // now adjust back to the left
          }
        }

        // get the left boundary of the ideal crop rectangle
        let idealCropRectLeft = Math.floor(faceBoundLeft - faceWidth / 2)
        // check if it is negative; this means that face is too much to the left
        if (0 > idealCropRectLeft) {
          console.debug(`doAutoCrop(), crop rect too Left: ${idealCropRectLeft}, widthMargin: ${widthMargin}`)
          // crop rectangle is off to the left of the source image
          if (Math.abs(idealCropRectLeft) <= widthMargin) {
            // within the allowed margin; move back to the right
            idealCropRectLeft = 0
          } else {
            setFaceImageStatus(FaceImageStatus.faceTooLeftError)
            return
          }
        }

        // get the right boundary of the ideal crop rectangle
        let idealCropRectRight = Math.round(idealCropRectLeft + idealCropRectWidth)
        // check if it is past the image width; this means that face is too much to the right
        if (idealCropRectRight > sourceImage.width) {
          console.debug(
            `doAutoCrop(), crop rect too Right: ${idealCropRectRight}, sourceImage.width: ${sourceImage.width}, widthMargin: ${widthMargin}`
          )
          // crop rectangle is off to the right of the source image
          if (Math.abs(idealCropRectRight - sourceImage.width) <= widthMargin) {
            // within the allowed margin; move back to the left
            idealCropRectRight = sourceImage.width
            // if the left position ended up being negative at this point, then the face is too close still,
            // and this would be caught by the final checks
            idealCropRectLeft = sourceImage.width - idealCropRectWidth
          }
        }

        // get the height of the ideal crop rectangle
        let idealCropRectHeight = Math.round((idealCropRectWidth * 5) / 4)
        if (idealCropRectHeight > sourceImage.height) {
          console.debug(
            `doAutoCrop(), crop rect too Tall: ${idealCropRectHeight}, sourceImage.height: ${sourceImage.height}, heightMargin: ${heightMargin}`
          )
          // crop rectangle is taller than the source image height
          if (Math.abs(idealCropRectHeight - sourceImage.height) <= heightMargin) {
            // within the allowed margin; use the image height
            idealCropRectHeight = sourceImage.height
          } else {
            setFaceImageStatus(FaceImageStatus.faceTooCloseError)
            return
          }
        }

        // get the top boundary of the crop rectangle
        let idealCropRectTop = Math.floor(eyeAverageY - 0.45 * idealCropRectHeight) // 45% is above, 55% is below
        if (0 > idealCropRectTop) {
          console.debug(`doAutoCrop(), crop rect too High: ${idealCropRectTop}, heightMargin: ${heightMargin}`)
          // crop rectangle is off to the top of the source image
          if (Math.abs(idealCropRectTop) <= heightMargin) {
            // within the allowed margin; move back lower
            idealCropRectTop = 0
          } else {
            setFaceImageStatus(FaceImageStatus.faceTooHighError)
            return
          }
        }

        // get the bottom boundary of the crop rectangle
        let idealCropRectBottom = Math.round(idealCropRectTop + idealCropRectHeight)
        // check if it is past the image height; this means that face is too low
        if (idealCropRectBottom > sourceImage.height) {
          console.debug(`doAutoCrop(), crop rect too Low: ${idealCropRectBottom}, heightMargin: ${heightMargin}`)
          // crop rectangle is off to the bottom of the source image
          if (Math.abs(idealCropRectBottom - sourceImage.height) <= heightMargin) {
            // within the allowed margin; move back up
            idealCropRectBottom = sourceImage.height
            // if the top position ended up being negative at this point, then the face is too close still,
            // and this would be caught by the final checks
            idealCropRectTop = idealCropRectBottom - idealCropRectHeight
          } else {
            setFaceImageStatus(FaceImageStatus.faceTooLowError)
            return
          }
        }

        console.debug(
          `doAutoCrop(), After adjustments, idealCropRectLeft: ${idealCropRectLeft}, idealCropRectTop: ${idealCropRectTop}, idealCropRectBottom: ${idealCropRectBottom}, idealCropRectWidth: ${idealCropRectWidth}, idealCropRectHeight: ${idealCropRectHeight}, widthMargin: ${widthMargin}, heightMargin: ${heightMargin}`
        )

        // If after all of the adjustments above, we still end up with negative values in the crop rectangle,
        // then that would eventually lead to displaying of a face positioning error during the checks that follow below

        const cropRect = new Rect(idealCropRectLeft, idealCropRectTop, idealCropRectWidth, idealCropRectHeight)
        const imageRect = new Rect(0, 0, sourceImage.width, sourceImage.height)

        console.debug(
          `doAutoCrop(), eyeAverageY: ${eyeAverageY}, cropRect: ${JSON.stringify(
            cropRect
          )}, imageRect: ${JSON.stringify(imageRect)}`
        )

        let supposedZoom = clamp((cropSize?.width ?? 0) / idealCropRectWidth, 1, 3)
        supposedZoom = supposedZoom || 1

        const cropCenter = {
          x: Math.round(idealCropRectLeft + idealCropRectWidth / 2),
          y: Math.round(idealCropRectTop + idealCropRectHeight / 2)
        }
        const imageCenter = {
          x: Math.round(imageRect.width / 2),
          y: Math.round(imageRect.height / 2)
        }

        const cropX = Math.round((imageCenter.x - cropCenter.x) * supposedZoom)
        const cropY = Math.round((imageCenter.y - cropCenter.y) * supposedZoom)

        let allFacePoints = faceLandmarks.positions.map((point) => {
          return new faceapi.Point(point.x + highestScoreFaceBounds.box.left, point.y + highestScoreFaceBounds.box.top)
        })

        console.debug(
          `doAutoCrop(), cropRect: ${JSON.stringify(cropRect)}, imageRect: ${JSON.stringify(
            imageRect
          )}, cropX: ${cropX}, cropY: ${cropY}, supposedZoom: ${supposedZoom}`
        )

        console.debug(
          `doAutoCrop(), cropCenter: ${JSON.stringify(cropCenter)}, imageCenter: ${JSON.stringify(
            imageCenter
          )}, croppedAreaPixels: ${JSON.stringify(
            croppedAreaPixels
          )}, minWidth: ${minWidth}, minHeight: ${minHeight}, supposedZoom: ${supposedZoom}`
        )

        if (croppedAreaPixels && (croppedAreaPixels.width < minWidth || croppedAreaPixels.height < minHeight)) {
          setFaceImageStatus(FaceImageStatus.faceAboveMinPixelsError)
        } else if (!isRectangleInBounds(cropRect, imageRect)) {
          console.debug(
            `doAutoCrop(), Rectangle not in bounds, cropRect: ${JSON.stringify(cropRect)}, imageRect: ${JSON.stringify(
              imageRect
            )}`
          )

          // check if in bounds
          if (isRectangleInBounds(cropRect, imageRect)) {
            setFaceImageStatus(FaceImageStatus.facePerfectText)
          } else {
            if (isFaceTooLeft(cropRect, imageRect)) {
              setFaceImageStatus(FaceImageStatus.faceTooLeftError)
            } else {
              if (isFaceTooRight(cropRect, imageRect)) {
                setFaceImageStatus(FaceImageStatus.faceTooRightError)
              } else {
                if (isFaceTooHigh(cropRect, imageRect)) {
                  setFaceImageStatus(FaceImageStatus.faceTooHighError)
                } else {
                  if (isFaceTooLow(cropRect, imageRect)) {
                    setFaceImageStatus(FaceImageStatus.faceTooLowError)
                  } else {
                    if (isFaceTooClose(cropRect, imageRect)) {
                      setFaceImageStatus(FaceImageStatus.faceTooCloseError)
                    } else {
                      setFaceImageStatus(FaceImageStatus.facePositionError)
                    }
                  }
                }
              }
            }
          }
        } else {
          setFaceImageStatus(FaceImageStatus.facePerfectText)
        }

        setDetectedFaceRect(cropRect)
        setResizedImageSrc(sourceImage.src)
        setFaceBoundLeft(faceBoundLeft)
        setFaceBoundRight(faceBoundRight)
        setAllPoints(allFacePoints)
        setEyeAverageY(eyeAverageY)
        setCrop({ x: cropX, y: cropY })
        setZoom(supposedZoom)
      }

      setCropping(false)
    }

    doAutoCrop()
  }, [cropSize, faceapiModelsLoaded, croppedAreaPixels])

  useEffect(() => {
    if (bioType === BioType.FaceFront && cropSize && faceDetectionEnabled) {
      performCropForResize()
    }
  }, [cropSize, bioType, faceDetectionEnabled, performCropForResize])

  // some utility functions, TODO: we should be able to refactor this and the above autocrop function somewhere else
  const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)

  function isRectangleInBounds(faceRect: Rect, imageBounds: Rect): boolean {
    return (
      faceRect.x >= imageBounds.x &&
      faceRect.x + faceRect.width <= imageBounds.x + imageBounds.width &&
      faceRect.y >= imageBounds.y &&
      faceRect.y + faceRect.height <= imageBounds.y + imageBounds.height
    )
  }

  // simple face closeness check, if the face height is too big and the face Y is negative, then the face is
  // probably just too close to the camera
  function isFaceTooClose(faceRect: Rect, imageBounds: Rect): boolean {
    return (
      faceRect.y < 0 ||
      (faceRect.y + faceRect.height > imageBounds.height && faceRect.height > imageBounds.height * 0.75) ||
      faceRect.x < 0 ||
      (faceRect.x + faceRect.width > imageBounds.width && faceRect.width > imageBounds.width * 0.5)
    )
  }

  function isFaceTooHigh(faceRect: Rect, imageBounds: Rect): boolean {
    return faceRect.y < 0
  }

  function isFaceTooLow(faceRect: Rect, imageBounds: Rect): boolean {
    return faceRect.y + faceRect.height > imageBounds.height
  }

  function isFaceTooLeft(faceRect: Rect, imageBounds: Rect): boolean {
    return faceRect.x < 0
  }

  function isFaceTooRight(faceRect: Rect, imageBounds: Rect): boolean {
    return faceRect.x + faceRect.width > imageBounds.width
  }

  function startManualCropping() {
    setManualCropping(true)
  }

  async function onFinishCaptureInternal() {
    if (!croppedAreaPixels || !imageSrc) {
      return
    }

    const croppedImage = await getCroppedImgFromImage(imageSrc, croppedAreaPixels, rotation)
    onFinishCapture(croppedImage ? croppedImage : '')
  }

  function onCropSizeChange(newCropSize: { width: number; height: number }) {
    console.debug(`onCropSizeChange(), newCropSize width: ${newCropSize.width}, height: ${newCropSize.height}`)
    setCropSize(newCropSize)
  }

  return (
    <Box height="100%" style={{ display: isHidden ? 'none' : 'block' }}>
      <ModalHeader>Edit photo</ModalHeader>
      <ModalCloseButton />
      <Box ml={6} mr={6} mb={3}>
        {faceCaptureModalPrompt}
      </Box>
      <VStack>
        <Box position="relative" w="100%" minW="100%" height="500px">
          <Box id="crop_container">
            {isHidden || debugPreviewSettings.previewEnabled ? (
              <FacePaintingCanvas
                imageSrc={resizedImageSrc}
                croppedArea={detectedFaceRect}
                eyeAverageY={eyeAverageY}
                eyePoints={allPoints}
                faceBoundsLeft={faceBoundLeft}
                faceBoundsRight={faceBoundRight}
              />
            ) : (
              <Cropper
                style={
                  bioType === BioType.FaceFront &&
                  faceImageStatus === FaceImageStatus.faceNotFoundError &&
                  !manualCropping
                    ? { mediaStyle: { filter: 'blur(4px)' } }
                    : {}
                }
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                rotation={rotation}
                aspect={4 / 5}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                showGrid={false}
                onCropSizeChange={onCropSizeChange}
                ref={cropperRef}
                initialCroppedAreaPixels={croppedAreaPixels}
                restrictPosition={false}
              />
            )}
          </Box>
          {!debugPreviewSettings.previewEnabled && (
            <Center pointerEvents="none" height="100%">
              <ChakraImage
                src={goalposts}
                alt="align the eyes with the goal post"
                width={cropSize?.width}
                height={cropSize?.height}
                zIndex={2}
              />
              {bioType === BioType.FaceFront &&
                !cropping &&
                !manualCropping &&
                faceImageStatusIsFaceError(faceImageStatus) && (
                  <Box
                    width={cropSize?.width}
                    height={cropSize?.height}
                    position="absolute"
                    borderColor="green.600"
                    borderWidth={3}
                    zIndex={3}
                  />
                )}
              {bioType === BioType.FaceFront && !manualCropping && (
                <Box
                  src={goalposts}
                  alt="align the eyes with the goal post"
                  width="100%"
                  bgColor="gray.700"
                  opacity="0.9"
                  color="white"
                  position="absolute"
                  zIndex={3}
                  bottom={0}
                >
                  <Center padding={2}>{faceImageStatus}</Center>
                </Box>
              )}
            </Center>
          )}
        </Box>

        <Grid id="photo_manip_bar" w="100%" pl={6} pr={6} templateColumns="repeat(18, 1fr)" gap={1}>
          <GridItem colSpan={4}>
            {bioType === BioType.FaceFront && (
              <HStack
                as={Button}
                p={2}
                rounded="8px"
                onClick={startManualCropping}
                border="none"
                alt="crop icon"
                variant={variantColor.secondary}
                bg="gray.100"
                // Commenting out the line below but leaving it as a reference for now,
                // to revisit when we refactor the auto cropping feature and options
                // disabled={faceDetectionEnabled && !faceImageStatusIsFaceError(faceImageStatus)}
              >
                <Icon as={CropIcon} fill={color.secondary} h={4} w={4} />
                <Text textOverflow="ellipsis" fontWeight={400}>
                  Crop manually
                </Text>
              </HStack>
            )}
          </GridItem>
          <GridItem colSpan={5}>
            <HStack bg="gray.100" p={2} rounded="8px">
              <Icon as={ZoomIn} color={color.secondary} h={4} w={4} />
              <Text color={color.secondary} pr={1}>
                Zoom
              </Text>
              <Slider value={zoom} onChange={(zoom) => setZoom(zoom)} min={1} max={3} step={0.01}>
                <SliderTrack bg="gray.200" height="8px" borderRadius="8px">
                  <SliderFilledTrack bg={color.secondary} />
                </SliderTrack>
                <SliderThumb bg={color.secondary} height="16px" width="16px" />
              </Slider>
            </HStack>
          </GridItem>
          <GridItem colSpan={5}>
            <HStack bg="gray.100" p={2} rounded="8px">
              <Icon as={RotateIcon} fill={color.secondary} h={5} w={5} />
              <Text color={color.secondary} pl={1}>
                Rotate
              </Text>
              <Slider defaultValue={0} onChange={(rotation) => setRotation(rotation)} min={-90} max={90} step={1}>
                <SliderTrack bg="gray.200" height="8px" borderRadius="8px">
                  <SliderFilledTrack bg={color.secondary} />
                </SliderTrack>
                <SliderThumb bg={color.secondary} height="16px" width="16px" />
              </Slider>
            </HStack>
          </GridItem>
          <GridItem colSpan={4}>
            {bioType === BioType.FaceFront && faceDetectionEnabled && (
              <HStack
                as={Button}
                p={2}
                rounded="8px"
                onClick={performCropForResize}
                border="none"
                alt="crop icon"
                variant={variantColor.secondary}
                bg="gray.100"
              >
                <Icon as={Reply} color={color.secondary} h={4} w={4} />
                <Text color={color.secondary} textOverflow="ellipsis" fontWeight={400}>
                  Revert to original
                </Text>
              </HStack>
            )}
          </GridItem>
        </Grid>
      </VStack>
      <ModalFooter bottom={0}>
        <Spacer />
        {/* acts like an Undo button that just does autocrop again */}
        <Button variant={variantColor.secondary} mr={3} onClick={onDismiss}>
          Cancel
        </Button>
        <Button variant={variantColor.primary} mr={3} onClick={goToTakePhotoScreen}>
          Retake
        </Button>
        <Button
          data-cy="savePhoto"
          variant={variantColor.primary}
          disabled={!shouldSaveButtonBeEnabled}
          onClick={onFinishCaptureInternal}
        >
          {finishButtonText}
        </Button>
      </ModalFooter>
    </Box>
  )
}
