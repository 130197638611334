import { useEffect, useRef } from 'react'

import BookingImage, { BookingImageMetadata } from './BookingImage'

/**
 *
 * @param bookingImage The image to download.
 * @param callback A function to be called once the download completes.
 */
export default function useDownloadImage(
  bookingImage: BookingImage<BookingImageMetadata> | null | undefined,
  callback?: () => void
) {
  const mountedRef = useRef(true)

  useEffect(() => {
    ;(async () => {
      if (bookingImage?.image.isURL) {
        await bookingImage.image.download()
        // prevent call if the component is unmounted before the download
        // finishes, see https://stackoverflow.com/a/60693711/9778302
        mountedRef.current && callback && callback()
      }
    })()

    return () => {
      mountedRef.current = false
    }
  }, [bookingImage, callback])
}
