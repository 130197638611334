import agencyConfig from '../lib/agencyConfig'

const { bookings } = agencyConfig.features

export const activeCaptureAnnotationCode = bookings.activeCaptureAnnotationCode

export const {
  postCaptureAnnotations: postCaptureFingerprintAnnotations,
  preCaptureAnnotations: preCaptureFingerprintAnnotations
} = bookings.fingerprint

export const {
  postCaptureAnnotations: postCapturePalmprintAnnotations,
  preCaptureAnnotations: preCapturePalmprintAnnotations
} = bookings.palm

export const { postCaptureAnnotations: postCaptureIrisAnnotations, preCaptureAnnotations: preCaptureIrisAnnotations } =
  bookings.iris
