import { useCallback, useEffect, useRef, useState } from 'react'

import agencyConfig from '../../lib/agencyConfig'
import { BioType, replaceImgByBioType } from '../../lib/api/booking'
import { readFile } from '../../lib/fileUtils'
// TODO: Refactor Fingerprint constants
import {
  ScanOrder,
  ScanStep,
  expectedFingersLeftFourSlap,
  expectedFingersRightFourSlap,
  isFingerprintDefinition
} from '../../pages/booking/fingerprint/constants'
import { bookingState } from '../../pages/booking/state'
import { activeCaptureAnnotationCode } from '../../utils/captureAnnotations'
import { DeviceManager } from '../DeviceManager'
import { DeviceInfo, ManagerEvents } from '../IWNativeExtDeviceDefines'
import {
  CaptureRequestOption,
  IWImage,
  ModalityDefinitions,
  ScanElementState,
  ScanResultStatus,
  ScanStatus
} from '../IWNativeExtDeviceDefines'
import { CaptureRequestOptions, ModalityConfiguration } from '../configuration'
import {
  cancelCaptureEventHandlerWrapper,
  captureEventHandlerWrapper,
  closeDeviceEventHandlerWrapper,
  deviceEventHandlerWrapper,
  getConfigurationOptionsEventHandlerWrapper,
  getDeviceOptionsEventHandlerWrapper,
  getDevicesListEventHandlerWrapper,
  getPluginsListEventHandlerWrapper,
  openDeviceEventHandlerWrapper
} from './deviceEventHandlers'
import { useScanStepsGenerator } from './useScanStepsGenerator'
import {
  addModalityCaptureDeviceInfoToBookingState,
  clearCanvas,
  deleteBookingImages,
  getIWImages,
  getImageInfo,
  getMissingDigits,
  removeModalityCaptureDeviceInfoFromBookingState
} from './utils'

/*
 * This hook creates listeners for the NativeExtension Device Events.
 * It subscribes to the Device Events and cleans them up when it is unmounted.
 * It keeps track of all variables involved in the scanning process.
 * This hook is not intended to be used by itself. It should be used by calling the
 * useDeviceManager hook.
 */

export function useDeviceEvents(
  deviceManager: DeviceManager,
  modalityConfiguration: ModalityConfiguration,
  scanOrder: ScanOrder,
  scanElements: { [x: string]: ScanElementState | string },
  finishScanCallback?: () => void
) {
  const {
    modality,
    captureRequestOptions: initialCaptureRequestOptions,
    loadFromFileOptions: { acceptedFileExtensions, maximumFileSize }
  } = modalityConfiguration
  const [pluginID, setPluginID] = useState<string | undefined>(undefined)
  const [pluginCapabilities, setPluginCapabilities] = useState<number[]>([])
  const [deviceInstance, setDeviceInstance] = useState<number>(0)
  const [deviceCapabilities, setDeviceCapabilities] = useState<number[]>([])
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfo | undefined>(undefined)
  const [isLoadFromFile, setIsLoadFromFile] = useState(false)
  const [isMultipleCaptureAvailable, setIsMultipleCaptureAvailable] = useState(false)
  const [isDeviceOpen, setDeviceOpen] = useState(false)
  const [isRecapture, setIsRecapture] = useState(false)
  const inputFileRef = useRef<HTMLInputElement>(null)
  const scanFramesCanvasRef1 = useRef<HTMLCanvasElement>(null)
  const scanFramesCanvasRef2 = useRef<HTMLCanvasElement>(null)

  // Secondary, optional plugin for fingerprint cards
  const [fpCardPluginID, setFPCardPluginID] = useState<string | undefined>(undefined)

  const { changeScanOrder, nextScanStep, scanSteps, isDirty } = useScanStepsGenerator(scanOrder, scanElements)
  const [globalScanStatus, setGlobalScanStatus] = useState(ScanStatus.Idle)
  const [scanStatus, setScanStatus] = useState<ScanStatus>(ScanStatus.Idle)
  const [scanProgress, setScanProgress] = useState<number>(0)
  const [scanResultStatus, setScanResultStatus] = useState<ScanResultStatus>(ScanResultStatus.Default)
  const [messageStatus, setMessageStatus] = useState('')
  const [scannedImageList, setScannedImageList] = useState<IWImage[]>([])

  const [deviceOptionsMap, setDeviceOptionsMap] = useState<Map<string, string> | null>(null)

  const capture = useCallback(
    async (currentStep: ScanStep, event?: Event) => {
      clearScanStep()
      setScanStatus(ScanStatus.Scanning)

      try {
        let captureRequestOptions: CaptureRequestOptions = initialCaptureRequestOptions
        const captureImageType = Array.isArray(currentStep)
          ? currentStep.filter((step) => scanElements[step] === activeCaptureAnnotationCode).join()
          : isFingerprintDefinition(currentStep)
          ? currentStep.key
          : currentStep

        if (isLoadFromFile && event) {
          const target = event.target as HTMLInputElement
          const file = target.files?.[0]
          target.value = ''

          if (file) {
            const base64File = await readFile(file, acceptedFileExtensions, maximumFileSize)
            captureRequestOptions[CaptureRequestOption.File] = base64File
          }
        }

        // Check for missing objects in the current modality. Define the missing digit option in any case.
        const missingDigits = getMissingDigits(scanElements, modality)
        captureRequestOptions[CaptureRequestOption.MissingDigits] = missingDigits.length ? missingDigits : ''

        captureRequestOptions[CaptureRequestOption.CaptureImageType] = captureImageType

        // method to get the quality and sequence check options
        const getSequenceAndQualityCheckSettings = (modality: ModalityDefinitions) => {
          const modalitySetting: any = new Map(Object.entries(agencyConfig.features?.bookings))?.get(
            ModalityDefinitions[modality].toLowerCase()
          )

          // if the disable options are undefined, we default to false
          const disableSequenceChecking: boolean = modalitySetting?.disableSequenceChecking ?? false
          const disableQualityChecking: boolean = modalitySetting?.disableQualityChecking ?? false

          console.debug(
            `modality: ${ModalityDefinitions[modality].toLowerCase()}, modalitySetting: ${JSON.stringify(
              modalitySetting
            )}, disableSequenceChecking: ${disableSequenceChecking}, disableQualityChecking: ${disableQualityChecking}`
          )

          return {
            doQualityChecking: false === disableQualityChecking,
            doSequenceChecking: false === disableSequenceChecking
          }
        }

        const { doQualityChecking, doSequenceChecking } = getSequenceAndQualityCheckSettings(modality)
        console.debug(
          `modality: ${ModalityDefinitions[modality]}, doQualityChecking: ${doQualityChecking}, doSequenceChecking: ${doSequenceChecking}`
        )

        captureRequestOptions[CaptureRequestOption.DoQualityChecking] = doQualityChecking.toString()

        // If sequence check is enabled, we pass all currently collected images
        const IWImages = doSequenceChecking ? getIWImages(bookingState, modality) : []

        // if an upper palm is requested, add all corresponding rolled impressions
        if (modality === ModalityDefinitions.Palm && doSequenceChecking) {
          // returns the corresponding rolled impressions for an upper palm
          const correspondingFingerRolls = (slapType: string): string[] | null => {
            switch (slapType) {
              case 'RightUpperPalm':
                return ['RightIndexRoll', 'RightMiddleRoll', 'RightRingRoll', 'RightLittleRoll']

              case 'LeftUpperPalm':
                return ['LeftIndexRoll', 'LeftMiddleRoll', 'LeftRingRoll', 'LeftLittleRoll']

              default:
                return null
            }
          }

          // get the entire list of rolls for this upper palm
          // rolledFingerNames will be valid only if we're capturing upper palms
          const rolledFingerNames = correspondingFingerRolls(captureImageType)
          if (rolledFingerNames) {
            // get all fingerprints and filter out the rolled impressions
            const fpImages = getIWImages(bookingState, ModalityDefinitions.Fingerprint)
            const rolledImages =
              0 < fpImages.length
                ? fpImages.filter((element) => rolledFingerNames.includes(element.metaData['CaptureImageType']))
                : null
            // get the names of the rolled fingers
            const rolledFingers = rolledImages?.map((x) => x.metaData['CaptureImageType'])
            // the missing names identify which fingers were NOT rolled
            const missing = rolledFingerNames?.filter((finger) => !rolledFingers?.includes(finger))
            // push whatever images we do have
            if (rolledImages) IWImages.push(...rolledImages)
            // and finally set the missing digits capture option
            if (missing) {
              captureRequestOptions.MissingDigits = (
                captureRequestOptions.MissingDigits?.length ? captureRequestOptions.MissingDigits.split(',') : []
              )
                .concat(missing.length ? missing.join(',').replaceAll('Roll', '').split(',') : [])
                .toString()
            }
          }
        }

        let preferredPluginID = pluginID

        // FBICard manages its own sequencing
        if (captureImageType === BioType.FBICard) {
          captureRequestOptions[CaptureRequestOption.DoQualityChecking] = 'false'
          preferredPluginID = fpCardPluginID
        }

        if (preferredPluginID) {
          deviceManager.Capture(
            preferredPluginID,
            deviceInstance,
            captureRequestOptions,
            IWImages?.length ? IWImages : null
          )
        }
      } catch (error) {
        setScanResultStatus(ScanResultStatus.ScanError)
        setMessageStatus(error as string)
        setScanStatus(ScanStatus.Error)
      }
    },
    [
      acceptedFileExtensions,
      deviceInstance,
      deviceManager,
      initialCaptureRequestOptions,
      isLoadFromFile,
      maximumFileSize,
      modality,
      pluginID,
      fpCardPluginID,
      scanElements
    ]
  )

  const scan = useCallback(() => {
    if (isLoadFromFile && inputFileRef.current) {
      inputFileRef.current.onchange = (e: Event) => capture(scanSteps.currentStep, e)
      inputFileRef.current.click()
    } else {
      capture(scanSteps.currentStep)
    }
  }, [capture, isLoadFromFile, scanSteps.currentStep])

  const acceptScan = useCallback(() => {
    if (scannedImageList.length) {
      if (modality === ModalityDefinitions.Fingerprint) {
        const image = scannedImageList[0]
        const { dataURI, wsqB64, bioType } = getImageInfo(image)
        let quality: string | string[] = image.metaData.SequencerQuality
        const width = image.metaData.ImageWidth || ''
        const height = image.metaData.ImageHeight || ''
        const minutiaeCount = image.metaData.MinutiaeCount || ''
        if ([BioType.LeftFourSlap, BioType.RightFourSlap].includes(bioType)) {
          const expectedFingers =
            bioType === BioType.LeftFourSlap ? expectedFingersLeftFourSlap : expectedFingersRightFourSlap

          quality = expectedFingers.map((expectedBioType) => {
            const image = scannedImageList.find((image) => image.metaData.CaptureImageType === expectedBioType)
            return image ? image.metaData.SequencerQuality : '-1'
          })
        }
        const metadata = { quality, width, height, minutiaeCount }
        bookingState.bookingImages.setImage(dataURI, bioType, metadata, wsqB64, replaceImgByBioType)

        if (bioType === BioType.FBICard) {
          // Up to 14 biotypes captured at once. The image of the entire card is scannedListImageList[0]
          scannedImageList.slice(1).forEach((subImage) => {
            const { dataURI, wsqB64, bioType } = getImageInfo(subImage)
            quality = subImage.metaData.SequencerQuality
            const width = subImage.metaData.ImageWidth || ''
            const height = subImage.metaData.ImageHeight || ''
            const minutiaeCount = subImage.metaData.MinutiaeCount || ''
            if ([BioType.LeftFourSlap, BioType.RightFourSlap].includes(bioType)) {
              const expectedFingers =
                bioType === BioType.LeftFourSlap ? expectedFingersLeftFourSlap : expectedFingersRightFourSlap
              quality = expectedFingers.map((expectedBioType) => {
                const image = scannedImageList.find((image) => image.metaData.CaptureImageType === expectedBioType)
                return image ? image.metaData.SequencerQuality : '-1'
              })
            }
            const metadata = { quality, width, height, minutiaeCount }
            bookingState.bookingImages.setImage(dataURI, bioType, metadata, wsqB64, replaceImgByBioType)
          })
        }
      } else if (modality === ModalityDefinitions.Iris) {
        scannedImageList.forEach((image) => {
          const { dataURI, bioType } = getImageInfo(image)
          const width = image.metaData.ImageWidth || ''
          const height = image.metaData.ImageHeight || ''
          const irisRadius = image.metaData.IrisRadius || ''
          const irisFocus = image.metaData.IrisFocus || ''
          const irisVisibility = image.metaData.IrisVisibility || ''
          const SNR = image.metaData.SNR || ''
          const irisPupilContrast = image.metaData.IrisPupilContrast || ''
          const scleraIrisContrast = image.metaData.ScleraIrisContrast || ''
          const contactLensType = image.metaData.ContactLensType || ''
          const contactLensScore = image.metaData.ContactLensScore || ''
          let quality: string = image.metaData.ScanCaptureQualityScore || '-1'
          const metadata = {
            quality,
            width,
            height,
            irisRadius,
            irisFocus,
            irisVisibility,
            SNR,
            irisPupilContrast,
            scleraIrisContrast,
            contactLensType,
            contactLensScore
          }
          bookingState.bookingImages.setImage(dataURI, bioType, metadata, undefined, replaceImgByBioType)
        })
      } else {
        const image = scannedImageList[0]
        const { dataURI, wsqB64, bioType } = getImageInfo(image)
        const width = image.metaData.ImageWidth || ''
        const height = image.metaData.ImageHeight || ''
        const quality = image.metaData.SequencerQuality || ''
        bookingState.bookingImages.setImage(dataURI, bioType, { quality, width, height }, wsqB64, replaceImgByBioType)
      }
    }

    clearScanStep()
    nextScanStep()
  }, [modality, nextScanStep, scannedImageList])

  useEffect(() => {
    const getPluginsListHandler = getPluginsListEventHandlerWrapper(
      setPluginID,
      setFPCardPluginID,
      setPluginCapabilities,
      setIsLoadFromFile,
      deviceManager
    )
    const getDevicesListHandler = getDevicesListEventHandlerWrapper(
      setDeviceInstance,
      setDeviceCapabilities,
      setIsMultipleCaptureAvailable,
      setDeviceInfo
    )
    const openDeviceEventHandler = openDeviceEventHandlerWrapper(
      setDeviceOpen,
      setMessageStatus,
      setScanResultStatus,
      setScanStatus
    )
    const closeDeviceEventHandler = closeDeviceEventHandlerWrapper(setDeviceOpen)
    const deviceEventHandler = deviceEventHandlerWrapper(setMessageStatus, setScanProgress, modality, scanStatus, [
      scanFramesCanvasRef1,
      scanFramesCanvasRef2
    ])
    const captureEventHandler = captureEventHandlerWrapper(
      setScannedImageList,
      setScanResultStatus,
      setMessageStatus,
      setScanStatus,
      clearScanStep,
      modality,
      isLoadFromFile,
      isMultipleCaptureAvailable,
      scanStatus,
      [scanFramesCanvasRef1, scanFramesCanvasRef2]
    )
    const cancelCaptureEventHandler = cancelCaptureEventHandlerWrapper()
    const getDeviceOptionsHandler = getDeviceOptionsEventHandlerWrapper(setDeviceOptionsMap)
    const getConfigurationOptionsHandler = getConfigurationOptionsEventHandlerWrapper()

    document.addEventListener(ManagerEvents.GetPluginsList, getPluginsListHandler)
    document.addEventListener(ManagerEvents.GetDevicesList, getDevicesListHandler)
    document.addEventListener(ManagerEvents.OpenDevice, openDeviceEventHandler)
    document.addEventListener(ManagerEvents.CloseDevice, closeDeviceEventHandler)
    document.addEventListener(ManagerEvents.DeviceEvent, deviceEventHandler)
    document.addEventListener(ManagerEvents.Capture, captureEventHandler)
    document.addEventListener(ManagerEvents.CancelCapture, cancelCaptureEventHandler)
    document.addEventListener(ManagerEvents.GetDeviceOptions, getDeviceOptionsHandler)
    document.addEventListener(ManagerEvents.GetConfigurationOptions, getConfigurationOptionsHandler)

    return () => {
      document.removeEventListener(ManagerEvents.GetPluginsList, getPluginsListHandler)
      document.removeEventListener(ManagerEvents.GetDevicesList, getDevicesListHandler)
      document.removeEventListener(ManagerEvents.OpenDevice, openDeviceEventHandler)
      document.removeEventListener(ManagerEvents.CloseDevice, closeDeviceEventHandler)
      document.removeEventListener(ManagerEvents.DeviceEvent, deviceEventHandler)
      document.removeEventListener(ManagerEvents.Capture, captureEventHandler)
      document.removeEventListener(ManagerEvents.CancelCapture, cancelCaptureEventHandler)
      document.removeEventListener(ManagerEvents.GetDeviceOptions, getDeviceOptionsHandler)
      document.removeEventListener(ManagerEvents.GetConfigurationOptions, getConfigurationOptionsHandler)
    }
  }, [deviceManager, isLoadFromFile, isMultipleCaptureAvailable, modality, scanStatus])

  useEffect(() => {
    if (!isLoadFromFile && isDeviceOpen && scanSteps.currentIndex !== -1) {
      scan()
    }
  }, [isDeviceOpen, isLoadFromFile, scan, scanSteps.currentIndex])

  useEffect(() => {
    const fingerWithSequencerScore =
      (modality === ModalityDefinitions.Fingerprint || modality === ModalityDefinitions.Palm) &&
      [ScanResultStatus.GoodQuality, ScanResultStatus.AcceptableQuality].includes(scanResultStatus)
    const irisWithScore =
      modality === ModalityDefinitions.Iris &&
      [ScanResultStatus.GoodQuality, ScanResultStatus.AcceptableQuality].includes(scanResultStatus)
    const otherModalityWithoutScore =
      modality !== ModalityDefinitions.Fingerprint && scanResultStatus === ScanResultStatus.NoQualityScore
    // TODO: Add a check if when there is a quality scan result.
    if (!isLoadFromFile && (fingerWithSequencerScore || irisWithScore || otherModalityWithoutScore)) {
      acceptScan()
      if (scanSteps.nextIndex === -1) {
        // If we got to this point, then the capture was successful and it auto advanced to the end.
        // Update the booking state with the capture device info for the current modality
        if (deviceInfo) addModalityCaptureDeviceInfoToBookingState(bookingState, modality, deviceInfo)

        finishScanCallback?.()
      }
    }
  }, [acceptScan, finishScanCallback, isLoadFromFile, modality, scanResultStatus, scanSteps.nextIndex, deviceInfo])

  function openDevice() {
    const isFPCardCapture =
      isFingerprintDefinition(scanSteps.currentStep) && scanSteps.currentStep.key === BioType.FBICard
    const preferredPluginID = isFPCardCapture ? fpCardPluginID : pluginID
    if (preferredPluginID) {
      setMessageStatus('Opening Device...')
      deviceManager.OpenDevice(preferredPluginID, deviceInstance)
    }
  }

  function closeDevice() {
    const isFPCardCapture =
      isFingerprintDefinition(scanSteps.currentStep) && scanSteps.currentStep.key === BioType.FBICard
    const preferredPluginID = isFPCardCapture ? fpCardPluginID : pluginID
    if (preferredPluginID) {
      deviceManager.CloseDevice(preferredPluginID, deviceInstance)
    }
  }

  function startScan(scanOrder: ScanOrder, callback?: () => void) {
    setIsRecapture(false)
    changeScanOrder(scanOrder)
    resetScanProcess()
    openDevice()
    setGlobalScanStatus(ScanStatus.Scanning)
    callback?.()
  }

  function recaptureScan(scanOrder: ScanOrder, callback?: () => void) {
    setIsRecapture(true)
    changeScanOrder(scanOrder)
    clearScanStep()
    openDevice()
    setGlobalScanStatus(ScanStatus.Rescanning)
    callback?.()
  }

  function abortScan(scanProccesDirtyCallback?: () => void, scanProccesCleanCallback?: () => void) {
    if ([ScanStatus.Scanning, ScanStatus.Rescanning, ScanStatus.Cancelling].includes(scanStatus)) {
      return
    }

    // Just in case, update the booking state to remove the capture device info for the modality.
    // The modality device info will get set anew when a new capture is started and completed successfully
    removeModalityCaptureDeviceInfoFromBookingState(bookingState, modality)

    if (isDirty) {
      scanProccesDirtyCallback?.()
    } else {
      closeDevice()
      clearScanStep()
      // clear the scan steps order
      changeScanOrder([])

      setGlobalScanStatus(ScanStatus.Idle)
      scanProccesCleanCallback?.()
    }
  }

  function finishScan(callback?: () => void) {
    acceptScan()

    // Gets called when one explicitly presses on the Done button at the end of a capture sequence.
    // If we got to this point, then the capture was successful
    // and we can save the capture device info for the current modality
    if (deviceInfo) addModalityCaptureDeviceInfoToBookingState(bookingState, modality, deviceInfo)

    closeDevice()
    clearScanStep()
    // clear the scan steps order
    changeScanOrder([])

    setGlobalScanStatus(ScanStatus.Done)
    callback?.()
  }

  function removeAllScans(callback?: () => void) {
    if (isDeviceOpen) {
      closeDevice()
    }
    resetScanProcess()
    setGlobalScanStatus(ScanStatus.Idle)
    // clear the scan steps order
    changeScanOrder([])

    // Just in case, update the booking state to remove the capture device info for the modality.
    // The modality device info will get set anew when a new capture is started and completed successfully
    removeModalityCaptureDeviceInfoFromBookingState(bookingState, modality)

    callback?.()
  }

  function startDevice(callback?: () => void) {
    openDevice()
    callback?.()
  }

  function stopDevice(callback?: () => void) {
    closeDevice()
    callback?.()
  }

  function cancelScan() {
    if (pluginID) {
      setScanStatus(ScanStatus.Cancelling)
      setMessageStatus('Cancelling Capture...')
      deviceManager.CancelCapture(pluginID, deviceInstance)
    }
  }

  function clearScanStep() {
    clearCanvas(scanFramesCanvasRef1)
    clearCanvas(scanFramesCanvasRef2)
    setScanStatus(ScanStatus.Idle)
    setScanProgress(0)
    setScanResultStatus(ScanResultStatus.Default)
    setMessageStatus('')
    setScannedImageList([])
  }

  function resetScanProcess() {
    clearScanStep()
    deleteBookingImages(bookingState, modality)
  }

  function getElementStatus(element: BioType) {
    return scanElements[element]
  }

  function getDeviceOptions(requestedOptions: string[]) {
    if (isDeviceOpen) {
      const isFPCardCapture =
        isFingerprintDefinition(scanSteps.currentStep) && scanSteps.currentStep.key === BioType.FBICard
      const preferredPluginID = isFPCardCapture ? fpCardPluginID : pluginID
      if (preferredPluginID) {
        deviceManager.GetDeviceOptions(preferredPluginID, deviceInstance, requestedOptions)
      }
    }
  }

  function sendDeviceCommand(options: Map<string, string>, callback?: () => void) {
    if (isDeviceOpen) {
      const isFPCardCapture =
        isFingerprintDefinition(scanSteps.currentStep) && scanSteps.currentStep.key === BioType.FBICard
      const preferredPluginID = isFPCardCapture ? fpCardPluginID : pluginID
      if (preferredPluginID) {
        deviceManager.SetDeviceOptions(preferredPluginID, deviceInstance, options, callback)
      }
    }
  }

  return {
    startScan,
    recaptureScan,
    abortScan,
    finishScan,
    removeAllScans,
    scan,
    cancelScan,
    acceptScan,
    getElementStatus,
    startDevice,
    stopDevice,
    getDeviceOptions,
    sendDeviceCommand,
    pluginCapabilities,
    deviceCapabilities,
    isDeviceOpen,
    isRecapture,
    scanSteps,
    globalScanStatus,
    inputFileRef,
    acceptedFileExtensions,
    scanFramesCanvasRef: scanFramesCanvasRef1,
    scanFramesCanvasRefs: [scanFramesCanvasRef1, scanFramesCanvasRef2],
    isLoadFromFile,
    isMultipleCaptureAvailable,
    scanStatus,
    scanProgress,
    messageStatus,
    scanResultStatus,
    scannedImageList,
    deviceOptionsMap
  }
}
