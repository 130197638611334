/////////////////////////////////////////////////////////////////////////////////
//
//  This class provides methods to manipulate the fp cardprint Events
//
/////////////////////////////////////////////////////////////////////////////////
import { HubManager } from './HubManager'
import {
  AvailablePrintersEventDetail,
  DeviceError,
  DeviceInfo,
  IWImage,
  IWResult,
  PrintEventDetail
} from './IWNativeExtDeviceDefines'

export class FPCardPrintManager extends HubManager {
  constructor(eventsCallback?: () => void) {
    const fPCardPrintDeviceManagerUrl = 'http://localhost:5995/fpcardprintmgr'
    super(fPCardPrintDeviceManagerUrl, eventsCallback)

    // bind the class methods to this instance
    this.Print = this.Print.bind(this)
    this.GetAvailablePrinters = this.GetAvailablePrinters.bind(this)
  }

  //
  // Methods that call the FPCardPrintManager server interfaces
  //
  Print(
    ebtsContent: string,
    options: { [key: string]: string },
    callbackMethod?: (iwResult: IWResult, resultList: DeviceInfo[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let previewImages: IWImage[] | null = null

    try {
      console.log('FPCardPrintMgr::Print() entered, options = ' + JSON.stringify(options))
      this.hubConnection
        ?.invoke('Print', ebtsContent, JSON.stringify(options))
        .then(function (list: IWImage[] | null) {
          console.log('connection.Print.then() entered, result list count: ' + list?.length)
          if (list?.length) {
            //Commented out the previous implementation; leaving the comments as a historical reference for now
            //iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            previewImages = list
          } else {
            //Commented out the previous implementation; leaving the comments as a historical reference for now
            //iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No images')
          }
          // We treat all these cases as success, since the print to printer will return an empty list
          // Any errors from the cardprint plugin would have been thrown as hub errors and handles by the catch below
          iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
        })
        .catch(function (err) {
          console.log('connection.Print.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.log('connection.Print.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<PrintEventDetail>('Print', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: previewImages }
            })
          )
        })
    } catch (e) {
      console.log('Exception in FPCardPrintMgr::Print() : ' + e)
    }
  }

  GetAvailablePrinters(
    callbackMethod?: (iwResult: IWResult, resultList: DeviceInfo[] | null, callbackContext?: any) => void,
    callbackContext?: any
  ) {
    let iwResult: IWResult
    let availablePrinters: string[] | null = null
    try {
      console.log('FPCardPrintMgr::GetAvailablePrinters() entered')
      this.hubConnection
        ?.invoke('GetAvailablePrinters')
        .then(function (list: string[] | null) {
          console.log('connection.GetAvailablePrinters.then() entered, result list: ' + list)
          if (list?.length) {
            iwResult = new IWResult(DeviceError.ERROR_SUCCESS, 'Success')
            availablePrinters = list
          } else {
            iwResult = new IWResult(DeviceError.ERROR_EMPTY, 'No printers')
          }
        })
        .catch(function (err) {
          console.log('connection.GetAvailablePrinters.catch() entered, exception: ' + err.toString())
          iwResult = new IWResult(DeviceError.ERROR_FUNCTION_FAILED, err.toString())
        })
        .finally(() => {
          console.log('connection.GetAvailablePrinters.finally() entered')

          callbackMethod?.(iwResult, callbackContext)

          document.dispatchEvent(
            new CustomEvent<AvailablePrintersEventDetail>('AvailablePrinters', {
              bubbles: true,
              cancelable: true,
              detail: { result: iwResult, list: availablePrinters }
            })
          )
        })
    } catch (e) {
      console.log('Exception in FPCardPrintMgr::Print() : ' + e)
    }
  }
}
