import { LE2UsersType } from '@le2/common-types'
import { proxy } from 'valtio'

import { LE2UserResponse } from '../pages/users/types'

export function mapUser(user: LE2UserResponse): LE2UsersType {
  return {
    ...user,
    id: user.id,
    department: user.department || '',
    isAdmin: user.isAdmin,
    groups: user.groups.map(({ addedAt, group }) => ({
      addedAt: new Date(addedAt),
      ...group
    })),
    createdAt: new Date(user.createdAt),
    updatedAt: new Date(user.updatedAt)
  }
}

export const authUserState = proxy<LE2UserAuthState>({
  me: {
    id: 0,
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    department: '',
    extId: '',
    isActive: false,
    isAdmin: false,
    isBlocked: false,
    isOidcLogin: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    groups: []
  },
  isLoaded: false
})

interface LE2UserAuthState {
  me: LE2UsersType
  isLoaded: boolean
}
