import { gql, useQuery } from '@apollo/client'
import { LE2UsersType } from '@le2/common-types'
import debounce from 'lodash/debounce'
import { ChangeEvent, useCallback, useMemo, useState } from 'react'

import agencyConfig from '../../../lib/agencyConfig'
import { PAGE_SIZE_GROUPS_USERS } from '../../../lib/api/constants'
import { OrderBy, OrderByDict } from '../../../lib/useTableSort'
import { mapUser } from '../../../utils/usersUtils'
import { LE2UserResponse } from '../types'

export const GET_USERS_SORTED = gql`
  query UserManagement($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    usersAggregate(where: $where, aggregate: { count: true }) {
      count
    }
    users(take: $take, skip: $skip, orderBy: $orderBy, where: $where) {
      id
      firstName
      lastName
      email
      username
      isAdmin
      isOidcLogin
      isActive
      isBlocked
      createdAt
      updatedAt
      department
      extId
      groups {
        addedAt
        group {
          id
          name
          permissions
        }
      }
    }
  }
`

/**
 * Users data, as returned by the API with the GET_USERS_SORTED query.
 */
export interface UsersResponse {
  users: LE2UserResponse[]
  usersAggregate: { count: number }
}

interface GetUsersResult {
  loading: boolean

  error: boolean

  users: LE2UsersType[]

  count: number

  pages: number

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: string

  /**
   * Contains the status as string. Instantly updated. To be passed as value to a select.
   */
  status: string

  /**
   * A function to pass to an input's `onChange` prop.
   */
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void

  /**
   * A function to pass to an select's `onChange` prop.
   */
  handleStatusFilter: (e: ChangeEvent<HTMLSelectElement>) => void
}

interface SearchParams {
  searchString?: string
  // role?: string
  isActive?: boolean
}

interface SearchVars {
  /**
   * Contains the search string. Updates are debounced to 500ms. To be sent to the server.
   */
  debouncedSearch: string

  /**
   * Contains the search string. Instantly updated. To be passed as value to an input.
   */
  search: SearchParams

  /**
   * A function to pass to `onChange`
   */
  handleSearch: (e: ChangeEvent<HTMLInputElement>) => void
  /**
   * A function to pass to `onChange`
   */
  handleStatusFilter: (e: ChangeEvent<HTMLSelectElement>) => void
}

function getOrderBy(orderBy: OrderByDict) {
  const entries = Object.entries(orderBy).filter(([, sortOrder]) => sortOrder !== OrderBy.none)

  if (entries.length > 0) {
    return [Object.fromEntries(entries)]
  } else {
    return [{ createdAt: 'desc' }]
  }
}

function getDebounced(setSearch: (val: string) => void) {
  return debounce((value: string) => {
    setSearch(value)
  }, 500)
}

function useSearchVars(): SearchVars {
  // here we use two `search` variables:
  // - one that is immediately updated that can be used as an input's value
  // - one that is updated each 500ms (debounced) that can be used to make
  //   API calls

  const [debouncedSearch, setDebouncedSearch] = useState('')
  const [search, setSearch] = useState<SearchParams>({})
  const debouncedSetSearch = useMemo(() => getDebounced(setDebouncedSearch), [])

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      // update the search string immediately
      setSearch({ ...search, searchString: e.target.value })
      // update the debounced search after 500ms
      debouncedSetSearch(e.target.value)
    },
    [search, debouncedSetSearch]
  )

  const handleStatusFilter = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      // update the role filter immediately
      setSearch({
        ...search,
        isActive: e.target.value === '' ? undefined : e.target.value === 'enabled' ? true : false
      })
    },
    [search]
  )
  return { debouncedSearch, search, handleSearch, handleStatusFilter }
}

export default function useGetUsers(currentPage: number, orderBy: OrderByDict): GetUsersResult {
  const searchableColumns = ['firstName', 'lastName', 'email', 'username', 'groups.name']
  const { debouncedSearch, search, handleSearch, handleStatusFilter } = useSearchVars()
  const hideOIDCUsers = agencyConfig.features?.oidc?.users?.hide || false

  const extra = {
    skip: PAGE_SIZE_GROUPS_USERS * (currentPage - 1),
    take: PAGE_SIZE_GROUPS_USERS,
    orderBy: getOrderBy(orderBy)
  }
  const queryWithAndCondition = []

  const contains = !!debouncedSearch ? debouncedSearch : undefined
  if (contains) {
    queryWithAndCondition.push({
      OR: searchableColumns.map((item) => ({
        [item]: {
          contains,
          mode: 'insensitive'
        }
      }))
    })
  }

  let status = ''
  const { isActive } = search
  if (typeof isActive === 'boolean') {
    queryWithAndCondition.push({ isActive })
    status = search.isActive ? 'enabled' : 'disabled'
  }

  if (hideOIDCUsers) {
    // We need to add the where 'isOidcLogin=false' clause only when we want to skip the OIDC users
    // Otherwise, all users are returned by default
    const isOidcLogin = false
    queryWithAndCondition.push({ isOidcLogin })
  }

  let variables
  if (queryWithAndCondition.length > 0) {
    variables = {
      where: {
        AND: queryWithAndCondition
      },
      ...extra
    }
  } else {
    variables = { ...extra }
  }

  const queryResult = useQuery<UsersResponse>(GET_USERS_SORTED, { variables })
  const pages = Math.ceil((queryResult.data?.usersAggregate?.count || 0) / PAGE_SIZE_GROUPS_USERS)
  const users = queryResult.data?.users || []

  return {
    loading: queryResult.loading,
    error: !!queryResult.error,
    users: users.map((user) => mapUser(user), []),
    count: queryResult.data?.usersAggregate?.count || 0,
    pages,
    search: search.searchString || '',
    status,
    handleSearch,
    handleStatusFilter
  }
}
