export function getFileData(acceptFiles: string = '*'): Promise<string | null> {
  return new Promise((resolve) => {
    // Creates an input file element and emits an event to open the File Explorer to select an image.
    const fileInput = document.createElement('input') as HTMLInputElement
    fileInput.setAttribute('type', 'file')
    fileInput.setAttribute('accept', acceptFiles)

    fileInput.onchange = (event) => {
      // Get the file selected from the file explorer.
      const { files } = event.currentTarget as HTMLInputElement

      if (files && files.length > 0) {
        const selectedFile = files[0]

        const fileReader = new FileReader()

        fileReader.onload = () => {
          // Since the app only request one file, the result should be a string.
          const srcData = fileReader.result as string

          if (srcData) {
            resolve(srcData)
          } else {
            resolve(null)
          }
        }

        fileReader.readAsDataURL(selectedFile)
      } else {
        // Nothing was provided
        resolve(null)
      }
    }

    fileInput.click()
  })
}
