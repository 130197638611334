import BioType from './BioType'
import Image from './Image'

export default class RecordImage<T extends RecordImageMetadata> {
  image: Image
  bioType: BioType
  metadata: T
  metadataProvider: (() => T) | null
  capturedAt: Date

  constructor(src: string, bioType: BioType, metadata: T, wsqSrc?: string, capturedAt?: string) {
    this.image = new Image(src, wsqSrc)
    this.bioType = bioType
    this.metadata = metadata
    this.capturedAt = capturedAt ? new Date(capturedAt) : new Date()
    this.metadataProvider = null
  }

  setMetadata() {
    if (this.metadataProvider) {
      this.metadata = this.metadataProvider()
    }
  }
}

export type RecordImageMetadata = FaceCaptureMetadata

export interface FaceCaptureMetadata extends GroupDateTimeMetadata {
  // TODO: These two fields might save a bit of work when generating EBTS files
  ImageType?: string
  SubjectPose?: string
  PhotoDate?: string
}

export interface GroupDateTimeMetadata {
  groupDateTime: Date
}

export type RecordImageGroupMetadata = FaceCaptureMetadata
export type FaceCaptureImage = RecordImage<FaceCaptureMetadata>
