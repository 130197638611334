export function rotateImage(imageBase64: string, rotation: number, callback: (imageRBase64: string) => void) {
  const img = new Image()
  img.src = imageBase64
  img.onload = () => {
    console.debug(`rotateImage(), image loaded, width: ${img.width}, height: ${img.height}`)

    const width = (90 === rotation || 270 === rotation ? img.height : img.width) || 0
    const height = (90 === rotation || 270 === rotation ? img.width : img.height) || 0

    const canvas = document.createElement('canvas')
    canvas.width = width //img.width
    canvas.height = height // img.height
    const ctx = canvas.getContext('2d')

    if (!ctx) {
      return null
    }

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((rotation * Math.PI) / 180)
    ctx.drawImage(img, -img.width / 2, -img.height / 2)

    callback(canvas.toDataURL('image/jpeg'))
  }
}
