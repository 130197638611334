import { useEffect } from 'react'

import { ManagerEvents } from '../IWNativeExtDeviceDefines'
import {
  hubClosedEventHandlerWrapper,
  hubConnectedEventHandlerWrapper,
  hubDisConnectedEventHandlerWrapper,
  hubIsConnectingEventHandlerWrapper,
  hubIsDisConnectingEventHandlerWrapper,
  hubIsReconnectingEventHandlerWrapper,
  hubReconnectedEventHandlerWrapper
} from './hubEventHandlers'

/*
 * This hook creates listeners for the NativeExtension Hub Events.
 * It subscribes to the Hub Events and cleans them up when it is unmounted.
 * It does not start any connection to the NativeExtension.
 * It should be called first to create the event listeners before trying
 * to start a connection.
 */

export function useSubscribeHubEvents() {
  useEffect(() => {
    // Subscribe to Hub events
    const hubConnectedEventHandler = hubConnectedEventHandlerWrapper()
    const hubReconnectedEventHandler = hubReconnectedEventHandlerWrapper()
    const hubDisConnectedEventHandler = hubDisConnectedEventHandlerWrapper()
    const hubClosedEventHandler = hubClosedEventHandlerWrapper()
    const hubIsConnectingEventHandler = hubIsConnectingEventHandlerWrapper()
    const hubIsReconnectingEventHandler = hubIsReconnectingEventHandlerWrapper()
    const hubIsDisConnectingEventHandler = hubIsDisConnectingEventHandlerWrapper()

    document.addEventListener(ManagerEvents.hubConnected, hubConnectedEventHandler)
    document.addEventListener(ManagerEvents.hubReconnected, hubReconnectedEventHandler)
    document.addEventListener(ManagerEvents.hubDisConnected, hubDisConnectedEventHandler)
    document.addEventListener(ManagerEvents.hubClosed, hubClosedEventHandler)
    document.addEventListener(ManagerEvents.hubIsConnecting, hubIsConnectingEventHandler)
    document.addEventListener(ManagerEvents.hubIsReconnecting, hubIsReconnectingEventHandler)
    document.addEventListener(ManagerEvents.hubIsDisConnecting, hubIsDisConnectingEventHandler)

    return () => {
      // Remove Hub events
      document.removeEventListener(ManagerEvents.hubConnected, hubConnectedEventHandler)
      document.removeEventListener(ManagerEvents.hubReconnected, hubReconnectedEventHandler)
      document.removeEventListener(ManagerEvents.hubDisConnected, hubDisConnectedEventHandler)
      document.removeEventListener(ManagerEvents.hubClosed, hubClosedEventHandler)
      document.removeEventListener(ManagerEvents.hubIsConnecting, hubIsConnectingEventHandler)
      document.removeEventListener(ManagerEvents.hubIsReconnecting, hubIsReconnectingEventHandler)
      document.removeEventListener(ManagerEvents.hubIsDisConnecting, hubIsDisConnectingEventHandler)
    }
  }, [])
}
