import {
  Button,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import filesize from 'filesize'
import { PhotographOutline } from 'heroicons-react'
import { ChangeEvent, ReactElement, useRef, useState } from 'react'

import { FileExtension, listFormat, readFile } from '../lib/fileUtils'
import { convertB64DataToDataURI } from '../lib/imageUtils'

interface FileSelectorModalProps {
  acceptedFileExtensions: FileExtension[]
  maximumFileSize: number
  isOpen: boolean
  onComplete: (imageSrc: string) => void
  nextView?: (params: NextViewParams) => ReactElement
  onClose: () => void
}

interface NextViewParams {
  imageSrc: string | undefined
  finishCapture: (imageSrc: string) => void
  reCapture: () => void
  cancelCapture: () => void
}

export default function FileSelectorModal(props: FileSelectorModalProps) {
  const [imageSrc, setImageSrc] = useState<string | undefined>(undefined)

  function finishCapture(imageSrc: string) {
    props.onComplete(imageSrc)
    cancelCapture()
  }

  function reCapture() {
    setImageSrc(undefined)
  }

  function cancelCapture() {
    props.onClose()
    setImageSrc(undefined)
  }

  const viewModal = props.nextView?.({ imageSrc, finishCapture, reCapture, cancelCapture })

  return (
    <>
      <Modal isOpen={props.isOpen} closeOnOverlayClick={false} onClose={props.onClose} isCentered size="4xl">
        <ModalOverlay />
        <ModalContent>
          {!imageSrc && (
            <FileSelectorContents
              onClose={props.onClose}
              onComplete={props.onComplete}
              onSelected={setImageSrc}
              isViewModalAvailable={!!viewModal}
              acceptedFileExtensions={props.acceptedFileExtensions}
              maximumFileSize={props.maximumFileSize}
            />
          )}
          {imageSrc && viewModal}
        </ModalContent>
      </Modal>
    </>
  )
}

function FileSelectorContents(props: {
  onClose: () => void
  onComplete: (imageSrc: string) => void
  onSelected: React.Dispatch<React.SetStateAction<string | undefined>>
  isViewModalAvailable: boolean
  acceptedFileExtensions: FileExtension[]
  maximumFileSize: number
}) {
  const acceptedFileTypes = props.acceptedFileExtensions.join()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const [error, setError] = useState<string | null>(null)
  const filesExt = props.acceptedFileExtensions.map((v) => v.substring(1))
  const acceptedFileExt = listFormat(filesExt, ',', 'or')
  const maxSize = filesize(props.maximumFileSize, { base: 2, standard: 'jedec' })

  function selectFile() {
    if (inputFileRef.current) {
      inputFileRef.current.click()
    }
  }

  function onSelectedFile(dataURI: string) {
    if (props.isViewModalAvailable) {
      props.onSelected(dataURI)
    } else {
      props.onComplete(dataURI)
      props.onClose()
    }
  }

  async function onChange(event: ChangeEvent) {
    const target = event.target as HTMLInputElement
    const file = target.files?.[0]
    target.value = ''

    if (file) {
      try {
        const fileType = file.type
        const base64File = await readFile(file, props.acceptedFileExtensions, props.maximumFileSize)
        const dataURI = convertB64DataToDataURI(base64File, fileType)
        setError(null)
        onSelectedFile(dataURI)
      } catch (error) {
        setError(error as string)
      }
    }
  }

  return (
    <>
      <ModalHeader py={6}>
        <Heading as="h3" size="lg" textAlign="center">
          Upload your photo
        </Heading>
      </ModalHeader>
      <ModalBody mb={4}>
        <VStack>
          <Text mb={3}>
            Files should be an image {acceptedFileExt}. Maximum upload file size is {maxSize}.
          </Text>
          <VStack w="100%" bgColor="gray.100" borderRadius={5} py={10} mb={3} spacing={5}>
            <Icon as={PhotographOutline} w={10} h={10} />
            <Text mb={3}>Select the file you want to upload</Text>
            <Button variant="primary" disabled={false} onClick={selectFile}>
              Upload
            </Button>
          </VStack>
          {error && (
            <Text alignSelf="flex-start" color="red.500">
              {error}
            </Text>
          )}
          <HStack w="100%" justifyContent="flex-end">
            <input
              type="file"
              accept={acceptedFileTypes}
              name={'iris-scan'}
              ref={inputFileRef}
              onChange={onChange}
              style={{ display: 'none' }}
            />
            <Button variant="secondary" disabled={false} onClick={props.onClose}>
              Cancel
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </>
  )
}
