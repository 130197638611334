import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Heading,
  Icon,
  IconButton,
  Link,
  Spacer,
  StackDivider,
  Text,
  Tooltip,
  VStack,
  chakra,
  useDisclosure,
  useToast
} from '@chakra-ui/react'
import { Group, GroupUserRelation, LE2UsersType } from '@le2/common-types'
import { SettingsPermissions } from '@le2/permissions'
import {
  ArrowNarrowLeftOutline,
  CloudOutline,
  InformationCircleOutline,
  PencilOutline,
  ShieldCheckOutline,
  TrashOutline,
  UserGroupOutline
} from 'heroicons-react'
import { Fragment, useMemo, useState } from 'react'
import { FaPlusSquare } from 'react-icons/fa'
import { Link as ReachLink, useHistory } from 'react-router-dom'

import ConfirmDialog from '../../components/ConfirmDialog'
import PermissionWrapper from '../../components/PermissionWrapper'
import { Body, Contents, Header } from '../../layouts/user'
import { handleLE2Error } from '../Errors'
import AddUser from './components/AddUserDialog'
import GroupUpsertDialog from './components/GroupUpsertDialog'
import useGetGroup from './hooks/useGetGroup'
import useUpdateGroup, { GroupUpdate } from './hooks/useUpdateGroup'
import { PermissionData, PermissionModule } from './types'
import { defaultGroupMessage, formattingPermissions, groupState } from './utils'

export default function ViewGroup() {
  const history = useHistory<{
    group: Group
  }>()

  const { group } = history.location.state
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [groupData, setGroupData] = useState<Group>()

  if (!group) {
    history.replace('/groups')
  }

  useGetGroup(group.id || 0, (group) => {
    groupState.id = group.id
    groupState.name = group.name
    groupState.permissions = group.permissions
    groupState.users = [...group.users]
    groupState.createdAt = group.createdAt
    groupState.createdBy = group.createdBy
    groupState.updatedAt = group.updatedAt
    groupState.updatedBy = group.updatedBy
    groupState.isEditable = group.isEditable
    setGroupData({ ...group })
  })

  function handleEditGroup() {
    groupState.operation = 'edit'
    onOpen()
  }

  if (!groupData) {
    return null
  }

  return (
    <Contents>
      <Header
        actions={
          <>
            <PermissionWrapper permission={SettingsPermissions.SettingsEditGroup}>
              <Button size="md" variant="ghost" onClick={handleEditGroup}>
                <Box borderWidth="1px" borderColor="gray.400" borderRadius={5} mr={2} p={1} as="span">
                  <Icon w={5} h={5} as={PencilOutline} />
                </Box>
                Edit details
              </Button>
            </PermissionWrapper>
          </>
        }
        heading={<GroupHeader group={groupData} />}
      ></Header>
      <Flex color="gray.700" flexGrow={1} flexDirection="row" alignItems="normal">
        <PermissionSection group={groupData} />
        <UserSection group={groupData} />
      </Flex>
      <GroupUpsertDialog isOpen={isOpen} onClose={onClose} />
    </Contents>
  )
}

interface PermissionsSectionProps {
  group?: Group
}

function PermissionSection({ group }: PermissionsSectionProps) {
  const permissions = useMemo(() => {
    if (group) {
      return formattingPermissions(group)
    }

    return []
  }, [group])

  return (
    <Body w="45%" mr={3}>
      <Box w="100%">
        <HStack h={10} alignItems="center">
          <Icon mr={1} w={5} h={5} as={ShieldCheckOutline} />
          <Heading as="h6" size="md">
            Permissions
          </Heading>
        </HStack>
        <Box mt={5}>
          <Accordion defaultIndex={0} allowToggle>
            {permissions.map((module, index) => (
              <Module module={module} key={index} index={index} />
            ))}
          </Accordion>
        </Box>
      </Box>
    </Body>
  )
}

interface UserSectionProps {
  group: Group
}

function UserSection({ group }: UserSectionProps) {
  const toast = useToast()
  const history = useHistory<{
    group: Group
  }>()
  const updateGroup = useUpdateGroup(
    () => {
      toast({
        title: 'Success',
        description: 'User successfully removed',
        status: 'success',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    },
    (message) => {
      handleLE2Error(message, history, toast)
    }
  )
  const { isOpen: isAddUserOpen, onOpen: onAddUserOpen, onClose: onAddUserClose } = useDisclosure()
  const { isOpen: isModalDeleteOpen, onOpen: onModalDeleteOpen, onClose: onModalDeleteClose } = useDisclosure()
  const [userToRemove, setUserToRemove] = useState<LE2UsersType | null>(null)
  const { users } = group

  function onModalClose() {
    onAddUserClose()
  }

  function onRemoveUser() {
    if (!userToRemove) {
      throw new Error('Need to provide a user to remove')
    }

    const usersGroups = group.users.filter(({ id }) => userToRemove.id !== id)
    const groupUpdate: GroupUpdate = {
      id: group.id,
      name: group.name,
      permissions: group.permissions,
      users: usersGroups.map(({ id }) => id)
    }

    updateGroup(groupUpdate)

    setUserToRemove(null)
  }

  return (
    <Body w="45%">
      <Box w="100%">
        <HStack h={10}>
          <Box>
            <HStack>
              <Icon mr={1} w={5} h={5} as={UserGroupOutline} />
              <Heading as="h6" size="md">
                Users ({group.users.length})
              </Heading>
            </HStack>
          </Box>
          <Spacer />
          <PermissionWrapper
            permission={[SettingsPermissions.SettingsCreateUser, SettingsPermissions.SettingsAssignGroups]}
          >
            <Button size="md" color="secondary" variant="ghost" p={0} m={0} onClick={onAddUserOpen}>
              <Icon mr={1} w={5} h={5} as={FaPlusSquare} />
              Create New User
            </Button>
          </PermissionWrapper>
          <AddUser isOpen={isAddUserOpen} onClose={onModalClose} showGroups={false} group={group} />
        </HStack>
        <Box mt={5}>
          {users.length === 0 && (
            <Text color="gray.700" fontWeight="400">
              No users associated with this group
            </Text>
          )}
          {users.map((user, key) => (
            <UserDetail
              key={key}
              user={user}
              onDelete={() => {
                setUserToRemove(user)
                onModalDeleteOpen()
              }}
            />
          ))}
        </Box>
      </Box>
      <ConfirmDialog
        isOpen={isModalDeleteOpen}
        onClose={() => {
          setUserToRemove(null)
          onModalDeleteClose()
        }}
        onConfirm={onRemoveUser}
        header={
          <VStack>
            <Box bg="gray.100" borderRadius="lg">
              <Icon as={TrashOutline} color="gray.500" w={12} h={12} m={3} border={1} borderColor="black" />
            </Box>
            <Text>
              Remove{' '}
              <chakra.span color="secondary">
                {userToRemove?.firstName} {userToRemove?.lastName}
              </chakra.span>
              ?
            </Text>
          </VStack>
        }
        body="This user will immediately lose all permissions of this group."
        cancelText="Cancel"
        confirmText="Remove user"
        justifyContent="center"
      />
    </Body>
  )
}

interface UserDetailProps {
  user: GroupUserRelation
  onDelete: () => void
}
function UserDetail({ user, onDelete }: UserDetailProps) {
  const { id, addedAt, firstName, lastName } = user
  return (
    <Fragment>
      <Flex alignItems="center">
        <HStack mt={2} spacing={2} divider={<StackDivider h={5} borderColor="gray.400" />}>
          <Tooltip label={`name: ${firstName} ${lastName}`}>
            <Text minWidth="250px" maxWidth="250px" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
              {firstName} {lastName}
            </Text>
          </Tooltip>
          <Tooltip label={`added on: ${addedAt.toLocaleDateString()}`}>
            <Text
              color="gray.500"
              minWidth="200px"
              maxWidth="200px"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              Added on {addedAt.toLocaleDateString()}
            </Text>
          </Tooltip>
        </HStack>
        <Spacer />
        <PermissionWrapper
          permission={[SettingsPermissions.SettingsAssignGroups, SettingsPermissions.SettingsEditGroup]}
          strict={false}
        >
          <IconButton
            mt={2}
            color="gray.400"
            size="xs"
            variant="outline"
            aria-label={`delete-user-${id}`}
            icon={<Icon w={4} h={4} as={TrashOutline} />}
            onClick={onDelete}
          />
        </PermissionWrapper>
      </Flex>
      <Divider borderColor="gray.300" mt={3} />
    </Fragment>
  )
}

interface GroupHeaderProps {
  group?: Group
}

function GroupHeader({ group }: GroupHeaderProps) {
  return (
    <Box>
      <Link
        as={ReachLink}
        to={{
          pathname: '/users'
        }}
        color="secondary"
      >
        <Icon as={ArrowNarrowLeftOutline} w={10} h={7} />
      </Link>
      <HStack>
        <Heading color="text.title" textStyle="title" textTransform="capitalize">
          {group?.name}
        </Heading>
        {!group?.isEditable && (
          <Tooltip
            label={`This is a default group. ${defaultGroupMessage}`}
            fontSize={12}
            placement="right-end"
            borderRadius={4}
          >
            <Icon w={6} h={6} as={InformationCircleOutline} />
          </Tooltip>
        )}
      </HStack>

      <HStack color="gray.700" divider={<Divider borderColor="gray.500" h={4} orientation="vertical" />}>
        <Text>
          Date created: {group?.createdAt && group.createdAt.toLocaleDateString()}{' '}
          {group?.createdBy && `by ${group.createdBy.firstName} ${group.createdBy.lastName}`}
        </Text>
        {group?.updatedBy && (
          <Flex>
            <Icon mr={1} alignSelf="center" as={CloudOutline} />
            <Text>
              Last updated: {group?.updatedAt && group.updatedAt.toLocaleDateString()} by {group?.updatedBy?.firstName}{' '}
              {group?.updatedBy?.lastName}
            </Text>
          </Flex>
        )}
      </HStack>
    </Box>
  )
}

interface ModuleProps {
  module: PermissionModule
  index: number
}
function Module({ index, module }: ModuleProps) {
  return (
    <AccordionItem tabIndex={index} border="none">
      <>
        <AccordionButton pl={0} _focus={{ outline: 'none', boxShadow: 'none' }}>
          <Box as="span" flex="1" textAlign="left" fontWeight={700} fontSize={16}>
            {module.name}
          </Box>
          <AccordionIcon />
        </AccordionButton>
        <Divider borderColor="gray.300" />
      </>
      <AccordionPanel pb={4} pl={0}>
        {module.permissions.map((permission, key) => (
          <PermissionItem permission={permission} key={key} />
        ))}
      </AccordionPanel>
    </AccordionItem>
  )
}

interface PermissionItemProps {
  permission: PermissionData
}
function PermissionItem({ permission }: PermissionItemProps) {
  return (
    <Text pl={6} pb={3}>
      {permission.label}
    </Text>
  )
}
