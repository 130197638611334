import { gql } from '@apollo/client'
import { JFDFieldDef, JFDValues } from '@le2/jfd'
import { createJFD } from '@le2/jfd'
import get from 'lodash/get'
import set from 'lodash/set'

import agencyConfig from '../../agencyConfig'
import { OrderByDict } from '../../useTableSort'
import BioType from './BioType'
import { RecordImages } from './index'
import { RecordEnrollmentStatus, Record as T5Record } from './record.interface'

const jfdInst = createJFD(agencyConfig.jfd)

export const disabledOnViewModeMessage = 'Not possible on view mode'

/**
 * Client-side params to query records. These can be mapped to a format
 * that can be consumed by the API with `getAPIQueryParams`
 */
export type RecordsQueryParams = SimpleRecordsQueryParams | AdvancedRecordsQueryParams

interface BaseRecordsQueryParams {
  currentPage?: number
  pageSize?: number
  orderBy?: OrderByDict
  bioTypes?: BioType[]
  isSealed?: boolean
}

interface SimpleRecordsQueryParams extends BaseRecordsQueryParams {
  type: 'simple'
  search: string
}

interface AdvancedRecordsQueryParams extends BaseRecordsQueryParams {
  type: 'advanced'
  search: {}
}

interface JsonBExtraParams {
  bioType?: BioType[]
  isSealed?: boolean
}

export const DEFAULT_PAGE_SIZE = 20

/**
 * Maps the given params to a format that can be used to query the API.
 */
export function getAPIQueryParams(params: RecordsQueryParams) {
  const currentPage = params.currentPage || 1
  const pageSize = params.pageSize || DEFAULT_PAGE_SIZE

  const extra = {
    skip: pageSize * (currentPage - 1),
    take: pageSize,
    orderBy: params.orderBy
  }

  const jsonbExtra: JsonBExtraParams = {}
  if (params.bioTypes) {
    jsonbExtra.bioType = params.bioTypes
  }

  if (undefined !== params.isSealed) {
    jsonbExtra.isSealed = params.isSealed
  }

  if (params.type === 'advanced') {
    return {
      jsonbWhere: {
        biographics: params.search,
        ...jsonbExtra
      },
      ...extra
    }
  } else {
    // we also use jsonb here so that we can order by JFD fields
    return {
      jsonbWhere: {
        biographics: getBiographicsParams(params.search),
        ...jsonbExtra
      },
      ...extra
    }
  }
}

export function getBiographicsParams(search: string) {
  const result = {}

  // to utilize trigrams indexing, need search string of 3 or more length
  if (search.length > 2) {
    agencyConfig.features.records.search.simple.forEach((field) => {
      const jfdField: JFDFieldDef = jfdInst.getFieldDef(field)
      set(result, field, {
        contains: jfdField.convertToUpperCase ? search.toUpperCase() : search,
        operation: 'OR'
      })
    })
  }

  return result
}

/**
 * Records data, as returned by the API with the GET_RECORDS query.
 */
export interface RecordsResponse {
  records: RecordResponse[]
  recordsAggregate: { count: number }
}

/**
 * Record data, as returned by the API with the GET_RECORDS query.
 */
export interface RecordResponse {
  id: number
  recordNum: string
  biographics: JFDValues
  enrollmentStatus: RecordEnrollmentStatus
  imageMetadata: ImageResponse[]
  createdAt: string
  updatedAt: string
  isActive: boolean
  isSealed: boolean
}

/**
 * Images data, as returned by the API with the GET_RECORDS query.
 */
export interface ImageResponse {
  imageId: string
  bioType: string
  metadata: Record<string, string>
  capturedAt: string
  createdAt: string
  updatedAt: string
}

export const GET_RECORDS = gql`
  query GetRecords($where: JSON, $jsonbWhere: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    records(orderBy: $orderBy, where: $where, jsonbWhere: $jsonbWhere, skip: $skip, take: $take) {
      id
      recordNum
      biographics
      enrollmentStatus
      imageMetadata
      createdAt
      updatedAt
      isActive
      isSealed
    }
    recordsAggregate(where: $where, jsonbWhere: $jsonbWhere, aggregate: { count: true }) {
      count
    }
  }
`
/**
 * Map a record as returned by the API to a client-side object.
 */
export function mapRecordResponse(data: RecordResponse): T5Record {
  return {
    id: data.id,
    recordNum: data.recordNum,
    biographics: data.biographics,
    enrollmentStatus: data.enrollmentStatus,
    images: RecordImages.createFromAPIResponse(data.imageMetadata),
    createdAt: new Date(data.createdAt),
    updatedAt: new Date(data.updatedAt),
    isActive: data.isActive,
    isSealed: data.isSealed,

    get name() {
      return get(this.biographics, 'DisplayFullName') as string
    }
  }
}
