import { darken } from '@chakra-ui/theme-tools'

const Button = {
  baseStyle: {
    paddingX: 5,
    paddingY: 2,
    fontSize: '16px',
    fontWeight: 500,
    borderRadius: '8px',
    _disabled: {
      border: 'none',
      color: 'white',
      background: 'gray.400'
    },
    _hover: {
      _disabled: {
        border: 'none',
        color: 'white',
        background: 'gray.400'
      }
    }
  },
  variants: {
    primary: {
      color: 'gray.100',
      background: 'secondary',
      borderColor: 'secondary',
      _hover: {
        background: darken('secondary', 5)
      }
    },
    secondary: {
      color: 'secondary',
      border: '1px solid',
      borderColor: 'secondary',
      _hover: {
        color: darken('secondary', 5),
        borderColor: darken('secondary', 5)
      }
    },
    tertiary: {
      color: 'secondary',
      _hover: {
        background: 'gray.100'
      }
    },
    danger: {
      color: 'gray.100',
      background: 'red.500',
      _hover: {
        background: darken('red.500', 10)
      }
    },
    loginSecondary: {
      color: 'primary',
      background: 'gray.300',
      width: '100%',
      _hover: {
        background: darken('gray.300', 10),
        textDecoration: 'none'
      }
    },
    loginPrimary: {
      color: 'gray.100',
      background: 'secondary',
      width: '100%',
      _hover: {
        background: darken('secondary', 7)
      }
    },
    newPrimary: {
      color: 'gray.100',
      background: 'blueWhale',
      borderColor: 'blueWhale',
      _hover: {
        background: darken('blueWhale', 3)
      }
    },
    newSecondary: {
      color: 'blueWhale',
      border: '1px solid',
      borderColor: 'blueWhale',
      _hover: {
        color: darken('blueWhale', 5),
        borderColor: darken('blueWhale', 3)
      }
    }
  },
  defaultProps: {
    variant: 'primary'
  }
}

export default Button
