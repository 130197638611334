import { LE2UsersType } from '@le2/common-types'
import useAxios from 'axios-hooks'
import { useCallback, useEffect } from 'react'

import { RecordEnrollmentStatus } from '../../../lib//api/record/record.interface'
import { RecordImages } from '../../../lib/api/record'
import { GenerateRecordNumResponse, Status } from '../interfaces'
import { recordState } from '../state'
import { onlyMandatoryCheckbox } from '../utils'

export function useGetRecordNumber(printType: string, user: LE2UsersType) {
  // here we pass an empty object as the first param since the URL is dynamic
  // and it depends on a machine code that is also loaded from the API
  // once we have the machine code we can call `fetch` and _then_ set the URL
  const [{ data, loading, error }, execute] = useAxios<GenerateRecordNumResponse>({}, { manual: true })

  const fetch = useCallback(
    (machineCode: string) => {
      execute({ url: `/v1/record/number/${machineCode}` })
    },
    [execute]
  )

  useEffect(() => {
    if (data) {
      /**
       * Added RecordNumber to the valtio state to cause a re-render so
       * it could be displayed in the UI as soon as it is returned from the server
       */
      // TODO refactor PrintType usage based on Booking vs Record item creation
      // NOTE corrolary apps/web/src/pages/record/hooks.tsx should
      // a) set RecordPrintType if both BookingPrintType and RecordPrintType exist in the JFD, or
      // b) set PrintType if only it exists in the JFD (also remove setting BookingPrintType below)
      recordState.jfd.resetFields()
      if (recordState.jfd.hasFieldDef('PrintType')) {
        recordState.jfd.setValue('PrintType', printType)
      }

      if (recordState.jfd.hasFieldDef('RecordPrintType')) {
        recordState.jfd.setValue('RecordPrintType', printType)
      }

      if (recordState.jfd.hasFieldDef('User')) {
        recordState.jfd.setValue('User.firstName', user?.firstName)
        recordState.jfd.setValue('User.lastName', user?.lastName)
        recordState.jfd.setValue('User.email', user?.email)
        recordState.jfd.setValue('User.username', user?.username || '')
        recordState.jfd.setValue('User.department', user?.department || '')
      }
      recordState.jfd.onInit()
      recordState.jfd.onCreate()
      recordState.recordNumber = data.recordNum
      recordState.jfd.bookingNumber = data.recordNum
      recordState.originalJFD.values = recordState.jfd.getValues()
      recordState.originalJFD.errors = recordState.jfd.getErrors()
      recordState.enrollmentStatus = RecordEnrollmentStatus.NEW
      recordState.createdAt = undefined
      recordState.updatedAt = undefined
      recordState.submittedAt = undefined
      recordState.isActive = false
      recordState.hasSaveButtonBeenClicked = false
      recordState.saveStatus = Status.Idle
      recordState.submitStatus = Status.Idle
      recordState.recordImages = new RecordImages([])
      recordState.isOnlyMandatory = onlyMandatoryCheckbox
    }
  }, [data, printType, user])

  return {
    data,
    fetch,
    loading,
    error
  }
}
