import { Box, Flex, FlexProps, HStack, Heading, Spacer, StackDivider, VStack, useTheme } from '@chakra-ui/react'
import React, { ReactNode } from 'react'

import { contentMinusTopNavHeight, contentPadding, topNavHeight } from './UserLayout'

interface ContentProps {
  children: ReactNode
  fixed?: boolean
}

/**
 * @param children
 * @param fixed If this element should have a fixed height.
 * @constructor
 */
export function Contents({ children, fixed = true }: ContentProps) {
  if (fixed) {
    return (
      <Flex direction="column" height={contentMinusTopNavHeight} padding={contentPadding}>
        {children}
      </Flex>
    )
  }

  return (
    <Flex direction="column" minHeight={contentMinusTopNavHeight} px={contentPadding} pb={contentPadding}>
      {children}
    </Flex>
  )
}

export interface StickyHeaderContainerProps {
  children: ReactNode
}

export function StickyHeaderContainer({ children }: StickyHeaderContainerProps) {
  const theme = useTheme()
  return (
    <Box position="sticky" top={topNavHeight} zIndex={theme.zIndices.sticky} pt={contentPadding} bgColor="background">
      {children}
    </Box>
  )
}

export interface HeaderProps {
  /**
   * A picture (or any other component) to be displayed on the left.
   */
  avatar?: ReactNode

  /**
   * Heading ReactElement or ReactText.
   */
  heading: ReactNode

  /**
   * An array of React elements. They're rendered inside a <HStack> with a
   * <StackDivider> between each.
   */
  details?: ReactNode

  /**
   * Buttons (or any other component) to be displayed on the right.
   */
  actions?: ReactNode

  children?: ReactNode
}

export function Header({ avatar, heading, details, children, actions }: HeaderProps) {
  return (
    <HStack px={4} py={2} bg="white" borderRadius={4} spacing={4}>
      {avatar}
      <VStack spacing={1} align="flex-start">
        {typeof heading === 'string' ? (
          <Heading color="text.title" textStyle="title">
            {heading}
          </Heading>
        ) : (
          heading
        )}
        {children && <Box>{children}</Box>}
        {details && (
          <HStack
            color="gray.700"
            textStyle="paragraph"
            divider={<StackDivider borderColor="gray.500" height={5} alignSelf="center" />}
          >
            {details}
          </HStack>
        )}
      </VStack>
      {actions && (
        <>
          <Spacer />
          <HStack alignSelf="flex-end">{actions}</HStack>
        </>
      )}
    </HStack>
  )
}

export function Body({ children, ...extra }: FlexProps) {
  return (
    <Flex flexGrow={1} mt={2} px={4} py={2} bg="white" borderRadius={4} overflow="hidden" {...extra}>
      {children}
    </Flex>
  )
}
