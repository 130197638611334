/////////////////////////////////////////////////////////////////////////////////
//
//  This base class provides methods to manipulate the Hub Events
//
/////////////////////////////////////////////////////////////////////////////////
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr'

import {
  DeviceEventArgs,
  DeviceEventDetail,
  HubClosedEventDetail,
  HubConnectedEventDetail,
  HubDisConnectedEventDetail,
  HubIsConnectingEventDetail,
  HubIsDisConnectingEventDetail,
  HubIsReconnectingEventDetail,
  HubReconnectedEventDetail,
  ManagerEvents,
  StatusEvents
} from './IWNativeExtDeviceDefines'

export class HubManager {
  hubConnection: HubConnection | null
  isHubConnected: boolean
  isHubReconnecting: boolean
  clientEventsCallback?: (statusEvents: StatusEvents) => void
  url: string

  constructor(url: string, eventsCallback?: () => void) {
    console.debug('HubManager constructor entered: ' + url?.substring(url?.lastIndexOf('/') + 1))
    // defaults
    this.hubConnection = null
    this.isHubConnected = false
    this.isHubReconnecting = false
    this.clientEventsCallback = eventsCallback

    // bind the class methods to this instance
    this.hubConnectedCallback = this.hubConnectedCallback.bind(this)
    this.hubDisConnectedCallback = this.hubDisConnectedCallback.bind(this)
    this.hubReconnectingCallback = this.hubReconnectingCallback.bind(this)
    this.hubReconnectedCallback = this.hubReconnectedCallback.bind(this)
    this.hubClosedCallback = this.hubClosedCallback.bind(this)

    this.IsConnected = this.IsConnected.bind(this)
    this.hubConnect = this.hubConnect.bind(this)
    this.hubDisConnect = this.hubDisConnect.bind(this)

    this.url = url

    // try to connect to the hub now
    try {
      this.hubConnection = new HubConnectionBuilder()
        .configureLogging(LogLevel.Debug)
        .withUrl(url)
        .withAutomaticReconnect() // automatically reconnect
        .build()

      this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 60 * 12 // 12 hrs
      this.hubConnection.keepAliveIntervalInMilliseconds = 10000 // 10 seconds (default is 15)

      this.hubConnection.onreconnecting(this.hubReconnectingCallback)

      // If the client successfully reconnects within its first four attempts,
      // the HubConnection will transition back to the Connected state and fire its onreconnected callbacks.
      // This provides an opportunity to inform users the connection has been reestablished.
      // Since the connection looks entirely new to the server, a new connectionId will be provided to the onreconnected callback.
      this.hubConnection.onreconnected(this.hubReconnectedCallback)

      // If the client doesn't successfully reconnect within its first four attempts,
      // the HubConnection will transition to the Disconnected state and fire its onclose callbacks.
      // This provides an opportunity to inform users the connection has been permanently lost and recommend refreshing the page
      this.hubConnection.onclose(this.hubClosedCallback)

      this.hubConnection.on('DeviceEvent', function (deviceEventArgs: DeviceEventArgs) {
        console.debug('connection.on.DeviceEvent() received')
        //this.OnDeviceEvent(deviceEventArgs) TODO: This was commented
        if (deviceEventArgs) {
          // always fire the events in case there might be subscribers
          document.dispatchEvent(
            new CustomEvent<DeviceEventDetail>(ManagerEvents.DeviceEvent, {
              bubbles: true,
              cancelable: true,
              detail: { deviceEventArgs }
            })
          )
        }
      })

      // try to open a connection
      // Open a connection will be managed by react
      // this.hubConnect()
    } catch (e) {
      console.error('Exception in HubManager::constructor() : ' + e)
    }
  }

  //// class methods
  hubConnect(): Promise<boolean> {
    return new Promise((resolve) => {
      console.debug('HubManager::hubConnect() entered')
      try {
        document.dispatchEvent(
          new CustomEvent<HubIsConnectingEventDetail>(ManagerEvents.hubIsConnecting, {
            bubbles: true,
            cancelable: true,
            detail: { hubUrl: this.url }
          })
        )

        this.hubConnection
          ?.start()
          .then(() => {
            this.hubConnectedCallback()
            resolve(true)
          })
          .catch((error) => {
            this.hubDisConnectedCallback(error)
            resolve(false)
          }) //alert('Error! ' + error.message) );
      } catch (e) {
        console.error('Exception in HubManager::connect() : ' + e)
        resolve(false)
      }
    })
  }

  hubDisConnect() {
    console.debug('HubManager::hubDisConnect() entered')
    try {
      document.dispatchEvent(
        new CustomEvent<HubIsDisConnectingEventDetail>(ManagerEvents.hubIsDisConnecting, {
          bubbles: true,
          cancelable: true,
          detail: { hubUrl: this.url }
        })
      )
      this.hubConnection
        ?.stop()
        .then(() => this.hubDisConnectedCallback(new Error('HubManager Connection stopped')))
        .catch((error) => this.hubDisConnectedCallback(error)) //alert('Error! ' + error.message) );
    } catch (e) {
      console.error('Exception in HubManager::DisConnect() : ' + e)
    }
  }

  hubConnectedCallback() {
    try {
      console.debug('HubManager::hubConnectedCallback() entered: ' + this.url.substring(this.url.lastIndexOf('/') + 1))

      // set a default
      this.isHubConnected = false

      // now set to the result from the hub connect call
      if (this.hubConnection) {
        this.isHubConnected = this.hubConnection.state === HubConnectionState.Connected
      }
      console.debug(`Hub connected status: "${this.isHubConnected}"`)

      this.clientEventsCallback?.(StatusEvents.deviceMgrConnected)

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        this.isHubConnected
          ? new CustomEvent<HubConnectedEventDetail>(ManagerEvents.hubConnected, {
              bubbles: true,
              cancelable: true,
              detail: { hubUrl: this.url }
            })
          : new CustomEvent<HubDisConnectedEventDetail>(ManagerEvents.hubDisConnected, {
              bubbles: true,
              cancelable: true,
              detail: { disconnectedReason: this.hubConnection?.state, hubUrl: this.url }
            })
      )
    } catch (e) {
      console.error('Exception in HubManager::hubConnectedCallback() : ' + e)
    }
  }

  hubDisConnectedCallback(disconnectedReason: Error) {
    try {
      console.debug(
        'HubManager::hubDisConnectedCallback() entered: ' + this.url.substring(this.url.lastIndexOf('/') + 1)
      )
      console.debug('Hub disconnected: ' + disconnectedReason)

      this.isHubConnected = false

      this.clientEventsCallback?.(StatusEvents.deviceMgrDisConnected)

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        new CustomEvent<HubDisConnectedEventDetail>(ManagerEvents.hubDisConnected, {
          bubbles: true,
          cancelable: true,
          detail: { disconnectedReason, hubUrl: this.url }
        })
      )
    } catch (e) {
      console.error('Exception in HubConnection::hubDisConnectedCallback() : ' + e)
    }
  }

  hubReconnectingCallback(reconnectingStatus?: Error) {
    try {
      console.debug(
        'HubManager::hubReConnectingCallback() entered: ' + this.url.substring(this.url.lastIndexOf('/') + 1)
      )
      console.debug('Hub reconnecting: ' + reconnectingStatus)
      this.isHubConnected = false
      this.isHubReconnecting = true

      this.clientEventsCallback?.(StatusEvents.deviceMgrReConnecting)

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        new CustomEvent<HubIsReconnectingEventDetail>(ManagerEvents.hubIsReconnecting, {
          bubbles: true,
          cancelable: true,
          detail: { reconnectingStatus, hubUrl: this.url }
        })
      )
    } catch (e) {
      console.error('Exception in HubManager::hubReconnectingCallback() : ' + e)
    }
  }

  hubReconnectedCallback(connectionId?: string) {
    try {
      console.debug('HubManager::hubReconnectCallback() entered: ' + this.url.substring(this.url.lastIndexOf('/') + 1))

      console.debug(`Connection reestablished. Connected with connectionId "${connectionId}".`)
      this.isHubReconnecting = false
      this.isHubConnected = true

      this.clientEventsCallback?.(StatusEvents.deviceMgrReConnected)

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        new CustomEvent<HubReconnectedEventDetail>(ManagerEvents.hubReconnected, {
          bubbles: true,
          cancelable: true,
          detail: { connectionId, hubUrl: this.url }
        })
      )
    } catch (e) {
      console.error('Exception in HubManager::hubReconnectedCallback() : ' + e)
    }
  }

  hubClosedCallback(error?: Error) {
    try {
      console.debug('HubManager::onHubClosedCallback() entered: ' + this.url.substring(this.url.lastIndexOf('/') + 1))

      this.isHubReconnecting = false
      this.isHubConnected = false

      this.clientEventsCallback?.(StatusEvents.deviceMgrConnectionClosed)

      // always fire the events in case there might be subscribers
      document.dispatchEvent(
        new CustomEvent<HubClosedEventDetail>(ManagerEvents.hubClosed, {
          bubbles: true,
          cancelable: true,
          detail: { error, hubUrl: this.url }
        })
      )
    } catch (e) {
      console.error('Exception in HubManager::hubClosedCallback() : ' + e)
    }
  }

  IsConnected() {
    return this.isHubConnected
  }
}
