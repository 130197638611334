import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack
} from '@chakra-ui/react'
import { Group } from '@le2/common-types'
import { ArrowNarrowLeftOutline } from 'heroicons-react'
import { useEffect, useState } from 'react'

import LoadingOverlay from '../../../components/LoadingOvelay'
import { IWImage } from '../../../nativeExtension/IWNativeExtDeviceDefines'
import { usePrintDeviceManager } from '../../../nativeExtension/hooks/usePrintManager'
import { getImageUrlFromB64, isBase64ImageEmpty } from '../../../utils/strings'
import CardFileUpload from '../../cards/components/CardFileUpload'

interface CardModalProps {
  isOpen: boolean
  onPrintIdName: string
  onClose: () => void
  onOpenPrintOptions: () => void
  group?: Group
}

async function mapImages(images: IWImage[][]) {
  const newImages = []
  for (let i = 0; images !== null && i < images.length; i++) {
    for (const { imageData } of images[i]) {
      const imageUrl = getImageUrlFromB64(imageData, 'bmp')
      const isEmpty = await isBase64ImageEmpty(imageUrl)
      if (!isEmpty) {
        newImages.push(imageUrl)
      }
    }
  }
  return newImages
}

export default function IdCardReport({ isOpen, onClose, onOpenPrintOptions, onPrintIdName }: CardModalProps) {
  const { imagesList, errorMessage } = usePrintDeviceManager()
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    if (imagesList) {
      mapImages(imagesList)
        .then((newImages) => {
          setPreviewImages(newImages)
        })
        .finally(() => {
          setLoading(false)
        })
    } else if (errorMessage) {
      setLoading(false)
    }
  }, [imagesList, errorMessage])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxWidth="fit-content">
        <ModalHeader textStyle="title" color="gray.700" textTransform="capitalize">
          <Icon
            as={ArrowNarrowLeftOutline}
            color="#1B3A6A"
            w={8}
            h={8}
            mr={2}
            cursor="pointer"
            onClick={() => {
              onOpenPrintOptions()
              onClose()
            }}
          />
          {onPrintIdName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody w={556}>
          <Stack spacing={3}>
            {previewImages?.length && (
              <Heading as="h6" size="sm" fontWeight={500}>
                Preview
                <CardFileUpload operation="preview" images={previewImages} handleFileUpload={() => {}} />
              </Heading>
            )}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button variant="newSecondary" rounded={'md'} mr={3} onClick={onClose}>
            Cancel
          </Button>

          <Button variant="newPrimary" rounded={'md'} form="recordsForm" type="submit">
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
      <LoadingOverlay isLoading={loading} text="Loading Card..." />
    </Modal>
  )
}
