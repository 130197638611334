import { JFL, Modality, createJFD, createJFL } from '@le2/jfd'
import { observe } from '@nx-js/observer-util'
import isEqual from 'lodash/isEqual'
import { ref, subscribe } from 'valtio'
import { devtools, proxyWithComputed } from 'valtio/utils'

import { JFLGroupRefProviderImpl } from '../../components/JFDRender/utils'
import { topSpace } from '../../layouts/resource'
import agencyConfig from '../../lib/agencyConfig'
import { RecordImages } from '../../lib/api/record'
import { RecordEnrollmentStatus } from '../../lib/api/record/record.interface'
import { BiographicsState, BiographicsStateComputedValues, Record, Status, newValidationResult } from './interfaces'
import { BiographicsSectionValidator } from './validator'

export const jfdInst = createJFD(agencyConfig.jfd)
const bookingJFLInst = createJFL(jfdInst, prepareJFL(agencyConfig.bookingJFL))

/**
 * Add `JFLGroupOptions.groupStyles.scrollMarginTop` to each JFLGroup.
 *
 * This is needed so that the `scrollIntoView` call in `BookingInformation.tsx`
 * works with the sticky sections of the UI.
 */
function prepareJFL(jfl: JFL): JFL {
  return {
    ...jfl,
    groups: jfl.groups.map((group) => {
      return {
        ...group,
        options: {
          ...group.options,
          groupStyles: {
            ...group.options?.groupStyles,
            scrollMarginTop: `calc(${topSpace})`
          }
        }
      }
    })
  }
}

const initialState: Record = {
  recordNumber: '',
  enrollmentStatus: RecordEnrollmentStatus.NEW,
  createdAt: undefined,
  updatedAt: undefined,
  submittedAt: undefined,
  isActive: false,
  isSealed: false,
  jfd: ref(jfdInst),
  originalJFD: {
    values: {},
    errors: {}
  },
  recordJFL: ref(bookingJFLInst),
  jflGroupRefProvider: ref(new JFLGroupRefProviderImpl()),
  recordImages: new RecordImages([]),
  hasSaveButtonBeenClicked: false,
  saveStatus: Status.Idle,
  submitStatus: Status.Idle,
  isLoadFromFile: false,
  isOnlyMandatory: false,
  isDirty() {
    return (
      !isEqual(this.jfd.getValues(), this.originalJFD.values) ||
      !isEqual(this.jfd.getErrors(), this.originalJFD.errors) ||
      this.recordImages.isDirty()
    )
  },
  maxImages: {
    [Modality.Face]: false
  },
  validation: {
    biographics: newValidationResult(Status.Idle),
    [Modality.Face]: newValidationResult(Status.Idle)
  },
  /**
   * Small utility to set `status` and `message` from a `ValidationResult`.
   *
   * We need to set those attributes separately (instead of assigning `result`
   * directly) to avoid an infinite render loop since the `ValidationResult`.
   * object will always be different.
   *
   * @param modality
   * @param result
   */
  setValidationStatus(modality, result) {
    this.validation[modality].status = result.status
    this.validation[modality].message = result.message
  },
  bypassSubmitValidations: false,
  mappedRecord: {
    id: 0,
    recordNum: '',
    enrollmentStatus: RecordEnrollmentStatus.NEW,
    biographics: {},
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: false,
    isSealed: false,
    name: '',
    images: new RecordImages([])
  }
}

export const recordState = proxyWithComputed(initialState, {
  isLoading: (snap) => {
    return (
      snap.validation.biographics.status === Status.Loading ||
      snap.validation[Modality.Face].status === Status.Loading ||
      snap.saveStatus === Status.Loading
    )
  },

  /**
   * Checks that all tabs are either `Idle` or `Complete`.
   *
   * @param snap
   */
  isSubmitEnabled: (snap) => {
    const validStatuses = [Status.Idle, Status.Complete]

    let modality: keyof Record['validation']
    for (modality in snap.validation) {
      if (!validStatuses.includes(snap.validation[modality].status)) {
        return false
      }
    }

    return true
  }
})

const initialBiographicsState: BiographicsState = {
  sections: []
}

export const biographicsState = proxyWithComputed(initialBiographicsState, {
  errorCount: (snap) => {
    return snap.sections.reduce((prev, curr) => prev + curr.errorCount, 0)
  },
  errorCountLabel: (snap) => {
    const casted = snap as BiographicsState & BiographicsStateComputedValues
    if (casted.errorCount === 1) {
      return `${casted.errorCount} missing field`
    } else {
      return `${casted.errorCount} missing fields`
    }
  }
})

/**
 * Register validators for the Submit button.
 *
 * We use two different mechanisms since errors come from different sources:
 * - Valtio's `subscribe` utility: specifically to subscribe to changes in
 *   `bookingImages` and `hasSaveButtonBeenClicked`. We trigger the validation
 *   when they change.
 *
 * - observer-util's `observe`: to listen for errors in the JFD and set them in
 *   the `recordState`.
 */
subscribe(recordState, doValidate)
observe(doValidate)

export function doValidate() {
  // const validator = new BookingSubmitValidator(
  //   recordState.jfd,
  //   recordState.bookingImages,
  //   recordState.hasSaveButtonBeenClicked
  // )
  // const allTabs: (keyof Record['validation'])[] = [
  //   'biographics',
  //   Modality.Face,
  // ]
  // allTabs.forEach((modality) => recordState.setValidationStatus(modality, validator.validate(modality)))
}

/**
 * Similar to the previous code: register validators for the JFL groups in the
 * Biographics section.
 */
subscribe(recordState, doValidateBiographicsSections)
observe(doValidateBiographicsSections)

function doValidateBiographicsSections() {
  const validator = new BiographicsSectionValidator(recordState.jfd, recordState.hasSaveButtonBeenClicked)
  biographicsState.sections.forEach((section) => {
    const result = validator.validate(section)
    section.status = result.status
    section.errorCount = result.errorCount
  })
}

devtools(recordState, 'Booking')
