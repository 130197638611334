import { useDisclosure } from '@chakra-ui/react'
import { BioType } from '@le2/jfd'
import { BookingPermissions } from '@le2/permissions'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BookingAction } from '../../App'
import { Booking } from '../../lib/api/booking'
import UpdateLineupFiltersDialog from './components/UpdateLineupFiltersDialog'
import useLineupFilters from './useLineupFilters'
import { createLineup, filterFields, getFirstAvailableBioType, lineupState } from './utils'

interface useCreateLineupFromBookingOptions {
  text: string
  iconAs: BookingAction['iconAs']
  iconMenu: BookingAction['iconMenu']
}

export default function useCreateLineupFromBooking({
  text,
  iconAs,
  iconMenu
}: useCreateLineupFromBookingOptions): BookingAction {
  const [bioType, setBioType] = useState(BioType.FaceFront)
  const lineupFilters = useLineupFilters(filterFields)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const history = useHistory()

  function onSelectSubject(booking: Booking) {
    lineupState.reset()
    lineupFilters.setFromBooking(booking)

    const lineup = createLineup()

    lineup.booking = booking
    lineupState.lineup = lineup

    setBioType(getFirstAvailableBioType(booking) || BioType.FaceFront)
    onOpen()
  }

  function createLineupFromBooking() {
    // set the new filters into the state
    lineupState.lineup.filters = [...lineupFilters.filterFields]
    lineupState.lineup.type = bioType === BioType.SMT ? 'smt' : 'face'
    lineupState.bioType = bioType
    lineupState.maxResults = lineupFilters.maxResults

    history.push('/lineups/new')
  }

  function onCancel() {
    lineupFilters.resetFilters()
    lineupState.reset()
    onClose()
  }

  function canCreateLineupFromBooking(booking: Booking) {
    return getFirstAvailableBioType(booking) !== undefined && !booking.isSealed
  }

  function sealedOrInvalidPhotoMessage(booking: Booking): string {
    return booking.isSealed ? 'This record is sealed.' : "This record doesn't have any valid lineup photo."
  }

  return {
    text,
    iconMenu,
    iconAs,
    onClick: onSelectSubject,
    isEnabled: canCreateLineupFromBooking,
    disableMessage: sealedOrInvalidPhotoMessage,
    permission: BookingPermissions.BookingCreateLineup,
    dialog: (
      <UpdateLineupFiltersDialog
        isOpen={isOpen}
        onClose={onClose}
        onCancel={onCancel}
        onUpdate={createLineupFromBooking}
        booking={lineupState.lineup.booking!}
        bioType={bioType}
        setBioType={setBioType}
        updateButtonText="Next"
        {...lineupFilters}
      />
    )
  }
}
