import { Modality } from '@le2/jfd'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import { RecordInformation } from '../RecordInformation'
import { jfdInst } from '../state'
import { FaceCaptureContainer } from './faceCapture/FaceCapture'

export interface RecordRoutesProps {
  action: 'create' | 'view'
}

export default function RecordRoutes(props: RecordRoutesProps) {
  const { path } = useRouteMatch()
  const modalitiesAvailable = jfdInst.getModalitesAvailable()

  return (
    <Switch>
      <Route path={`${path}/info`}>
        <RecordInformation action={props.action} />
      </Route>
      {modalitiesAvailable.includes(Modality.Face) && (
        <Route path={`${path}/face`}>
          <FaceCaptureContainer />
        </Route>
      )}
    </Switch>
  )
}
