import { useQuery } from '@apollo/client'
import { Group } from '@le2/common-types'

import { GroupResponse } from '../types'
import { GET_GROUP, mapGroup } from '../utils'

export interface GroupServerResponse {
  groups: GroupResponse[]
}

export default function useGetGroup(id: number, onCompleted?: (groups: Group) => void) {
  return useQuery<GroupServerResponse>(GET_GROUP, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: { id },
      take: 1
    },
    onCompleted(data) {
      if (onCompleted && data.groups) {
        return onCompleted(mapGroup(data.groups[0]))
      }
    }
  })
}
