import { SVGProps } from 'react'

export default function CropIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={16} height={16} fill="none" {...props}>
      <path
        fill="current"
        fillRule="evenodd"
        d="M4.364 0v11.636H16v1.455h-2.91V16h-1.454v-2.91H4.364c-.804 0-1.455-.65-1.455-1.454V4.364H0V2.909h2.91V0h1.454Zm8.727 10.182h-1.455V4.364H5.818V2.909h5.818c.804 0 1.455.651 1.455 1.455v5.818Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
