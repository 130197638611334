import { useEffect, useRef } from 'react'

/**
 * Hook to get the previous value of a state.
 * @param value The value of the state you want to preserve.
 * @returns the previous value of that state
 */
export function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
