import { JFD } from '@le2/jfd'
import { ReactNode, createContext, useContext } from 'react'

import { ErrorCountProvider, JFLGroupRefProvider } from './utils'

const JFDContext = createContext<JFDProviderValue | null>(null)

interface JFDProviderProps {
  children: ReactNode
  value: JFDProviderValue
}

interface JFDProviderValue {
  jfd: JFD
  onlyMandatory: boolean
  readOnly: boolean

  /**
   * See the prop with the same name in JFDFormProps.
   */
  action?: 'create' | 'view'

  /**
   * See the prop with the same name in JFDFormProps.
   */
  editable: boolean

  /**
   * Callback that can be used to manually re-render the root `<JFDForm>`
   * component.
   */
  forceUpdate: () => void

  /**
   * See the prop with the same name in JFDFormProps
   */
  errorCountProvider?: ErrorCountProvider

  /**
   * See the prop with the same name in JFDFormProps
   */
  jflGroupRefProvider?: JFLGroupRefProvider
}

export function JFDProvider({ children, value }: JFDProviderProps) {
  return <JFDContext.Provider value={value}>{children}</JFDContext.Provider>
}

export function useJFD(): JFDProviderValue {
  const ctx = useContext(JFDContext)
  if (ctx) {
    return ctx
  } else {
    throw new Error('useJFD must be used within JFDProvider')
  }
}
