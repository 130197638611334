import { proxy, useSnapshot } from 'valtio'

interface RouteStateI {
  historyStack: string[]
  currentPage: number
  reset: () => void
}

export const routeState = proxy<RouteStateI>({
  historyStack: [],
  currentPage: 0,
  reset() {
    this.historyStack = []
    this.currentPage = 0
  }
})

export function loadRouteStateFromSessionStorage() {
  routeState.reset()
  if (sessionStorage.getItem('historyStackState')) {
    const historyStackSate = JSON.parse(sessionStorage.getItem('historyStackState') || '')
    routeState.historyStack = historyStackSate.historyStackArr
    routeState.currentPage = historyStackSate.currentPage
  }
}

export function useRouteState() {
  const { historyStack: historyStackSnap, currentPage: currentPageSnap } = useSnapshot(routeState)

  function pushHistoryStack(key: string) {
    routeState.historyStack = [...routeState.historyStack, key]
    routeState.currentPage = routeState.historyStack.length
    sessionStorage.setItem(
      'historyStackState',
      JSON.stringify({
        historyStackArr: routeState.historyStack,
        currentPage: routeState.currentPage
      })
    )
  }

  function popHistoryStack(key: string) {
    const index = routeState.historyStack.indexOf(key)
    routeState.currentPage = index + 1
    sessionStorage.setItem(
      'historyStackState',
      JSON.stringify({
        historyStackArr: routeState.historyStack,
        currentPage: routeState.currentPage
      })
    )
  }

  return {
    historyStackSnap,
    currentPageSnap,
    pushHistoryStack,
    popHistoryStack
  }
}
