import {
  Box,
  Button,
  Link as CLink,
  FormControl,
  FormErrorMessage,
  Input,
  InputLeftElement,
  Text,
  chakra
} from '@chakra-ui/react'
import axios, { AxiosResponse } from 'axios'
import { LockClosedOutline, UserCircleOutline } from 'heroicons-react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import AccountLockoutTimeout from '../../components/AccountLockoutTimeout'
import { SystemVersionLabel } from '../../components/SystemVersionLabel'
import { LoginLayout } from '../../layouts/LoginLayout'
import auth from '../../services/auth'
import { clearLocalStorageKeys } from '../../utils/useAutomaticLogoutOnPageLeave'
import { systemInfoState } from '../../utils/useGetSystemInfo'

/**
 * This is a special component for that creates HTML/CSS that looks like this:
 * -------------------------------Text------------------------------------
 * @param text is the child of the span and the text displayed
 */
function TextHorizRule({ text }: { text: string }) {
  const horizRuleStyleDiv = {
    width: '100%',
    height: '.75rem',
    borderBottom: '1px solid #E5E7EB',
    marginBottom: '1.5rem',
    textAlign: 'center' as const
  }
  return (
    <Box style={horizRuleStyleDiv}>
      <chakra.span color="gray.400" backgroundColor="bgLogin" fontSize="1rem" pr="10px" pl="10px">
        {text}
      </chakra.span>
    </Box>
  )
}

interface login {
  username: string
  password: string
  stayLoggedIn: boolean
}
interface locationType {
  search: string
  state: {
    from: string
  }
}

export default function Login() {
  const { clearErrors, errors, formState, handleSubmit, register, setError } = useForm()
  const history = useHistory()
  const location: locationType = useLocation()
  const [lockoutTimeout, setLockoutTimeout] = useState<Date>()

  const { data: systemInfoData, loading: isLoadingSystemInfoData } = useSnapshot(systemInfoState)
  clearLocalStorageKeys()

  async function onSubmit(values: login) {
    try {
      await auth.login(values)
      // small hack so that useAutomaticLogoutOnPageLeave() doesn't make the logout
      // call once we land on the home page
      sessionStorage.setItem('reloaded', 'yes')

      if (location?.state?.from) {
        history.push(location.state.from)
      } else {
        history.push('/')
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response && error.response?.status === 401) {
          const { message } = error.response.data

          setError('password', { type: 'manual', message })

          verifyUserHasLockout(error.response)
        }
      } else {
        setError('password', { type: 'manual', message: 'Invalid Username/Password.' })
      }
    }
  }

  function verifyUserHasLockout(response: AxiosResponse) {
    const lockoutTimeout = response.headers['x-lockout-timeout']

    if (lockoutTimeout) {
      setLockoutTimeout(new Date(lockoutTimeout))
    }
  }

  const params = new URLSearchParams({ redirect_uri: `${window.location.origin}/web/OIDCCallback` })
  const redirectURI = `/v1/iwa/login?${params.toString()}`

  const queryParams = new URLSearchParams(location?.search || '')
  // we don't want to set a default value here in order for the checks during rendering to be used
  const OIDCError = queryParams.get('OIDCError')

  const welcomeMessage = `Welcome to ${systemInfoData.productName}`

  return (
    <LoginLayout>
      <Text textAlign="center" color="primary" textStyle="heading" mb={12}>
        {!isLoadingSystemInfoData && welcomeMessage}
      </Text>
      {systemInfoData.oidcEnabled && (
        <Box>
          <Button data-cy="loginOIDC" as={CLink} variant="loginSecondary" mb={4} href={redirectURI}>
            Login using {systemInfoData.oidcDisplayName || 'OIDC'}
          </Button>
          <FormControl isInvalid={!!OIDCError}>
            <FormErrorMessage>{OIDCError || ''}</FormErrorMessage>
          </FormControl>
          <TextHorizRule text="or" />
        </Box>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.username} mb={9}>
          <InputLeftElement color="primary" p={2} pointerEvents="none" children={<UserCircleOutline />} />
          <Input
            pl={9}
            background="white"
            name="username"
            placeholder="Username or email"
            autoComplete="username"
            maxLength={64}
            ref={register({ required: true })}
          />
          <FormErrorMessage>{errors.username && errors.username.message}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={errors.password} mb={5}>
          <InputLeftElement color="primary" p={2} pointerEvents="none" children={<LockClosedOutline />} />
          <Input
            pl={9}
            background="white"
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="current-password"
            maxLength={64}
            ref={register({ required: true })}
          />
          <FormErrorMessage>{errors.password && errors.password.message}</FormErrorMessage>
          <AccountLockoutTimeout
            onFinish={() => {
              setLockoutTimeout(undefined)
              clearErrors('password')
            }}
            lockoutTimeout={lockoutTimeout}
          />
        </FormControl>
        <Button variant="loginPrimary" isLoading={formState.isSubmitting} type="submit" mb={4}>
          LOGIN
        </Button>
      </form>
      <SystemVersionLabel le2Version={systemInfoData.le2Version} />
    </LoginLayout>
  )
}
