import { Avatar, Box, HStack, Modal, ModalBody, ModalContent, ModalOverlay, Text } from '@chakra-ui/react'
import { BioType } from '@le2/jfd'
import filesize from 'filesize'
import mime from 'mime-types'
import { ReactNode } from 'react'

import { Booking } from '../lib/api/booking'
import Image from '../lib/api/booking/Image'
import { getMIMETypeFromDataURI } from '../lib/imageUtils'
import BookingAvatar from './BookingAvatar'

export interface BookingImageModalProps {
  isOpen: boolean
  onClose: () => void
  booking: Booking
  bioType: BioType
}

export function BookingImageModal(props: BookingImageModalProps) {
  return (
    <ImageModal
      {...props}
      image={props.booking.images.getLatestImage(props.bioType)?.image}
      renderImage={<BookingAvatar booking={props.booking} bioType={props.bioType} width="100%" height="auto" />}
    />
  )
}

export interface ImageModalProps {
  isOpen: boolean
  onClose: () => void
  image?: Image | null
  renderImage?: ReactNode

  /**
   * Extra image details to be included below the picture. Note that this
   * component automatically includes the image size and format.
   */
  extraDetails?: Detail[]
}

export interface Detail {
  key: string
  label: string
  value: string
}

export function ImageModal({ image, renderImage, isOpen, onClose, extraDetails }: ImageModalProps) {
  const details = getImageDetails(image)
  let allDetails = extraDetails ? [...details, ...extraDetails] : details

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalBody padding={4} color="gray.700">
          {renderImage || <Avatar src={image?.src} borderRadius="4px" width="100%" height="auto" />}
          <Box mt={1} fontWeight={500}>
            Details
          </Box>
          <Box mt={1} textStyle="details">
            {allDetails.map((detail) => (
              <HStack key={detail.key}>
                <Text width="100px">{detail.label}</Text>
                <Text>{detail.value}</Text>
              </HStack>
            ))}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

function getImageDetails(image: Image | null | undefined) {
  const rawSize = image?.size || 0
  const size = filesize(rawSize, { base: 2, standard: 'jedec' })

  const mimeType = getMIMETypeFromDataURI(image?.src || '')
  const format = mimeType ? mime.extension(mimeType) : ''

  return [
    { key: 'size', label: 'Size', value: size },
    { key: 'format', label: 'Format', value: format && format.toUpperCase() }
  ]
}
