import { PrintType } from '@le2/jfd'
import { useMemo } from 'react'

export default function useGetPrintTypeSubItems(printTypes: PrintType[], basePath: string) {
  const subItems = useMemo(() => {
    return printTypes.map((printType) => {
      return generatePrintTypeSubItem(printType, basePath)
    })
  }, [printTypes, basePath])

  return subItems
}

export function generatePrintTypeSubItem(printType: PrintType, basePath: string): { label: string; to: string } {
  const params = new URLSearchParams({ 'jfd-value': printType.value })
  return {
    label: printType.label,
    to: `${basePath}?${params}`
  }
}
