import {
  Avatar,
  Box,
  Checkbox,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  VStack,
  useTheme
} from '@chakra-ui/react'
import { getColor } from '@chakra-ui/theme-tools'
import { InformationCircleOutline } from 'heroicons-react'
import { Fragment, ReactNode } from 'react'
import { DeepMap, FieldError } from 'react-hook-form'
import Select, { OptionProps, OptionTypeBase, OptionsType, Props, ValueContainerProps, components } from 'react-select'

import BookingAvatar, { bookingAvatarProps } from '../../../components/BookingAvatar'
import ReactSelectControl from '../../../components/ReactSelectControl'
import UploadStatusIndicator from '../../../components/UploadStatusIndicator'
import XOutlineThick from '../../../components/icons/XOutlineThick'
import { BioType, Booking } from '../../../lib/api/booking'
import Image from '../../../lib/api/booking/Image'
import { UseLineupFiltersResult } from '../useLineupFilters'
import {
  Filter,
  ImportedLineupFields,
  SimpleFilter,
  availableBioTypes,
  importedLineupFieldDef,
  isDummyBooking,
  results
} from '../utils'

interface LineupFiltersProps extends UseLineupFiltersResult {
  booking: Booking
  bioType?: BioType
  importedImage?: Image
  reuploadImportedImage?: () => void
  register?: Function
  errors?: DeepMap<ImportedLineupFields, FieldError>
  setBioType: (val: BioType) => void
}

const scrollBarStyles = {
  ':hover': {
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: `gray.200`
    }
  },
  '&::-webkit-scrollbar': {
    width: '8px'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px'
  }
}

const scrollBarSpacing = '2%'

export default function LineupFilters({
  booking,
  bioType,
  importedImage,
  errors,
  reuploadImportedImage,
  register,
  setBioType,
  filterFields,
  updateFilterState,
  maxResults,
  onChangeMaxResults
}: LineupFiltersProps) {
  const isImportedLineupCreation = isDummyBooking(booking) && importedImage && register

  return (
    <>
      <Flex alignItems="center">
        {isImportedLineupCreation ? (
          <LineupImportedBookingImage importedImage={importedImage} reuploadImportedImage={reuploadImportedImage} />
        ) : (
          <BookingAvatar {...bookingAvatarProps(booking, bioType)} size="2xl" borderRadius="8px" />
        )}
        <Box ml={5} overflow="hidden" flexGrow={1}>
          {!isImportedLineupCreation && <LineupFilterInfo booking={booking} />}
          <Box>
            <RadioGroup onChange={setBioType} value={bioType}>
              <Stack direction="column" pl={1} pb={1}>
                {availableBioTypes.map((e) => (
                  <Radio key={e.value} value={e.value} isDisabled={!booking.images.getLatestImage(e.value)}>
                    <Text textStyle="details" color="gray.500">
                      {e.label}
                    </Text>
                  </Radio>
                ))}
              </Stack>
            </RadioGroup>
          </Box>
        </Box>
      </Flex>
      <VStack
        mt={7}
        spacing={4}
        maxH={isImportedLineupCreation ? 420 : 220}
        overflowY="scroll"
        alignItems="flex-start"
        p={1}
        width={`calc(100% + ${scrollBarSpacing})`}
        sx={scrollBarStyles}
      >
        {isImportedLineupCreation && <ImportedLineupForm errors={errors} register={register} />}
        {isImportedLineupCreation && (
          <Text fontSize="xs" textStyle="emphasis" color="gray.700">
            Search Parameters:
          </Text>
        )}
        <LineupFilterFields
          booking={booking}
          maxResults={maxResults}
          onChangeMaxResults={onChangeMaxResults}
          filterFields={filterFields}
          updateFilterState={updateFilterState}
        />
      </VStack>
    </>
  )
}

function LineupImportedBookingImage({
  importedImage,
  reuploadImportedImage
}: {
  importedImage?: Image
  reuploadImportedImage?: () => void
}) {
  return (
    <Box position="relative" borderRadius="4px" overflow="hidden">
      <UploadStatusIndicator
        handleUpload={reuploadImportedImage}
        isUploading={importedImage?.isUploading}
        hasUploadError={importedImage?.hasUploadFailed}
      />
      <Avatar src={importedImage?.src} size="2xl" borderRadius="4px" />
    </Box>
  )
}

function LineupFilterInfo({ booking }: { booking: Booking }) {
  return (
    <>
      <Text textStyle="emphasis" fontWeight={700} color="gray.700" isTruncated>
        {booking.name}
      </Text>
      {booking.bookingNum && (
        <Text fontSize="xs" textStyle="emphasis" color="gray.700">
          Booking Number: {booking.bookingNum}
        </Text>
      )}
      <Divider my={2} color="gray.100" />
    </>
  )
}

function ImportedLineupForm({
  errors,
  register
}: {
  errors?: DeepMap<ImportedLineupFields, FieldError>
  register?: Function
}) {
  return (
    <>
      <Text fontSize="xs" textStyle="emphasis" color="gray.700">
        Subject Information:
      </Text>
      {importedLineupFieldDef.map(
        ({ label, name, required, minLength, maxLength, pattern, textTransform, setValueAs, placeholder }, i) => (
          <FormControlInternal label={label} key={i} isInvalid={!!errors?.[name as keyof ImportedLineupFields]}>
            <InputGroup>
              <Input
                ref={register?.({ required, minLength, maxLength, pattern, setValueAs })}
                name={name}
                fontSize={12}
                placeholder={placeholder}
                maxLength={maxLength.value}
                size="sm"
                borderRadius={4}
                textTransform={textTransform}
              />
              <InputRightElement
                color="primary"
                height="100%"
                children={
                  <Tooltip label={placeholder} minWidth={350}>
                    <InformationCircleOutline />
                  </Tooltip>
                }
              />
            </InputGroup>
            <FormErrorMessage>{errors?.[name as keyof ImportedLineupFields]?.message}</FormErrorMessage>
          </FormControlInternal>
        )
      )}
      <Divider my={2} color="gray.100" />
    </>
  )
}

function LineupFilterFields({
  booking,
  filterFields,
  updateFilterState,
  maxResults,
  onChangeMaxResults
}: {
  booking: Booking
  filterFields: Filter[]
  updateFilterState: () => void
  maxResults: number
  onChangeMaxResults: (maxResults: number) => void
}) {
  const options: OptionTypeBase[] = results.map((e) => ({ value: e, label: e }))

  const defaultValue = options.find((option) => option.value === maxResults)

  function onChange(option: OptionTypeBase) {
    onChangeMaxResults(option.value as number)
  }

  return (
    <>
      {filterFields.map((filter, idx) => (
        <Fragment key={`${booking.id}_${idx}`}>
          {filter.inputType === 'input' ? (
            <LineupFilterInput filter={filter} onChange={updateFilterState} />
          ) : (
            <LineupFilter filter={filter as SimpleFilter} onChange={updateFilterState} />
          )}
        </Fragment>
      ))}
      <FormControlInternal label="Max results">
        <SmallSelect
          value={options.find((option) => option.value === maxResults)}
          onChange={(option) => onChange(option as OptionTypeBase)}
          defaultValue={defaultValue}
          options={options}
          menuPortalTarget={document.querySelector('body')}
          menuShouldBlockScroll
          components={{ ValueContainer, Control: ReactSelectControl }}
        />
      </FormControlInternal>
    </>
  )
}

interface LineupFilterProps {
  filter: Filter
  onChange: () => void
}

interface LineupFilterSelectProps extends LineupFilterProps {
  filter: SimpleFilter
}

function LineupFilterInput({ filter, onChange }: LineupFilterProps) {
  function handleOnChange(value: string) {
    filter.onChange(value)
    onChange()
  }

  return (
    <FormControlInternal isInvalid={filter.hasError} errorMessage={filter.errorMessage} label={filter.label}>
      <InputGroup>
        <Input
          onChange={(event) => handleOnChange(event.target.value)}
          value={filter.value || ''}
          fontSize={12}
          placeholder={filter.placeholder}
          size="sm"
          maxLength={5}
          borderRadius={4}
        />
        {filter.value !== '' && (
          <InputRightElement height="auto">
            <IconButton
              aria-label="Clear input"
              variant="ghost"
              icon={<Icon as={XOutlineThick} w={4} h={4} />}
              padding={1}
              minWidth="auto"
              height={8}
              onClick={() => handleOnChange('')}
              color="hsl(0, 0%, 80%)"
              _hover={{
                bgColor: 'none',
                color: 'hsl(0, 0%, 60%)'
              }}
            />
          </InputRightElement>
        )}
      </InputGroup>
    </FormControlInternal>
  )
}

function LineupFilter({ filter, onChange }: LineupFilterSelectProps) {
  const { options, label } = filter

  const handleOnChange = (e: OptionsType<OptionTypeBase>) => {
    const value = e.length > 0 ? e.map((i) => i.value) : []

    filter.onChange(value)
    onChange()
  }

  return (
    <FormControlInternal label={label}>
      <SmallSelect
        inputId={label}
        options={options}
        defaultValue={filter.getDefaultValue()}
        onChange={(e) => handleOnChange(e)}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        menuPortalTarget={document.querySelector('body')}
        components={{ Option, ValueContainer, Control: ReactSelectControl }}
        menuShouldBlockScroll
        isMulti
        isClearable
      />
    </FormControlInternal>
  )
}

interface FormControlInternalProps {
  label: string
  children: ReactNode
  isInvalid?: boolean
  errorMessage?: string
}

function FormControlInternal({ label, children, isInvalid = false, errorMessage = '' }: FormControlInternalProps) {
  return (
    <Box width={`calc(100% - ${scrollBarSpacing})`}>
      <FormControl id={label} isInvalid={isInvalid} display="flex">
        <FormLabel width="80px" textStyle="details" color="gray.700" mr={1} mb={0} mt={2}>
          {label}
        </FormLabel>
        <Box flexGrow={1} textStyle="details">
          {children}
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </Box>
      </FormControl>
    </Box>
  )
}

function SmallSelect<OptionType extends OptionTypeBase, IsMulti extends boolean>(props: Props<OptionType, IsMulti>) {
  const paddingProps = {
    paddingLeft: '3px',
    paddingRight: '4px',
    paddingTop: '0px',
    paddingBottom: '0px'
  }

  const theme = useTheme()

  function multiOptionStyles(state: OptionProps<OptionType, IsMulti>) {
    return {
      backgroundColor: state.isFocused ? getColor(theme, 'gray.100') : 'transparent',
      position: 'relative',
      ':active': {
        backgroundColor: getColor(theme, 'gray.200')
      }
    } as React.CSSProperties
  }

  return (
    <Select
      {...props}
      styles={{
        control: (base) => ({ ...base, minHeight: '32px' }),
        /* Text will overflow without occupying space in the layout.
        Thanks to this, the Select's ValueContainer will maitain one line. */
        input: (base) => ({ ...base, width: 0 }),
        valueContainer: (base) => ({ ...base, ...paddingProps, lineHeight: '150%' }),
        dropdownIndicator: (base) => ({ ...base, ...paddingProps }),
        clearIndicator: (base) => ({ ...base, ...paddingProps }),
        menuPortal: (base) => ({ ...base, zIndex: theme.zIndices.popover }),
        multiValueLabel: (base) => ({ ...base, padding: 1, maxWidth: '90px' }),
        multiValueRemove: (base) => ({ ...base, backgroundColor: getColor(theme, 'gray.200') }),
        option: (base, state) => ({ ...base, ...(state.isMulti && multiOptionStyles(state)) })
      }}
    />
  )
}

function Option<Option, IsMulti extends boolean>(props: OptionProps<Option, IsMulti>) {
  return (
    <components.Option {...props}>
      <Checkbox
        color="gray.700"
        textStyle="paragraph"
        isChecked={props.isSelected}
        onChange={() => null}
        onClick={(e) => e.preventDefault()}
      >
        {props.label}
      </Checkbox>
    </components.Option>
  )
}

function PlusBox({ number }: { number: number }) {
  return (
    <Text bgColor="gray.200" p="1px 3px" fontSize="10px" borderRadius="2px">
      + {number}
    </Text>
  )
}

function ValueContainer<Option, IsMulti extends boolean>({ children, ...props }: ValueContainerProps<Option, IsMulti>) {
  const maxOptions = 3
  let [values, input] = children as any

  if (Array.isArray(values)) {
    const { length } = values
    if (length > maxOptions) {
      values = values.slice(0, maxOptions)
      values.push(<PlusBox number={length - maxOptions} />)
    }
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  )
}
