import { gql } from '@apollo/client'

const USER_FIELDS = gql`
  fragment UserDetails on User {
    id
    firstName
    lastName
    email
    username
    isOidcLogin
    isAdmin
    createdAt
    updatedAt
    isActive
    isBlocked
    department
    extId
  }
`

export const GET_USERS = gql`
  ${USER_FIELDS}
  query GetUsers {
    users {
      ...UserDetails
    }
  }
`

export const GET_DEPARTMENTS = gql`
  query GetDepartments {
    departments
  }
`

export const ADD_USER = gql`
  ${USER_FIELDS}
  mutation CreateUser($user: UserCreateInput!) {
    createUser(input: $user) {
      ...UserDetails
    }
  }
`
export const EDIT_USER = gql`
  ${USER_FIELDS}
  mutation UpdateUser($user: UserUpdateInput!, $action: String) {
    updateUser(input: $user, action: $action) {
      ...UserDetails
      groups {
        addedAt
        group {
          id
          name
          permissions
        }
      }
    }
  }
`

export const ENABLE_USER = gql`
  mutation enableUser($id: Int) {
    enableUser(userId: $id)
  }
`

export const DISABLE_USER = gql`
  mutation disableUser($id: Int) {
    disableUser(userId: $id)
  }
`

export const UNBLOCK_USER = gql`
  mutation unblockUser($id: Int!) {
    unblockUser(userId: $id)
  }
`

export const BLOCK_USER = gql`
  mutation blockUser($id: Int!) {
    blockUser(userId: $id)
  }
`

export const CHANGE_USER_PASSWORD = gql`
  mutation changePwdUser($id: Int, $password: String) {
    changePwdUser(userId: $id, password: $password)
  }
`

export const ME = gql`
  ${USER_FIELDS}
  query {
    me {
      ...UserDetails
      groups {
        addedAt
        group {
          id
          name
          permissions
        }
      }
    }
  }
`
