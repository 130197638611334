import { darken } from '@chakra-ui/theme-tools'

const Radio = {
  baseStyle: {
    control: {
      border: '1px solid',
      borderColor: 'gray.400',
      bg: 'gray.100',
      _hover: {
        borderColor: darken('gray.400', 5),
        bg: darken('gray.100', 5)
      },
      _checked: {
        borderColor: 'secondary',
        bg: 'gray.100',
        _hover: {
          borderColor: darken('secondary', 5),
          bg: darken('gray.100', 5)
        },
        _before: {
          w: '66%',
          h: '66%',
          bg: 'secondary'
        }
      },
      _disabled: {
        bg: 'gray.400',
        borderColor: 'gray.400'
      }
    }
  }
}

export default Radio
