import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useMemo, useRef, useState } from 'react'
import Webcam from 'react-webcam'

import EditPhotoModalContents, {
  EditPhotoModalProps,
  faceCaptureModalPrompt
} from '../../../components/EditPhotoModalContents'
import TakePhotoModalContents, { TakePhotoModalProps } from '../../../components/TakePhotoModalContents'
import { ThemeModeEnum } from '../../../hooks/useThemeMode'
import { BioType } from '../../../lib/api/booking'
import { DeviceManagerProps } from '../../../nativeExtension/hooks'
import { rotateImage } from '../../../utils/imageUtils'

enum ImageCaptureModalState {
  Preview, // state where the webcam is showing to the user and they take a photo to move forward
  Edit // state where the user is finishing adjustments and either bin the image (dismiss) or save (saves and dismisses)
}

interface PhotoModalProps extends TakePhotoModalProps, EditPhotoModalProps {
  state: ImageCaptureModalState
  deviceManagerProps: DeviceManagerProps
  finishButtonText?: string
  themeMode: ThemeModeEnum
  prompt?: string
  displayImageGuidelines?: boolean
}

function PhotoModalContents(props: PhotoModalProps) {
  const isEditStep = useMemo(() => props.state === ImageCaptureModalState.Edit, [props.state])
  return (
    <>
      <TakePhotoModalContents {...props} isHidden={isEditStep} />
      <EditPhotoModalContents {...props} isHidden={!isEditStep} />
    </>
  )
}

interface ImageCaptureModalProps {
  bioType: BioType
  onComplete: (imageStr: string) => void
  isOpen: boolean
  onClose: () => void
  deviceManagerProps: DeviceManagerProps
  finishButtonText?: string
  themeMode: ThemeModeEnum
  faceDetectionEnabled?: boolean
  prompt?: string
}

// pops up a modal that lets the user edit their photo and returns the string image (or undefined if the user cancels)
export function ImageCaptureModal(props: ImageCaptureModalProps) {
  const webcamRef = useRef<Webcam>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const [state, setModalState] = useState(ImageCaptureModalState.Preview)
  const [imageSrc, setImageSrc] = useState<string | undefined>()
  const modalPrompt: string = faceCaptureModalPrompt

  // here if it's a face capture we want to autocrop around the face, if 1 face is detected
  function goToEditScreen(imageRotationdAngle: number) {
    const screenshot = webcamRef.current?.getScreenshot()
    if (!screenshot) {
      return
    }

    let imageSrc = screenshot
    if (imageRotationdAngle > 0) {
      // If image is rotated
      rotateImage(screenshot, imageRotationdAngle, (rotatedImage) => {
        setModalState(ImageCaptureModalState.Edit)
        setImageSrc(rotatedImage)
      })
    } else {
      if (imageSrc) {
        setModalState(ImageCaptureModalState.Edit)
        setImageSrc(imageSrc)
      }
    }
  }

  function goToTakePhotoScreen() {
    setModalState(ImageCaptureModalState.Preview)
    setImageSrc(undefined)
  }

  // propagates the image up the heirarchy and resets the modal state for later
  function finishCapture(newImageSrc: string) {
    props.onComplete(newImageSrc)
    dismiss()
  }

  // reset the state to preview before we leave the view
  function dismiss() {
    setModalState(ImageCaptureModalState.Preview)
    props.onClose()
  }

  return (
    <Modal isOpen={props.isOpen} onClose={dismiss} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent
        width="50%"
        maxWidth="50%"
        style={{
          right: 'auto',
          bottom: 'auto',
          outline: '0',
          overflow: 'hidden'
        }}
      >
        <PhotoModalContents
          state={state}
          goToEditScreen={goToEditScreen}
          goToTakePhotoScreen={goToTakePhotoScreen}
          imageSrc={imageSrc}
          onDismiss={dismiss}
          onFinishCapture={finishCapture}
          webcamRef={webcamRef}
          canvasRef={canvasRef}
          bioType={props.bioType}
          deviceManagerProps={props.deviceManagerProps}
          finishButtonText={props.finishButtonText}
          themeMode={props.themeMode}
          faceDetectionEnabled={props.faceDetectionEnabled}
          prompt={modalPrompt}
          displayImageGuidelines={true}
        />
      </ModalContent>
    </Modal>
  )
}

interface FaceCaptureModalProps {
  onComplete: (imageSrc: string, bioType: BioType) => void
  bioType: BioType
  isOpen: boolean
  onClose: () => void
  deviceManagerProps: DeviceManagerProps
  finishButtonText?: string
  themeMode?: ThemeModeEnum
  faceDetectionEnabled?: boolean
}

export function FaceCaptureModal(props: FaceCaptureModalProps) {
  function internalComplete(imageStr: string) {
    props.onComplete(imageStr, props.bioType)
  }

  return (
    <ImageCaptureModal
      onComplete={internalComplete}
      bioType={props.bioType}
      isOpen={props.isOpen}
      onClose={props.onClose}
      deviceManagerProps={props.deviceManagerProps}
      finishButtonText={props.finishButtonText}
      themeMode={props.themeMode || ThemeModeEnum.OLD}
      faceDetectionEnabled={props.faceDetectionEnabled}
    />
  )
}
