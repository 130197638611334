import { useLocation } from 'react-router-dom'

import { RecordViewMode } from '../interfaces'

export function useRecordViewMode(): RecordViewMode {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const mode = params.get('mode')

  if (mode && mode in RecordViewMode) {
    return RecordViewMode[mode as keyof typeof RecordViewMode]
  } else {
    // default to edit mode, for new bookings
    return RecordViewMode.edit
  }
}
