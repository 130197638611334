import { Box, Button, Flex, HStack, Icon, Text, Tooltip, useDisclosure } from '@chakra-ui/react'
import { RecordPermissions } from '@le2/permissions'
import { BanOutline, PencilOutline, PrinterOutline } from 'heroicons-react'
import * as React from 'react'
import { Prompt, useHistory, useLocation, useParams } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import { RecordAction } from '../../App'
import PermissionWrapper from '../../components/PermissionWrapper'
import { StatusContentPage } from '../../components/StatusContentPage'
import StatusControl from '../../components/StatusControl'
import { StatusMessage } from '../../components/StatusMessage'
import { TableActionButton } from '../../components/TableActionButton'
import { usePromptMessage } from '../../hooks/usePromptMessage'
import { useRouteState } from '../../hooks/useRouteState'
import { ResourceDetails, ResourceLayout } from '../../layouts/resource'
import agencyConfig from '../../lib/agencyConfig'
import { useDownloadImage } from '../../lib/api/record'
import { useHistoryState } from '../../lib/useHistoryState'
import { useConnectToDeviceHub, useConnectToPrintHub } from '../../nativeExtension/hooks'
import { getHeaderFromPrintType } from '../../utils/getHeaderFromPrintType'
import usePreventUnload from '../../utils/usePreventUnload'
import RecordPrintReport from './components/RecordPrintReport'
import RecordRoutes from './components/RecordRoutes'
import { useGetRecordToState } from './hooks/useGetRecordToState'
import { useRecordMenuItems } from './hooks/useRecordMenuItems'
import { useRecordViewMode } from './hooks/useRecordViewMode'
import { useSaveRecord } from './hooks/useSaveRecord'
import { Record, RecordViewMode, Status } from './interfaces'
import { jfdInst, recordState } from './state'

interface RecordUrlParams {
  recordNum: string
}

const footerHeight = '72px'

interface RecordDisplayProps {
  recordActions?: RecordAction[]
}

export default function RecordView({ recordActions = [] }: RecordDisplayProps) {
  // Start a connection to NativeExtensions Device Manager
  useConnectToDeviceHub()
  // Start a connection to NativeExtensions Print Manager
  useConnectToPrintHub()

  const backToRecordsPage = useBackToRecordsPage()

  const { recordNum } = useParams<RecordUrlParams>()
  const { loading, error, refetch } = useGetRecordToState(recordNum)

  const snap = useSnapshot(recordState)

  // prevent page navigation when editing
  const viewMode = useRecordViewMode()
  const shouldBlock = viewMode === RecordViewMode.edit
  usePreventUnload(() => snap.isDirty(), shouldBlock)
  const promptMessage = usePromptMessage(`/records/${recordNum}`, () => snap.isDirty(), shouldBlock)

  const recordMenuItems = useRecordMenuItems()

  // get the latest FaceFront to be used as avatar
  // use a dummy placeholder to show an empty image while it loads
  let src = 'placeholder'
  const latestFaceFront = snap.recordImages.getLatestFaceFront()
  if (latestFaceFront?.image && !latestFaceFront.image.isURL) {
    src = latestFaceFront.image.src
  }
  useDownloadImage(recordState.recordImages.getLatestFaceFront())

  const toggleViewMode = useToggleViewMode(viewMode)
  const handleSave = useSaveRecord()

  const history = useHistory()

  // redirect to home if we land here with mode=edit, and we have read-only
  // records in the agency config
  if (viewMode === RecordViewMode.edit && !agencyConfig.features.records.edit) {
    history.replace('/')
  }

  const { isOpen: isOpenPrintOptions, onOpen: onOpenPrintOptions, onClose: onClosePrintOptions } = useDisclosure()

  const actionDialogs = React.useMemo(() => {
    return recordActions.reduce((dialogs, action) => {
      if (action.dialog) {
        dialogs.push(action.dialog)
      }

      return dialogs
    }, [] as JSX.Element[])
  }, [recordActions])

  return (
    <StatusContentPage
      isLoading={loading}
      hasError={Boolean(error)}
      errorMessage="Oops, it looks like there is a problem, please try again"
      onRetry={refetch}
    >
      <Prompt message={promptMessage} />

      <RecordPrintReport
        isOpen={isOpenPrintOptions}
        onClose={onClosePrintOptions}
        onOpen={onOpenPrintOptions}
        jfd={jfdInst}
        records={[recordState.mappedRecord]}
      />

      <ResourceLayout
        avatar={src}
        header={getHeader(snap as Record)}
        status={(snap as Record).isSealed && <StatusControl label="Sealed" iconAs={BanOutline} variantColor="gray" />}
        menuItems={recordMenuItems}
        details={<RecordDetailsImpl record={snap as Record} />}
        actions={
          <RecordActions
            record={snap as Record}
            recordActions={recordActions}
            onPrint={onOpenPrintOptions}
            viewMode={viewMode}
            onEdit={toggleViewMode}
          />
        }
        footer={
          <Footer
            viewMode={viewMode}
            isSaveEnabled={!snap.isLoading}
            onSave={handleSave}
            onCancel={backToRecordsPage}
          />
        }
      >
        {/* add some bottom padding for the sticky footer */}
        <Box pb={footerHeight}>
          <RecordRoutes action="view" />
        </Box>
      </ResourceLayout>
      {actionDialogs.map((dialog, index) => (
        <React.Fragment key={index}>{dialog}</React.Fragment>
      ))}
    </StatusContentPage>
  )
}

function getHeader(record: Record) {
  const fullName = record.jfd.getValue('DisplayFullName')
  return fullName || 'Unknown'
}

interface RecordDetailsImplProps {
  record: Record
}

function RecordDetailsImpl({ record }: RecordDetailsImplProps) {
  const { jfd } = record

  return (
    <ResourceDetails>
      <Text>{`Record Number [${jfd.recordNumber}]`}</Text>
      {jfd.hasFieldDef('PrintType') && <Text>{getHeaderFromPrintType(jfd.getValue('PrintType'), 'records')}</Text>}
      <Text>Created: {record.createdAt?.toLocaleDateString()}</Text>
      {record.saveStatus === Status.Idle && ( //
        <Text>Last saved: {record.updatedAt?.toLocaleDateString()}</Text>
      )}
      {record.saveStatus !== Status.Idle && (
        <StatusMessage
          status={record.saveStatus}
          date={record.updatedAt}
          loadingMessage="Saving..."
          errorMessage="Save failed, please try again..."
          completeMessage="Saved:"
          completeMessageSeconds="Saved Changes"
        />
      )}
      {record.submitStatus !== Status.Idle && (
        <StatusMessage
          status={record.submitStatus}
          date={record.submittedAt}
          loadingMessage="Submitting..."
          errorMessage="Submit failed, please try again..."
          completeMessage="Submitted:"
          completeMessageSeconds="Submitted"
        />
      )}
    </ResourceDetails>
  )
}

interface RecordActionsProps {
  viewMode: RecordViewMode
  record: Record
  recordActions: RecordAction[]
  onPrint: () => void
  onEdit: () => void
}

function RecordActions({ record, recordActions, ...props }: RecordActionsProps) {
  if (props.viewMode === RecordViewMode.view) {
    const buttonSize = { buttonSize: 'sm', iconSize: 5 }
    return (
      <>
        {recordActions.map((action, index) => (
          <PermissionWrapper permission={action.permission} key={`wrapper_${index}`}>
            <Tooltip
              label={action.disableMessage(record.mappedRecord)}
              shouldWrapChildren
              isDisabled={action.isEnabled(record.mappedRecord)}
            >
              <Button
                size="sm"
                onClick={() => action.onClick(record.mappedRecord)}
                variant="secondary"
                isDisabled={!action.isEnabled(record.mappedRecord)}
              >
                {action.text}
              </Button>
            </Tooltip>
          </PermissionWrapper>
        ))}
        {agencyConfig.features.records.edit && (
          <PermissionWrapper permission={RecordPermissions.RecordEdit}>
            <TableActionButton
              onClick={props.onEdit}
              ariaLabel="Edit mode"
              iconAs={PencilOutline}
              tooltip={{
                label: 'Edit'
              }}
              {...buttonSize}
            />
          </PermissionWrapper>
        )}
        <PermissionWrapper permission={RecordPermissions.RecordPrint}>
          <TableActionButton
            onClick={props.onPrint}
            ariaLabel="Print"
            iconAs={PrinterOutline}
            tooltip={{
              label: 'Print'
            }}
            {...buttonSize}
          />
        </PermissionWrapper>
      </>
    )
  } else {
    return (
      <Flex align="center" color="gray.700" bgColor="gray.100" borderRadius={4} px={4} py={1}>
        <Icon as={PencilOutline} w={6} h={6} />
        <Text ml={2} fontWeight={500}>
          Edit Mode
        </Text>
      </Flex>
    )
  }
}

function useToggleViewMode(viewMode: RecordViewMode) {
  const location = useLocation()
  const history = useHistory()

  return function toggleViewMode() {
    const params = new URLSearchParams(location.search)
    if (viewMode === RecordViewMode.view) {
      params.set('mode', RecordViewMode.edit)
    } else {
      params.set('mode', RecordViewMode.view)
    }

    history.push({
      pathname: location.pathname,
      search: params.toString()
    })
  }
}

interface FooterProps {
  viewMode: RecordViewMode
  isSaveEnabled: boolean
  onSave: () => void
  onCancel: () => void
}

function Footer({ viewMode, isSaveEnabled, onSave, onCancel }: FooterProps) {
  const containerProps = { px: 6, py: 4, justifyContent: 'flex-end', spacing: 4, height: footerHeight }

  if (viewMode === RecordViewMode.view) {
    return (
      <HStack {...containerProps}>
        <Button onClick={onCancel}>Back to records</Button>
      </HStack>
    )
  } else {
    return (
      <HStack {...containerProps}>
        <Button onClick={onCancel} variant="secondary" disabled={!isSaveEnabled}>
          Cancel
        </Button>
        <Button onClick={onSave} variant="secondary" disabled={!isSaveEnabled}>
          Save
        </Button>
      </HStack>
    )
  }
}
interface RecordViewHistoryState {
  lastPage: number
}

interface LocationState {
  from?: string
}

const recordViewStateRef = 'recordViewState'

/**
 * Handles the navigation to records page and keeps the search, filters and page number
 * used before entering this record.
 */
function useBackToRecordsPage() {
  const location = useLocation()
  const { state } = location
  const history = useHistory()
  const { hasValue, setValue, getValue } = useHistoryState<RecordViewHistoryState>()
  const { historyStackSnap, currentPageSnap } = useRouteState()

  const locationState = (state ?? {}) as LocationState
  const fromUrl = location && 'from' in locationState ? locationState.from : null

  const lastRecordsPage = React.useMemo(() => {
    // Verify if the value exists on the history state
    if (hasValue(recordViewStateRef)) {
      // Gets the value from the history state
      return getValue(recordViewStateRef).lastPage
    } else if (fromUrl && fromUrl === '/records') {
      // If previous page is records table, we can ensure records path
      // was displayed before current path (records/:number)
      return historyStackSnap.length - 1
    } else {
      // Since there is no existing value, previous page can be anything,
      // then there is no last records page known.
      return null
    }
  }, [hasValue, getValue, fromUrl, historyStackSnap.length])

  // Saves the ref to the records page even if the page reloads.
  const setIntoHistoryState = React.useCallback(
    (lastRecordsPage: number) => {
      setValue(recordViewStateRef, {
        lastPage: lastRecordsPage
      })
    },
    [setValue]
  )

  // Sets the last page to records into the history state when the page loads or the user navigate between tabs.
  React.useEffect(() => {
    if (lastRecordsPage) {
      setIntoHistoryState(lastRecordsPage)
    }
  }, [location, setIntoHistoryState, lastRecordsPage])

  // Redirects to the last records page.
  function backToRecordsPage() {
    if (!lastRecordsPage) {
      // If there is no lastRecordsPage, then navigate to records page
      history.push('/records')
    } else {
      // if there is lastRecordsPage, go back in history to the /records page
      // Has the negative number of pages to return to the records page.
      const pageNumberToRedirect = lastRecordsPage - currentPageSnap
      history.go(pageNumberToRedirect)
    }
  }

  return backToRecordsPage
}
