import { Flex, Icon, Text } from '@chakra-ui/react'
import { ReactNode, useRef } from 'react'

import './react-contextmenu.css'

export { ContextMenu, ContextMenuTrigger, SubMenu, MenuItem } from 'react-contextmenu'

let index = 0

/**
 * Generate an ID for <ContextMenuTrigger> and <ContextMenu>.
 */
export function useContextMenuId() {
  const id = useRef(index++).current
  return `context-menu-${id}`
}

export interface MenuItemContentsProps {
  iconAs: any
  children: ReactNode
}

export function MenuItemContents({ iconAs, children }: MenuItemContentsProps) {
  return (
    <Flex alignItems="center">
      <Icon as={iconAs} w={4} h={4} mr={2} />
      <Text textStyle="details">{children}</Text>
    </Flex>
  )
}
