import { Box, Button, Flex, HStack, Spinner, VStack } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

import theme from '../theme'
import { PhotoDisplayProps } from './PhotoDisplay'

export default function UploadStatusIndicator({
  handleUpload,
  isUploading,
  hasUploadError,
  uploadErrorMessage
}: PhotoDisplayProps) {
  if (isUploading) {
    return (
      <Flex
        position="absolute"
        width="100%"
        height="100%"
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        backgroundColor={transparentize('gray.100', 0.8)(theme)}
      >
        <Spinner color="secondary" size="lg" thickness="3px" />
      </Flex>
    )
  }

  if (hasUploadError) {
    return (
      <HStack
        position="absolute"
        width="100%"
        height="100%"
        zIndex={1}
        justifyContent="center"
        alignItems="center"
        backgroundColor={transparentize('gray.100', 0.9)(theme)}
        textAlign="center"
      >
        <VStack spacing={5}>
          {uploadErrorMessage && <Box px={3}>{uploadErrorMessage}</Box>}
          <Button variant="secondary" onClick={handleUpload}>
            Try again
          </Button>
        </VStack>
      </HStack>
    )
  }

  return null
}
