import hotkeys from 'hotkeys-js'
import { useEffect } from 'react'
import { useSnapshot } from 'valtio'

import { LE2Hotkeys, systemInfoState } from '../../../../utils/useGetSystemInfo'
import { Record } from '../../interfaces'

/**
 * It creates a hotkey to toggle the isLoadFromFile flag
 */
export default function useToggleLoadFromFile(recordState: Record) {
  const systemInfoSnap = useSnapshot(systemInfoState)
  useEffect(() => {
    console.log('isLoadFromFile enabled:', recordState.isLoadFromFile)
    const hotkey = LE2Hotkeys.ToggleLoadFromFile
    if (systemInfoSnap.isHotkeyAvailable(hotkey)) {
      hotkeys(hotkey, function (event) {
        // Prevent the default action
        event.preventDefault()

        recordState.isLoadFromFile = !recordState.isLoadFromFile
        console.log('LoadFromFile enabled:', recordState.isLoadFromFile)
      })
    }

    // Unbind hotkeys so they don't get re-bound on next useEffect run
    return () => {
      hotkeys.unbind(hotkey)
    }
  }, [recordState, systemInfoSnap])
}
