import { gql, useQuery } from '@apollo/client'
import { JFDFieldDef, JFDValues, createJFD } from '@le2/jfd'
import { set } from 'lodash'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import random from 'lodash/random'
import { useLocation } from 'react-router-dom'
import { proxy } from 'valtio'
import { devtools } from 'valtio/utils'

import { ME } from '../../apollo/Users'
import agencyConfig from '../../lib/agencyConfig'
import {
  BioType,
  Booking,
  BookingEnrollmentStatus,
  BookingImage,
  BookingImages,
  faceCaptureBioTypes
} from '../../lib/api/booking'
import { FaceCaptureMetadata } from '../../lib/api/booking/BookingImage'
import Image from '../../lib/api/booking/Image'
import { BookingResponse, mapBookingResponse } from '../../lib/api/booking/utils'
import dateFormat from '../../utils/dateFormat'

export const filterFields = agencyConfig.features.lineups.filters
export const tableFilters = agencyConfig.features.lineups.table.columns
export const defaultResults = 200
export const results = [50, 100, 200, 1000]
export const lineupSizes = agencyConfig.features.lineups.lineupSizes || [6, 8, 16, 24]
export const jfdInst = createJFD(agencyConfig.jfd)
export const layoutAvatarSize = { w: '80px', h: '80px' }

export const availableBioTypes = [
  { value: BioType.FaceFront, label: 'Front' },
  { value: BioType.FaceLeft90, label: 'Left' },
  { value: BioType.FaceRight90, label: 'Right' }
]

export const importedLineupFieldDef = [
  {
    name: 'fullName',
    label: 'Full name',
    placeholder: agencyConfig.features.audits.subjectNameFormat,
    textTransform: 'uppercase',
    required: { value: false },
    pattern: {
      // This regex was extracted from the JFD (lowercase letters were added)
      value: /^(?!.{31,})([A-Za-z,][- ]?)*([A-Za-z])$/,
      message: 'The Name may contain from 2-30 Alpha characters.'
    },
    minLength: { value: 2, message: 'Min length of 2 is not met.' },
    maxLength: { value: 30, message: 'Max length of 30 is exceeded.' },
    setValueAs: (v: string) => v.toUpperCase()
  }
] as const

export type ImportedLineupFields = { [K in typeof importedLineupFieldDef[number]['name']]: string }

export type LineupViewMode = 'saved' | 'view' | 'preview'

export function useLineupsViewMode(): LineupViewMode {
  const { search } = useLocation()
  const params = new URLSearchParams(search)
  const mode = params.get('mode')

  return mode as LineupViewMode
}

interface LineupState {
  lineup: Lineup
  maxResults: number
  bioType: BioType | undefined
  reset: (deleteImportedImage?: boolean) => void
  onPreview: (suspectBooking: Booking, selectedBookings: Booking[], lineupSize: number) => void
}

interface LineupMetadata {
  SubjectName?: {
    First: string
    Fast: string
  }
}

interface LineupDuplicateMetadata {
  id: number
  name: string
  caseNumber: string
}

export interface Lineup {
  id: number
  name: string
  caseNumber: string
  type: LineupType | undefined
  bioType: LineupBioType | undefined
  description: string
  status: LineupStatus | undefined
  size: number
  filters: Filter[]
  metadata?: LineupMetadata
  booking: Booking | undefined
  image: Image | null | undefined
  entries: LineupEntry[]
  createdAt: Date
  createdBy: User | null
  isGrayscale: boolean
  getSuspectEntry: () => LineupEntry | undefined
  setEntries: (suspectBooking: Booking, fillerBookings: Booking[], bioType: BioType) => void
  swapEntries: () => void
  typeAsString: () => string
  getSubjectName: () => string
  getFilterLabel: (field: string) => string
  visibility: string
  getFillerBookings: () => Booking[]
  duplicateMetadata?: LineupDuplicateMetadata
}

export type LineupType = 'face' | 'smt'

export type LineupBioType = BioType.FaceFront | BioType.FaceLeft90 | BioType.FaceRight90 | BioType.SMT

export enum LineupStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  SEALED = 'SEALED',
  DELETED = 'DELETED'
}

export interface LineupImproveConfig {
  crop: {
    x: number
    y: number
  }
  pixelCrop: {
    width: number
    height: number
    x: number
    y: number
  }
  zoom: number
  rotation: number
}

export interface LineupEntry {
  isSuspect: boolean
  booking: Booking | undefined
  image: Image | null | undefined
  originalImage?: Image
  imageImproveConfig?: LineupImproveConfig
}

export interface User extends UserResponse {
  name: string
}

export interface LineupDataForNewTab {
  lineup: Lineup
  bioType: BioType
  maxResults: number
}

const LINEUP_FIELDS = gql`
  fragment LineupFields on Lineup {
    id
    name
    description
    caseNumber
    bioType
    status
    size
    filterJson
    metadata
    bookingId
    imageId
    createdAt
    updatedAt
    isGrayscale
    lineupEntries {
      id
      lineupId
      lineupPosition
      isSuspect
      imageHeight
      imageWidth
      imageImproveConfig
      bookingId
      imageId
      createdAt
      updatedAt
      Booking
    }
    Booking {
      id
      bookingNum
      biographics
      imageMetadata
      isSealed
    }
    createdBy {
      firstName
      lastName
      email
    }
  }
`

export const GET_LINEUPS = gql`
  ${LINEUP_FIELDS}
  query GetLineups($where: JSON, $skip: Int, $take: Int, $orderBy: [JSON]) {
    lineups(where: $where, skip: $skip, take: $take, orderBy: $orderBy) {
      ...LineupFields
    }
    lineupsAggregate(aggregate: { count: true }, where: $where) {
      count
    }
  }
`

export const GET_LINEUP = gql`
  ${LINEUP_FIELDS}
  query GetLineup($where: JSON) {
    lineups(where: $where) {
      ...LineupFields
      Booking {
        id
        bookingNum
        biographics
        isSealed
      }
    }
  }
`

export interface LineupsResponse {
  lineups: LineupResponse[]
  lineupsAggregate: { count: number }
}

export interface LineupResponse {
  id: number
  name: string
  description: string
  caseNumber: string
  type: string
  bioType: string
  status: string
  size: number
  /* The filterJson's value should only be string[]
  Added a single string as value to support old filters */
  filterJson: { [key: string]: string[] | string }
  metadata?: LineupMetadata
  bookingId: string
  imageId: string
  createdAt: string
  updatedAt: string
  isGrayscale: boolean
  lineupEntries: {
    id: number
    lineupId: string
    lineupPosition: string
    isSuspect: boolean
    imageHeight: string
    imageWidth: string
    imageImproveConfig: LineupImproveConfig
    bookingId: string
    imageId: string
    createdAt: string
    updatedAt: string
    Booking: BookingResponse
  }[]
  Booking: BookingResponse | undefined
  createdBy: {
    firstName: string
    lastName: string
    email: string
  }
}

export interface UserResponse {
  firstName: string
  lastName: string
  email: string
}

type JSONFilter = Record<string, string | string[] | JSONAgeFilter>

/**
 * It validates that all the values of the object are an array.
 * If one value is a string, it is transformed into an array.
 */
function validateFilterJson(filterJson: JSONFilter) {
  const values = mapValues(filterJson, (val, filter) => {
    if (filter === ageFilterField) {
      return val as JSONAgeFilter
    }

    return Array.isArray(val) ? val : ([val] as string[])
  })

  return mapFilterJson(values)
}

export function mapLineup(lineupResp: LineupResponse): Lineup {
  const result = createLineup()

  result.id = lineupResp.id
  result.name = lineupResp.name
  result.description = lineupResp.description
  result.caseNumber = lineupResp.caseNumber
  result.type = faceCaptureBioTypes.includes(lineupResp.bioType as LineupBioType) ? 'face' : 'smt'
  result.bioType = lineupResp.bioType as LineupBioType
  result.status = LineupStatus[lineupResp.status as keyof typeof LineupStatus]
  result.size = lineupResp.size
  result.filters = validateFilterJson(lineupResp.filterJson)
  result.image = Image.createFromID(lineupResp.imageId)
  result.createdAt = new Date(lineupResp.createdAt)
  result.isGrayscale = lineupResp.isGrayscale
  result.createdBy = mapUser(lineupResp.createdBy)

  result.entries = lineupResp.lineupEntries.map((entryResp) => {
    const image = Image.createFromID(entryResp.imageId)
    let booking: Booking | undefined = undefined
    if (entryResp.Booking) {
      booking = mapBookingResponse(entryResp.Booking)
    } else {
      const bookingImages = createImagesForDummyBooking(image)
      booking = createDummyBooking({ images: bookingImages, biographics: lineupResp.metadata as JFDValues }, true)
    }

    return {
      isSuspect: entryResp.isSuspect,
      booking,
      image,
      imageImproveConfig: entryResp.imageImproveConfig
    }
  })

  if (lineupResp.Booking) {
    const booking = {
      ...lineupResp.Booking,
      imageMetadata: lineupResp.Booking.imageMetadata || []
    }
    result.booking = mapBookingResponse(booking)
  } else {
    const bookingImages = createImagesForDummyBooking(result.image)
    result.booking = createDummyBooking({ images: bookingImages, biographics: lineupResp.metadata as JFDValues }, true)
  }

  return result
}

function mapUser(user: UserResponse): User | null {
  if (user) {
    return {
      ...user,
      get name() {
        return `${user.firstName} ${user.lastName}`.trim()
      }
    }
  } else {
    return null
  }
}

export const lineupState = proxy<LineupState>({
  lineup: createLineup(),
  bioType: undefined,
  maxResults: defaultResults,

  reset(deleteImportedImage: boolean = false) {
    if (deleteImportedImage) {
      this.lineup.image?.delete()
    }

    this.bioType = undefined
    this.lineup.id = 0
    this.lineup.name = ''
    this.lineup.caseNumber = ''
    this.lineup.type = undefined
    this.lineup.description = ''
    this.lineup.status = undefined
    this.lineup.size = lineupSizes[0]
    this.lineup.filters = []
    this.lineup.booking = undefined
    this.lineup.image = undefined
    this.lineup.createdAt = new Date()
    this.lineup.createdBy = null
    this.lineup.entries = []
    this.lineup.isGrayscale = false
  },
  onPreview(suspectBooking: Booking, selectedBookings: Booking[], lineupSize: number) {
    const { entries } = this.lineup

    const isExactCopy =
      isLineupCopy(this.lineup.entries, selectedBookings, suspectBooking) && this.lineup.size === lineupSize

    if (entries.length === 0 && suspectBooking && this.bioType) {
      // New lineup
      this.lineup.setEntries(suspectBooking, selectedBookings, this.bioType)
      this.lineup.swapEntries()
    } else if (!isExactCopy && this.bioType) {
      // Existing lineup but with new bookings.

      this.lineup.entries = mapNewBookings(suspectBooking, selectedBookings, entries, this.bioType)
    } else {
      // Exact copy of Lineup
    }

    this.lineup.size = lineupSize
  }
})

export function createLineup() {
  return {
    id: 0,
    name: '',
    caseNumber: '',
    type: undefined,
    bioType: undefined,
    description: '',
    status: undefined,
    size: lineupSizes[0],
    filters: [],
    booking: undefined,
    image: undefined,
    createdAt: new Date(),
    createdBy: null,
    entries: [],
    isGrayscale: false,

    getSuspectEntry() {
      return this.entries.find((entry) => entry.isSuspect)
    },

    setEntries(suspectBooking: Booking, fillerBookings: Booking[], bioType: BioType) {
      this.entries = [
        {
          isSuspect: true,
          booking: suspectBooking,
          image: suspectBooking.images.getLatestImage(bioType)?.image
        },
        ...fillerBookings.map((filler) => ({
          isSuspect: false,
          booking: filler,
          image: filler.images.getLatestImage(bioType)?.image
        }))
      ]
    },

    swapEntries() {
      this.entries.sort(() => Math.random() - 0.5)

      // if the suspect ends up in first position swap it with another random
      // entry
      if (this.entries[0].isSuspect) {
        const newPos = random(1, this.entries.length - 1)
        ;[this.entries[0], this.entries[newPos]] = [this.entries[newPos], this.entries[0]]
      }
    },

    getSubjectName() {
      return getSubjectName(this.booking?.biographics)
    },

    typeAsString() {
      switch (this.type) {
        case 'face':
          return 'Face'
        case 'smt':
          return 'SMT'
        default:
          throw new Error('Invalid type: ' + this.type)
      }
    },

    getFilterLabel(fieldName: string) {
      const filter: Filter | undefined = this.filters.find((filter) => filter.fieldName === fieldName)

      if (!filter) {
        // if this particular field is not set in filters it means it wasn't
        // included
        return 'All'
      }

      return filter.displayValue
    },

    get visibility() {
      return this.status === LineupStatus.SEALED ? 'Seal' : 'Unseal'
    },

    getFillerBookings() {
      const result: Booking[] = []
      this.entries.forEach((e) => {
        if (!e.isSuspect && typeof e.booking !== 'undefined' && !e.booking.isSealed) {
          result.push(e.booking)
        }
      })
      return result
    }
  } as Lineup
}

function mapNewBookings(suspectBooking: Booking, bookings: Booking[], entries: LineupEntry[], bioType: BioType) {
  // Find existing entries and imported booking on the current Lineup.
  const existingEntries = entries.reduce((existingEntries, entry) => {
    const entryIdx = bookings.findIndex(
      (current) => current.id === entry.booking?.id || suspectBooking.id === entry.booking?.id
    )

    // The Lineup entry is already selected
    if (entryIdx >= 0) {
      existingEntries.push(entry)
    } else if (entry.isSuspect) {
      // The main subject was changed.
      existingEntries.push({
        isSuspect: true,
        booking: suspectBooking,
        image: suspectBooking.images.getLatestImage(bioType)?.image
      })
    }

    return existingEntries
  }, [] as LineupEntry[])

  // Find new entries
  const newEntries: LineupEntry[] = bookings.reduce((newEntries, booking) => {
    const isNewEntry = entries.findIndex((entry) => {
      return entry.booking && entry.booking.id === booking.id
    })

    if (isNewEntry === -1) {
      newEntries.push({
        isSuspect: false,
        booking,
        image: booking.images.getLatestImage(bioType)?.image
      })
    }

    return newEntries
  }, [] as LineupEntry[])

  // Merge existing and new entries.
  return [...existingEntries, ...newEntries]
}

function isLineupCopy(entries: LineupEntry[], newBookings: Booking[], suspectBooking: Booking) {
  const isSameMainSubject = entries.findIndex((entry) => entry.booking?.id === suspectBooking.id) >= 0

  if (!isSameMainSubject) {
    return false
  }

  let isEqual = true

  for (const newBooking of newBookings) {
    const exists = entries.findIndex((entry) => newBooking.id === entry.booking?.id) >= 0

    if (!exists) {
      isEqual = false
      break
    }
  }

  return isEqual
}

export async function copyLineup(lineup: Lineup): Promise<Lineup> {
  const { booking, isGrayscale, filters: lineupFilters, type, status, caseNumber, name, id } = lineup
  const lineupCopy = createLineup()

  const filters = filterFields.map((fieldName) => {
    const existingFilter = lineupFilters.find((filter) => filter.fieldName === fieldName)

    if (!existingFilter) {
      return fieldName === ageFilterField ? new AgeFilter() : new SimpleFilter({ fieldName })
    } else {
      return existingFilter
    }
  })

  lineupCopy.booking = booking
  lineupCopy.isGrayscale = isGrayscale
  lineupCopy.filters = filters
  lineupCopy.type = type
  lineupCopy.status = status
  lineupCopy.entries = lineup.entries
  lineupCopy.bioType = lineup.bioType
  lineupCopy.size = lineup.size
  lineupCopy.duplicateMetadata = {
    id,
    caseNumber,
    name
  }

  return lineupCopy
}
export abstract class Filter {
  fieldName: string = ''
  value: string[] | string = []
  isJFDField: boolean = true
  label: string = ''
  apiFilter: Record<string, string> | undefined
  inputType: 'input' | 'select' = 'input'
  hasError?: boolean
  errorMessage?: string
  displayValue?: string
  placeholder?: string

  abstract setFilterValues(args?: any): void

  abstract onChange(value: string[] | string): void

  abstract isActive(): boolean

  abstract getFilterJSON(): Record<string, string[] | object | boolean> | undefined
}

interface FilterOption {
  label: string
  value: string
}

export class SimpleFilter implements Filter {
  apiFilter = {}
  fieldName: string
  label: string
  isJFDField = true
  value: string[]
  displayValue: string = ''
  defaultValue: FilterOption | null = null
  inputType: 'input' | 'select' = 'select'
  hasError?: boolean
  errorMessage?: string
  options: FilterOption[] = []
  fieldDef: JFDFieldDef
  isMOS: boolean

  constructor(options: { fieldName: string; value?: string[] | null }) {
    const { fieldName, value = '' } = options

    // Extracts the parent field
    const [mainFieldName] = fieldName.split('.')

    this.fieldName = fieldName
    this.fieldDef = jfdInst.getFieldDef(fieldName)
    this.label = this.fieldDef.label
    this.value = value || []
    this.isMOS = jfdInst.getFieldDef(mainFieldName).getType() === 'MOS'

    // Get options
    this.options = this.fieldDef.options.map((opt) => ({ value: opt[0], label: opt[1] }))

    if (value !== '') {
      this.setFilterValues()
      this.setDisplayValue()
    }
  }

  protected setDisplayValue() {
    const optionsSelected = this.options.reduce((options, option) => {
      if (this.value.includes(option.value)) {
        options.push(option.label)
      }

      return options
    }, [] as string[])

    if (optionsSelected.length > 0) {
      this.displayValue = optionsSelected.join(', ')
    } else {
      this.displayValue = ''
    }
  }

  getDefaultValue() {
    return this.options.filter((opt) => this.value.includes(opt.value))
  }

  setFilterValues() {
    this.apiFilter = {
      in: this.value,
      isMOS: this.isMOS
    }
  }

  onChange(value: string[]): void {
    this.value = value
    this.apiFilter = {}

    if (value.length > 0) {
      this.setFilterValues()
      this.setDisplayValue()
    }
  }

  isActive() {
    return this.value.length > 0
  }

  getFilterJSON() {
    // Extract the fields
    const [field, subfield] = this.fieldName.split('.')
    let filterJSON = {}

    if (subfield) {
      // If the filter is a subfield, it sets the parent field and the subfield.
      set(filterJSON, field, {
        [subfield]: {
          in: this.value,
          isMOS: this.isMOS
        },
        isMOS: this.isMOS
      })
    } else {
      // Otherwise, It only sets the parent field.
      set(filterJSON, field, {
        in: this.value,
        isMOS: this.isMOS
      })
    }

    return filterJSON
  }
}

export const ageFilterField = 'DateOfBirth'

interface JSONAgeFilter {
  range: string
  dates: string[]
}

export class AgeFilter implements Filter {
  apiFilter:
    | {
        gte: string
        lte: string
      }
    | undefined
  fieldName = ageFilterField
  isJFDField = false
  label = 'Age Range'
  value: string = ''
  hasError: boolean = false
  errorMessage = 'The value must be a valid range'
  DateOfBirth?: string
  inputType: 'input' = 'input'
  displayValue = ''
  placeholder = '21-25'

  constructor(options?: { DateOfBirth?: string; value?: string | null; jsonFilter?: JSONAgeFilter }) {
    if (options) {
      const { DateOfBirth, value, jsonFilter } = options

      if (DateOfBirth) {
        this.DateOfBirth = DateOfBirth
        this.getRangeFromDateOfBirth()
      }

      if (value) {
        this.onChange(value)
      }

      if (jsonFilter) {
        const { dates, range = '' } = jsonFilter
        this.value = range
        this.displayValue = range

        this.apiFilter = {
          gte: dates[0],
          lte: dates[1]
        }
      }
    }
  }

  getRangeFromDateOfBirth() {
    if (this.DateOfBirth) {
      const currentDate = new Date()

      const yearOfBirth = new Date(this.DateOfBirth as string).getFullYear()

      const age = currentDate.getFullYear() - yearOfBirth

      const ageRange = age < 26 ? '21-26' : `${age - 5}-${age + 5}`

      const rangeParts = this.getRangeParts(ageRange)

      this.value = ageRange
      this.placeholder = ageRange
      this.setFilterValues(rangeParts)
    } else {
      this.value = ''
    }
  }

  setFilterValues(rangeParts: number[]) {
    const currentYear = new Date().getFullYear()
    const startDate = new Date(currentYear - rangeParts[1], 0, 1) // January 1
    const endDate = new Date(currentYear - rangeParts[0], 11, 31) // December 31

    this.hasError = false
    this.apiFilter = {
      gte: dateFormat(startDate).toISO(),
      lte: dateFormat(endDate).toISO()
    }
    this.displayValue = this.value
  }

  getRangeParts(value: string) {
    return value.split('-').reduce((parts, value) => {
      if (value !== '') {
        parts.push(parseInt(value))
      }
      return parts
    }, [] as number[])
  }

  getValue() {
    return this.value
  }

  onChange(value: string) {
    this.apiFilter = undefined
    const rangeParts = this.getRangeParts(value)
    if (value === '') {
      this.hasError = false
    } else if (rangeParts.length < 2) {
      this.hasError = true
    } else if (rangeParts[0] >= rangeParts[1]) {
      this.hasError = true
    } else if (rangeParts[0] < 21) {
      this.hasError = true
    } else {
      this.setFilterValues(rangeParts)
    }

    this.value = value
    this.displayValue = value
  }

  isActive(): boolean {
    return this.value !== ''
  }

  getFilterJSON() {
    if (this.apiFilter) {
      return {
        [this.fieldName]: {
          range: this.value,
          dates: [this.apiFilter.gte, this.apiFilter.lte]
        }
      }
    }
  }
}

function mapFilterJson(filterJson: Record<string, string[] | JSONAgeFilter>) {
  const filterFields = Object.entries(filterJson).reduce((filters, [fieldName, value]) => {
    const filterField =
      fieldName === ageFilterField
        ? new AgeFilter({ jsonFilter: value as JSONAgeFilter })
        : new SimpleFilter({ fieldName, value: value as string[] })

    filters.push(filterField)

    return filters
  }, [] as Filter[])

  return filterFields
}

export function getFilterJson(filters: Filter[]): Record<string, string[]> {
  return filters.reduce((filters, filter) => {
    if (filter.isActive()) {
      filters = {
        ...filters,
        ...filter.getFilterJSON()
      }
    }

    return filters
  }, {})
}

export function getFirstAvailableBioType(booking: Booking): BioType | undefined {
  for (const bioType of availableBioTypes) {
    if (booking.images.getLatestImage(bioType.value)) {
      return bioType.value
    }
  }
  return undefined
}

/**
 * Creates a dummy booking with default data.
 * The id is equal to -1 in a dummy booking.
 * If the data of a dummy booking is NOT stored in the database, enrollmentStatus is equal to NEW.
 * If the data of a dummy booking is already stored in the database, enrollmentStatus is equal to COMPLETE.
 */
export function createDummyBooking(properties: Partial<Booking>, isDataSaved: boolean = false): Booking {
  return {
    id: -1,
    bookingNum: '',
    biographics: {},
    enrollmentStatus: isDataSaved ? BookingEnrollmentStatus.COMPLETE : BookingEnrollmentStatus.NEW,
    images: new BookingImages([]),
    createdAt: new Date(),
    updatedAt: new Date(),
    isActive: false,
    isSealed: false,

    get name() {
      return getSubjectName(this.biographics)
    },

    ...properties
  }
}

/**
 * Returns true if the booking passed as parameter is a dummy booking.
 */
export function isDummyBooking(booking?: Booking | null): boolean {
  return booking?.id === -1
}

/**
 * Returns true if it is safe to delete the image of a Dummy Booking from the database.
 */
export function shouldDeleteDummyBookingImage(booking?: Booking | null): boolean {
  return isDummyBooking(booking) && booking?.enrollmentStatus === BookingEnrollmentStatus.NEW
}

/**
 * Returns a BookingImages object with the availableBioTypes for BookingImages.
 * Each BookingImage points to the same Image that is passed as a parameter.
 */
export function createImagesForDummyBooking(image: Image): BookingImages {
  const bookingImagesArray = availableBioTypes.map(({ value }) => {
    const bookingImage = new BookingImage('', value, {} as FaceCaptureMetadata)
    bookingImage.image = image
    return bookingImage
  })

  const bookingImages = new BookingImages(bookingImagesArray)

  return bookingImages
}

export function useIsLineupBookingViewable() {
  const { data: userDetails } = useQuery(ME, {
    onError(error) {
      console.error(JSON.stringify(error))
    }
  })
  const isAdmin = userDetails?.me?.isAdmin

  return function (lineup: Lineup): boolean {
    return !lineup.booking?.isSealed || isAdmin
  }
}

/**
 * @param source Can be:
 * <ul>
 *   <li>A `Booking.biographics` object for lineups with an associated booking</li>
 *   <li>`Lineup.metadata` for lineups with imported images</li>
 * </ul>
 */
function getSubjectName(source?: JFDValues) {
  let fullName

  if (get(source, 'SubjectName')) {
    // support for previous lineups that have the old format
    const firstName = get(source, 'SubjectName.First', '')
    const lastName = get(source, 'SubjectName.Last', '')
    fullName = `${firstName} ${lastName}`.trim()
  } else {
    // new format
    fullName = get(source, 'DisplayFullName', '') as string
  }

  return fullName || 'Unknown'
}

devtools(lineupState, 'Lineup')
