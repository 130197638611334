import { Box, Flex, FormLabel } from '@chakra-ui/react'
import { JFLGroup } from '@le2/jfd'
import { PlusOutline, TrashOutline } from 'heroicons-react'
import { useState } from 'react'

import { QuickActionButton } from '../QuickActionButton'
import { TableActionButton } from '../TableActionButton'
import { JFLRender } from './JFLRender'
import { useJFD } from './jfd-context'

/**
 * Main Field Array driver
 * jfdField - The field from jfd, may or may not have subfields
 * register - RHF register
 * control = ref for RHF
 */
export function JFDExplicitFieldArray({ group }: { group: JFLGroup }) {
  const { jfd, readOnly } = useJFD()
  const rawBaseField = Array.isArray(group.fields[0].field) ? group.fields[0].field[0] : group.fields[0].field
  const [baseField] = rawBaseField.split('.')
  const initialEntries = jfd.numEntries(baseField)
  const fieldDef = jfd.getFieldDef(baseField)
  const [recordCount, setRecordCount] = useState(initialEntries)

  // make sure that we have the same number of entries in `recordCount` and in
  // the JFD instance
  if (recordCount !== initialEntries) {
    setRecordCount(initialEntries)
  }

  function onAdd() {
    jfd.createOccurrence(fieldDef.name, recordCount)
    setRecordCount((current) => current + 1)
  }

  function onDelete(index: number) {
    jfd.removeField(fieldDef.name, index)
    setRecordCount((current) => current - 1)
  }

  const fieldLabels = group.options?.fieldLabels || 'all'

  return (
    <>
      {[...Array(Math.min(initialEntries, recordCount))].map((_, index) => {
        return (
          <Flex key={index} data-testid="field-array-row">
            <Box w="100%">
              <JFLRender group={group} index={index} />
            </Box>
            <Box ml="5px">
              {fieldLabels === 'all' ||
                (index === 0 && (
                  <FormLabel textStyle="details" visibility="hidden" mx={0} w={1}>
                    h
                  </FormLabel>
                ))}
              <Box pt={1}>
                <TableActionButton
                  onClick={() => onDelete(index)}
                  ariaLabel="Remove item"
                  iconAs={TrashOutline}
                  isDisabled={readOnly}
                />
              </Box>
            </Box>
          </Flex>
        )
      })}
      <QuickActionButton
        label={group.options?.addButtonLabel || 'Add'}
        isDisabled={readOnly || recordCount >= fieldDef.maxOccurrences}
        onClick={onAdd}
        iconAs={PlusOutline}
      />
    </>
  )
}
