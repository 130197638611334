(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("sharp"));
	else if(typeof define === 'function' && define.amd)
		define(["sharp"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("sharp")) : factory(root["sharp"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(this, function(__WEBPACK_EXTERNAL_MODULE__993__) {
return 