import { Input as ChakraInput, InputProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

/**
 * Custom <Input> that adds `data-lpignore="true"`. This way these inputs are
 * not autocompleted by the LastPass browser extension.
 * See https://stackoverflow.com/a/44984917/9778302
 */
const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return <ChakraInput ref={ref} {...props} data-lpignore="true" />
})

export default Input
