import useAxios from 'axios-hooks'
import { proxy } from 'valtio'

export enum LE2Hotkeys {
  EBTSToJSON = 'ctrl+f6',
  GenerateEBTSWithImages = 'ctrl+f7',
  FillMockData = 'ctrl+f8',
  ToggleLoadFromFile = 'ctrl+f9',
  ToggleDebugPreview = 'ctrl+f10',
  ToggleSubmitValidationsByPass = 'ctrl+f11'
}

const devLE2HotKeys = [
  LE2Hotkeys.EBTSToJSON,
  LE2Hotkeys.FillMockData,
  LE2Hotkeys.GenerateEBTSWithImages,
  LE2Hotkeys.ToggleLoadFromFile,
  LE2Hotkeys.ToggleDebugPreview,
  LE2Hotkeys.ToggleSubmitValidationsByPass
]

const testingLE2HotKeys = [
  LE2Hotkeys.ToggleLoadFromFile, //
  LE2Hotkeys.ToggleSubmitValidationsByPass
]

interface SystemInfoResponse {
  le2Version: string
  oidcEnabled: boolean
  oidcDisplayName: string
  machineCode: string
  productName: string
  sessionTimeoutEnabled: boolean
  hotkeysLevel: 'all' | 'testing' | 'none'
}

const DEFAULT_SYSTEM_INFO: SystemInfoResponse = {
  le2Version: 'Version not available',
  oidcEnabled: false,
  oidcDisplayName: '',
  machineCode: '',
  productName: 'Tech5 Law Enforcement',
  sessionTimeoutEnabled: true,
  hotkeysLevel: 'none'
}

export function useGetSystemInfo() {
  const [{ data, loading, error }, fetch] = useAxios<SystemInfoResponse>(`/v1/system/info`)

  return {
    data: data || DEFAULT_SYSTEM_INFO,
    fetch,
    loading,
    error
  }
}

export interface SystemInfoState {
  data: SystemInfoResponse
  loading: boolean
  hotkeysAvailable: LE2Hotkeys[]
  isHotkeyAvailable: (hotkey: LE2Hotkeys) => boolean
}

export const systemInfoState = proxy<SystemInfoState>({
  data: DEFAULT_SYSTEM_INFO,
  loading: true,
  hotkeysAvailable: [],
  isHotkeyAvailable: function (hotkey: LE2Hotkeys) {
    return this.hotkeysAvailable.includes(hotkey)
  }
})

export function createSystemInfoState(data: SystemInfoResponse) {
  let hotkeysAvailable: LE2Hotkeys[] = []

  if (data.hotkeysLevel === 'all') {
    hotkeysAvailable = devLE2HotKeys
  } else if (data.hotkeysLevel === 'testing') {
    hotkeysAvailable = testingLE2HotKeys
  }

  systemInfoState.data = data
  systemInfoState.loading = false
  systemInfoState.hotkeysAvailable = hotkeysAvailable
}
