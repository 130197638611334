import { useContext, useEffect } from 'react'

import { NativeExtensionsContext } from '../../contexts/NativeExtensionsContext'
import { HubStatus } from '../IWNativeExtDeviceDefines'

/*
 * This hook starts a connection to the NativeExtension hub when is mounted.
 * It only starts a connection if the HubStatus is DisConnected or Closed.
 * It keeps the connection open until the user closes the browser or
 * the NativeExtension server is turned off.
 * In order to start a connection is required to subscribe to the Hub Events first
 * using the useSubscribeHubEvents hook.
 */

export function useConnectToFPCardPrintHub() {
  // Get the card print manager status and object from the native extensions global context object
  // (make sure that the native extensions context provider wraps any root callers of this component)
  const { fpCardPrintHubStatus, fpCardPrintManager } = useContext(NativeExtensionsContext)
  useEffect(() => {
    console.log(`useConnectToFPCardPrintHub(), fpCardPrintHubStatus=${fpCardPrintHubStatus}`)

    if (fpCardPrintHubStatus === HubStatus.DisConnected || fpCardPrintHubStatus === HubStatus.Closed) {
      // Connect to the hub
      fpCardPrintManager?.hubConnect()
    }
    // ignore the dependencies, we want to call `hubConnect` once like `useConnectToDeviceHub`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
