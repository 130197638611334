import { BookingsConfig, PrintType, RecordsConfig } from '@le2/jfd'

import agencyConfig from '../lib/agencyConfig'

// We need to get this from the JFD definition
export function getHeaderFromPrintType(printType: string | null, feature: 'bookings' | 'records') {
  let printTypeDef: PrintType | undefined

  const getSelectedPrintType = (feature: BookingsConfig | RecordsConfig) => {
    return feature.printTypes.find((t) => t.value === printType)
  }

  if (feature === 'bookings') {
    printTypeDef = getSelectedPrintType(agencyConfig.features.bookings)
  } else if (feature === 'records') {
    printTypeDef = getSelectedPrintType(agencyConfig.features.records)
  } else {
    throw new Error(`The passed feature is not supported: ${feature}. Please replace it with a valid one`)
  }
  return printTypeDef ? printTypeDef.label : ''
}
