import { LoadFromFileOptions, acceptedFileExtensions, maximumFileSize } from '../lib/fileUtils'
import {
  CaptureRequestOption,
  CompressedImages,
  ImageFormat,
  ModalityDefinitions,
  QualityMethod,
  SearchOption
} from './IWNativeExtDeviceDefines'

/*
 * This file is intended to set the configuration details for the different modalities.
 * The ModalityConfiguration object is required by the useDeviceManager hook.
 */

export type SearchOptions = Partial<{ [key in SearchOption]: string }>

export type CaptureRequestOptions = Partial<{ [key in CaptureRequestOption]: string }>

// The following CaptureRequestOption are reserved for the useDeviceEvents hook
type ProhibitedCaptureRequestOptions =
  | CaptureRequestOption.File
  | CaptureRequestOption.MissingDigits
  | CaptureRequestOption.CaptureImageType

export type PermittedCaptureRequestOptions = Omit<CaptureRequestOptions, ProhibitedCaptureRequestOptions>

export interface ModalityConfiguration {
  modality: ModalityDefinitions
  loadFromFileOptions: LoadFromFileOptions
  searchOptions: SearchOptions
  captureRequestOptions: PermittedCaptureRequestOptions
}

export const fingerprintConfiguration: ModalityConfiguration = {
  modality: ModalityDefinitions.Fingerprint,
  loadFromFileOptions: {
    acceptedFileExtensions,
    maximumFileSize
  },
  searchOptions: {
    [SearchOption.Modality]: ModalityDefinitions[ModalityDefinitions.Fingerprint],
    [SearchOption.ModalityProfile]: ModalityDefinitions[ModalityDefinitions.Fingerprint]
  },
  captureRequestOptions: {
    [CaptureRequestOption.ReturnCompressedImages]: CompressedImages.YES,
    [CaptureRequestOption.QualityMethod]: QualityMethod.NFIQ,
    [CaptureRequestOption.ImageFormat]: ImageFormat.PNG
  }
}

export const palmprintConfiguration: ModalityConfiguration = {
  modality: ModalityDefinitions.Palm,
  loadFromFileOptions: {
    acceptedFileExtensions,
    maximumFileSize
  },
  searchOptions: {
    [SearchOption.Modality]: ModalityDefinitions[ModalityDefinitions.Palm],
    [SearchOption.ModalityProfile]: ModalityDefinitions[ModalityDefinitions.Palm]
  },
  captureRequestOptions: {
    [CaptureRequestOption.ReturnCompressedImages]: CompressedImages.YES,
    [CaptureRequestOption.ImageFormat]: ImageFormat.PNG
  }
}

export const irisConfiguration: ModalityConfiguration = {
  modality: ModalityDefinitions.Iris,
  loadFromFileOptions: {
    acceptedFileExtensions,
    maximumFileSize
  },
  searchOptions: {
    [SearchOption.Modality]: ModalityDefinitions[ModalityDefinitions.Iris],
    [SearchOption.ModalityProfile]: ModalityDefinitions[ModalityDefinitions.Iris]
  },
  captureRequestOptions: {
    [CaptureRequestOption.QualityMethod]: QualityMethod.Vendor,
    [CaptureRequestOption.ImageFormat]: ImageFormat.PNG
  }
}

export const faceConfiguration: ModalityConfiguration = {
  modality: ModalityDefinitions.Face,
  loadFromFileOptions: {
    acceptedFileExtensions,
    maximumFileSize
  },
  searchOptions: {
    [SearchOption.Modality]: ModalityDefinitions[ModalityDefinitions.Face],
    [SearchOption.ModalityProfile]: ModalityDefinitions[ModalityDefinitions.Face]
  },
  captureRequestOptions: {
    [CaptureRequestOption.ImageFormat]: ImageFormat.PNG
  }
}

export const smtConfiguration: ModalityConfiguration = {
  modality: ModalityDefinitions.SMT,
  loadFromFileOptions: {
    acceptedFileExtensions,
    maximumFileSize
  },
  searchOptions: {
    [SearchOption.Modality]: ModalityDefinitions[ModalityDefinitions.SMT],
    [SearchOption.ModalityProfile]: ModalityDefinitions[ModalityDefinitions.SMT]
  },
  captureRequestOptions: {
    [CaptureRequestOption.ImageFormat]: ImageFormat.PNG
  }
}

export interface CameraConfiguration {
  useDiscreteCommandMode: boolean
  defaultSpeed: number
  panSpeed: number
  tiltSpeed: number
  stepCount: number
  stepTime: number
}

export const ptzCameraConfiguration: CameraConfiguration = {
  useDiscreteCommandMode: false, // true/discrete - means that each press of a key is executed as a sequence of:
  //                            start command -> let run for: stepCount * stepTime, at Speed -> stop command
  //                            false/continuous - means that the the press of a key executes start command and the command
  //                            is left to run until the respective stop command is explicitly issued

  // Specify the optional values if wanting to use values different than the following defaults:
  //
  // stepCount = 1    (discrete command mode only)
  // stepTime = 500   (discrete command mode only)
  // defaultSpeed = 0 (lowest; specified as a percentage between 0/lowest to 100/highest)
  // panSpeed = 0     (lowest; specified as a percentage between 0/lowest to 100/highest)
  // tiltSpeed = 0    (lowest; specified as a percentage between 0/lowest to 100/highest)
  defaultSpeed: 0,
  panSpeed: 0,
  tiltSpeed: 0,
  stepCount: 1,
  stepTime: 500
}
