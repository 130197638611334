import { Flex, Spinner } from '@chakra-ui/react'
import { FC } from 'react'

type LoadingOverlayProps = {
  isLoading: boolean
  text?: string
}

const LoadingOverlay: FC<LoadingOverlayProps> = ({ isLoading, text }: LoadingOverlayProps) => {
  if (!isLoading) {
    return null
  }

  return (
    <Flex
      align="center"
      justify="center"
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      backgroundColor="rgba(0, 0, 0, 0.5)"
      zIndex={9999}
    >
      <Flex direction="column" align="center" p={4} bgColor="white" borderRadius="md">
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        {text && <p>{text}</p>}
      </Flex>
    </Flex>
  )
}

export default LoadingOverlay
