import { JFDValues } from '@le2/jfd'

import RecordImages from './RecordImages'

/**
 * Similar to RecordResponse but with some attributes mapped to objects.
 */
export interface Record {
  id: number
  recordNum: string
  biographics: JFDValues
  enrollmentStatus: RecordEnrollmentStatus
  images: RecordImages
  createdAt: Date
  updatedAt: Date
  isActive: boolean
  isSealed: boolean
  name: string
}

export enum RecordEnrollmentStatus {
  NEW = 'NEW',
  WIP = 'WIP',
  COMPLETE = 'COMPLETE'
}
