import { useDisclosure } from '@chakra-ui/react'
import { BookingPrintField, JFD } from '@le2/jfd'

/* TODO When having correct record types, replace it*/
import React, { useCallback } from 'react'
import { useReactToPrint } from 'react-to-print'

import agencyConfig from '../../../lib/agencyConfig'
import { PAGE_SIZE } from '../../../lib/api/constants'
import { Record } from '../../../lib/api/record'
import { LogRecordEventInput, RecordEventAction, useLogRecordEvent } from '../../audit/hooks'
import useGetCardCredentials from '../../cards/hooks/useGetCardCredentials'
import FullRecordReport from './FullRecordReport'
import IdCardReport from './IdCardReport'
import PrintSelectionModal from './PrintSelectionModal'
import SummaryRecordReport from './SummaryRecordReport'

export enum RecordReportType {
  Summary = 'Summary',
  Full = 'Full',
  IdCard = 'IdCard'
}

interface RecordPrintReportProps {
  records: Record[]
  jfd: JFD
  isOpen: boolean
  onOpen: () => void
  onClose: () => void
}

export default function RecordPrintReport(props: RecordPrintReportProps) {
  const [recordReportType, setRecordReportType] = React.useState<RecordReportType>(RecordReportType.Summary)
  const [downloadPercent, setDownloadPercent] = React.useState<number>(0)
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false)
  const [hasError, setHasError] = React.useState<boolean>(false)
  const [canLoadReport, setCanLoadReport] = React.useState<boolean>(false)
  const [onPrintIdName, setOnPrintIdName] = React.useState<string>('')
  const { isOpen: isOpenIdCardOptions, onOpen: onOpenIdCardOptions, onClose: onCloseIdCardOptions } = useDisclosure()

  const { credentials } = useGetCardCredentials(1, PAGE_SIZE, {}, { useErrorAlert: true })

  const componentRef = React.useRef<HTMLDivElement>(null)

  const printFields = getReportFields(agencyConfig.features.records?.printFields, recordReportType)

  const handleLogRecordEvent = useLogRecordEvent()

  const onPrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setCanLoadReport(false)
      setIsDownloading(false)
      setDownloadPercent(0)
      setRecordReportType(RecordReportType.Summary)
      props.onClose()
      // Logs print event
      props.records.forEach((record) => {
        const input: LogRecordEventInput = {
          action: RecordEventAction.PRINT,
          recordNum: record.recordNum,
          metadata: {
            recordReportType: recordReportType
          }
        }
        handleLogRecordEvent(input)
      })
    }
  })

  const onChangeDownloadPercent = useCallback((percent: number) => {
    setDownloadPercent(percent)
  }, [])

  React.useEffect(() => {
    if (downloadPercent >= 100) {
      setTimeout(() => {
        onPrint()
      }, 300) // This timeout gives time load the images into the DOM.
    }
  }, [downloadPercent, onPrint])

  function onClose() {
    setDownloadPercent(0)
    setCanLoadReport(false)
    setIsDownloading(false)
    setRecordReportType(RecordReportType.Summary)
    props.onClose()
  }
  const onFailed = useCallback(() => {
    setDownloadPercent(0)
    setCanLoadReport(false)
    setHasError(true)
  }, [])

  function onRetry() {
    setCanLoadReport(true)
    setHasError(false)
  }

  function onConfirm() {
    setCanLoadReport(true)
    if (recordReportType === RecordReportType.IdCard) {
      onOpenIdCardOptions()
      onClose()
    }
  }

  return (
    <>
      {recordReportType === RecordReportType.Summary && canLoadReport && (
        <SummaryRecordReport
          printFields={printFields}
          onChangeProgress={onChangeDownloadPercent}
          jfd={props.jfd}
          componentRef={componentRef}
          records={props.records}
          onFailed={onFailed}
        />
      )}
      {recordReportType === RecordReportType.Full && canLoadReport && (
        <FullRecordReport
          printFields={printFields}
          onChangeProgress={onChangeDownloadPercent}
          jfd={props.jfd}
          componentRef={componentRef}
          records={props.records}
          onFailed={onFailed}
        />
      )}

      <IdCardReport
        isOpen={isOpenIdCardOptions}
        onClose={onCloseIdCardOptions}
        onOpenPrintOptions={props.onOpen}
        onPrintIdName={onPrintIdName}
      />

      <PrintSelectionModal
        isOpen={props.isOpen}
        onRetry={onRetry}
        onClose={onClose}
        onConfirm={onConfirm}
        recordReportType={recordReportType}
        setRecordReportType={setRecordReportType}
        isLoading={isDownloading}
        setOnPrintIdName={setOnPrintIdName}
        credentials={credentials}
        setIsLoading={setIsDownloading}
        downloadedPercent={downloadPercent}
        hasError={hasError}
      />
    </>
  )
}

function getReportFields(fields: BookingPrintField[], recordReportType: RecordReportType): string[] {
  return fields.reduce((reportFields, field) => {
    if (typeof field === 'string') {
      reportFields.push(field)
    } else if (recordReportType === RecordReportType.Full && field.full) {
      reportFields.push(field.field)
    } else if (recordReportType === RecordReportType.Summary && field.summary) {
      reportFields.push(field.field)
    } else if (recordReportType === RecordReportType.IdCard && field.idcard) {
      reportFields.push(field.field)
    }

    return reportFields
  }, [] as string[])
}
