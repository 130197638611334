import { Text, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'

import { Booking } from '../../../lib/api/booking'
import { isDummyBooking } from '../utils'

export default function ImportedLineupMessage({ booking }: { booking?: Booking | null }) {
  const theme = useTheme()

  if (!isDummyBooking(booking)) {
    return null
  }

  return (
    <Text
      as="span"
      color="secondary"
      borderRadius="4px"
      fontWeight={400}
      fontSize="sm"
      bgColor={transparentize('secondary', 0.1)(theme)}
      p={2}
      ml={2}
    >
      Imported image
    </Text>
  )
}
