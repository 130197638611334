import { useQuery } from '@apollo/client'

import { GET_DEPARTMENTS } from '../../../apollo/Users'
import { LE2Departments } from '../types'

export default function useGetAllDepartments() {
  const { data, loading, error } = useQuery<LE2Departments>(GET_DEPARTMENTS, {})

  return {
    loading,
    error,
    departments: data?.departments || []
  }
}
