import { ApolloClient, ApolloLink, createHttpLink, fromPromise } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'

import auth from '../services/auth'
import { cache } from './Cache'

const httpLink = createHttpLink({
  uri: `/graphql`,
  credentials: 'include'
})

const checkResponseLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext()
    const { response: fetchResponse } = context

    // Check if the response headers indicate a JSON content type
    if (!fetchResponse || !fetchResponse.headers.get('content-type').includes('application/json')) {
      throw new Error(`Invalid JSON response: ${fetchResponse.statusText}`)
    }

    return response
  })
})

const refreshHandler = (function refreshHandler() {
  let isRefreshing = false
  let pendingRequests: Function[] = []
  return {
    addPendingRequest(pendingRequest: Function) {
      pendingRequests.push(pendingRequest)
    },
    resolvePendingRequests() {
      pendingRequests.map((callback) => callback())
      pendingRequests = []
    },
    setIsRefreshing(value: boolean) {
      isRefreshing = value
    },
    getIsRefreshing() {
      return isRefreshing
    }
  }
})()

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const definitions = operation.query.definitions
  let operationType = ''
  definitions.forEach((definitionNode) => {
    if (definitionNode.kind === 'OperationDefinition') {
      operationType = definitionNode.operation
    }
  })

  if (graphQLErrors) {
    graphQLErrors.forEach((err) => {
      if (operationType === 'query') {
        console.error(`[GraphQL Query Error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`)
      } else if (operationType === 'mutation') {
        console.error(
          `[GraphQL Mutation Error]: Message: ${err.message}, Location: ${err.locations}, Path: ${err.path}`
        )
      }

      switch (err.message) {
        case 'jwt expired':
        case 'Authentication Header required for request':
          if (!refreshHandler.getIsRefreshing()) {
            refreshHandler.setIsRefreshing(true)
            return fromPromise(
              //get new token
              auth
                .refreshToken()
                .then((refreshResponse) => {
                  const oldHeaders = operation.getContext().headers
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      Authorization: `Bearer ${auth.getToken()}`
                    }
                  })
                  // Retry the request
                  return forward(operation)
                })
                .catch(() => {
                  refreshHandler.setIsRefreshing(false)
                })
            ).flatMap(() => {
              refreshHandler.resolvePendingRequests()
              refreshHandler.setIsRefreshing(false)
              return forward(operation)
            })
          } else {
            return fromPromise(
              new Promise((resolve) => {
                refreshHandler.addPendingRequest(() => resolve(true))
              })
            ).flatMap(() => {
              const oldHeaders = operation.getContext().headers
              operation.setContext({
                headers: {
                  ...oldHeaders,
                  Authorization: `Bearer ${auth.getToken()}`
                }
              })
              return forward(operation)
            })
          }
        case 'EBTSTransferError':
        case 'JFDValidationError':
        case 'EBTSGenerationError':
          // Print error message extensions to console
          console.error('Error when processing node(s):', err.path, err.extensions)
      }
    })
  }

  // Handle network errors
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
    // Additional logic to handle network errors, if necessary
  }
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from in memory storage
  const token = auth.getToken()
  if (token) {
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      }
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(checkResponseLink).concat(httpLink),
  cache,
  connectToDevTools: true
})

export default client
