const BASE64_MARKER = ';base64,'
const DATA_MARKER = 'data:'

export enum ImageMimeType {
  PNG = 'image/png',
  JPEG = 'image/jpeg',
  BMP = 'image/bmp'
}

export function getMIMETypeFromDataURI(dataURI: string): string | undefined {
  return dataURI.split(DATA_MARKER)[1]?.split(BASE64_MARKER)[0]
}

export function convertB64DataToDataURI(base64Data: string, MIMEType: string): string {
  return `data:${MIMEType}${BASE64_MARKER}${base64Data}`
}

export function convertDataURIToB64Data(dataURI: string): string {
  return dataURI.split(BASE64_MARKER)[1]
}

export function convertB64DataToBinary(base64Data: string): Uint8Array {
  return new Uint8Array(Buffer.from(base64Data, 'base64'))
}

export function convertDataURIToBinary(dataURI: string): Uint8Array {
  const base64Data = convertDataURIToB64Data(dataURI)
  return convertB64DataToBinary(base64Data)
}

export function convertBinaryToB64Data(binary: ArrayBuffer): string {
  return Buffer.from(binary).toString('base64')
}

export function convertBinaryToDataURI(binary: ArrayBuffer, MIMEType: string): string {
  const base64Data = convertBinaryToB64Data(binary)
  return convertB64DataToDataURI(base64Data, MIMEType)
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

export interface PixelCrop {
  x: number
  y: number
  width: number
  height: number
}

export async function resizeImage(imageSource: CanvasImageSource, newWidth: number, newHeight: number) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (ctx == null) {
    console.log('resizeImage() could not create canvas')
    return
  }

  canvas.width = newWidth
  canvas.height = newHeight

  ctx.drawImage(imageSource, 0, 0, newWidth, newHeight)
  const data = ctx.getImageData(0, 0, newWidth, newHeight)
  ctx.putImageData(data, newWidth, newHeight)

  return canvas.toDataURL('image/jpeg')
}

export function convertImage(imageSrc: string, imageMimeType: ImageMimeType) {
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!canvas || !ctx) {
    console.log('convertImage() could not create canvas')
    return
  }

  const image = new Image()
  image.src = imageSrc

  canvas.width = image.naturalWidth
  canvas.height = image.naturalHeight

  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight)
  const data = ctx.getImageData(0, 0, image.naturalWidth, image.naturalHeight)
  ctx.putImageData(data, image.naturalWidth, image.naturalHeight)

  return canvas.toDataURL(imageMimeType)
}

// gets a cropped image from the passed image source string
export async function getCroppedImgFromImage(imageSrc: string, pixelCrop: PixelCrop, rotation = 0) {
  let image = new Image()
  image.src = imageSrc
  await image.decode()

  let croppedImage = await getCroppedImgFromCanvasSource(image, pixelCrop, rotation)

  return croppedImage
}

// gets a cropped image from the passed canvas image source
export async function getCroppedImgFromCanvasSource(
  imageSource: CanvasImageSource,
  pixelCrop: PixelCrop,
  rotation = 0
) {
  const imageWidth =
    typeof imageSource.width === 'number' ? imageSource.width : (imageSource.width as SVGAnimatedLength).baseVal.value
  const imageHeight =
    typeof imageSource.height === 'number'
      ? imageSource.height
      : (imageSource.height as SVGAnimatedLength).baseVal.value

  if (!document) {
    console.log("getCroppedImageFromCanvasSource() could not find `document`, are you sure you're on a website?")
    return
  }

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!canvas || !ctx) {
    console.log('getCroppedImageFromCanvasSource() could not create canvas')
    return
  }

  const maxSize = Math.max(imageWidth, imageHeight)
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2)
  ctx.rotate(getRadianAngle(rotation))
  ctx.translate(-safeArea / 2, -safeArea / 2)

  // draw rotated image and store data.
  ctx.drawImage(imageSource, safeArea / 2 - imageWidth * 0.5, safeArea / 2 - imageHeight * 0.5)
  const data = ctx.getImageData(0, 0, safeArea, safeArea)

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + imageWidth * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + imageHeight * 0.5 - pixelCrop.y)
  )

  // As Base64 string
  return canvas.toDataURL('image/jpeg')
}

// from https://stackoverflow.com/a/55257089
export function convertBase64ToBlob(base64Image: string) {
  // Split into two parts
  const parts = base64Image.split(';base64,')

  // Hold the content type
  const imageType = parts[0].split(':')[1]

  return new Blob([convertDataURIToBinary(base64Image)], { type: imageType })
}
