import { JFDValues } from '@le2/jfd'

import { useGetRecord } from '../../../lib/api/record'
import { intersection } from '../../../utils/resourceUtils'
import { Status } from '../interfaces'
import { recordState } from '../state'
import { onlyMandatoryCheckbox } from '../utils'
import { useHandleRecordError } from './useHandleRecordError'

/**
 * Query the given record from the API and load the result into `recordState`.
 * @param recordNum
 */
export function useGetRecordToState(recordNum: string) {
  const handleRecordError = useHandleRecordError()

  return useGetRecord(recordNum, (record) => {
    recordState.jfd.resetFields()
    recordState.recordNumber = record.recordNum
    recordState.jfd.recordNumber = record.recordNum

    // Load another record, clear the capture devices from the state

    try {
      recordState.jfd.setValues(record.biographics)
    } catch (e) {
      // changed the second parameter to <e as Error> because the IDE
      // was complaining "Argument of type 'unknown' is not assignable to parameter of type 'Error'.ts(2345)"
      // when the call was just handleRecordError(record, e)
      handleRecordError(record, e as Error)

      // set the valid fields to the JFD so that we can display them in the UI
      const validFields = intersection(record.biographics, recordState.jfd.getValues())
      console.warn('Setting only valid fields:', validFields)
      recordState.jfd.setValues(validFields as JFDValues)
    }

    // call onInit after setValues allowing layout to be adjusted based on content
    recordState.jfd.onInit()

    recordState.originalJFD.values = recordState.jfd.getValues()
    recordState.originalJFD.errors = recordState.jfd.getErrors()
    recordState.enrollmentStatus = record.enrollmentStatus
    recordState.createdAt = new Date(record.createdAt)
    recordState.updatedAt = new Date(record.updatedAt)
    recordState.submittedAt = undefined
    recordState.isActive = record.isActive
    recordState.hasSaveButtonBeenClicked = false
    recordState.saveStatus = Status.Idle
    recordState.submitStatus = Status.Idle
    recordState.recordImages = record.images
    recordState.mappedRecord = record
    recordState.isSealed = record.isSealed
    recordState.isOnlyMandatory = onlyMandatoryCheckbox
  })
}
