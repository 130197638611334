import BookingImage, { BookingImageMetadata } from './BookingImage'

/**
 * This class keeps track of all the images that might be deleted.
 */
export default class BookingImagesToBeDeleted {
  bookingImagesIdsOriginal: number[]
  bookingImages: BookingImage<BookingImageMetadata>[]

  constructor(bookingImagesIdsOriginal: number[], bookingImages: BookingImage<BookingImageMetadata>[] = []) {
    this.bookingImagesIdsOriginal = bookingImagesIdsOriginal
    this.bookingImages = bookingImages
  }

  /**
   * If the image received is not in the bookingImagesIdsOriginal array, this image is not
   * added to the bookingImages array, and the Image.delete method is executed to remove
   * the image from the database.
   * Otherwise, if the image received is in the bookingImagesIdsOriginal array, this image is
   * added to the bookingImages array to be removed later.
   */
  add(bookingImage: BookingImage<BookingImageMetadata>) {
    if (this.isBookingImageLinked(bookingImage.capturedAt.getTime())) {
      this.bookingImages.push(bookingImage)
    } else {
      bookingImage.image.delete()
    }
  }

  /**
   * It executes the Image.delete method of all the images in the bookingImages array.
   * It requires the updated bookingImagesIdsOriginal.
   * It empties the bookingImages array.
   */
  deleteAll(bookingImagesIdsOriginal: number[]) {
    this.bookingImages.forEach((bookingImage) => {
      bookingImage.image.delete()
    })
    this.bookingImages = []
    this.bookingImagesIdsOriginal = bookingImagesIdsOriginal
  }

  /**
   * It returns a boolean that indicates if the image exists in the bookingImagesIdsOriginal array
   */
  isBookingImageLinked(bookingImageId: number): boolean {
    return this.bookingImagesIdsOriginal.findIndex((imageId) => imageId === bookingImageId) !== -1
  }
}
