import { HStack, StackDivider } from '@chakra-ui/react'

export default function ResourceDetails({ children }: { children: React.ReactNode }) {
  return (
    <HStack
      color="gray.700"
      textStyle="paragraph"
      divider={<StackDivider borderColor="gray.500" height={6} alignSelf="center" />}
    >
      {children}
    </HStack>
  )
}
