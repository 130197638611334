import { JFD, JFDFieldDef, JFLGroup, getAllFieldNames } from '@le2/jfd'
import React from 'react'

import { CHECKBOX_ARRAY_IGNORE } from './CheckboxArray'

export type MapFormValue = (newValues: any, fieldName: string, value: any, jfdDef: JFDFieldDef) => void

//Be aware that when this resolver is set,
//RHF's own validation functionality is turned off
export function jfdResolver(jfd: JFD, forceRender: React.DispatchWithoutAction, mapFormValue?: MapFormValue) {
  return (values: any, context: any, v: any) => {
    // Here we pass removeExtraOccurrences=false
    //
    // This is needed to cover <JFLMultiOccurrence>: since we don't use RHF's
    // fieldArray, and we only show one record at a time, RHF doesn't have
    // all the occurrences for these fields, only the current one.
    //
    // We handle the extra occurrences by calling directly JFD.removeFields()
    // in that component when the user clicks on the "delete" button.
    jfd.setValues(getJFDValues(jfd, values, mapFormValue), false, false)

    // Force re-render for JFDForm so that useEffect() runs and sets values
    // from JFD to RHF. Note that this doesn't completely re-render everything
    // since JFDFormField instances are memoized in some cases
    forceRender()

    return { values, errors: getRHFErrors(jfd) }
  }
}

/**
 * Convert RHF values for JFD
 */
function getJFDValues(jfd: JFD, formValues: Record<string, any>, mapFormValue?: MapFormValue) {
  const newValues: any = {}

  Object.entries(formValues).forEach(([fieldName, value]) => {
    // ignore internal values
    if (fieldName.startsWith(CHECKBOX_ARRAY_IGNORE)) {
      return
    }

    const jfdDef = jfd.getFieldDef(fieldName)
    // Custom map for form values, if it's included.
    if (mapFormValue) {
      mapFormValue(newValues, fieldName, value, jfdDef)
    } else {
      // Default map for form values
      if (jfdDef.getType() === 'MONS') {
        // remove duplicated name
        const mValue = value as Record<string, string>[]
        newValues[fieldName] = mValue.map((e) => e[fieldName])
      } else {
        newValues[fieldName] = value
      }
    }
  })

  return newValues
}

function getRHFErrors(jfd: JFD) {
  const errors = jfd.getErrors()
  const result: Record<string, any> = {}

  Object.entries(errors).forEach(([fieldName, error]) => {
    const jfdDef = jfd.getFieldDef(fieldName)
    if (jfdDef.getType() === 'MONS') {
      const monsErrors = error as string[]
      result[fieldName] = monsErrors.map((err) => ({ [fieldName]: err }))
    } else {
      result[fieldName] = error
    }
  })

  return result
}

/**
 * Set values from JFD to RHF
 */
export function setJFDValue(setValue: (name: string, value: any, options?: any) => void) {
  // todo clean dirty status
  const opts = { shouldDirty: false }

  return function ([fieldName, value, index]: [string, any, number | undefined]) {
    if (index === undefined) {
      // single occurrence fields
      setValue(fieldName, value, opts)
    } else {
      // multiple occurrence fields
      const [field, subfield] = fieldName.split('.')
      if (subfield) {
        // has subfields
        setValue(`${field}[${index}].${subfield}`, value, opts)
      } else {
        // no subfields
        // note that we use `field` twice since RHF doesn't support flat arrays:
        // https://react-hook-form.com/api#useFieldArray
        setValue(`${field}[${index}].${field}`, value, opts)
      }
    }
  }
}

/**
 * Get the name to use in a <input>:
 *
 * - for single occurrence fields: return the original field name
 *   e.g. "field" or "field.subfield"
 *
 * - for multiple occurrences fields
 *   - with subfields: return something like: "field[index].subfield"
 *   - with no subfields: return something like: "field[index].field"
 *     (note the duplicated "field")
 */
export function getFieldName(jfdField: JFDFieldDef, fieldName: string, index: number | undefined) {
  if (index === undefined) {
    return fieldName
  } else {
    if (jfdField.getType() === 'MONS') {
      return `${fieldName}[${index}].${fieldName}`
    } else {
      const [field, subfield] = fieldName.split('.')
      return `${field}[${index}].${subfield}`
    }
  }
}

/**
 * Returns a function that can be used to filter groups that would be empty
 * if `onlyMandatory` is set to `true`.
 */
export function isEmptyGroup(jfd: JFD, onlyMandatory: boolean, group: JFLGroup): boolean {
  const filtered = getAllFieldNames(group).filter((fieldDesc) => {
    const fieldDef = jfd.getFieldDef(fieldDesc.name)
    return shouldRenderField(fieldDef, onlyMandatory)
  })

  return filtered.length === 0
}

export function shouldRenderField(fieldDef: JFDFieldDef, onlyMandatory: boolean) {
  if (fieldDef.isApplicable()) {
    return !onlyMandatory || fieldDef.required
  } else {
    return false
  }
}

export type ErrorCountProvider = (group: JFLGroup) => number

export interface JFLGroupRefProvider {
  get: (group: JFLGroup) => HTMLElement | null
  set: (group: JFLGroup, el: HTMLElement | null) => void
}

export class JFLGroupRefProviderImpl implements JFLGroupRefProvider {
  private jflGroupRefs: Map<string, HTMLElement | null>

  constructor() {
    this.jflGroupRefs = new Map()
  }

  get(jflGroup: JFLGroup) {
    return this.jflGroupRefs.get(jflGroup.label || '') || null
  }

  set(jflGroup: JFLGroup, el: HTMLElement | null) {
    this.jflGroupRefs.set(jflGroup.label || '', el)
  }
}
