import { useEffect, useState } from 'react'
import { useSnapshot } from 'valtio'

import { bookingState } from '../../pages/booking/state'
import { HubStatus, IWResult } from '../IWNativeExtDeviceDefines'
import { nativeExtensionsState } from '../nativeextState'

const captureStationInfoRef = 'CaptureStationInfo'

export function useCaptureStationInfo() {
  const [isLoaded, setIsLoaded] = useState(false)
  const snap = useSnapshot(nativeExtensionsState)
  const hubStatus = snap.deviceHubStatus

  /**
   * Callback after getting the capture station info from NativeExts.
   */
  function setCaptureStationInfo(iwResult: IWResult, resultList: Map<string, string> | null) {
    try {
      if (iwResult.IsSuccessful() && resultList) {
        if (resultList) {
          const captureStationInfo = JSON.parse(resultList.get('CaptureStationInfo') || '')

          // Verifies if there is any capture station info field included on the agency JFD.
          if (bookingState.jfd.hasFieldDef(captureStationInfoRef) && captureStationInfo) {
            // Extract each capture station info's subfield and set its value inside the JFD.
            Object.entries(captureStationInfo).forEach(([subfield, value]) => {
              // Always sets string values on the JFD.
              const fieldValue = typeof value === 'string' ? value : String(value)
              // Sets the subfield in the JFD.
              bookingState.jfd.setValue(`${captureStationInfoRef}.${subfield}`, fieldValue)
            })

            // Runs every process on the onInit callback, and sets as loaded.
            bookingState.jfd.onLoadCaptureStationInfo()
          }

          setIsLoaded(true)
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        console.warn(err.message)
      } else {
        console.warn('Unable to set the capture station info on the JFD. Please check the fields.')
      }
    }
  }

  useEffect(() => {
    // Gets if the CaptureStationInfo JFD field is available.
    const hasCaptureStationInfoField = bookingState.jfd.hasFieldDef('CaptureStationInfo')

    // Once the app connected to NativeExts, it gets the local station info.
    if (hubStatus === HubStatus.Connected || hubStatus === HubStatus.Reconnected) {
      // Continues getting the capture station info to NativeExts if the corresponding JFD field is available on
      // the agency JFD.
      if (hasCaptureStationInfoField) {
        snap.deviceManager?.GetConfigurationOptions([captureStationInfoRef], setCaptureStationInfo)
        setIsLoaded(true)
      } else {
        // Skips the process and marks it as loaded.
        setIsLoaded(true)
      }
    }
  }, [hubStatus, snap.deviceManager])

  return isLoaded
}
