import { BookingActionSubscriber } from '../../App'
import { cache } from '../../apollo/Cache'
import { Booking } from '../../lib/api/booking'

export function useLineupSubscribeToBookingActions(): BookingActionSubscriber {
  const deleteLineupsCache = (): boolean => {
    try {
      return cache.modify({
        fields: {
          // remove cached lineups, so that the Lineups list re-fetches
          // data from the server
          lineups(_, { DELETE }) {
            return DELETE
          }
        }
      })
    } catch {}
    return false
  }

  /**
   * Callback to use in the subscriber to booking operations for the Seal action
   * Not declaring the optional parameters for now, since we don't really need them; we're only updating the cache
   */
  function handleSealBookingSubscription() {
    deleteLineupsCache()
  }

  /**
   * Callback to use in the subscriber to booking operations for the Update action
   * Not declaring the optional parameters for now, since we don't really need them; we're only updating the cache
   */
  function handleUpdateBookingSubscription(booking?: Booking) {
    deleteLineupsCache()
  }

  /**
   * Callback to use in the subscriber to booking operations for the Delete action
   * Not declaring the optional parameters for now, since we don't really need them; we're only updating the cache
   */
  function handleDeleteBookingSubscription() {
    deleteLineupsCache()
  }

  /**
   * Callback to use in the subscriber to booking operations for the Activate action
   * Not declaring the optional parameters for now, since we don't really need them; we're only updating the cache
   */
  function handleActivateBookingSubscription() {
    deleteLineupsCache()
  }

  return {
    onUpdate: handleUpdateBookingSubscription,
    onSeal: handleSealBookingSubscription,
    onDelete: handleDeleteBookingSubscription,
    onActivate: handleActivateBookingSubscription
  }
}
