import { Flex, Heading, Icon, Text, useTheme } from '@chakra-ui/react'
import { transparentize } from '@chakra-ui/theme-tools'
import { JFLGroup } from '@le2/jfd'
import { ExclamationCircleOutline } from 'heroicons-react'

import { useJFD } from './jfd-context'

export interface GroupHeaderProps {
  group: JFLGroup
}

export function GroupHeader(props: GroupHeaderProps) {
  if (!props.group.label) {
    return null
  }

  return (
    <Flex justifyContent="space-between" alignItems="flex-end">
      <Flex>
        <Heading
          as="h4"
          color="gray.700"
          textStyle="subtitle"
          textTransform="uppercase"
          sx={props.group.options?.headerStyles}
        >
          {props.group.label}
        </Heading>
        <ErrorsIndicator group={props.group} />
      </Flex>
    </Flex>
  )
}

function ErrorsIndicator({ group }: { group: JFLGroup }) {
  // Note, at first glance looks like the `errorCount` won't be updated when
  // it changes because it's not tied to any React state.
  //
  // However, we (ab)use the fact that we re-render the form whenever something
  // changes in the form (via the RHF resolver), which means that this component
  // will be re-rendered all the time.
  const { errorCountProvider } = useJFD()
  const errorCount = errorCountProvider ? errorCountProvider(group) : 0
  const theme = useTheme()

  if (errorCount === 0) {
    return null
  }

  let label
  if (errorCount === 1) {
    label = `${errorCount} missing field`
  } else {
    label = `${errorCount} missing fields`
  }

  return (
    <Flex ml={2} px={2} py={1} alignItems="center" borderRadius="4px" bgColor={transparentize('red.500', 0.05)(theme)}>
      <Icon color="red.500" as={ExclamationCircleOutline} />
      <Text as="span" ml={2} fontSize={12} fontWeight={500}>
        {label}
      </Text>
    </Flex>
  )
}
