import { Permission } from '@le2/permissions'
import { useEffect } from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSnapshot } from 'valtio'

import auth from '../services/auth'
import { authUserState } from '../utils/usersUtils'
import PageNotFound from './PageNotFound'
import { usePermissionVerifier } from './PermissionWrapper'
import { LoadingContentPage } from './StatusContentPage'

interface PrivateRouteProps extends RouteProps {
  permissions: Permission[]
  children: JSX.Element
}

export default function PrivateRoute({ children, ...props }: PrivateRouteProps) {
  let snap = useSnapshot(auth.state)
  const { isLoaded: isLoadedAuthDataSnap } = useSnapshot(authUserState)

  //When visiting a protected route, kickoff refresh on invalid/expired token
  useEffect(() => {
    const refreshAccessToken = async () => {
      if (!auth.isAuthorized()) {
        await auth.refreshToken()
      }
    }

    refreshAccessToken()
  })

  return (
    <>
      <Route
        {...props}
        render={() => (
          <AuthRouteContent
            isLoadedAuthData={isLoadedAuthDataSnap}
            loggedIn={snap.loggedIn}
            refreshTokenFailed={snap.refreshTokenFailed}
            {...props}
          >
            {children}
          </AuthRouteContent>
        )}
      />
    </>
  )
}

interface AuthRouteContentProps extends PrivateRouteProps {
  loggedIn: boolean
  refreshTokenFailed: boolean
  isLoadedAuthData: boolean
}

function AuthRouteContent({
  children,
  loggedIn,
  refreshTokenFailed,
  permissions,
  isLoadedAuthData
}: AuthRouteContentProps) {
  const grantAccess = usePermissionVerifier(permissions)

  if (isLoadedAuthData && !grantAccess) {
    return <PageNotFound />
  }

  if (!isLoadedAuthData && refreshTokenFailed) {
    return <Redirect to={{ pathname: '/login' }} />
  }

  if (loggedIn && grantAccess) {
    return children
  }

  return <LoadingContentPage />
}
