import { createContext } from 'react'
import { useSnapshot } from 'valtio'

import { nativeExtensionsState } from '../nativeExtension/nativeextState'

// Declare an empty context initially; we'll fill set the values when first used by the NativeExtensionsContextProvider
export const NativeExtensionsContext = createContext<any>({})

export interface NativeExtensionsContextProviderProps {
  children: React.ReactNode
}

export const NativeExtensionsContextProvider = ({ children }: NativeExtensionsContextProviderProps) => {
  // TODO: add other native extensions options eventually, e.g. deviceManagerHubStatus
  const {
    deviceManager: deviceManagerSnap,
    deviceHubStatus: deviceHubStatusSnap,
    fpCardPrintHubStatus: fpCardHubStatusSnap,
    fpCardPrintManager: fPCardPrintManagerSnap,
    printManager: printManagerSnap,
    printHubStatus: printHubStatusSnap
  } = useSnapshot(nativeExtensionsState)

  return (
    <NativeExtensionsContext.Provider
      value={{
        deviceManager: deviceManagerSnap,
        deviceHubStatus: deviceHubStatusSnap,
        fpCardPrintHubStatus: fpCardHubStatusSnap,
        fpCardPrintManager: fPCardPrintManagerSnap,
        printManager: printManagerSnap,
        printHubStatus: printHubStatusSnap
        /*TODO once implemented: <, other_native_exts_properties> */
      }}
    >
      {children}
    </NativeExtensionsContext.Provider>
  )
}
