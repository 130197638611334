export function handleLE2Error(error: any, history: any, toast: Function) {
  let message
  if (error?.message.indexOf('Unique constraint') > 0) message = 'Username/Email already exists'
  if (error?.message.indexOf('Cannot read property') >= 0) message = 'An unknown error occurred'
  if (error?.message.indexOf('Validation error') >= 0) message = 'One or more fields have errors'
  if (
    error?.networkError?.statusCode === 401 ||
    error?.response?.status === 401 ||
    error?.networkError?.response?.status === 401
  ) {
    history.push({ pathname: '/login', state: { from: history?.location?.pathname } })
  } else {
    if (!message && (0 < error?.message.indexOf('not successful') || error?.networkError?.statusCode === 400)) {
      // Message not set by any of the checks above but there's an error nonetheless;
      // sometimes we see these with Apollo when a field value is undefined or otherwise there's a type mismatch
      // So, let's just set a generic error message
      message = 'An error occurred while performing this operation'
    }
    if (!message) {
      message = 'An unexpected error ocurred'
    }
    if (message) {
      toast({
        title: 'An error has occurred.',
        description: message,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top-right'
      })
    }
  }
}
