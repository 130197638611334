import { Image as JFDImage, Modality, ModalityBioTypeMap } from '@le2/jfd'
import isEqual from 'lodash/isEqual'
import some from 'lodash/some'

import { IWImage } from '../../../nativeExtension/IWNativeExtDeviceDefines'
import { Status } from '../../../pages/booking/interfaces'
import { convertDataURIToB64Data } from '../../imageUtils'
import BioType, { SMTBioTypes, faceCaptureBioTypes } from './BioType'
import BookingImage, { BookingImageGroupMetadata, BookingImageMetadata, GroupDateTimeMetadata } from './BookingImage'
import BookingImagesToBeDeleted from './BookingImagesToBeDeleted'
import { ImageResponse } from './utils'

export enum BookingTabModality {
  FaceCapture = 'FaceCapture',
  SMT = 'SMT'
}

/**
 * If this class is used inside of Valtio, the methods that modify
 * the state (methods that ADD or DELETE something) must be used with the real state.
 * They should not be used with a snapshot.
 * The methods that ONLY GET information can be used with either the real
 * state or a snapshot.
 */

export default class BookingImages {
  bookingImagesIdsOriginal: number[]
  bookingImages: BookingImage<BookingImageMetadata>[]
  tabs: BookingImageTabs[]
  bookingImagesToBeDeleted: BookingImagesToBeDeleted

  constructor(bookingImages: BookingImage<BookingImageMetadata>[]) {
    this.bookingImages = bookingImages
    this.bookingImagesIdsOriginal = bookingImages.map(({ capturedAt }) => capturedAt.getTime())
    this.tabs = []
    this.bookingImagesToBeDeleted = new BookingImagesToBeDeleted(this.bookingImagesIdsOriginal)
  }

  static createFromAPIResponse(imageMetadata: ImageResponse[]): BookingImages {
    const mappedImages = imageMetadata.map((img) => {
      const bookingImage = new BookingImage(
        `/v1/images/${img.imageId}`,
        BioType[img.bioType as keyof typeof BioType],
        BookingImages.getImageMetadata(img),
        undefined,
        img.capturedAt
      )
      bookingImage.image.id = img.imageId
      bookingImage.image.isURL = true
      return bookingImage
    })

    const result = new BookingImages(mappedImages)

    result.getGroupsByBioTypes(faceCaptureBioTypes).forEach((date) => {
      result.addTab(BookingTabModality.FaceCapture, date)
    })
    result.getGroupsByBioTypes(SMTBioTypes).forEach((date) => {
      result.addTab(BookingTabModality.SMT, date)
    })

    return result
  }

  private static getImageMetadata(img: ImageResponse): BookingImageMetadata {
    const meta = img.metadata as unknown as BookingImageMetadata
    if (img.metadata.groupDateTime) {
      // Assume the image metadata has the necessary fields
      return { ...meta, groupDateTime: new Date(img.metadata.groupDateTime) }
    } else {
      return { ...meta, quality: img.metadata.quality }
    }
  }

  /**
   * It returns an array of the unique groupDateTimes that are in the
   * booking images. It only loops over the booking images of the bioTypes
   * passed as parameters.
   */
  getGroupsByBioTypes(bioTypes: BioType[]): Date[] {
    const dateTimeSet = this.bookingImages.reduce((acc, bookingImage) => {
      const groupDateTimeMetadata = bookingImage.metadata as GroupDateTimeMetadata
      if (bioTypes.includes(bookingImage.bioType) && groupDateTimeMetadata.groupDateTime) {
        acc.add(groupDateTimeMetadata.groupDateTime.toISOString())
      }
      return acc
    }, new Set<string>())
    return Array.from(dateTimeSet).map((strDatetime) => new Date(strDatetime))
  }

  /**
   * It returns a map of booking images filtered by GroupDateTime where
   * the key is the bioType and the value is a booking image.
   * If the groupDateTime has many images of the same bioType, those images will be
   * replaced by the other keeping the last one. A map can not have repeated keys.
   */
  getMappedImagesByGroupDateTime(groupDateTime: Date): Map<BioType, BookingImage<BookingImageGroupMetadata>> {
    return this.bookingImages.reduce((acc, bookingImage) => {
      if ((bookingImage.metadata as GroupDateTimeMetadata).groupDateTime?.getTime() === groupDateTime.getTime()) {
        acc.set(bookingImage.bioType, bookingImage as BookingImage<BookingImageGroupMetadata>)
      }
      return acc
    }, new Map<BioType, BookingImage<BookingImageGroupMetadata>>())
  }

  /**
   * It returns a map of booking images filtered by BioType where
   * the key is the bioType and the value is a booking image.
   * If there are many images of the same bioType, those images will be
   * replaced by the other keeping the last one. A map can not have repeated keys.
   */
  getMappedImagesByBioType(bioType: BioType[]): Map<BioType, BookingImage<BookingImageMetadata>> {
    return this.bookingImages.reduce((acc, bookingImage) => {
      if (bioType.includes(bookingImage.bioType)) {
        acc.set(bookingImage.bioType, bookingImage as BookingImage<BookingImageMetadata>)
      }
      return acc
    }, new Map<BioType, BookingImage<BookingImageMetadata>>())
  }

  /**
   * It returns an array of booking images filtered by GroupDateTime.
   * If there is no any booking image, the array is empty.
   */
  getImagesByGroupDateTime(groupDateTime: Date): BookingImage<BookingImageGroupMetadata>[] {
    return this.bookingImages.filter((bookingImage) => {
      return (bookingImage.metadata as GroupDateTimeMetadata).groupDateTime?.getTime() === groupDateTime.getTime()
    }) as BookingImage<BookingImageGroupMetadata>[]
  }

  /**
   * It returns a booking image if it was found, otherwise it returns undefined.
   */
  getImage(capturedAt: Date): BookingImage<BookingImageMetadata> | undefined {
    return this.bookingImages.find((bookingImage) => bookingImage.capturedAt.getTime() === capturedAt.getTime())
  }

  /**
   * It returns an array with all the information of booking images needed
   * to perform the request.
   * If one image is not uploaded or its id is undefined, the image is not
   * included in the array.
   * If there is no any booking image, the array is empty.
   */
  // TODO Add imageWSQ to getAllImages()
  getAllImages(): EnrollBookingImageInfo[] {
    return this.bookingImages
      .filter(({ image }) => image.isUploaded && image.id)
      .map(({ image, bioType, capturedAt, metadata }) => ({ imageId: image.id!, bioType, capturedAt, metadata }))
  }

  /**
   * It returns an array of IWImage. If there is no any booking image, the array is empty.
   * The type IWImage is required by the NativeExtensions.
   */
  getIWImagesByBioType(bioTypes: BioType[]): IWImage[] {
    const bookingImages = this.bookingImages.filter((bookingImage) => bioTypes.includes(bookingImage.bioType))
    return bookingImages.map(({ image, bioType }) => {
      const imageData = convertDataURIToB64Data(image.src)
      const metaData = { CaptureImageType: bioType }
      return { imageData, metaData }
    })
  }

  /**
   * Get images to generate an EBTS file
   */
  getAllImagesEBTS(): BookingImage<BookingImageMetadata>[] {
    // Only return uploaded images that have a valid image ID
    return this.bookingImages.filter(({ image }) => image.isUploaded && image.id)
  }

  getJFDImages(): JFDImage[] {
    return this.bookingImages.map((bookingImage) => {
      // Only use the groupDateTime image metadata
      let groupId
      if ('groupDateTime' in bookingImage.metadata) {
        groupId = bookingImage.metadata?.groupDateTime?.getTime() + ''
      } else {
        groupId = ''
      }

      return {
        bioType: bookingImage.bioType,
        imageId: bookingImage.image.id!,
        groupId,
        metadata: bookingImage.metadata as unknown as any
      } as JFDImage
    })
  }

  /**
   * It returns an array of BookingImageTabs.
   * If there is no found any tab, the array is empty.
   */
  getTabs(modality: BookingTabModality): BookingImageTabs[] {
    return this.tabs.filter((tab) => tab.modality === modality)
  }

  /**
   * It returns an array of BookingImages filtered by BioTypes.
   * If there is no found any booking image, the array is empty.
   */
  getImagesByBioType(bioTypes: BioType[]): BookingImage<BookingImageMetadata>[] {
    return this.bookingImages.filter((bookingImage) => bioTypes.includes(bookingImage.bioType))
  }

  /**
   *
   * It returns an array of BookingImages filtered by Modalities
   *  If there is no found any booking image, the array is empty
   */
  getImagesByModality(modalites: Modality[]): BookingImage<BookingImageMetadata>[] {
    let modalitiesBioTypes: BioType[] = []

    modalites.forEach((modality) => {
      const bioTypes = ModalityBioTypeMap[modality]
      modalitiesBioTypes = modalitiesBioTypes.concat(bioTypes)
    })

    return this.getImagesByBioType(modalitiesBioTypes)
  }

  /**
   * It returns the status of the images that belogs to the bioTypes
   * passed as parameter.
   * If one image is uploading, it returns Status.Loading.
   * If for one image the uploading proccess has failed, and there is no image
   * uploading, it returns Status.Error.
   * If all images were upload properly and the save button has not been
   * clicked, it returns Status.Idle.
   * If all images were upload properly and the save button has been
   * clicked, it returns Status.Complete.
   */
  getImagesStatusByBioTypes(bioTypes: BioType[], hasSaveButtonBeenClicked: boolean): Status {
    let error = false
    for (const bookingImage of this.bookingImages) {
      if (!bioTypes.includes(bookingImage.bioType)) {
        continue
      }
      if (bookingImage.image?.isUploading) {
        return Status.Loading
      }
      if (bookingImage.image?.hasUploadFailed) {
        error = true
      }
    }
    if (error) {
      return Status.Error
    }

    return hasSaveButtonBeenClicked ? Status.Complete : Status.Idle
  }

  isUploading(modality: Modality) {
    return some(this.getImagesByModality([modality]), (e) => e.image.isUploading)
  }

  hasUploadError(modality: Modality) {
    return some(this.getImagesByModality([modality]), (e) => e.image.hasUploadFailed)
  }

  getLatestFaceFront() {
    return this.getLatestImage(BioType.FaceFront)
  }

  getLatestImage(bioType: BioType) {
    const images = this.getImagesByBioType([bioType]) //
      .sort((imgA, imgB) => {
        if (imgA.capturedAt < imgB.capturedAt) {
          return 1
        } else if (imgA.capturedAt > imgB.capturedAt) {
          return -1
        } else {
          return 0
        }
      })

    if (images.length > 0) {
      return images[0]
    } else {
      return null
    }
  }

  getImageById(imageId: string) {
    return this.bookingImages.find((b) => b.image.id === imageId)
  }

  /**
   * It creates a booking image and adds it to the bookingImages array.
   * It also uploads this image to the server.
   * In addition, it cancels or deletes (if the image is uploaded) the booking image that satisfies the
   * condition of the replaceCallback parameter.
   */
  setImage(
    src: string,
    bioType: BioType,
    metadata: BookingImageMetadata,
    wsqSrc?: string,
    replaceCallback?: (
      bioType: BioType,
      metadata: BookingImageMetadata,
      bookingImage: BookingImage<BookingImageMetadata>
    ) => boolean
  ): BookingImage<BookingImageMetadata> {
    if (replaceCallback) {
      const prevBookingImageIndex = this.bookingImages.findIndex((bookingImage) =>
        replaceCallback(bioType, metadata, bookingImage)
      )
      if (prevBookingImageIndex !== -1) {
        this.bookingImagesToBeDeleted.add(this.bookingImages[prevBookingImageIndex])
        this.bookingImages.splice(prevBookingImageIndex, 1)
      }
    }
    const newImage = new BookingImage(src, bioType, metadata, wsqSrc)
    const length = this.bookingImages.push(newImage)

    const newImageFromState = this.bookingImages[length - 1]
    newImageFromState.image.upload().then()

    // return the image from state since it's wrapped by valtio and we can
    // perform operations on it
    return newImageFromState
  }

  /**
   * It creates a booking image in the position of the image to be replaced.
   * It also uploads this image to the server.
   * In addition, it deletes the image to be replaced.
   */
  replaceImage(
    bookingImage: BookingImage<BookingImageMetadata>,
    src: string,
    bioType: BioType,
    metadata: BookingImageMetadata,
    wsqSrc?: string
  ): BookingImage<BookingImageMetadata> {
    const newImage = new BookingImage(src, bioType, metadata, wsqSrc)

    const prevBookingImageIndex = this.bookingImages.findIndex((image) => image === bookingImage)
    this.bookingImagesToBeDeleted.add(this.bookingImages[prevBookingImageIndex])
    this.bookingImages.splice(prevBookingImageIndex, 1, newImage)

    const newImageFromState = this.bookingImages[prevBookingImageIndex]
    newImageFromState.image.upload().then()

    return newImageFromState
  }

  /**
   * It adds a new booking image tab to the array.
   */
  addTab(modality: BookingTabModality, groupDateTime?: Date, smtType?: string | null): void {
    this.tabs.push({ modality, groupDateTime: groupDateTime ? groupDateTime : new Date(), smtType })
  }

  /**
   * It deletes all the booking images that belong to the groupDateTime parameter
   * from the bookingImages array. It cancels all the image requests of this group if
   * there is one or deletes all the images of this group from the server if uploaded.
   * In addition, it deletes the tab that belongs to this groupDateTime from the array.
   */
  deleteGroup(bioTypes: BioType[], groupDateTime: Date): void {
    const bookingImages = this.bookingImages.filter((bookingImage) => {
      if (bioTypes.includes(bookingImage.bioType)) {
        if ((bookingImage.metadata as GroupDateTimeMetadata).groupDateTime.getTime() === groupDateTime.getTime()) {
          this.bookingImagesToBeDeleted.add(bookingImage)
          return false
        }
      }
      return true
    })
    const tabs = this.tabs.filter((tab) => tab.groupDateTime.getTime() !== groupDateTime.getTime())
    this.bookingImages = bookingImages
    this.tabs = tabs
  }

  /**
   * It deletes the booking image by capturedAt from the bookingImages array.
   * It cancels the image request of the image if there is one or deletes the image
   * from the server if uploaded.
   */
  deleteImage(capturedAt: Date): void {
    const index = this.bookingImages.findIndex(
      (bookingImage) => bookingImage.capturedAt.getTime() === capturedAt.getTime()
    )
    if (index !== -1) {
      const bookingImage = this.bookingImages[index]
      this.bookingImagesToBeDeleted.add(bookingImage)
      this.bookingImages = this.bookingImages.filter(
        (bookingImage) => bookingImage.capturedAt.getTime() !== capturedAt.getTime()
      )
    }
  }

  /**
   * It deletes the booking images by bioTypes from the bookingImages array.
   * It cancels the image request of the image if there is one or deletes the image
   * from the server if uploaded.
   */
  deleteImagesByBioType(bioTypes: BioType[]): void {
    this.bookingImages = this.bookingImages.filter((bookingImage) => {
      if (bioTypes.includes(bookingImage.bioType)) {
        this.bookingImagesToBeDeleted.add(bookingImage)
        return false
      }
      return true
    })
  }

  /**
   * It returns a boolean that indicates if the state is dirty.
   * The method checks whether the bookingImagesIdsOriginal array and
   * the bookingImagesIds array are equal or not to know if the state is dirty.
   */
  isDirty(): boolean {
    const bookingImagesId = this.bookingImages.map(({ capturedAt }) => capturedAt.getTime())

    return !isEqual(this.bookingImagesIdsOriginal, bookingImagesId)
  }

  /**
   * It synchronizes the new bookingImagesIds with the bookingImagesIdsOriginal array, and
   * it calls the BookingImagesToBeDeleted.deleteAll method to remove all the discarted images.
   */
  applyChanges(): void {
    this.bookingImagesIdsOriginal = this.bookingImages.map(({ capturedAt }) => capturedAt.getTime())
    this.bookingImagesToBeDeleted.deleteAll(this.bookingImagesIdsOriginal)
  }

  setImagesMetadata() {
    this.bookingImages.forEach((img) => img.setMetadata())
  }
}

export function replaceImgByGroupDateTimeBioType(
  bioType: BioType,
  metadata: BookingImageMetadata,
  bookingImage: BookingImage<BookingImageMetadata>
): boolean {
  return (
    bookingImage.bioType === bioType &&
    (bookingImage.metadata as GroupDateTimeMetadata).groupDateTime.getTime() ===
      (metadata as GroupDateTimeMetadata).groupDateTime.getTime()
  )
}

export function replaceImgByBioType(
  bioType: BioType,
  metadata: BookingImageMetadata,
  bookingImage: BookingImage<BookingImageMetadata>
): boolean {
  return bookingImage.bioType === bioType
}

export interface EnrollBookingImageInfo {
  imageId: string
  bioType: BioType
  capturedAt: Date
  metadata: BookingImageMetadata
}

export interface BookingImageTabs {
  modality: BookingTabModality
  groupDateTime: Date
  smtType?: string | null
}
